import { FormFieldsGroup, FormItemType, IFormField } from '../dynamicForm/DynamicForm';
import _ from 'lodash';

type ModalAction = 'create' | 'edit';
type ModalType =
    | 'project'
    | 'template'
    | 'renter'
    | 'contact'
    | 'business_account'
    | 'instance'
    | 'custom_field'
    | 'document_template'
    | 'pricing_scheme'
    | 'subrent_shipping'
    | 'product'
    | 'kit'
    | 'product_variants'
    | 'product_instances'
    | 'location'
    | 'vehicle'
    | 'transportation'
    | 'profession'
    | 'crew_member'
    | 'offer';

export type ModalName = `${ModalAction}-${ModalType}`;

export type ModalActionData = {
    [Key in ModalName]?: string;
};

export const createHiddenField = <T extends string>(id: T, opts?: Partial<IFormField>) => {
    return {
        label: '',
        id: id,
        type: FormItemType.Hidden,
        ...opts,
    };
};

export const formFieldsGroupsWithFilteredFields = (
    formFieldsGroups: FormFieldsGroup[],
    filteredFormIds: string[][],
    hideCustomFields: boolean = true
) => {
    const hiddenFields: FormFieldsGroup['fields'] = [];

    formFieldsGroups.forEach((group, groupIndex) => {
        group.fields = group.fields.filter((field) => {
            let fieldForHideExists = false;
            if (!filteredFormIds.some((idGroup) => idGroup.includes(field.id))) {
                if (field.id.includes('customField_')) {
                    if (hideCustomFields) {
                        fieldForHideExists = true;
                        field.type = FormItemType.Hidden;
                        hiddenFields.push(field);
                    }
                } else {
                    fieldForHideExists = true;
                    field.type = FormItemType.Hidden;
                    hiddenFields.push(field);
                }
            }
            return !fieldForHideExists;
        });
    });

    formFieldsGroups.unshift({
        fields: hiddenFields,
    });

    return formFieldsGroups;
};

export const isErrorValidationFieldHidden = (errorsObject: object, filterIds?: string[][]): boolean => {
    if (filterIds == null) {
        return false;
    }

    const flattenedFilterIds = _.flatten(filterIds);
    const errorIds = Object.keys(errorsObject);

    const idsFromThisDisplayMode: string[] = [];
    const idsFromAnotherDisplayMode: string[] = [];

    errorIds.forEach((id) => {
        if (flattenedFilterIds.includes(id)) {
            idsFromThisDisplayMode.push(id);
        } else {
            idsFromAnotherDisplayMode.push(id);
        }
    });

    if (idsFromThisDisplayMode.length > 0) {
        return false;
    } else {
        return idsFromAnotherDisplayMode.length > 0;
    }
};
