import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { KitMemberObjRead } from '../../../../../server';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getStateFromPath2 } from '../../../../../shared/util/utils';
import lodash from 'lodash';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'components/LOAD_ENTITIES',
    LOAD_EDIT_ENTITIES: 'components/LOAD_EDIT_ENTITIES',
    RESET: 'components/RESET',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'lastActivityDate' as string | undefined,
    sortOrder: 'DESC' as 'DESC' | 'ASC' | undefined,
    page: 1,
    limit: 10,
};

const initialState = {
    loading: true,
    loadingError: null,
    entities: null as Array<KitMemberObjRead> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type ComponentsState = Readonly<typeof initialState>;

// Reducer

export default (state: ComponentsState = initialState, action): ComponentsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);
            if (/\/\d+\/inventory\/kits\/[0-9]/.test(pathName)) {
                /*if(params.finalTotalPrice){
                    const finalTotalPrice = params.finalTotalPrice.toString().split(',').map((item) => +item);
                    if(''+finalTotalPrice === ''+initialParamsState.finalTotalPrice) params.finalTotalPrice = undefined;
                    else params.finalTotalPrice = finalTotalPrice;
                }*/
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loading: false,
                entities: action.payload,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const loadComponents = (gridComponents) => {
    return (dispatch, getState) => {
        const params = getState().components.params;
        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: getGrid(gridComponents, params),
        });
    };
};
const getGrid = async (gridComponents, params) => {
    let newGrid = [...gridComponents];

    if (params.search) {
        newGrid = newGrid.filter((item) => item.productShortName.toLowerCase().includes(params.search.toLowerCase()));
    }

    newGrid = await lodash.sortBy(newGrid, params.sortBy);

    if (params.sortOrder === 'ASC') {
        newGrid.reverse();
    }

    newGrid = newGrid.slice(
        (params.page - 1) * (params.limit ? params.limit : 10),
        (params.page - 1) * (params.limit ? params.limit : 10) + params.limit
    );

    return newGrid;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
