import { ActionCreatorWithoutPayload, AsyncThunkAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { GetEntityByIdArgs } from '../types/api';
import { EntityGridURLParams, ListParams, URLDrawerParams } from '../types/params';
import _ from 'lodash';
import { ParamsUtils } from '../../../../core/utils/paramsUtils';
import { PageURLParamDescriptionObject } from '../../../../core/utils/descriptions';
import { EntityGridName } from '../types/grid';
import { entityGridMainParamsDescription } from '../data/paramsDescription';
import { usePageURLParams } from '../../../../core/hooks/urlParams/usePageURLParams';
import { PageUrlParamsObject } from '../../../../core/hooks/urlParams/types';

export type EntityLoadActions<
    EntityInfoRead extends {
        id: number;
    }
> = {
    loadEntity: (arg: GetEntityByIdArgs) => AsyncThunkAction<AxiosResponse<EntityInfoRead>, GetEntityByIdArgs, any>;
    clearEntity: ActionCreatorWithoutPayload;
};

export const useQueryParamsWithoutModalParams = <EntityParams extends object>(
    params: EntityGridURLParams<EntityParams>
): Extract<typeof params, URLDrawerParams> => {
    return useMemo(() => {
        const queryParams = _.cloneDeep(params);
        delete queryParams.id;
        delete queryParams.modal;
        delete queryParams.view;
        return queryParams;
    }, [params]);
};

export const useLoadEntityFromParamsEffect = <
    PageParams extends { id?: number },
    EntityInfoRead extends {
        id: number;
    }
>(
    pageParams: PageParams,
    entity: EntityInfoRead | null,
    { loadEntity, clearEntity }: EntityLoadActions<EntityInfoRead>
) => {
    const dispatch = useAppDispatch();
    const { businessAccountId } = useAppSelector((state) => state.system);
    const location = useLocation();

    useEffect(() => {
        const queryParams: URLDrawerParams = ParamsUtils.getParamsFromSearchString(location.search);
        const isEdit = queryParams?.modal === 'edit';
        const isCopy = queryParams?.modal === 'copy';
        if (isEdit || isCopy) {
            if (entity == null && pageParams.id) {
                dispatch(
                    loadEntity({
                        businessAccountId: businessAccountId,
                        entityId: pageParams.id,
                    })
                );
            }
        } else if (entity != null && !pageParams.id) {
            dispatch(clearEntity());
        }
    }, [businessAccountId, dispatch, location, pageParams.id, entity, loadEntity, clearEntity]);
};

export const usePageURLParamsFromDescription = <PageParams extends object>(args: {
    pageParamsDescription: PageURLParamDescriptionObject<PageParams>;
    gridName: EntityGridName;
    initialValues?: Partial<PageParams & ListParams>;
    skipLocationChange?: boolean;
    outerParams?: (keyof PageParams)[];
    hiddenParams?: string[];
}): PageUrlParamsObject<EntityGridURLParams<PageParams>> => {
    const { pageParamsDescription, gridName, skipLocationChange, outerParams, hiddenParams, initialValues } = args;

    const pageParamsProps = useMemo(
        () => ({
            paramsDescription: {
                ...pageParamsDescription,
                ...entityGridMainParamsDescription,
            } as PageURLParamDescriptionObject<EntityGridURLParams<PageParams>>,
            pageName: gridName,
            initialValues,
            skip: skipLocationChange,
            outerParams,
            hiddenParams,
        }),
        [pageParamsDescription, gridName, initialValues, skipLocationChange, outerParams, hiddenParams]
    );

    return usePageURLParams(pageParamsProps) as PageUrlParamsObject<EntityGridURLParams<PageParams>>;
};
