import { TaxBaseTypeCodeEnum } from '../../server';

export class MoneyUtils {
    /**
     * Рубли переводим в копейки / Доллары в центы
     * От этого избавляемся
     * @param value
     */
    static toCents(value: number): number {
        return Math.round(value * 100);
    }
    static toWhole(value: number): number {
        return Math.round(value / 100);
    }

    /**
     * Расчет итоговой стоимости экземпляра в копейках, с учетом скидки
     * (Сначала считаем итоговую стоимость экземпляра - берем цену и скидку применяем, затем это округляем в копейки и сохраняем в округленном виде -> целое число копеек finalInstancePrice.)
     * https://git-new.council.ru/rr/frontend/-/issues/1555
     * @param price цена в копейках
     * @param discount скидка в процентах
     */
    static calculateFinalInstancePrice(price: number, discount: number): number {
        return Math.round((price * (100 - discount)) / 100);
    }

    /**
     * Расчет стоимости обязательства в копейках
     * (Затем finalInstancePrice умножаем на число экземпляров и число смен, округляем к копейкам и сохраняем как -> целое число копеек finalTotalPrice.)
     * https://git-new.council.ru/rr/frontend/-/issues/1555
     * @param finalInstancePrice цена в копейках
     * @param instanceCount Количество экземпляров, целое
     * @param shiftCount Количество смен, дробное
     */
    static calculateFinalTotalPrice(finalInstancePrice: number, instanceCount: number, shiftCount: number): number {
        return Math.round(finalInstancePrice * instanceCount * shiftCount);
    }

    /**
     * Расчитываем скидку, исходя из цены экз-ра и стоимости экз-ра
     * @param pricePerShift цена экземпляра за смену в копейках
     * @param finalInstancePrice стоимость экземпляра за смену в копейках
     */
    static calculateInstanceDiscount(pricePerShift: number, finalInstancePrice: number): number {
        return 100 - (finalInstancePrice * 100) / pricePerShift;
    }

    /**
     * Расчет стоимости с налогом
     * https://git-new.council.ru/rr/frontend/-/issues/1590
     * @param costBeforeTaxes Стоимость в копейках до налогов
     * @param taxRate Налог в процентах
     * @param baseType Код типа используемой базы для вычисления налога
     */
    static calculatePriceWithTaxes(costBeforeTaxes: number, taxRate: number, baseType: TaxBaseTypeCodeEnum): number {
        if (taxRate === 0) return costBeforeTaxes;

        if (baseType === TaxBaseTypeCodeEnum.COSTBEFORETAX) {
            return Math.round(((100 + taxRate) / 100) * costBeforeTaxes);
        } else if (baseType === TaxBaseTypeCodeEnum.SUMAFTERTAX) {
            return costBeforeTaxes + Math.round((taxRate / (100 - taxRate)) * costBeforeTaxes);
        } else {
            throw new Error(`Unknown TaxBaseTypeCodeEnum value: ${baseType}`);
        }
    }
}
