import React, {useEffect, useMemo, useState} from 'react';
import {CustomRangeCalendar} from '../../../../../../components/datePicker/CustomRangeCalendar';
import moment from "moment";
import {InstanceRecord, NomenclatureEntityTypeCodeEnum, TimetableTypeCodeEnum} from "../../../../../../server";
import {Spin} from "../../../../../../components";
import {useAppDispatch, useAppSelector} from "../../../../../../store/hooks";
import {NomenclatureOnInterval, loadNomenclaturesOnIntervalsIfNeed, getDateIntervalsForCalendar} from "../../../utils/nomenclaturesUtils";
import {updateAnyTimetables} from "../../../reducers/operationForm.reducer";
import {InstanceState} from "../../product-instances-select/ProductInstancesSelect";
import {CustomRangeCalendarAccessibilityCustomFooter} from "../../../../../../components/datePicker/CustomRangeCalendarAccessibilityCustomFooter/CustomRangeCalendarAccessibilityCustomFooter";

interface InstanceCalendarPopoverContentProps {
    startDate: number;
    endDate: number;
    nomenclature: InstanceRecord;
    getInstancesDataFn: (instances: InstanceRecord[]) => InstanceState[];
}

const disabledDate = (current) => {
    return true;
};

/**
 * Календарь доступности для экземпляра
 * При открытии показывается доступность со списка,
 * и начинается загрузка: дата обязательства, с начала года - 1 год, дата окончания обязательства на конец года + 1 год.
 * При следуещем открытии покажуться предыдущие данные и начнется загрузка.
 * Если ошибка, то будет сообщение об ошибке.
 * @param from
 * @param until
 * @param instanceId
 * @param intervals
 * @constructor
 */
export const InstanceCalendarPopoverContent = ({startDate, endDate, nomenclature, getInstancesDataFn}: InstanceCalendarPopoverContentProps) => {
    const dispatch = useAppDispatch();
    const timetables = useAppSelector((state) => state.operationForm.timeTables);
    const operationFormState = useAppSelector((state) => state.operationForm);
    const [isLoading, setIsLoading] = useState(false);
    const [from, until] = getDateIntervalsForCalendar(startDate, endDate);

    useEffect(() => {
        const n:NomenclatureOnInterval = {id: nomenclature.id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until};
        setIsLoading(true);
        loadNomenclaturesOnIntervalsIfNeed([n]).then((data) => {
            dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            //showNotification('error', 'Не удалось загрузить доступность');
            setIsLoading(false);
        });
    }, [from, until, nomenclature.id, dispatch]);

    const timeTable = timetables.find((tt) => tt.id === nomenclature.id && tt.type === NomenclatureEntityTypeCodeEnum.INSTANCE);
    const intervals = useMemo(() => {
        const data = getInstancesDataFn([timeTable?.nomenclature as InstanceRecord || nomenclature]);
        return data[0].intervals;
    }, [timeTable, nomenclature, operationFormState]);

    return (
        <Spin spinning={isLoading} delay={50}>
            <CustomRangeCalendar
                className={'rr-range-calendar-without-selection'}
                selectedValue={[moment(startDate), moment(endDate)]}
                disabledDate={disabledDate}
                mode={['date', 'date']}
                intervals={intervals}
                typeCode={TimetableTypeCodeEnum.AVAILABLE}
                hasOwnShiftLength={false}
                dontShowBadges={true}
                customFooter={
                    <CustomRangeCalendarAccessibilityCustomFooter
                        productId={nomenclature.productId}
                        kitId={undefined}
                        variantId={nomenclature.variantId}
                        screenLeft={startDate}
                        screenRight={endDate}
                        instanceId={nomenclature.id}
                    />
                }
            />
        </Spin>
    );
};

// TODO При листании вправо/влево подгружать еще по сколько-то (по году мб?)
//Та как этот календарь не показывается для шаблона, то вcю логику для шаблона убираем...