import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconHardHatSolid } from '../../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { Status } from '../../../../../../../components/status/status';
import { Link } from 'react-router-dom';
import { CrewPageTabsEnum } from '../../../../../../../shared/constants/tabEnums';
import { ArchiveBadge } from '../../../../../../../components/archiveBadge/archiveBadge';
import { CrewMemberInfoRead } from '../../../../../../../server';
import { DrawerUtils } from '../../../../../../../shared/util/drawerUtils';
import { crewPageUrlRoute } from '../../../../utils/tabs';

interface CrewMemberPageHeaderNavigationBlockProps {
    crewMember: CrewMemberInfoRead | undefined;
}

export const CrewMemberPageHeaderNavigationBlock: FC<CrewMemberPageHeaderNavigationBlockProps> = (props) => {
    const { crewMember } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (crewMember == null) return null;

    const { stateCode, archive, creationAuthorFullName } = crewMember;
    const name = DrawerUtils.getFullName(creationAuthorFullName);

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconHardHatSolid} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <Status entityType={'crewMember'} code={stateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${crewPageUrlRoute}`} className={'link'}>
                            Персонал
                        </Link>
                        <span>/</span>
                        <Link to={`/${businessAccountId}/${crewPageUrlRoute}/${CrewPageTabsEnum.CREW_MEMBERS}`} className={'link'}>
                            Проектные работники
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};
