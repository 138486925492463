import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../core/utils/descriptions';
import { ListExpensesFilters } from '../../api/expenses.api.types';
import { ExpenseStateCodeEnum } from '../../../../../server';

export const ExpensesPageFiltersDescription: PageURLParamDescriptionObject<ListExpensesFilters> = {
    search: PageURLParamDescriptions.searchParam(),
    stateCode: PageURLParamDescriptions.enumSelectParam(ExpenseStateCodeEnum),
    projectId: PageURLParamDescriptions.numberParam,
    hideCancelled: PageURLParamDescriptions.switchParam,
    internalFinalTotalPrice: PageURLParamDescriptions.numberInterval,
    externalFinalTotalPrice: PageURLParamDescriptions.numberInterval,
    authorId: PageURLParamDescriptions.numberParam,
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
};
