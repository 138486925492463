import React, {CSSProperties, ReactNode} from "react";
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import classNames from "classnames";
import {LocalizationEnum, useLocalize} from "../../../../core/hooks/useLocalize";
import {IconArchive, IconControlPointDuplicateBlack, IconEdit, IconEllipsisHSolid, IconLevelUp, IconPlus, IconTrash} from "../../../icons";
import {Color} from "../../../../core/types/color";
import './actionButton.less';

export type ActionButtonData<T extends string> = Record<T, ActionButtonType>;

export type ActionButtonType = ActionButtonDataRecordWithName | ActionButtonDataRecordWithLocalizationId | ActionButtonOnlyWithIcon;

interface ActionButtonDataRecordWithName {
    name: string;
    color: Color;
    icon: ReactNode;
    style?: CSSProperties;
}

interface ActionButtonDataRecordWithLocalizationId {
    localizationId: LocalizationEnum;
    color: Color;
    icon: ReactNode;
    style?: CSSProperties;
}

interface ActionButtonOnlyWithIcon {
    color: Color;
    icon: ReactNode;
    style?: CSSProperties;
}

export interface ActionButtonProps<T> {
    type: T;
    onClick?: ButtonProps['onClick'];
    style?: CSSProperties;
    disabled?: boolean;
}

export const useActionButton = () => {
    const L = useLocalize();
    return <T extends string>(props: ActionButtonProps<T> & { data: ActionButtonData<T> }) => {
        const d = props.data[props.type];
        const text = 'localizationId' in d ? L(d.localizationId) : ('name' in d ? d.name : null);
        return (
            <Button
                style={{...props.style, ...d.style}}
                title={text || undefined}
                onClick={props.onClick}
                className={classNames('rr-action-btn', `rr-action-btn--${d.color}`)}
                disabled={props.disabled}
            >
                {d.icon}
                {text}
            </Button>
        );
    };
};

export enum ActionButtonActionType {
    CREATE = 'create',
    OTHER = 'other'
}

const data: ActionButtonData<ActionButtonActionType> = {
    [ActionButtonActionType.CREATE]: {
        color: Color.Purple1,
        name: 'Создать',
        icon: <Icon component={IconPlus}/>
    },
    [ActionButtonActionType.OTHER]: {
        color: Color.Purple2,
        icon: <Icon component={IconEllipsisHSolid} style={{fontSize: 36, verticalAlign: '-0.36em'}}/>,
        style: {minWidth: 60}
    }
}

export const ActionButton = (props: ActionButtonProps<ActionButtonActionType>) => {
    return useActionButton()({...props, data});
};

export class BasicActionsData {
    static readonly editAction: ActionButtonType = {
        localizationId: LocalizationEnum.ASPECT__ACTION__EDIT,
        icon: <Icon component={IconEdit}/>,
        color: Color.Purple4,
    };
    static readonly deleteAction: ActionButtonType = {
        localizationId: LocalizationEnum.ASPECT__ACTION__DELETE,
        icon: <Icon component={IconTrash}/>,
        color: Color.Red1,
    };
    static readonly toArchiveAction: ActionButtonType = {
        localizationId: LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE,
        icon: <Icon component={IconArchive}/>,
        color: Color.Grey3,
    };
    static readonly fromArchiveAction: ActionButtonType = {
        localizationId: LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE,
        icon: <Icon component={IconLevelUp}/>,
        color: Color.Cyan,
    };
    static readonly duplicate: ActionButtonType = {
        localizationId: LocalizationEnum.ASPECT__ACTION__DUPLICATE,
        icon: <Icon component={IconControlPointDuplicateBlack}/>,
        color: Color.Green,
    };
}

/*
    <>
                                        {deleteLocation ? (
                                            <Button
                                                disabled={!location.deleteable}
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    deleteLocation(location).then(() => onClose());
                                                }}
                                            >
                                                <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                <span>Удалить</span>
                                            </Button>
                                        ) : undefined}
                                        {location.archivable && !location.archive && archiveLocation ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveLocation(location);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                        {location.archive && archiveLocation ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveLocation(location);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                    </>
 */