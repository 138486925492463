import { ListTransportationsArgs } from './transportations.api.types';
import { TransportationRecord, TransportationStateCodeEnum } from '../../../../../../server';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';

export abstract class TransportationsUtils {
    static createRequestFilters = (params: ListTransportationsArgs['params']) => {
        return ServerUtils.createRequestFilters<TransportationRecord>([
            params.stateCode ? ['stateCode', 'EQ', params.stateCode] : undefined,
            params.dateTimeOfLoading?.[0] ? ['dateTimeOfLoading', 'GE', params.dateTimeOfLoading[0].valueOf()] : undefined,
            params.dateTimeOfLoading?.[1] ? ['dateTimeOfLoading', 'LE', params.dateTimeOfLoading[1].valueOf()] : undefined,
            params.dateTimeOfUnloading?.[0] ? ['dateTimeOfUnloading', 'GE', params.dateTimeOfUnloading[0].valueOf()] : undefined,
            params.dateTimeOfUnloading?.[1] ? ['dateTimeOfUnloading', 'LE', params.dateTimeOfUnloading[1].valueOf()] : undefined,
            params.hideCancelled
                ? [
                      'stateCode',
                      'IN',
                      [TransportationStateCodeEnum.PLANNED, TransportationStateCodeEnum.INPROGRESS, TransportationStateCodeEnum.FINISHED],
                  ]
                : undefined,
            params.shipperId ? ['shipperId', 'EQ', params.shipperId] : undefined,
            params.loadingLocationId ? ['loadingLocationId', 'EQ', params.loadingLocationId] : undefined,
            params.consigneeId ? ['consigneeId', 'EQ', params.consigneeId] : undefined,
            params.unloadingLocationId ? ['unloadingLocationId', 'EQ', params.unloadingLocationId] : undefined,
            params.projectId ? ['projectId', 'EQ', params.projectId] : undefined,
            params.carId ? ['carId', 'EQ', params.carId] : undefined,
            params.trailerId ? ['trailerId', 'EQ', params.trailerId] : undefined,
            params.driverId ? ['driverIds', 'EQ', params.driverId] : undefined,
        ]);
    };
}
