import { CSSProperties } from 'react';

export const getStylesForSubrent = (isBtnSubrent: boolean, currentSubrentShipmentDelay: number) => {
    let stylesForSubrent: CSSProperties = {
        margin: '0px 5px',
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    };

    if (isBtnSubrent) {
        stylesForSubrent['border'] = '1px solid #F4516c';
        stylesForSubrent['backgroundColor'] = '#F4516c';
    } else {
        stylesForSubrent['backgroundColor'] = '#fff';
        stylesForSubrent['color'] = '#F4516c';
    }

    if (currentSubrentShipmentDelay === 0) {
        delete stylesForSubrent['backgroundColor'];
        delete stylesForSubrent['color'];
    }

    if (currentSubrentShipmentDelay) {
        stylesForSubrent['borderColor'] = '#F4516c';
    } else {
        stylesForSubrent['borderColor'] = '#d9d9d9';
    }

    return stylesForSubrent;
};
