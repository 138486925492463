import React from 'react';
import {Route} from 'react-router';
import {ModalFormNEWProps} from '../../../../../modalForm/ModalFormNEW';
import {typedMemo} from '../../../../../../core/hooks/useMemo';
import _ from 'lodash';
import {EntityLoadActions, useLoadEntityFromParamsEffect} from '../../../hooks/useParams';
import {EntityGridCreateModalWrapper, EntityGridEditModalWrapper} from '../../../types/modal';
import {usePageUrlParamsContext} from '../../context/PageUrlParamsContext';
import {EntityGridRootPath} from '../../../types/params';

interface ModalsProps<EntityInfoRead extends object> {
    create?: {
        component: EntityGridCreateModalWrapper<EntityInfoRead>;
        props?: Partial<ModalFormNEWProps>;
    };
    edit?: {
        component: EntityGridEditModalWrapper<EntityInfoRead>;
        props?: Partial<ModalFormNEWProps>;
    };
}

export interface EntityModalsProps<EntityInfoRead extends { id: number }> {
    rootPath: EntityGridRootPath;
    currentEntity: EntityInfoRead | null;
    entitiesLoading: boolean;
    modals: ModalsProps<EntityInfoRead>;
    entityLoadActions: EntityLoadActions<EntityInfoRead>;
}

export const EntityModalsComponent = <PageParams extends object, EntityInfoRead extends { id: number }>(
    props: EntityModalsProps<EntityInfoRead>
) => {
    const { rootPath, currentEntity, entitiesLoading, modals, entityLoadActions } = props;

    const { pageParams, getSearchParams } = usePageUrlParamsContext<PageParams>();
    const urlSearchParams = getSearchParams(pageParams);

    const {
        create: { component: CreateModalComponent, props: createModalComponentProps } = {},
        edit: { component: EditModalComponent, props: editModalProps } = {},
    } = modals;

    useLoadEntityFromParamsEffect(pageParams, currentEntity, entityLoadActions);

    return (
        <>
            {CreateModalComponent && pageParams.modal === 'new' && (
                <Route
                    exact
                    path={rootPath}
                    render={() => (
                        <CreateModalComponent rootPath={rootPath} urlSearchParams={urlSearchParams} {...createModalComponentProps} />
                    )}
                />
            )}
            {CreateModalComponent && !entitiesLoading && currentEntity && pageParams.modal === 'copy' && pageParams.id && (
                <Route
                    exact
                    path={rootPath}
                    render={() => (
                        <CreateModalComponent
                            rootPath={rootPath}
                            urlSearchParams={urlSearchParams}
                            entity={currentEntity}
                            {...createModalComponentProps}
                        />
                    )}
                />
            )}
            {EditModalComponent && !entitiesLoading && currentEntity && pageParams.modal === 'edit' && pageParams.id && (
                <Route exact path={rootPath} render={() => <EditModalComponent entity={currentEntity} {...editModalProps} />} />
            )}
        </>
    );
};

export const EntityModals = typedMemo(EntityModalsComponent, (prevProps, nextProps) => _.isEqual(prevProps, nextProps));
