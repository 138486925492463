import {KitInfoRead} from '../../../../../../../server';

export const useGridStatistics = (kit: KitInfoRead) => {
    let instanceCount = 0;
    let price = 0;
    let discount = 0;
    let finalTotalPrice = 0;
    let discountPercent = 0;

    kit.members.forEach((member) => {
        instanceCount += +member.instanceCount;
        price += member.pricePerShift * member.instanceCount;
        finalTotalPrice += +member.finalTotalPrice;
        discountPercent += +member.discount;
    });

    discount = price - finalTotalPrice;
    discountPercent = finalTotalPrice === 0 || price - finalTotalPrice === 0 ? 0 : 100 - (finalTotalPrice / price) * 100;

    return {
        instanceCount,
        price,
        discount,
        finalTotalPrice,
        discountPercent,
    };
};
