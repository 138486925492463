import React, { ReactNode, useState } from 'react';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { Alert, Spin } from '../../index';
import { ServerError } from '../../../server';
import { DrawerProps } from 'antd/lib/drawer';
import './EntityDrawer.less';

interface EntityDrawerHeaderProps {
    icon?: ReactNode;
    title?: ReactNode;
    code?: ReactNode;
    archive?: boolean;
    stateBadge?: ReactNode;
    actions?: ReactNode;
}

interface EntityDrawerProps extends DrawerProps {
    onClose?: () => void;
    onStartClose?: () => void;
    isFetching?: boolean;
    isLoading?: boolean;
    error?: ServerError;
    children: ReactNode;
    title?: ReactNode;
    width?: number;
    headerProps?: EntityDrawerHeaderProps;
}

const componentClassName = `rr-entity-drawer`;
const componentHeaderClassName = `${componentClassName}-header`;

/**
 * Всплывающее справа окно (Используется в списке движения инвентаря)
 * @param children Контент
 * @param onClose
 * @param onStartClose
 * @param isFetching
 * @param isLoading
 * @param error Ошибка
 * @param title Заголовок
 * @param width ширина окна, по умолчанию 800
 * @param className
 * @param headerProps
 * @param drawerProps rest props для Drawer компонента
 * @constructor
 */
export const EntityDrawer = ({
    children,
    onClose,
    onStartClose,
    isFetching,
    isLoading,
    error,
    title,
    width,
    className,
    headerProps,
    ...drawerProps
}: EntityDrawerProps) => {
    const [visible, setVisible] = useState(true);

    return (
        <Drawer
            className={classNames(componentClassName, className)}
            closable
            destroyOnClose={false}
            visible={visible}
            mask={true}
            title={
                <>
                    {!title && headerProps && (
                        <div className={componentHeaderClassName}>
                            <div className={`${componentHeaderClassName}-bg`} />
                            {headerProps?.icon && <div className={`${componentHeaderClassName}-icon`}>{headerProps.icon}</div>}
                            {headerProps?.title && <div className={`${componentHeaderClassName}-title`}>{headerProps.title}</div>}
                            {headerProps?.code && <div className={`${componentHeaderClassName}-code`}>{headerProps.code}</div>}
                            {headerProps?.archive && <div className={`${componentHeaderClassName}-archive`}>Архив</div>}
                            {headerProps?.stateBadge && <div className={`${componentHeaderClassName}-state`}>{headerProps.stateBadge}</div>}
                            {headerProps?.actions && (
                                <div className={`${componentHeaderClassName}-actions`}>
                                    <div>{headerProps.actions}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {title && !headerProps && (
                        <div
                            style={{
                                padding: '18px 22px 17px 30px',
                                borderBottom: '2px solid #ebedf2',
                            }}
                        >
                            <div className={`${componentHeaderClassName}-bg`} />
                            {title}
                        </div>
                    )}
                </>
            }
            maskClosable={true}
            width={width ?? 800}
            zIndex={100}
            placement={'right'}
            onClose={() => {
                setVisible(false);
                onStartClose?.();
            }}
            afterVisibleChange={(visible) => {
                if (!visible && onClose) onClose();
            }}
            keyboard={true}
            {...drawerProps}
        >
            <Spin spinning={isFetching || isLoading} delay={100}>
                {error && !isFetching && (
                    <div style={{ padding: 32 }}>
                        <Alert message={'Ошибка'} description={<>{error.message}</>} type="error" showIcon closable />
                    </div>
                )}
                {children}
            </Spin>
        </Drawer>
    );
};
