import React, { FC, useMemo, useState } from 'react';
import { ModalForm } from '../../../../components/v2/modalForm/modalForm';
import {
    useCreateLegalDetailsMutation,
    useGetLegalDetailsFieldsListByCountryQuery,
    useUpdateLegalDetailsMutation,
} from '../api/legalDetails.api';
import {
    CountryLegalDetailsFieldsObjRead,
    EntityTypeCodeEnum,
    LegalCountryCodeEnum,
    LegalDetailsFieldInfoWrite,
    LegalDetailsFieldTypeCodeEnum,
    LegalDetailsInfoRead,
    LegalDetailsInfoWrite,
} from '../../../../server';
import { FormItemType, IFormField } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../localization';
import { LocalizeFn, useLocalize } from '../../../../core/hooks/useLocalize';
import { legalCountriesCodes, legalCountriesNamesMap, legalDetailsFieldNamesMap, legalSubjectNamesMap } from '../data/legalDetailsData';
import { DatePickerWithManualInput } from '../../../../components/datePicker/DatePickerWithManualInput/DatePickerWithManualInput';
import { useAppSelector } from '../../../../store/hooks';
import { showNotification } from '../../../../components/notification/showNotification';
import { getServerError } from '../../../../shared/util/utils';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { datePickerFormValidatorFunction } from '../../../../components/datePicker/DatePickerWithManualInput/helpers/formValidatorFunction';
import moment from 'moment';
import { canUpdateFinancialDataSelector, canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';

export interface LegalDetailsModalProps {
    referenceEntityId: number;
    entityType: EntityTypeCodeEnum.RENTER | EntityTypeCodeEnum.BUSINESSACCOUNT;
    legalDetails?: LegalDetailsInfoRead;
    onSuccess: () => void;
    onCancel: () => void;
}

const convertFormFieldsToLegalDetailsFieldInfoWrite = (data: { [p: string]: any }, country: string) => {
    const fields: LegalDetailsFieldInfoWrite[] = [];

    Object.keys(data).forEach((key) => {
        if (data[key]) {
            if (key.indexOf(`${data[`${country}_typeCode`]}:`) === 0) {
                const name = key.substring(`${data[`${country}_typeCode`]}:`.length) as LegalDetailsFieldTypeCodeEnum;
                let value = data[key];
                if (name === LegalDetailsFieldTypeCodeEnum.BIRTHDATE && moment.isMoment(value)) {
                    value = value.format('YYYY-MM-DD');
                }
                fields.push({
                    name,
                    value,
                });
            }
        }
    });

    return fields;
};

const convertCountryLegalDetailsFieldsObjReadToFields = (
    entityType: LegalDetailsModalProps['entityType'],
    fieldsData: CountryLegalDetailsFieldsObjRead[],
    L: LocalizeFn,
    country: LegalCountryCodeEnum | undefined,
    legalDetails: LegalDetailsInfoRead | undefined,
    setCountry: (country: LegalCountryCodeEnum | undefined) => void,
    fieldsValues: {},
    setFieldsValues: (values: {}) => void,
    canViewFinancialData: boolean,
    canUpdateFinancialData: boolean
) => {
    let fields: IFormField[] = [
        {
            label: 'Наименование',
            id: 'fullName',
            type: FormItemType.String,
            defaultValue: legalDetails?.fullName,
            maxLength: 255,
            colSpan: 24,
            visible: (getFieldValue) => entityType === EntityTypeCodeEnum.BUSINESSACCOUNT,
            required: true,
        },
        {
            label: 'Страна',
            id: 'country',
            type: FormItemType.Select,
            defaultValue: country,
            values: legalCountriesCodes.map((code) => {
                return {
                    name: legalCountriesNamesMap[code],
                    value: code,
                };
            }),
            onChange: (value: LegalCountryCodeEnum, form) => {
                setCountry(value);
                // setTimeout(() => {
                //     const defaultRadio = (legalDetails?.country === value && legalDetails?.subjectType) || fieldsData[0]?.subject;
                //     console.log('XXX', defaultRadio, fieldsData, legalDetails?.country, value);
                //     form.setFieldsValue({typeCode: defaultRadio});
                // }, 100);
            },
            colSpan: 24,
            required: true,
        },
    ];

    if (fieldsData.length) {
        const v: any[] = [];
        fieldsData.forEach((item) => {
            v.push({
                name: L(legalSubjectNamesMap[item.subject]),
                value: item.subject,
            });
        });

        const defaultRadio = (legalDetails?.country === country && legalDetails?.subjectType) || v[0].value;

        fields.push({
            label: LocalizationEnum.ASPECT__FIELDS__COMMON__TYPE,
            id: `${country}_typeCode`,
            type: FormItemType.RadioGroup,
            defaultValue: defaultRadio,
            values: v,
            colSpan: 24,
            onChange: (value, form) => {
                const data = form.getFieldsValue();
                const f = {};
                Object.keys(data).forEach((key) => {
                    //key.split(':')
                    if (key.indexOf(':') > -1) {
                        f[key] = data[key];
                    }
                });
                const values = { ...fieldsValues, ...f };

                setTimeout(() => {
                    setFieldsValues(values);
                    form.setFieldsValue(values);
                    form.validateFields();
                }, 100);
            },
        });
    }

    fieldsData.forEach((item) => {
        item.fields
            .filter((field) => {
                return !field.financial || (field.financial && canViewFinancialData);
            })
            .forEach((field) => {
                const label = legalDetailsFieldNamesMap[field.name];
                const fieldId = `${item.subject}:${field.name}`;
                const infoMessage = field.mask ? `${L(LocalizationEnum.ASPECT__GLOBAL__EXAMPLE)}: ${field.mask}` : undefined;
                let formField: Partial<IFormField>;
                let otherFormFieldParams: Partial<IFormField>;

                formField = {
                    label: label,
                    id: fieldId,
                    visible: (getFieldValue) => getFieldValue(`${getFieldValue('country')}_typeCode`) === item.subject,
                    colSpan: field.maxLength && field.maxLength > 30 ? 24 : 12,
                };

                if (field.name === LegalDetailsFieldTypeCodeEnum.SEX) {
                    otherFormFieldParams = {
                        type: FormItemType.Select,
                        values: [
                            { name: L(LocalizationEnum.ASPECT__GLOBAL__SEX__MALE), value: 'M' },
                            { name: L(LocalizationEnum.ASPECT__GLOBAL__SEX__FEMALE), value: 'F' },
                        ],
                        placeholder: 'Не выбрано',
                    };
                } else if (field.name === LegalDetailsFieldTypeCodeEnum.BIRTHDATE) {
                    otherFormFieldParams = {
                        type: FormItemType.Component,
                        component: DatePickerWithManualInput,
                        validationRules: [
                            {
                                validator: datePickerFormValidatorFunction,
                            },
                        ],
                    };
                } else {
                    otherFormFieldParams = {
                        type: FormItemType.String,
                        maxLength: field.maxLength,
                        infoMessage: infoMessage,
                        validationRules: [
                            {
                                pattern: field.regex,
                                message: 'Указано недопустимое значение!',
                            },
                        ],
                        disabled: (getFieldValue) => {
                            return field.financial && canViewFinancialData && !canUpdateFinancialData;
                        },
                    };
                }

                fields.push({ ...formField, ...otherFormFieldParams } as IFormField);
            });
    });
    return fields;
};

export const LegalDetailsModal: FC<LegalDetailsModalProps> = ({ referenceEntityId, entityType, legalDetails, onSuccess, onCancel }) => {
    const L = useLocalize();
    let defaultLegalCountryCode = useAppSelector((state) => state.businessAccountPreferences.preferences?.legalCountry);
    if (legalDetails?.country) defaultLegalCountryCode = legalDetails.country;
    const [country, setCountry] = useState(defaultLegalCountryCode);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [fieldsValues, setFieldsValues] = useState({});

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const canUpdateFinancialData = useAppSelector(canUpdateFinancialDataSelector);

    const getFieldsQuery = useGetLegalDetailsFieldsListByCountryQuery(
        { businessAccountId, legalCountryTypeCode: country! },
        { skip: country === undefined }
    );
    const [createLegalDetails, createLegalDetailsData] = useCreateLegalDetailsMutation();
    const [updateLegalDetails, updateLegalDetailsData] = useUpdateLegalDetailsMutation();

    const fields = useMemo(() => {
        return getFieldsQuery.data
            ? [
                  {
                      fields: convertCountryLegalDetailsFieldsObjReadToFields(
                          entityType,
                          getFieldsQuery.data,
                          L,
                          country,
                          legalDetails,
                          setCountry,
                          fieldsValues,
                          setFieldsValues,
                          canViewFinancialData,
                          canUpdateFinancialData
                      ),
                  },
              ]
            : undefined;
    }, [getFieldsQuery.data, entityType, L, country, legalDetails, fieldsValues, canViewFinancialData, canUpdateFinancialData]);

    const initialValues = useMemo(() => {
        let initialValues: { [p: string]: any } = {};
        if (legalDetails) {
            legalDetails.fields?.forEach((field) => {
                initialValues[`${legalDetails.subjectType}:${field.name}`] = field.value;
            });
        }
        return initialValues;
    }, [legalDetails]);

    const error = getServerError(getFieldsQuery.error || createLegalDetailsData.error || updateLegalDetailsData.error);
    const isLoading = getFieldsQuery.isLoading;
    const isFetching = getFieldsQuery.isFetching || createLegalDetailsData.isLoading || updateLegalDetailsData.isLoading;
    const okButtonDisabled = !getFieldsQuery?.data || getFieldsQuery.data.length === 0;

    return (
        <ModalForm
            title={'Реквизиты'}
            cancelPreventionMessage={'Прервать редактирование?'}
            isLoading={isLoading}
            isFetching={isFetching}
            formFields={fields}
            error={error}
            onCancel={onCancel}
            initialValues={initialValues}
            okButtonDisabled={okButtonDisabled}
            formRowType={'flex'}
            onFormChange={(data) => {
                // if(data.country){
                //     const f = {};
                //     Object.keys(data).forEach((key) => {
                //         //key.split(':')
                //         if(key.indexOf(':') > -1){
                //             f[key] = data[key];
                //         }
                //     });
                //     setFieldsValues({...fieldsValues, ...f});
                // }
            }}
            onOk={async (data) => {
                const fields = convertFormFieldsToLegalDetailsFieldInfoWrite(data, country!);

                const infoWrite: LegalDetailsInfoWrite = {
                    subjectType: data[`${country}_typeCode`],
                    referenceEntityId,
                    entityType,
                    fields: fields,
                    country: country!,
                    businessVersion: legalDetails?.businessVersion,
                    fullName: data['fullName'],
                };
                try {
                    if (legalDetails && legalDetails.id) {
                        await updateLegalDetails({ businessAccountId, id: legalDetails.id, infoWrite: infoWrite }).unwrap();
                    } else {
                        await createLegalDetails({ businessAccountId, infoWrite: infoWrite }).unwrap();
                    }
                    showNotification('success', 'Реквизиты успешно сохранены');
                    onSuccess();
                } catch (error) {
                    showNotification('error', 'Реквизиты не сохранены');
                }
            }}
        />
    );
};
