import {OperationElement} from "../reducers/operationForm.reducer";
import {OperationTypeCodeEnum, RentStateCodeEnum} from "../../../../server";

/**
 * Уменьшает ли доступность
 * @param element
 */
export const getIsElementReducesAvailability = (element:OperationElement) => {
    return (
        element.stateCode === RentStateCodeEnum.BOOKED ||
        element.stateCode === RentStateCodeEnum.RENT ||
        element.stateCode === RentStateCodeEnum.RETURNED ||
        element.stateCode === RentStateCodeEnum.RETURNEDBROKEN ||
        element.stateCode === RentStateCodeEnum.LOSTDURINGRENT ||
        element.stateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER ||
        element.stateCode === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
        element.stateCode === RentStateCodeEnum.SUBRENT
    );
}

/**
 * Это операция корректировка + отмена
 * @param typeCode
 * @param targetStateCode
 */
export const getIsCorrectCancelOperation = (typeCode: OperationTypeCodeEnum|undefined, targetStateCode: RentStateCodeEnum|undefined) => {
    return typeCode === OperationTypeCodeEnum.CORRECT && targetStateCode === RentStateCodeEnum.CANCELED;
}
