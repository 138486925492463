import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../shared/reducers';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {IPageHeaderProps} from '../../../components/pageHeader/pageHeader';
import {IconClose, IconFolder, IconPlusCircle} from '../../../components/icons';
import {NewOperationModule} from './newOperationModule/NewOperationModule';
import {OperationModule} from './operationModule/OperationModule';
import './OperationFormPage.less';
import {getOperationTypeByCode, LocalizationEnum} from '../../../localization';
import {resetOperationForm} from "./reducers/operationForm.reducer";
import {isSubrentOperation} from "./utils/utils";
import {RRoundButton} from "../../../components/button/rRoundButton";
import {ConcurrentOperationsPopover} from "./concurrentOperations/concurrentOperationsPopover";
import moment from "moment";
import {ProjectTypeCodeEnum} from "../../../server";

interface IProps extends StateProps, DispatchProps, IBasePageProps {
}

class Component extends BasePage<IProps> {

    private operationModuleRef;

    componentWillUnmount() {
        super.componentWillUnmount();
        // Очищаем форму операции
        this.props.resetOperationForm();
    }

    onCancelButtonClick = async () => {
        if(this.operationModuleRef){
            this.operationModuleRef.resetOperation();
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        let {mnemoKey, isSubrentOperation, typeCode, renterId, renterShortName, projectId, projectShortName, projectTemplate, businessAccountId, intl, operationStartTime, projectType} = this.props;
        let createRequestMode = !projectId;

        return {
            name: createRequestMode ? '' : '' + getOperationTypeByCode(typeCode, intl) + (operationStartTime ? moment(operationStartTime).format(' HH:mm') : ''),//  + ' ' + this.props.mnemoKey,
            renterId: mnemoKey ? (!isSubrentOperation ? renterId : undefined) : undefined,
            renterName: mnemoKey ? (!isSubrentOperation ? renterShortName : undefined) : undefined,
            supplierId: mnemoKey ? (isSubrentOperation ? renterId : undefined) : undefined,
            supplierName: mnemoKey ? (isSubrentOperation ? renterShortName : undefined) : undefined,
            projectId: mnemoKey ? (!isSubrentOperation ? projectId : undefined) : undefined,
            projectName: mnemoKey ? (!isSubrentOperation ? projectShortName : undefined) : undefined,
            subrentId: mnemoKey ? (isSubrentOperation ? projectId : undefined) : undefined,
            subrentName: mnemoKey ? (isSubrentOperation ? projectShortName : undefined) : undefined,
            projectTemplate: mnemoKey ? projectTemplate : undefined,
            projectIsOffer: mnemoKey ? projectType === ProjectTypeCodeEnum.OFFER : undefined,
            businessAccountId: businessAccountId,
            icon: createRequestMode ? IconPlusCircle : IconFolder,
            title: intl.formatMessage({
                id: createRequestMode ? LocalizationEnum.ASPECT__MAIN_MENU__CREATE_OPERATION : LocalizationEnum.ASPECT__MAIN_MENU__OPERATION_FORM
            }),
            rightBlock: mnemoKey ? <RRoundButton
                style={{marginLeft: 40, marginRight: -22}}
                disabled={false}
                colorScheme={'danger2'}
                onClick={this.onCancelButtonClick}
                icon={IconClose}
                title={LocalizationEnum.PAGE__NEW_OPERATION__ACTIONS__DISCARD_OPERATION}
            /> : undefined,
            leftBlock: mnemoKey ? <ConcurrentOperationsPopover/> : undefined
        };
    }

    renderContent() {
        return !this.props.projectId || !this.props.typeCode || !this.props.mnemoKey ? (
            <NewOperationModule/>
        ) : (
            <OperationModule ref={(ref) => {
                if (ref) this.operationModuleRef = ref;
            }
            } location={this.props.location} />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        renterId: storeState.operationForm.renterId,
        renterShortName: storeState.operationForm.renterShortName,
        projectId: storeState.operationForm.projectId,
        projectShortName: storeState.operationForm.projectShortName,
        projectTemplate: storeState.operationForm.projectTemplate,
        typeCode: storeState.operationForm.typeCode,
        targetStateCode: storeState.operationForm.targetStateCode,
        mnemoKey: storeState.operationForm.mnemoKey,
        isSubrentOperation: storeState.operationForm.isSubrent,//isSubrentOperation(storeState.operationForm.typeCode, storeState.operationForm.targetStateCode),
        operationStartTime: storeState.operationForm.operationStartTime,
        projectType: storeState.operationForm.projectType
    };
};

const mapDispatchToProps = {resetOperationForm};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormPage = connect(mapStateToProps, mapDispatchToProps)(Component);
