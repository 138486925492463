import { ListTransportationsFilters } from '../api/transportations.api.types';
import { TransportationStateCodeEnum } from '../../../../../../server';
import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';

export const TransportationsPageFiltersDescription: PageURLParamDescriptionObject<ListTransportationsFilters> = {
    stateCode: PageURLParamDescriptions.enumSelectParam(TransportationStateCodeEnum),
    dateTimeOfLoading: PageURLParamDescriptions.dateTimeIntervals,
    dateTimeOfUnloading: PageURLParamDescriptions.dateTimeIntervals,
    hideCancelled: PageURLParamDescriptions.switchParam,
    shipperId: PageURLParamDescriptions.numberParam,
    loadingLocationId: PageURLParamDescriptions.numberParam,
    consigneeId: PageURLParamDescriptions.numberParam,
    unloadingLocationId: PageURLParamDescriptions.numberParam,
    projectId: PageURLParamDescriptions.numberParam,
    carId: PageURLParamDescriptions.numberParam,
    trailerId: PageURLParamDescriptions.numberParam,
    driverId: PageURLParamDescriptions.numberParam,
};
