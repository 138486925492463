import React from "react";
import {DatePickerModified, DatePickerModifiedProps} from "./DatePickerModified";

interface DatePickerProps extends DatePickerModifiedProps {
    align?: { points?: string[], offset?: number[] };
}

export class DatePicker extends React.PureComponent<DatePickerProps> {

    render() {
        return (
            <DatePickerModified
                {...this.props}
                allowClear={this.props.allowClear}
                showToday={false}
                {...this.props.align ? {align: this.props.align} : {align: {points: ['bl', 't'], offset: [0, -6]}}}
            />
        );
    }
}
