import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import { EntityButton } from '../../buttons/EntityButton/v1/EntityButton';

export interface SelectAndCreateProps {
    select: () => ReactNode;
    modal: (args: { close: () => void }) => ReactNode;
    disabled?: boolean;
}

export const SelectAndCreate: FC<SelectAndCreateProps> = memo(({ select, modal, disabled }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const onClose = useCallback(() => {
        setModalOpen(false);
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            {select()}
            <EntityButton
                style={{ marginLeft: 12 }}
                onClick={(e) => {
                    setModalOpen(true);
                }}
                disabled={disabled}
            />
            {modalOpen && modal({ close: onClose })}
        </div>
    );
});
