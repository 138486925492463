import {RentStateCodeEnum} from '../../../../server';
import React, {FC, memo, useEffect} from 'react';
import {Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {joinClasses} from '../../../../shared/util/utils';
import {LocalizationEnum, useLocalize} from '../../../../core/hooks/useLocalize';
import './statuses-filters.less';
import {useAppSelector} from '../../../../store/hooks';
import {subrentModuleEnabledSelector} from '../../../../shared/reducers/businessAccountPreferences.reducer';

type SelectItem = {
    name: LocalizationEnum;
    value: RentStateCodeEnum;
};

const { Option } = Select;

const arrSubrent: SelectItem[] = [
    {
        value: RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED,
    },
    {
        value: RentStateCodeEnum.SUBRENT,
        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT,
    },
    {
        value: RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER,
    },
    {
        value: RentStateCodeEnum.SUBRENTCANCELED,
        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED,
    },
    {
        value: RentStateCodeEnum.SUBRENTDRAFT,
        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT,
    },
];

const arrRent: SelectItem[] = [
    { value: RentStateCodeEnum.ORDERED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED },
    { value: RentStateCodeEnum.BOOKED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED },
    { value: RentStateCodeEnum.RENT, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT },
    { value: RentStateCodeEnum.RETURNED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED },
    { value: RentStateCodeEnum.RETURNEDBROKEN, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN },
    { value: RentStateCodeEnum.LOSTDURINGRENT, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT },
    { value: RentStateCodeEnum.CANCELED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED },
    { value: RentStateCodeEnum.DRAFT, name: LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT },
];

const arrOther: SelectItem[] = [{ value: RentStateCodeEnum.MIXED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER }];

const arrTemplate: SelectItem[] = [
    { value: RentStateCodeEnum.CANCELED, name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED },
    { value: RentStateCodeEnum.DRAFT, name: LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT },
];

export interface StatusesFilterProps extends SelectProps {
    type?: 'project' | 'projectTemplate' | 'shipping' | 'shippingTemplate' | 'all';
}

export const StatusesFilter: FC<StatusesFilterProps> = memo((props) => {
    const L = useLocalize();
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const disableInput = () => {
        const selects: any = document.querySelectorAll(`.rr-statuses-filter .ant-select-search__field`);
        for (let el of selects) {
            el.setAttribute(`maxlength`, 0);
        }
    };

    useEffect(() => {
        disableInput();
    }, []);

    let items: (SelectItem | undefined)[];

    if (props.type === 'project') {
        items = [...arrRent, undefined, ...arrOther];
    } else if (props.type === 'projectTemplate') {
        items = [...arrTemplate, undefined, ...arrOther];
    } else if (props.type === 'shipping') {
        items = [...(subrentModuleEnabled ? arrSubrent : []), ...(subrentModuleEnabled ? [undefined] : []), ...arrOther];
    } else {
        items = [
            ...arrRent,
            undefined,
            ...(subrentModuleEnabled ? arrSubrent : []),
            ...(subrentModuleEnabled ? [undefined] : []),
            ...arrOther,
        ];
    }

    return (
        <Select
            {...props}
            className={joinClasses('rr-statuses-filter', props.className)}
            style={{ maxWidth: 261, minWidth: 150 }}
            defaultActiveFirstOption={false}
            dropdownMatchSelectWidth={false}
            mode="multiple"
            showSearch={false}
            autoClearSearchValue
            allowClear={true}
            dropdownRender={(menu) => <div style={{ minWidth: 261, backgroundColor: '#fff' }}>{menu}</div>}
        >
            {items.map((item, index) => {
                return item ? (
                    <Option key={index} value={item.value} title={L(item.name)}>
                        <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                        <span className="calendar-multi-select">{L(item.name)}</span>
                    </Option>
                ) : (
                    <Option key={index} disabled={true}>
                        <div className="rr-status-filter-separator">
                            <div style={{ width: '100%', borderBottom: '1px solid #ccc' }}></div>
                        </div>
                    </Option>
                );
            })}
        </Select>
    );
});
