import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { NumberInput, NumberInputProps } from '../../../numberInputs/numberInput';
import './InlineNumberInput.less';
import { NumberInputMoney } from '../../../numberInputs';
import { NumberInputMoneyProps } from '../../../numberInputs/numberInputMoney';

interface InlineNumberInputProps {
    children: ReactNode;
    onChange: (value: any) => void;
    onEditing?: (isEditing: boolean) => void;
    value?: number;
    max?: number;
    min?: number;
    step?: number;
    canBeEdit: boolean;
    type?: 'money' | 'number';
}

export const InlineNumberInput = (props: InlineNumberInputProps) => {
    const [value, setValue] = useState<number | undefined>(props.value);
    const [editable, setEditable] = useState<boolean | undefined>(undefined);
    const [beforeEditValue, setBeforeEditValue] = useState<number | undefined>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (editable !== undefined) {
            props.onEditing?.(editable);
        }
    }, [editable]);

    const setPrevValues = useCallback(() => {
        props.onChange(beforeEditValue);
        setValue(beforeEditValue);
        setEditable(false);
    }, [beforeEditValue]);

    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === 'Escape' && editable) setPrevValues();
        }
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [editable, setPrevValues]);

    const Component: FC<NumberInputMoneyProps> | FC<NumberInputProps> = props.type === 'money' ? NumberInputMoney : NumberInput;

    return props.canBeEdit && editable ? (
        <Component
            min={props.min}
            step={props.step}
            max={props.max}
            className={'rr-input-number-inline rr-input-number rr-input-gray'}
            style={{ width: 104 }}
            onFocus={(e) => {
                let t = e.currentTarget;
                setTimeout(() => {
                    t.setSelectionRange(0, t.value.length);
                }, 50);
            }}
            onChange={(value) => {
                if (!value) value = 0;
                else if (props.max !== undefined && value > props.max) value = props.max;
                else if (props.min !== undefined && value < props.min) value = props.min;
                props.onChange(value);
            }}
            onBlur={() => {
                setEditable(false);
            }}
            autoFocus={true}
            defaultValue={value}
            onPressEnter={() => {
                props.onChange(value);
                setEditable(false);
            }}
        />
    ) : (
        <span
            className={props.canBeEdit ? 'rr-inline-editable' : undefined}
            onClick={(e) => {
                setEditable(true);
                setBeforeEditValue(value);
            }}
        >
            {props.children}
        </span>
    );
};
