import React, { FC } from 'react';
import { LegalDetailsInfoRead } from '../../../../../server';
import { Select } from 'antd';
import { ExportSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface DownloadDocumentPopoverLegalDetailsProps {
    legalDetails: LegalDetailsInfoRead[];
    selectedLegalDetailValue: number | undefined;
    setSelectedLegalDetailValue: (value: number | undefined) => void;
    documentsLocalStorage: ExportSettings;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
}

export const DownloadDocumentPopoverLegalDetails: FC<DownloadDocumentPopoverLegalDetailsProps> = (props) => {
    let { legalDetails, selectedLegalDetailValue, setSelectedLegalDetailValue, documentsLocalStorage, setDocumentsLocalStorage } = props;

    return (
        <div className={'content'}>
            <div className={'title'}>Реквизиты компании</div>
            <Select
                style={{ width: '100%' }}
                placeholder={'Выберите реквизиты'}
                className={'rr-select-gray'}
                allowClear
                value={selectedLegalDetailValue}
                defaultValue={selectedLegalDetailValue}
                onChange={(value) => {
                    setSelectedLegalDetailValue(value as number);
                    setDocumentsLocalStorage({
                        ...documentsLocalStorage,
                        legalDetail: value,
                    });
                }}
            >
                {legalDetails.map(({ id, fullName }) => (
                    <Select.Option key={id} value={id}>
                        {fullName}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
