import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    BusinessAccountPermissionCodeEnum,
    DocumentTemplateRecord,
    DocumentTemplateRecordList,
    serverApi,
    ServerError,
} from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { getServerError, getStateFromPath2 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import { columns } from '../documentTemplatesModuleListColums';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

const ACTION_TYPES = {
    LOAD_ENTITIES: 'documentTemplates/LOAD_ENTITIES',
    DELETE_ENTITIES: 'documentTemplates/DELETE_ENTITIES',
    UPLOAD_TEMPLATE: 'documentTemplates/UPLOAD_TEMPLATE',
    DOWNLOAD_TEMPLATE: 'documentTemplates/DOWNLOAD_TEMPLATE',
    RESET: 'documentTemplates/RESET',
    HIDE_SHOW: 'documentTemplates/HIDE_SHOW',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'shortName' as string | undefined,
    sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
    documentType: undefined as string | undefined,
    page: 1,
    limit: 10,
    hideStandardTemplates: undefined as boolean | undefined,
    mode: undefined as string | undefined,
    id: undefined as number | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<DocumentTemplateRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

export type DocumentTemplatesState = Readonly<typeof initialState>;

// Reducer

export default (state: DocumentTemplatesState = initialState, action): DocumentTemplatesState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp = /\/(\d+)\/settings\/documentTemplates/;

            if (regexp.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        documentType: params.documentType !== undefined ? params.documentType : initialParamsState.documentType,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideStandardTemplates:
                            params.hideStandardTemplates !== undefined
                                ? params.hideStandardTemplates
                                : initialParamsState.hideStandardTemplates,
                        mode: params.mode !== undefined ? params.mode : initialParamsState.mode,
                        id: params.id !== undefined ? +params.id : initialParamsState.id,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as DocumentTemplateRecordList).records,
                filteredCount: (action.payload.data as DocumentTemplateRecordList).listAttributes.filteredCount,
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                //loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DELETE_ENTITIES):
            //console.dir(action.payload);
            return {
                ...state,
                //loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
                //entities: (action.payload.data as DocumentTemplateRecordList).records,
                //filteredCount: (action.payload.data as DocumentTemplateRecordList).listAttributes.filteredCount
            };

        case REQUEST(ACTION_TYPES.UPLOAD_TEMPLATE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.UPLOAD_TEMPLATE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.UPLOAD_TEMPLATE):
            return {
                ...state,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.DOWNLOAD_TEMPLATE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DOWNLOAD_TEMPLATE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.DOWNLOAD_TEMPLATE):
            return {
                ...state,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: true };
        case FAILURE(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: false };
        case SUCCESS(ACTION_TYPES.HIDE_SHOW):
            return { ...state, loading: false };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: DocumentTemplatesState = (getState() as IRootState).documentTemplates;
        let params = state.params;
        const filters: string[] = [];
        if (params.hideStandardTemplates === true) filters.push('common;EQ;false');
        if (params.documentType !== undefined) filters.push(`documentType;IN;${params.documentType}`);
        const sortBy = getAvailableGridSortById(params.sortBy, columns, undefined, initialParamsState.sortBy);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listDocumentTemplates(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                filters,
                sortBy,
                params.sortOrder,
                params.search,
                true
            ),
        });
    };
};

export const deleteEntity = (businessAccountId: number, id: number, common: boolean) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: common ? serverApi.deleteCommonDocumentTemplate(id) : serverApi.deleteDocumentTemplate(businessAccountId, id),
        });
    };
};

export const uploadTemplate = (businessAccountId: number, id: number, file: any, businessVersion: number, common: boolean) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: common
                ? serverApi.updateCommonDocumentTemplateContent(
                      id,
                      file,
                      new Blob([JSON.stringify(businessVersion)], {
                          type: 'application/json',
                      }) as any
                  )
                : serverApi.updateDocumentTemplateContent(
                      businessAccountId,
                      id,
                      file,
                      new Blob([JSON.stringify(businessVersion)], {
                          type: 'application/json',
                      }) as any
                  ),
        });
    };
};

export const downloadTemplate = (businessAccountId: number, id: number, common: boolean) => {
    return (dispatch, getState) => {
        let state = getState() as IRootState;
        let hasBAPermission = state.permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS);
        return dispatch({
            type: ACTION_TYPES.DOWNLOAD_TEMPLATE,
            payload: common
                ? hasBAPermission
                    ? serverApi.getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId, id, { responseType: 'blob' })
                    : serverApi.getCommonDocumentTemplateContentById(id, { responseType: 'blob' })
                : serverApi.getDocumentTemplateContentById(businessAccountId, id, { responseType: 'blob' }),
        });
    };
};

export const hideShow = (businessAccountId: number, id: number, common: boolean, businessVersion: number, hidden: boolean) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.HIDE_SHOW,
            payload: serverApi.updateDocumentTemplateBusinessAccountPreferences(businessAccountId, id, common, businessVersion, hidden),
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
