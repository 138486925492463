import React, { FC, memo } from 'react';
import { Card, Col, Row } from 'antd';
import { useAppSelector } from '../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { DashboardRentDelayWidget } from './components/widgets/delay/DashboardRentDelayWidget';
import { DashboardRentShortageWidget } from './components/widgets/shortage/DashboardRentShortageWidget';
import { DashboardRentDebtWidget } from './components/widgets/debt/DashboardRentDebtWidget';
import { DashboardRentOrderedWidget } from './components/widgets/ordered/DashboardRentOrderedWidget';
import { DashboardRentBookedWidget } from './components/widgets/booked/DashboardRentBookedWidget';
import { DashboardRentRentedWidget } from './components/widgets/rented/DashboardRentRentedWidget';
import { DashboardData } from '../../types/DashboardData';
import { DashboardRentLeftList } from '../../list/rent/left/DashboardRentLeftList';
import { DashboardRentRightList } from '../../list/rent/right/DashboardRentRightList';

interface DashboardRentProps {
    dashboardData: DashboardData;
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const DashboardRent: FC<DashboardRentProps> = memo((props) => {
    const { dashboardData, responsive } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const DelayWidget = <DashboardRentDelayWidget dashboardData={dashboardData} />;
    const ShortageWidget = <DashboardRentShortageWidget dashboardData={dashboardData} />;
    const DebtWidget = <DashboardRentDebtWidget dashboardData={dashboardData} />;
    const OrderedWidget = <DashboardRentOrderedWidget dashboardData={dashboardData} />;
    const BookedWidget = <DashboardRentBookedWidget dashboardData={dashboardData} />;
    const RentedWidget = <DashboardRentRentedWidget dashboardData={dashboardData} />;

    const LeftTable = <DashboardRentLeftList dashboardData={dashboardData} />;
    const RightTable = <DashboardRentRightList dashboardData={dashboardData} />;

    return responsive === 'xs' || responsive === 'sm' || responsive === 'md' || responsive === 'lg' ? (
        <div>
            <Row type="flex" justify="space-between" gutter={20}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {DelayWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {ShortageWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }} style={{ height: !isCanViewFinancialData ? 138 : undefined }}>
                    {isCanViewFinancialData && DebtWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {OrderedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {BookedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {RentedWidget}
                </Col>
            </Row>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftTable}</Card>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', marginTop: 30, paddingBottom: '36px' }}>{RightTable}</Card>
        </div>
    ) : (
        <div className="dashboard-main-grid">
            <div className="dashboard-grid-left">
                <Row type="flex" justify="space-between" gutter={20}>
                    <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                        {DelayWidget}
                    </Col>
                    <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                        {ShortageWidget}
                    </Col>
                    <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        style={{ flexGrow: 1, height: !isCanViewFinancialData ? 138 : undefined }}
                    >
                        {isCanViewFinancialData && DebtWidget}
                    </Col>
                </Row>
                <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftTable}</Card>
            </div>

            <div className="dashboard-grid-right">
                <Row type="flex" justify="space-between" gutter={20}>
                    <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                        {OrderedWidget}
                    </Col>
                    <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                        {BookedWidget}
                    </Col>
                    <Col xxl={{ span: 8, order: 0 }} xl={{ span: 12, order: 1 }} lg={{ span: 24, order: 0 }} style={{ flexGrow: 1 }}>
                        {RentedWidget}
                    </Col>
                </Row>

                <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{RightTable}</Card>
            </div>
        </div>
    );
});
