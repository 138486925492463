import React from 'react';
import {
    BusinessAccountRecord,
    BusinessAccountTransitionCodeEnum,
    BusinessAccountTypeCodeEnum,
    GlobalPermissionCodeEnum
} from "../../../server";
import {IRootState} from "../../../shared/reducers";
import {useSelector} from "react-redux";
import {ActionsPopoverV2} from "../../../components/v2/actionsPopover/ActionsPopoverV2";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";
import {LocalizationEnum, localize} from "../../../localization";
import {Icon} from "antd";
import {IconArchive, IconLevelUp, IconUnlock, IconUnlockAlt} from "../../../components/icons";

interface Props {
    onAction: (action: string) => void;
    record: BusinessAccountRecord;
    children: React.ReactNode;
}

export const BusinessAccountRecordActionsPopover = ({onAction, record, children}: Props) => {

    const permissions = useSelector((store:IRootState) => store.permissions.permissions);

    return (
        <ActionsPopoverV2 items={[
            permissions.includes(GlobalPermissionCodeEnum.UPDATEBUSINESSACCOUNT) && record.availableTransitionCodes?.includes(BusinessAccountTransitionCodeEnum.BLOCK) && {
                title: localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span'),
                icon: <Icon style={{color: '#9f9f9f'}} component={IconUnlock}/>,
                onClick: () => onAction('lock')
            },
            permissions.includes(GlobalPermissionCodeEnum.UPDATEBUSINESSACCOUNT) && record.availableTransitionCodes?.includes(BusinessAccountTransitionCodeEnum.ACTIVATE) && {
                title: localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span'),
                icon: <Icon style={{color: '#21bfa4'}} component={IconUnlockAlt}/>,
                onClick: () => onAction('lock')
            },
            permissions.includes(GlobalPermissionCodeEnum.UPDATEBUSINESSACCOUNT) && !record.archive && {
                title: localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span'),
                icon: <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive}/>,
                onClick: () => onAction('archive'),
                disabled: !record.archivable
            },
            permissions.includes(GlobalPermissionCodeEnum.UPDATEBUSINESSACCOUNT) && record.archive && {
                title: localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span'),
                icon: <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp}/>,
                onClick: () => onAction('archive')
            },
            permissions.includes(GlobalPermissionCodeEnum.DELETEBUSINESSACCOUNT) && {
                title: 'Удалить',
                icon: <SystemIcon type={SystemIconType.delete} />,
                onClick: () => onAction('delete'),
                disabled: record.typeCode !== BusinessAccountTypeCodeEnum.TRIAL
            }
        ]}>
            {children}
        </ActionsPopoverV2>
    );
};
