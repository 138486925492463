import {CurrencyCodeEnum} from '../../server/api';

export function getCurrencySign(currencyCode:CurrencyCodeEnum):string {
    let sign = '';
    if (currencyCode === CurrencyCodeEnum.RUB) sign = '₽';
    else if (currencyCode === CurrencyCodeEnum.USD) sign = '$';
    else if (currencyCode === CurrencyCodeEnum.EUR) sign = '€';
    else if (currencyCode === CurrencyCodeEnum.AMD) sign = '֏';
    else if (currencyCode === CurrencyCodeEnum.GEL) sign = '₾';
    else if (currencyCode === CurrencyCodeEnum.BYR) sign = 'Р';
    else if (currencyCode === CurrencyCodeEnum.KZT) sign = '₸';
    else if (currencyCode === CurrencyCodeEnum.KGS) sign = 'С';
    else if (currencyCode === CurrencyCodeEnum.UZS) sign = 'Soʻm';
    else if (currencyCode === CurrencyCodeEnum.AZN) sign = '₼';
    else if (currencyCode === CurrencyCodeEnum.THB) sign = '฿';
    else if (currencyCode === CurrencyCodeEnum.IDR) sign = 'Rp';
    else if (currencyCode === CurrencyCodeEnum.AED) sign = 'DH';
    return sign;
}
