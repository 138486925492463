import React, { FC, useState } from 'react';
import { CustomRangeCalendar } from '../../../../../../datePicker/CustomRangeCalendar';
import moment from 'moment';
import { Popover } from 'antd';
import { DownloadPeriodValues } from '../../hooks/useSelectOptions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

interface DownloadDocumentPeriodRangeCalendarProps {
    selectedValue: moment.Moment[];
    onChange: (value: DownloadPeriodValues | RangePickerValue | undefined) => void;
}
export const DownloadDocumentPeriodRangeCalendar: FC<DownloadDocumentPeriodRangeCalendarProps> = (props) => {
    const { selectedValue, onChange, children } = props;
    const [visible, setVisible] = useState(false);

    return (
        <Popover
            visible={visible}
            onVisibleChange={setVisible}
            overlayClassName={'rr-calendar-popover'}
            autoAdjustOverflow
            trigger="click"
            destroyTooltipOnHide
            content={
                <CustomRangeCalendar
                    selectedValue={selectedValue}
                    onChange={onChange}
                    showTime
                    onOpenChange={setVisible}
                    hideShiftRecalculateBlock
                    showRequiredTimeIndentBetweenElementsInMinutesIcon={false}
                />
            }
            placement={'topLeft'}
            align={{ targetOffset: [8, 0] }}
        >
            {children}
        </Popover>
    );
};
