import React, { ReactNode } from 'react';
import { Icon } from 'antd';
import { IconInfoSolid } from '../../../../components/icons';
import { TaxBaseTypeCodeEnum } from '../../../../server';

export const defaultTaxCalculationTypeTitle = {
    [TaxBaseTypeCodeEnum.COSTBEFORETAX]: 'От стоимости до налогов',
    [TaxBaseTypeCodeEnum.SUMAFTERTAX]: 'От полной стоимости после налогов',
};

export const DefaultTaxContent: ReactNode = (
    <div>
        <div
            style={{
                display: 'flex',
                borderBottom: '1px solid #e8e8e8',
                padding: '16px 0px 16px 14px',
                alignItems: 'center',
            }}
        >
            <Icon component={IconInfoSolid} style={{ fontSize: 31, color: '#525A95' }} />
            <div style={{ fontWeight: 600 }}>Тип расчёта налога</div>
        </div>
        <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 12, fontWeight: 600 }}>От стоимости до налогов</div>
            <div style={{ marginBottom: 22 }}>
                Налог берется напрямую, как процент стоимости
                <br />
                <br />
                <i>Пример: налог 10%, стоимость 100 — получаем налог 10</i>
            </div>
            <div style={{ marginBottom: 12, fontWeight: 600 }}>От полной стоимости после налога</div>
            <div>
                Подбирается такой налог, который составлял бы нужный процент от итоговой стоимости (суммы стоимости до налогов и налога)
                <br />
                <br />
                <i>Пример: налог 10%, стоимость 100 — получаем налог 11.11</i>
            </div>
        </div>
    </div>
);
