import React from 'react';
import { Alert, Button, Icon, Tabs, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { goBack, push, replace } from 'connected-react-router';
import { changeCounterpartyActivityType, loadRenter, reset, setStatusAndArchive } from '../../../reducers/renters/renter.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import { RoundButton, Spin } from '../../../../../../components';
import {
    IconArchive,
    IconBriefcase,
    IconCheck,
    IconControlPointDuplicateBlack,
    IconDollySolid,
    IconEdit,
    IconLevelUp,
    IconMedkit,
    IconUnlock,
    IconUnlockAlt,
} from '../../../../../../components/icons';
import RenterPageDescriptionTabPane from './CounterpartyPageDescription';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import './CounterpartyPage.less';
import { ProjectsList } from '../../../../projects/production/list/projects-list';
import { BasePage, IBasePageProps } from '../../../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../../../components/pageHeader/pageHeader';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../../../../shared/util/utils';
import { resetOperationForm } from '../../../../operationForm/reducers/operationForm.reducer';
import getParamsLink from '../../../../../../shared/util/getParamsLink';
import { OperationsList } from '../../../../operations/operationsList';
import { logEvent } from '../../../../../../analytics/analytics';
import {
    CounterpartyActivityTypeCodeEnum,
    EntityTypeCodeEnum,
    ProjectInfoRead,
    RenterInfoRead,
    RenterStateCodeEnum,
    SimpleTransitionCodeEnum,
    SubrentInfoRead,
} from '../../../../../../server';
import { ProjectCreateModal } from '../../../../projects/production/modal/project-create-modal';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../../localization';
import AdditionalEntityActions from '../../../../../../components/additionalEntityActions/additionalEntityActions';
import { SubrentShippingsListTabPane } from '../../../../subrent/shippings/SubrentShippingsListTabPane';
import { SubrentShippingCreateModal } from '../../../../subrent/shippings/subrentShippingCreateModal';
import { AdditionalEntityActionsButton } from '../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { createProjectModalFilters } from '../../../../projects/production/modal/projectsPageData';
import { createSubrentShippingSchemeModalFilters } from '../../../../subrent/shippings/subrentShippingCreateModalData';
import { CounterpartyCopyModalNew } from '../modal/CounterpartyCopyModalNew';
import { CounterpartyEditModalNew } from '../modal/CounterpartyEditModalNew';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { includes } from '../../../../../../shared/util/utils2';
import {
    statisticsModuleEnabledSelector,
    subrentModuleEnabledSelector,
} from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ReportReferenceTypeEnum } from '../../../../../../components/pageHeader/ReportSelectDropdown';
import { LegalDetailsModal } from '../../../../legalDetails/modal/legalDetailsModal';
import { LinkToCalendar } from '../../../../../../components/link/toCalendar/LinkToCalendar';

import { CalendarPageTabsEnum, CounterpartyPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { CounterpartyPageNavigationBlock } from '../../../components/HeaderNavigationBlock/CounterpartyPageNavigationBlock';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = CounterpartyPageTabsEnum.DESCRIPTION;

class CounterpartyPage extends BasePage<IProps, IState> {
    private tabs = {};
    private isEntityLoaded: boolean = false;

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    async componentDidMount() {
        super.componentDidMount();

        await this.props.loadRenter(this.props.businessAccountId, this.props.entityId);
        this.logViewEvent();
    }

    async componentDidUpdate(prevProps: IProps) {
        const { entity, tab } = this.props;

        if (tab && entity && !this.isEntityLoaded) {
            const safeTab = this.isTabExists(tab, entity) ? tab : defaultTab;

            saveCurrentTabToStore('counterparty', safeTab);

            this.isEntityLoaded = true;
            this.onTabsChange(isStringInEnum(safeTab, CounterpartyPageTabsEnum, defaultTab), true);
        }

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            this.props.loadRenter(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    isTabExists(tab: CounterpartyPageTabsEnum, entity: RenterInfoRead) {
        return Boolean(
            (tab !== CounterpartyPageTabsEnum.PROJECTS && tab !== CounterpartyPageTabsEnum.SHIPPINGS) ||
                (tab === CounterpartyPageTabsEnum.PROJECTS &&
                    includes(
                        [CounterpartyActivityTypeCodeEnum.RENTERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER],
                        entity.activityTypeCode
                    )) ||
                (tab === CounterpartyPageTabsEnum.SHIPPINGS &&
                    includes(
                        [CounterpartyActivityTypeCodeEnum.SUPPLIERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER],
                        entity.activityTypeCode
                    ))
        );
    }

    onTabsChange = (key: string, replace: boolean = false) => {
        const urlMethod = replace ? this.props.replace : this.props.push;
        this.tabs[this.props.tab] = this.props.location.search;
        let searchString = this.getSearchString(key as CounterpartyPageTabsEnum);

        if (!replace) {
            saveCurrentTabToStore('counterparty', key);
        }

        urlMethod(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
        this.logViewEvent();
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/crm/counterparties/${this.props.entityId}/edit?tab=description`);
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/crm/counterparties/${this.props.entityId}/copy?tab=description`);
    };

    onArchiveButtonClick = async () => {
        const { entity } = this.props;
        if (entity) {
            let toArchive = true;
            if (!entity.archive) {
                toArchive = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__MODAL__QUESTIONS__SEND_TO_ARCHIVE)
                );
            }
            if (toArchive)
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    entity.businessVersion,
                    undefined,
                    !entity.archive
                );
        }
    };

    onActivateButtonClick = async () => {
        const { entity } = this.props;
        if (entity)
            this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entityId,
                entity.businessVersion,
                'ACTIVATE'
            );
    };

    onLockButtonClick = async () => {
        const { entity } = this.props;
        const ok = await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__ACTIONS__BLOCK));
        if (entity && ok)
            this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entityId,
                entity.businessVersion,
                'BLOCK'
            );
    };

    onUnlockButtonClick = async () => {
        const { entity } = this.props;
        if (entity)
            this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entityId,
                entity.businessVersion,
                'ACTIVATE'
            );
    };

    changeCounterpartyActivityTypeClick = async () => {
        const { entity, changeCounterpartyActivityType, intl, businessAccountId } = this.props;
        if (entity) {
            changeCounterpartyActivityType(intl, businessAccountId, entity, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER);
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity, statisticsModuleEnabled } = this.props;
        return {
            archive: !!(entity && entity.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: !(entity && entity.fullName),
            rightBlock: (
                <LinkToCalendar
                    calendarPath={`/${this.props.businessAccountId}/calendar${getParamsLink(entity, 'renters')}&capture=all&tab=${
                        CalendarPageTabsEnum.ELEMENTS
                    }`}
                />
            ),
            parentPagePath: entity ? `/${this.props.businessAccountId}/crm/counterparties` : undefined,
            reportReferenceType: statisticsModuleEnabled ? ReportReferenceTypeEnum.RENTER : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? this.props.entityId : undefined,
            NavigationBlock: <CounterpartyPageNavigationBlock renter={entity || undefined} />,
        };
    }

    projectCreatedCallback = (data: ProjectInfoRead) => {
        this.props.push(`/${this.props.businessAccountId}/projects/production/${data.id}?tab=description`);
        logEvent({
            type: 'create project',
            data: {
                'entity id': data.id,
            },
        });
    };

    shippingCreatedCallback = (data: SubrentInfoRead) => {
        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${data.id}?tab=description`);
        logEvent({
            type: 'create shipping',
            data: {
                'entity id': data.id,
            },
        });
    };

    createShipping = () => {
        this.props.push(`/${this.props.businessAccountId}/crm/counterparties/${this.props.entity?.id}/newShipping`);
    };

    createProject = () => {
        this.props.push(`/${this.props.businessAccountId}/crm/counterparties/${this.props.entity?.id}/newProject`);
    };

    logViewEvent = () => {
        logEvent({ type: 'view renter', data: { 'entity id': this.props.entityId, tab: this.props.tab } });
    };

    getSearchString(key: CounterpartyPageTabsEnum) {
        return key === CounterpartyPageTabsEnum.OPERATIONS ||
            key === CounterpartyPageTabsEnum.PROJECTS ||
            key === CounterpartyPageTabsEnum.STATISTICS ||
            key === CounterpartyPageTabsEnum.SHIPPINGS
            ? this.tabs[key] || ''
            : '';
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let { entity, loading, subrentModuleEnabled } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, CounterpartyPageTabsEnum, defaultTab);
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, this.getSearchString(tab), {
                        tab,
                    })}
                />
            );
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        return (
            <>
                <Spin spinning={loading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <div className="card-container">
                                <Tabs
                                    activeKey={this.isEntityLoaded ? currentTab : undefined}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs'}
                                    type="line"
                                    animated={false}
                                    destroyInactiveTabPane
                                    tabBarExtraContent={
                                        <>
                                            <RoundButton colorScheme={'default'} onClick={this.onEditButtonClick}>
                                                <Icon component={IconEdit} />
                                                {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                                            </RoundButton>
                                            {entity.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                            entity.stateCode === 'NEW' ? (
                                                <RoundButton colorScheme={'success'} onClick={this.onActivateButtonClick}>
                                                    <Icon component={IconCheck} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                                                </RoundButton>
                                            ) : null}

                                            {entity.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                            entity.stateCode === 'BLOCKED' ? (
                                                <RoundButton colorScheme={'success'} onClick={this.onUnlockButtonClick}>
                                                    <Icon component={IconUnlockAlt} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                                                </RoundButton>
                                            ) : null}

                                            {entity.archive ? (
                                                <RoundButton colorScheme={'FROM_ARCHIVE'} onClick={this.onArchiveButtonClick}>
                                                    <Icon component={IconLevelUp} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                                                </RoundButton>
                                            ) : null}

                                            {this.isEntityLoaded &&
                                                currentTab === CounterpartyPageTabsEnum.PROJECTS &&
                                                !entity.archive &&
                                                (this.props.entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERONLY ||
                                                    this.props.entity?.activityTypeCode ===
                                                        CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && (
                                                    <Tooltip
                                                        title={
                                                            entity.stateCode === RenterStateCodeEnum.BLOCKED
                                                                ? 'Арендатор заблокирован, действие недоступно'
                                                                : undefined
                                                        }
                                                    >
                                                        <div>
                                                            <RoundButton
                                                                colorScheme={'success'}
                                                                title={LocalizationEnum.ASPECT__ACTION__CREATE_PROJECT}
                                                                onClick={this.createProject}
                                                                disabled={entity.stateCode === RenterStateCodeEnum.BLOCKED}
                                                            >
                                                                <Icon component={IconMedkit} />
                                                                {localize(LocalizationEnum.ASPECT__ACTION__CREATE_PROJECT, 'span')}
                                                            </RoundButton>
                                                        </div>
                                                    </Tooltip>
                                                )}

                                            {this.isEntityLoaded &&
                                                currentTab === CounterpartyPageTabsEnum.SHIPPINGS &&
                                                subrentModuleEnabled &&
                                                !entity.archive &&
                                                (this.props.entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY ||
                                                    this.props.entity?.activityTypeCode ===
                                                        CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && (
                                                    <Tooltip
                                                        title={
                                                            entity.stateCode === RenterStateCodeEnum.BLOCKED
                                                                ? 'Поставщик заблокирован, действие недоступно'
                                                                : undefined
                                                        }
                                                    >
                                                        <div>
                                                            <RoundButton
                                                                onClick={this.createShipping}
                                                                colorScheme={'success'}
                                                                title={'Создать поставку'}
                                                                disabled={entity.stateCode === RenterStateCodeEnum.BLOCKED}
                                                            >
                                                                <Icon component={IconDollySolid} />
                                                                <span>Создать поставку</span>
                                                            </RoundButton>
                                                        </div>
                                                    </Tooltip>
                                                )}

                                            <span style={{ marginLeft: '16px' }}>
                                                <AdditionalEntityActions
                                                    disabled={false}
                                                    content={
                                                        <>
                                                            {entity.activityTypeCode === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY &&
                                                                !subrentModuleEnabled && (
                                                                    <Button
                                                                        className={'ant-btn-page-actions'}
                                                                        block
                                                                        onClick={this.changeCounterpartyActivityTypeClick}
                                                                    >
                                                                        <Icon component={IconBriefcase} style={{ color: '#525A95' }} />
                                                                        Сделать арендатором
                                                                    </Button>
                                                                )}
                                                            <Button
                                                                className={'ant-btn-page-actions'}
                                                                block
                                                                onClick={this.onCopyButtonClick}
                                                            >
                                                                <Icon
                                                                    component={IconControlPointDuplicateBlack}
                                                                    style={{ color: '#34bfa3' }}
                                                                />{' '}
                                                                {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                            </Button>

                                                            {entity.archivable && !entity.archive ? (
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    onClick={this.onArchiveButtonClick}
                                                                >
                                                                    <Icon component={IconArchive} style={{ color: '#c4c5d6' }} />
                                                                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                                                                </Button>
                                                            ) : null}

                                                            {entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.BLOCK) ? (
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    block
                                                                    onClick={this.onLockButtonClick}
                                                                >
                                                                    <Icon component={IconUnlock} style={{ color: '#A0A0A0' }} />
                                                                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                                                                </Button>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <AdditionalEntityActionsButton />
                                                </AdditionalEntityActions>
                                            </span>
                                        </>
                                    }
                                >
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                        key={CounterpartyPageTabsEnum.DESCRIPTION}
                                    >
                                        <RenterPageDescriptionTabPane />
                                    </Tabs.TabPane>
                                    {(entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERONLY ||
                                        entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && (
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS)}
                                            key={CounterpartyPageTabsEnum.PROJECTS}
                                        >
                                            <ProjectsList
                                                gridName={'renter-projects'}
                                                location={this.props.location}
                                                renterId={entity.id}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    {(entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY ||
                                        entity?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && (
                                        <Tabs.TabPane tab={'Поставки'} key={CounterpartyPageTabsEnum.SHIPPINGS}>
                                            <SubrentShippingsListTabPane
                                                gridName={'renter-shippings'}
                                                location={this.props.location}
                                                supplierId={entity.id}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__MAIN_MENU__OPERATIONS)}
                                        key={CounterpartyPageTabsEnum.OPERATIONS}
                                    >
                                        <OperationsList
                                            gridName={'renter-operations'}
                                            location={this.props.location}
                                            renterId={entity.id}
                                            parentEntityType={'counterparty'}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>
                </Spin>

                {this.props.editMode ? (
                    <CounterpartyEditModalNew
                        entity={this.props.entity}
                        onSuccess={(data) => {
                            this.props.push(`/${data.businessAccountId}/crm/counterparties/${data.id}?tab=description`);
                        }}
                        onCancel={() => {
                            this.props.goBack();
                        }}
                    />
                ) : null}

                {/* Создание проекта из контрагента */}
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/crm/counterparties/${this.props.entity?.id}/newProject`}
                    render={(props) => (
                        <ProjectCreateModal
                            editMode={false}
                            renter={
                                this.props.entity
                                    ? {
                                          key: '' + this.props.entity.id,
                                          label: this.props.entity.shortName,
                                      }
                                    : undefined
                            }
                            title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            businessAccountId={this.props.businessAccountId}
                            successCallback={this.projectCreatedCallback}
                            renterObj={this.props.entity}
                            filters={createProjectModalFilters}
                            modalName={'create-project'}
                        />
                    )}
                />

                {/* Копирование контрагента */}
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/crm/counterparties/${this.props.entity?.id}/copy`}
                    render={(props) => (
                        <CounterpartyCopyModalNew
                            entity={this.props.entity}
                            onSuccess={(data) => {
                                this.props.push(`/${data.businessAccountId}/crm/counterparties/${data.id || ''}?tab=description`);
                            }}
                            onCancel={() => {
                                this.props.goBack();
                            }}
                        />
                    )}
                />

                {/* Создание новой поставки из контрагента */}
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/crm/counterparties/${this.props.entity?.id}/newShipping`}
                    render={(props) => (
                        <SubrentShippingCreateModal
                            editMode={false}
                            renterId={this.props.entity ? String(this.props.entity.id) : undefined}
                            title={'Создание новой поставки'}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            businessAccountId={this.props.businessAccountId}
                            renterObj={this.props.entity}
                            successCallback={this.shippingCreatedCallback}
                            filters={createSubrentShippingSchemeModalFilters}
                            modalName={'create-subrent_shipping'}
                        />
                    )}
                />

                {this.props.entity && (this.props.createLegalDetailsMode || this.props.editLegalDetailsMode) ? (
                    <LegalDetailsModal
                        referenceEntityId={this.props.entity.id}
                        entityType={EntityTypeCodeEnum.RENTER}
                        legalDetails={this.props.entity.legalDetails}
                        onCancel={() => {
                            this.props.goBack();
                        }}
                        onSuccess={() => {
                            this.props.loadRenter(this.props.businessAccountId, this.props.entityId);
                            this.props.goBack();
                        }}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.renter.entity,
        renter: storeState.renter,
        loading: storeState.renter.loading,
        loadingError: storeState.renter.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.renter.entity && ownProps.match.params['action'] === 'edit'),
        copyMode: !!(storeState.renter.entity && ownProps.match.params['action'] === 'copy'),
        createLegalDetailsMode: !!(storeState.renter.entity && ownProps.match.params['action'] === 'createLegalDetails'),
        editLegalDetailsMode: !!(storeState.renter.entity && ownProps.match.params['action'] === 'editLegalDetails'),
        tab:
            searchParams.tab &&
            (searchParams.tab === CounterpartyPageTabsEnum.DESCRIPTION ||
                searchParams.tab === CounterpartyPageTabsEnum.OPERATIONS ||
                searchParams.tab === CounterpartyPageTabsEnum.PROJECTS ||
                searchParams.tab === CounterpartyPageTabsEnum.SHIPPINGS) /*|| searchParams.tab === STATISTICS*/
                ? searchParams.tab
                : CounterpartyPageTabsEnum.PROJECTS,
        tabFromStore: getCurrentTabsSelector(storeState)?.['counterparty'],
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    loadRenter,
    setStatusAndArchive,
    reset,
    push,
    replace,
    goBack,
    resetOperationForm,
    changeCounterpartyActivityType,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CounterpartyPage);
