import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { TimetableTypeCodeEnum } from '../../../server/api';
import { CustomRangeCalendar } from '../../../components/datePicker/CustomRangeCalendar';
import { ProductUtils } from '../../../shared/util/productUtils';
import { serverApi } from '../../../server';
import { isDefined } from '../../../shared/util/utils';
import { CustomRangeCalendarAccessibilityCustomFooter } from '../../../components/datePicker/CustomRangeCalendarAccessibilityCustomFooter/CustomRangeCalendarAccessibilityCustomFooter';
import moment from 'moment';
import { DateUtils } from '../../../core/utils/dateUtils';

interface IProps extends StateProps, DispatchProps {
    productId?: number;
    kitId?: number;
    variantId?: number;

    typeCode: TimetableTypeCodeEnum;
    instanceId?: number;
}

interface IState {
    visible: boolean;
    intervals?: [number, number][];
    currentTime: number;
    currentFromTime: number;
    currentToTime: number;
}

class _ProductCalendarPopover extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        let curTime: number = Date.now();

        this.state = {
            visible: false,
            currentTime: curTime,
            currentFromTime: curTime,
            currentToTime: curTime,
        };
    }

    componentDidMount = async () => {
        console.log(`OperationFormProductCalendarPopover componentDidMount()`);
        let response;
        const { businessAccountId, productId, kitId, variantId, instanceId, typeCode } = this.props;

        if (isDefined(kitId)) {
            response = await serverApi.getKitTimetableById(businessAccountId, kitId, true, typeCode);
        } else if (isDefined(productId) && !isDefined(variantId) && !isDefined(instanceId)) {
            response = await serverApi.getProductTimetableById(businessAccountId, productId, true, typeCode);
        } else if (isDefined(productId) && isDefined(variantId) && !isDefined(instanceId)) {
            response = await serverApi.getVariantTimetableById(businessAccountId, productId, variantId, true, typeCode);
        } else if (isDefined(productId) && isDefined(variantId) && isDefined(instanceId)) {
            response = await serverApi.getProductVariantInstanceTimetableById(
                businessAccountId,
                productId,
                variantId,
                instanceId,
                true,
                typeCode
            );
        } else if (isDefined(productId) && !isDefined(variantId) && isDefined(instanceId)) {
            response = await serverApi.getProductInstanceTimetableById(businessAccountId, productId, instanceId, true, typeCode);
        }

        if (response) {
            let { mapString } = response.data;
            let intervals = ProductUtils.parseProductStackMap(mapString);
            this.setState({ intervals });
        }
    };

    onValueChange = (value) => {
        this.setState({ currentFromTime: value[0].valueOf(), currentToTime: value[1].valueOf() });
    };

    getCalendarInterval = (currentFromTime: number, currentToTime: number): [number, number] => {
        if (currentFromTime !== currentToTime) return [currentFromTime, currentToTime];

        const currentDate = moment(currentToTime);
        return DateUtils.getCalendarBoundaries(currentDate, 'month');
    };

    render() {
        const { productId, variantId, kitId, instanceId } = this.props;
        const { currentFromTime, currentToTime } = this.state;

        const [screenLeft, screenRight] = this.getCalendarInterval(currentFromTime, currentToTime);

        return (
            <CustomRangeCalendar
                className={'rr-range-calendar-without-selection'}
                disabledDate={() => true}
                mode={['date', 'date']}
                intervals={this.state.intervals}
                typeCode={this.props.typeCode}
                onValueChange={this.onValueChange}
                instance={!!this.props.instanceId}
                hasOwnShiftLength={false}
                customFooter={
                    <CustomRangeCalendarAccessibilityCustomFooter
                        productId={productId}
                        kitId={kitId}
                        variantId={variantId}
                        instanceId={instanceId}
                        screenLeft={screenLeft}
                        screenRight={screenRight}
                    />
                }
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
    };
};

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProductCalendarPopoverContent = connect(mapStateToProps, mapDispatchToProps)(_ProductCalendarPopover);
