import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { goBack } from 'connected-react-router';
import { expenseModalFormFields } from '../expenseModalFormFields';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { ModalActionData } from '../../../../../components/modalForm/utils';
import { ExpenseCreateModalFormData } from './expenseCreateModal.types';
import { ExpenseInfoCreate } from '../../../../../server';
import { clearExpense, createExpense } from '../../reducers/expense/expense.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    rootPath: string;
    urlSearchParams?: URLSearchParams;
}

export class _ExpenseCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: expenseModalFormFields,
    };

    onOk = (data: unknown) => {
        const formData = data as ExpenseCreateModalFormData;

        const expenseInfoCreate: ExpenseInfoCreate = {
            name: formData.name,
            internalFinalTotalPrice: formData.internalFinalTotalPrice,
            externalFinalTotalPrice: formData.externalFinalTotalPrice,
            state: formData.state,
            projectId: formData.projectId!,
            comment: formData.comment,
        };

        this.props.createExpense(
            {
                businessAccountId: this.props.businessAccountId,
                expenseInfoCreate: expenseInfoCreate,
            },
            this.props.rootPath,
            this.props.urlSearchParams
        );
    };

    onCancel = async (isFieldsTouched: boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
            this.props.clearExpense();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.expense.updating,
    updatingError: storeState.expense.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    createExpense,
    clearExpense,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ExpenseCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ExpenseCreateModal));
