import React, {CSSProperties} from 'react';
import {FileTypeCodeEnum} from "../../../server";
import {Icon} from "antd";
import classNames from "classnames";
import {CustomIconComponentProps} from "antd/lib/icon";
import {
    IconFile,
    IconFileAlt,
    IconFileArchive,
    IconFileAudio,
    IconFileExcel,
    IconFileImage,
    IconFilePdf,
    IconFilePowerpoint,
    IconFileVideo,
    IconFileWord
} from "../../../components/icons";
import './attachmentIcon.less';

interface InventoryMovementIconProps {
    type?: FileTypeCodeEnum;
    style?: CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const AttachmentIcon = ({type, ...props}: InventoryMovementIconProps) => {

    let component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;
    let className: string | undefined = `rr-icon-attachment-${type}`;

    if(type === FileTypeCodeEnum.EXCEL) component = IconFileExcel;
    else if(type === FileTypeCodeEnum.AUDIO) component = IconFileAudio;
    else if(type === FileTypeCodeEnum.ARCHIVE) component = IconFileArchive;
    else if(type === FileTypeCodeEnum.IMAGE) component = IconFileImage;
    else if(type === FileTypeCodeEnum.WORD) component = IconFileWord;
    else if(type === FileTypeCodeEnum.PDFTYPE) component = IconFilePdf;
    else if(type === FileTypeCodeEnum.PRESENTATION) component = IconFilePowerpoint;
    else if(type === FileTypeCodeEnum.TEXTDOCUMENT) component = IconFileAlt;
    else if(type === FileTypeCodeEnum.VIDEO) component = IconFileVideo;
    else if(type === FileTypeCodeEnum.OTHERTYPE) component = IconFile;

    return <Icon className={classNames('rr-icon-', className, props.className)} style={props.style} component={component} onClick={props.onClick}/>;
};
