import { ServerUtils } from '../../../../core/utils/serverUtils';
import { ProjectRecord, ProjectStateCodeEnum, ProjectTypeCodeEnum } from '../../../../server';
import { ListProjectsArgs } from './types';

export abstract class OffersUtils {
    static createRequestFilters = (params: ListProjectsArgs['params']) => {
        return ServerUtils.createRequestFilters<ProjectRecord>([
            ['projectType', 'EQ', ProjectTypeCodeEnum.OFFER],
            params.stateCode ? ['stateCode', 'IN', params.stateCode] : undefined,
            params.tags ? ['tags', 'IN', params.tags] : undefined,
            params.projectId ? ['mainProjectId', 'EQ', params.projectId] : undefined,
            params.assigneeId ? ['assigneeId', 'EQ', params.assigneeId] : undefined,
            params.partOfThePrice !== undefined ? ['partOfThePrice', 'EQ', params.partOfThePrice] : undefined,
            params.renterId ? ['renterId', 'EQ', params.renterId] : undefined,
            params.hide ? ['stateCode', 'IN', [ProjectStateCodeEnum.NEW, ProjectStateCodeEnum.APPROVED]] : undefined,
        ]);
    };
}
