import React, {useEffect, useMemo, useState} from 'react';
import {CustomRangeCalendar} from '../../../../../../components/datePicker/CustomRangeCalendar';
import moment from "moment";
import {NomenclatureEntityTypeCodeEnum, NomenclatureRecord, TimetableTypeCodeEnum} from "../../../../../../server";
import {Spin} from "../../../../../../components";
import {useAppDispatch, useAppSelector} from "../../../../../../store/hooks";
import {getDateIntervalsForCalendar, loadNomenclaturesOnIntervalsIfNeed} from "../../../utils/nomenclaturesUtils";
import {updateAnyTimetables} from "../../../reducers/operationForm.reducer";
import {getNomenclatureAvailability} from "../../../utils/availabilityUtils";
import {CustomRangeCalendarAccessibilityCustomFooter} from "../../../../../../components/datePicker/CustomRangeCalendarAccessibilityCustomFooter/CustomRangeCalendarAccessibilityCustomFooter";

interface NomenclatureCalendarPopoverContentProps {
    startDate: number;
    endDate: number;
    nomenclatureId: number;
    nomenclatureType: NomenclatureEntityTypeCodeEnum;
    nomenclature: NomenclatureRecord;
}

const disabledDate = (current) => {
    return true;
};

export const NomenclatureCalendarPopoverContent = ({nomenclatureId, nomenclatureType, endDate, startDate, nomenclature}: NomenclatureCalendarPopoverContentProps) => {
    const dispatch = useAppDispatch();
    const projectTemplate = useAppSelector((state) => state.operationForm.projectTemplate);
    const timetables = useAppSelector((state) => state.operationForm.timeTables);
    const operationFormState = useAppSelector((state) => state.operationForm);
    const [isLoading, setIsLoading] = useState(false);
    const [from, until] = getDateIntervalsForCalendar(startDate, endDate);

    useEffect(() => {
        const n = {id: nomenclatureId, type: nomenclatureType, from, until};
        setIsLoading(true);
        loadNomenclaturesOnIntervalsIfNeed([n]).then((data) => {
            dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            //showNotification('error', 'Не удалось загрузить доступность');
            setIsLoading(false);
        });
    }, [from, until, nomenclatureId, nomenclatureType, dispatch]);

    const timeTable = timetables.find((tt) => tt.id === nomenclatureId && tt.type === nomenclatureType);
    const intervals = useMemo(() => {
        const intervals = getNomenclatureAvailability(timeTable?.nomenclature as NomenclatureRecord || nomenclature, operationFormState);
        return intervals;
    }, [timeTable, nomenclature, operationFormState]);

    return (
        <Spin spinning={isLoading} delay={100}>
            {
                useMemo(()=>{
                    return (
                        <CustomRangeCalendar
                            className={'rr-range-calendar-without-selection'}
                            selectedValue={!projectTemplate ? [moment(startDate), moment(endDate)] : undefined}
                            disabledDate={disabledDate}
                            mode={['date', 'date']}
                            intervals={intervals}
                            typeCode={projectTemplate ? TimetableTypeCodeEnum.STOCK : TimetableTypeCodeEnum.AVAILABLE}
                            hasOwnShiftLength={false}
                            customFooter={
                                <CustomRangeCalendarAccessibilityCustomFooter
                                    productId={nomenclature.productId}
                                    kitId={nomenclature.kitId}
                                    variantId={nomenclature.variantId}
                                    screenLeft={startDate}
                                    screenRight={endDate}
                                    instanceId={undefined}
                                />
                            }
                        />
                    );
                }, [projectTemplate, startDate, endDate, disabledDate, intervals])
            }
        </Spin>
    );
};

// TODO При листании вправо/влево подгружать еще по сколько-то (по году мб?)