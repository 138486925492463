import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { OperationCompositionObj, RentAuditWithPredecessorRecord, RentTermsObj, serverApi, ServerError } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';

import { getServerError } from '../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'rentElements3/LOAD_ENTITIES',
    SET_PAGE_PARAMS: 'rentElements3/SET_PAGE_PARAMS',
    RESET: 'rentElements3/RESET',
};

const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as string | undefined,
    page: 1,
    onPage: 10,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    renterId: undefined as string | undefined,
    projectId: undefined as number | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: undefined as Array<RentAuditWithPredecessorRecord> | undefined,
    commonRentTerms: undefined as RentTermsObj | undefined,
    totalCount: 0,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type RentElementsState = Readonly<typeof initialState>;

// Reducer

export default (state: RentElementsState = initialState, action): RentElementsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES): {
            gridDataChangedSignal();

            let auditList = (action.payload.data as OperationCompositionObj).auditList;
            let records: Array<RentAuditWithPredecessorRecord> = [];
            if (auditList) {
                records = auditList.records;
            }

            let commonRentTerms;
            if (action.payload.data as OperationCompositionObj) {
                commonRentTerms = (action.payload.data as OperationCompositionObj).commonRentTerms;
            }

            return {
                ...state,
                loading: false,
                entities: records,
                commonRentTerms: commonRentTerms,
                totalCount: 0, //(action.payload.data as RentElementRecordList).listAttributes.filteredCount,
                filteredCount: 0, //(action.payload.data as RentElementRecordList).listAttributes.filteredCount
            };
        }
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case SUCCESS(ACTION_TYPES.SET_PAGE_PARAMS): {
            let newParams = { ...initialParamsState };
            Object.keys(action.payload).forEach(function (key) {
                let value = action.payload[key];
                if (value !== undefined) newParams[key] = value;
            });
            return {
                ...state,
                params: newParams,
            };
        }

        default:
            return state;
    }
};

// Actions

// Загрузить список обязательств
export const loadEntities = (intl, businessAccountId: number, parentId: number) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.getOperationComposition(businessAccountId, parentId),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const setPageParams = (params: { [k: string]: any }) => ({
    type: ACTION_TYPES.SET_PAGE_PARAMS,
    payload: Promise.resolve(params),
});
