import React, {ReactNode} from 'react';
import {removeInstances} from "../../reducers/operationForm.reducer";
import {connect} from "react-redux";
import {showConfirm} from "../../../../../components/confirm/showConfirm";
import {injectIntl, WrappedComponentProps} from "react-intl";

interface IProps extends DispatchProps, WrappedComponentProps {
    children: () => ReactNode;
    id: number;
    disabled: boolean;
}

class _OperationFormKitRemovePopover extends React.Component<IProps> {

    onClick = async (e) => {
        let yes = await showConfirm(this.props.intl, 'Убрать набор из операции?');
        if (yes) this.props.removeInstances(this.props.id, 1);
    };

    render() {
        return (
            <span onClick={this.onClick}>
                {
                    this.props.children()
                }
            </span>
        );
    }
}

const mapDispatchToProps = {removeInstances};
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormKitRemovePopover = connect(undefined, mapDispatchToProps)(injectIntl(_OperationFormKitRemovePopover));
