import React from 'react';
import {CardProps} from "antd/lib/card";
import {formatMoney} from "../../../../shared/util/formatMoney";
import FormValue from "../../../../components/formValue/FormValue";
import './kit-indicators-block.less';
import {LocalizationEnum, localize} from '../../../../localization';
import {FormatPercents} from "../../../../components/formatPercents/formatPercents";

interface IProps extends CardProps {
    instanceCount: number;
    price: number;
    discount: number;
    finalTotalPrice: number;
    discountPercent: number;
}

export class KitIndicatorsBlock extends React.PureComponent<IProps> {

    render() {

        return (
            <>
            <div className="rr-kit-indicators-block" style={{cursor: 'default'}}>
                <div className={'rr-form-item rr-kit-indicators-block-item rr-kit-indicators-item1'} style={{ margin: '0px', borderRight: '1px solid #eaeaea'}}>
                    <div>
                    <div className='rr-label'>{localize(LocalizationEnum.ASPECT__RENT_INDICATORS__INSTANCE_COUNT, undefined, { value: 0 })}</div>
                    <div className='rr-value'>
                        <FormValue value={this.props.instanceCount} originalValue={this.props.instanceCount} />
                    </div>
                    </div>
                </div>
                <div className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item2'} style={{ margin: '0px',  borderRight: '1px solid #eaeaea'}}>
                    <div>
                        <div className='rr-label'>{localize(LocalizationEnum.ASPECT__RENT_INDICATORS__PRICE_BEFORE_DISCOUNT)}</div>
                        <div className='rr-value'>
                            <FormValue value={formatMoney(this.props.price)} originalValue={this.props.price} />
                        </div>
                    </div>
                </div>
                <div className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item3'} style={{ margin: '0px',  borderRight: '1px solid #eaeaea'}}>
                    <div>
                    <div className='rr-label'>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}</div>
                    <div className='rr-value'>
                        <FormValue value={<FormatPercents value={this.props.discountPercent || 0} withoutDots={false} precision={2} />} originalValue={this.props.discountPercent}/>
                    </div>
                    </div>
                </div>
                <div className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item4'} style={{ margin: '0px', }}>
                    <div>
                    <div className='rr-label'>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TOTAL_DISCOUNT)}</div>
                    <div className='rr-value'>
                        <FormValue value={formatMoney(this.props.discount)} originalValue={this.props.discount} />
                    </div>
                    </div>
                </div>
                <div className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item5'} style={{ margin: '0px', backgroundColor: '#525a95'}}>
                    <div>
                    <div className='rr-label' style={{color: '#ffffff'}}>{localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}</div>
                    <div className='rr-value' style={{color: '#ffffff'}}>
                        <FormValue value={formatMoney(this.props.finalTotalPrice)} originalValue={this.props.finalTotalPrice} />
                    </div>
                    </div>
                </div>
            </div>


                </>
        );
    }
}