import { IModalFormFilters } from '../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../../components/modalForm/components/Header/types/displayData';
import { RenterInfoRead } from '../../../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof RenterInfoRead | string> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [
            ['shortName', 'activityTypeCode', 'defaultDiscount'],
            ['contacts[0].fullName.lastname', 'contacts[0].fullName.firstname'],
        ],
    },
];

export const createRenterModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editRenterModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};
