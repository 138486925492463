import React from 'react';
import { Button, Dropdown, Icon, Popover } from 'antd';
import { IconAngleDown } from '../../../../components/icons';
import {
    OperationAvailableStatesResponseObj,
    OperationTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../server/api';
import { getRentElementStateByCode, localize } from '../../../../localization';
import './OperationTypeSelectButton.less';
import { OperationProblemModalType } from '../operationModule/OperationProblemModal';
import { RRoundButton } from '../../../../components/button/rRoundButton';
import { IRootState } from '../../../../shared/reducers';
import { isDefined, joinClasses } from '../../../../shared/util/utils';
import {
    changeOperationType,
    createOperation,
    resetOperation,
    setOperationEndDateToCurrent,
    setOperationStartDateToCurrent,
} from '../reducers/operationForm.reducer';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { serverApi } from '../../../../server';
import { AxiosResponse } from 'axios';
import { ALL_OPERATION_TYPES, ALL_RENT_STATE_CODES } from '../../../../types';
import { showNotification } from '../../../../components/notification/showNotification';
import { unique } from '../../../../shared/util/unique';
import { canCreateCorrectionOperation } from '../../../../shared/util/permissionUtils';

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    currentOperationTypeCode?: OperationTypeCodeEnum;
    onApplyOperation: () => void;
    onChangeOperationType: (code: OperationTypeCodeEnum, correctionCode?: RentStateCodeEnum | undefined) => void;
    operationWarningMessage: any;
    errType: OperationProblemModalType;
    loading: boolean;
    disabled: boolean;
}

interface IState {
    loading: boolean;
    dropdownVisible: boolean;
    correctionDropdownVisible: boolean;
    availableCorrectionTargetStateCodes?: RentStateCodeEnum[];
    availableOperationTypeCodes?: OperationTypeCodeEnum[];
}

export class _OperationTypeSelectButton1 extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dropdownVisible: false,
            correctionDropdownVisible: false,
        };
    }

    listAvailableOperationTypes = async () => {
        let result: AxiosResponse<OperationAvailableStatesResponseObj> | undefined;
        try {
            this.setState({
                availableOperationTypeCodes: undefined,
                availableCorrectionTargetStateCodes: undefined,
                loading: true,
                dropdownVisible: false,
            });

            const { createdNewInstances, variantChanged, rentElementCanceledByCorrection, leftoverCleared } = this.props;

            result = await serverApi.listAvailableOperationTypes(this.props.businessAccountId, {
                activityFrameId: this.props.projectId || 0,
                activityFrameType: this.props.projectTemplate
                    ? RentActivityFrameTypeCodeEnum.TEMPLATE
                    : this.props.isSubrent
                    ? RentActivityFrameTypeCodeEnum.SUBRENT
                    : RentActivityFrameTypeCodeEnum.PROJECT,
                kitIds: unique(this.props.elements.filter((element) => isDefined(element.kitId)).map((element) => element.kitId || 0)),
                productIds: unique(
                    this.props.elements.filter((element) => isDefined(element.productId)).map((element) => element.productId)
                ),
                rentStateCodes: unique(this.props.elements.map((element) => element.stateCode || RentStateCodeEnum.NOTEXISTS)),
                createdNewInstances,
                variantChanged,
                rentElementCanceledByCorrection,
                leftoverCleared,
            });
        } catch (err) {
            // Ошибка получения доступных типов
            this.setState({
                loading: false,
                dropdownVisible: false,
            });
            showNotification('error', 'Не удалось загрузить список доступных типов операций');
        }
        if (result) {
            // Получены типы
            this.setState({
                availableOperationTypeCodes: result.data.operationTypeCodes,
                availableCorrectionTargetStateCodes: result.data.correctionTargetStateCodes,
                loading: false,
                dropdownVisible: true,
            });
        }
    };

    generateButtons = () => {
        let availableTypeCodes = this.state.availableOperationTypeCodes || [];
        let buttons = ALL_OPERATION_TYPES.filter((type) => availableTypeCodes.includes(type.code))
            .filter(
                (type) =>
                    type.code !== this.props.currentOperationTypeCode ||
                    (type.code === this.props.currentOperationTypeCode &&
                        this.props.currentOperationTypeCode === OperationTypeCodeEnum.CORRECT)
            )
            .filter(
                (type) =>
                    !(
                        type.code === OperationTypeCodeEnum.CORRECT &&
                        (!this.state.availableCorrectionTargetStateCodes || !this.state.availableCorrectionTargetStateCodes.length)
                    )
            )
            .map((type, index) => {
                return type.code !== OperationTypeCodeEnum.CORRECT ? (
                    <Button
                        key={index}
                        block
                        onClick={() => {
                            this.setState({ dropdownVisible: false, correctionDropdownVisible: false });
                            this.props.onChangeOperationType(type.code);
                        }}
                    >
                        <Icon className={'rr-operation-status-color-' + type.colorScheme} component={type.icon} />
                        <span>{localize(type.localizationCode)}</span>
                    </Button>
                ) : (
                    this.createCorrectionPopover(index)
                );
            });

        return <div className={'ant-popover-inner rr-grid-actions-popover-content'}>{buttons}</div>;
    };

    createCorrectionPopover = (key: number) => {
        let availableTypeCodes = this.state.availableCorrectionTargetStateCodes || [];
        let buttons = ALL_RENT_STATE_CODES.filter((type) => availableTypeCodes.includes(type.code)).map((type, index) => {
            return (
                <Button
                    key={`${key}_${index}`}
                    block
                    onClick={() => {
                        this.setState({ dropdownVisible: false, correctionDropdownVisible: false });
                        this.props.onChangeOperationType(OperationTypeCodeEnum.CORRECT, type.code);
                    }}
                >
                    <div className={`rr-dot rr-status-bg-${type.code}`}></div>
                    <span>{getRentElementStateByCode(type.code)}</span>
                </Button>
            );
        });

        let correctionType = ALL_OPERATION_TYPES.find((type) => type.code === OperationTypeCodeEnum.CORRECT);
        return (
            correctionType && (
                <Popover
                    key={key}
                    overlayClassName={'rr-operationForm-type-select-buttons-group__correction-popover-content'}
                    visible={this.state.correctionDropdownVisible}
                    onVisibleChange={this.onCorrectionDropdownVisibleChange}
                    content={<div className={'rr-grid-actions-popover-content'}>{buttons}</div>}
                    placement={'leftBottom'}
                    trigger={'hover'}
                    overlayStyle={{ width: 300 }}
                >
                    <Button block>
                        <Icon className={'rr-operation-status-color-' + correctionType.colorScheme} component={correctionType.icon} />
                        <span>{localize(correctionType.localizationCode)}</span>
                    </Button>
                </Popover>
            )
        );
    };

    onDropdownVisibleChange = (visible: boolean) => {
        this.setState({
            dropdownVisible: visible,
        });
    };

    onCorrectionDropdownVisibleChange = (visible: boolean) => {
        this.setState({
            correctionDropdownVisible: visible,
        });
    };

    onListAvailableOperationsButtonClick = (e) => {
        if (!this.state.dropdownVisible) {
            this.listAvailableOperationTypes();
        }
    };

    renderButton = (disabled: boolean = false) => {
        const btnData = ALL_OPERATION_TYPES.find((type) => type.code === this.props.currentOperationTypeCode);

        return (
            <div style={{ display: 'inline-block' }}>
                {btnData ? (
                    <RRoundButton
                        className={joinClasses(
                            disabled ? 'rr-round-btn-disabled' : undefined,
                            'rr-operationForm-type-select-buttons-group-create-button'
                        )}
                        loading={this.props.loading}
                        title={btnData.localizationCode}
                        icon={btnData.icon}
                        colorScheme={btnData.colorScheme as any}
                        onClick={this.props.onApplyOperation}
                    />
                ) : null}
            </div>
        );
    };

    render = () => {
        const { operationWarningMessage } = this.props;
        const btnData = ALL_OPERATION_TYPES.find((type) => type.code === this.props.currentOperationTypeCode);
        const buttonDisabled =
            operationWarningMessage || this.props.disabled /*(
                this.props.errType === OperationProblemModalType.ERROR && (
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.RENT ||
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.BOOK ||
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.PROLONG ||
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.RETURN ||
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                    this.props.currentOperationTypeCode === OperationTypeCodeEnum.LOSTNORETURN
                )
            )*/
                ? true
                : false;

        return (
            <div className={'rr-operationForm-type-select-buttons-group'}>
                {operationWarningMessage ? (
                    <Popover
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        autoAdjustOverflow={true}
                        content={operationWarningMessage}
                        overlayStyle={{ maxWidth: 400 }}
                    >
                        {this.renderButton(buttonDisabled)}
                    </Popover>
                ) : (
                    this.renderButton(buttonDisabled)
                )}
                {btnData && (
                    <Dropdown
                        overlayStyle={{ width: 300 }}
                        overlay={this.generateButtons()}
                        placement="bottomLeft"
                        trigger={['click']}
                        visible={this.state.dropdownVisible}
                        onVisibleChange={this.onDropdownVisibleChange}
                    >
                        <RRoundButton
                            style={{ cursor: 'pointer' }}
                            className={joinClasses(
                                buttonDisabled ? 'rr-round-btn-disabled' : undefined,
                                'rr-operationForm-type-select-buttons-group-select-button'
                            )}
                            //disabled={btnData[4]}
                            loading={!true}
                            icon={IconAngleDown}
                            colorScheme={btnData.colorScheme as any}
                            onClick={this.onListAvailableOperationsButtonClick}
                        />
                    </Dropdown>
                )}
            </div>
        );
    };
}

const mapStateToProps = (storeState: IRootState, ownProps: any) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        userPermissions: storeState.permissions.permissions,
        elements: storeState.operationForm.elements.entities,
        projectId: storeState.operationForm.projectId,
        isSubrent: storeState.operationForm.isSubrent,
        projectTemplate: storeState.operationForm.projectTemplate,
        canCreateCorrectionOperation: canCreateCorrectionOperation(storeState.permissions.permissions),
        createdNewInstances: storeState.operationForm.createdNewInstances,
        variantChanged: storeState.operationForm.variantChanged,
        rentElementCanceledByCorrection: storeState.operationForm.rentElementCanceledByCorrection,
        leftoverCleared: storeState.operationForm.leftoverCleared,
    };
};

const mapDispatchToProps = {
    createOperation,
    resetOperation,
    push,
    setOperationStartDateToCurrent,
    setOperationEndDateToCurrent,
    changeOperationType,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationTypeSelectButton = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_OperationTypeSelectButton1));
