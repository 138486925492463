import React from "react";
import moment from "moment";
import Combobox1 from 'rc-time-picker/lib/Combobox';
import {IconCrosshairsSolid} from "../icons";
import {Button, Icon} from "antd";

interface IProps {
    onChange: (value) => void;
    onOk?: () => void;
    onSelect?: (value) => void;
    onSetCurrentTime?: () => void;
    minutesStep: number;
    value?: moment.Moment;
    disabledHours?: number[];
    disabledMinutes?: number[];
}

interface IState {
    value?:moment.Moment;
}

export class TimeRangePickerPanel extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);
        this.state = {value: this.props.value || this.getCurrentMoment()};
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(this.props.value && this.state.value && this.props.value.valueOf() !== this.state.value.valueOf()){
            this.setState({value: this.props.value});
        }
    }

    onChange = (value: moment.Moment) => {
        this.setState({value});
        if(this.props.onSelect) this.props.onSelect(value);
    };

    getCurrentMoment = (): moment.Moment => {
        let currentTime = moment();
        let minutes = Math.floor(currentTime.minutes() / this.props.minutesStep) * this.props.minutesStep;
        return currentTime.milliseconds(0).seconds(0).minutes(minutes);
    };

    render() {
        //let step = 5;
        var hourOptions = new Array(24).fill(0).map((i,index) => index);//generateOptions(24, disabledHourOptions, hideDisabledOptions, 1);
        var minuteOptions = new Array(60 / this.props.minutesStep).fill(0).map((i,index) => index*this.props.minutesStep);//generateOptions(60, disabledMinuteOptions, hideDisabledOptions, 1);

        if(this.state.value){
            let min = this.state.value.minutes();
            if(!minuteOptions.includes(min)){
                let startMin = Math.floor(min / this.props.minutesStep) * this.props.minutesStep;
                minuteOptions.splice(minuteOptions.indexOf(startMin)+1, 0, min);
            }
        }

        let canSetCurrentTime = true;

        if((this.props.disabledHours && this.props.disabledHours.length > 0) || (this.props.disabledMinutes && this.props.disabledMinutes.length > 0)){
            let currentTime = moment().milliseconds(0).seconds(0);
            currentTime.minutes(Math.ceil(currentTime.minutes() / this.props.minutesStep) * this.props.minutesStep);

            let h = (this.props.disabledHours && this.props.disabledHours.length > 0) ? this.props.disabledHours[this.props.disabledHours.length - 1] : 0;
            let m = (this.props.disabledMinutes && this.props.disabledMinutes.length > 0) ? this.props.disabledMinutes[this.props.disabledMinutes.length - 1] : 0;

            if(h < 23) h += 1;
            if(m < 60) m += this.props.minutesStep;

            let disTime = currentTime.clone().hours(h).minutes(m);

            if(disTime.diff(currentTime) > 0) canSetCurrentTime = false;
        }

        return (
            <div style={{width: 112}}
                 className={'rr-time-picker-panel ant-time-picker-panel ant-time-picker-panel-narrow ant-time-picker-panel-column-2'}>
                <div className={'ant-time-picker-panel-inner'}>
                    <Combobox1
                        className={'ant-time-picker-panel ant-time-picker-panel-narrow ant-time-picker-panel-column-2'}
                        format={'HH:mm'}
                        prefixCls={'ant-time-picker-panel'}
                        // value={moment('15:10:00', 'HH:mm:ss')}
                        value={this.state.value || moment('15:10:00', 'HH:mm:ss')}

                        showHour={true}
                        showMinute={true}
                        showSecond={false}

                        disabledHours={() => this.props.disabledHours}
                        disabledMinutes={() => this.props.disabledMinutes}
                        disabledSeconds={() => []}

                        use12Hours={false}

                        hourOptions={hourOptions}
                        minuteOptions={minuteOptions}

                        onCurrentSelectPanelChange={(panelType) => {
                            console.log('onCurrentSelectPanelChange', panelType);
                        }}

                        onChange={this.onChange}

                        //disabledSeconds={()=>new Array(60).fill(0).map((i,index) => index)}
                        //hideDisabledOptions minuteStep={10}
                    />

                    <div style={{display: 'flex', width: '100%', borderTop: '1px solid #f0f0f0', padding: '8px 0'}}>
                        <div style={{display: 'flex', width: '50%', alignItems: 'center', userSelect: 'none', justifyContent: 'center', cursor: canSetCurrentTime ? 'pointer' : undefined, fontSize: 18}} onClick={canSetCurrentTime ? () => {
                            if (this.props.onSetCurrentTime) {
                                this.props.onSetCurrentTime();
                            } else {
                                let currentTime = moment();
                                let minutes = Math.ceil(currentTime.minutes() / this.props.minutesStep) * this.props.minutesStep;
                                this.onChange(currentTime.milliseconds(0).seconds(0).minutes(minutes));
                            }
                        } : undefined}>
                            <Icon component={IconCrosshairsSolid} style={{color: canSetCurrentTime ? '#00afee' : '#cccccc'}} />
                        </div>
                        <div style={{display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'center'}}>
                            <Button className={'rr-btn-blue'} style={{padding: '4px 14px', height: 'unset', lineHeight: 'unset'}} onClick={() => {
                                this.props.onChange(this.state.value);
                                if (this.props.onOk) this.props.onOk();
                            }}>OK</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
