import { FC, useContext } from 'react';
import { ActivityFrameItemContext } from '../../../../../../../../context/CalendarItemContext';
import { ActivitySubrentBaseBorder } from '../../../Borders/activityFrames/subrent/ActivitySubrentBaseBorder';

export const ActivityFramesBorders: FC = () => {
    const { item } = useContext(ActivityFrameItemContext);

    const { elementType } = item;

    if (elementType === 'activitySubrent') {
        return <ActivitySubrentBaseBorder />;
    }

    return null;
};
