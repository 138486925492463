import React, {ReactNode} from 'react';
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import classNames from "classnames";
import {IconPlus} from "../../icons";
import './addButton.less';

interface AddButtonProps {
    disabled?: ButtonProps['disabled'];
    style?: ButtonProps['style'];
    className?: ButtonProps['className'];
    onClick?: ButtonProps['onClick'];
    label?: ReactNode;
}

export const AddButton = ({style, className, disabled, onClick, label}: AddButtonProps) => {
    return (
        <Button style={style} type={'link'} className={classNames('rr-add-btn', className)}
                disabled={disabled} onClick={onClick}>
            <Icon component={IconPlus}/>{label === undefined ? 'Добавить' : label}
        </Button>
    );
};
