import React, {ChangeEvent, FC, memo, useCallback} from 'react';
import {Card, Icon, Input} from 'antd';
import classNames from 'classnames';
import {GridHeader} from '../GridHeader/GridHeader';
import {IconIconsSolid} from '../../../../../../../../../components/icons';
import {LocalizationEnum, localize} from '../../../../../../../../../localization';
import RoundButton from '../../../../../../../../../components/button/roundButton';
import {Grid} from '../../../../../../../../../components/grid/Grid';
import {productCompositionColumns} from '../../../columns/productCompositionColumns';
import {PRODUCT_COMPOSITION_GRID_NAME} from '../../../ProductComposition';
import {useGridData} from '../../../hooks/useGridData';
import {KitInfoRead} from '../../../../../../../../../server';
import {productCompositionFiltersDescription} from './filters/productCompositionFilters.description';
import './ProductCompositionStaticGrid.less';
import {usePageURLParams} from '../../../../../../../../../core/hooks/urlParams/usePageURLParams';

interface ProductCompositionStaticGridProps {
    kit: KitInfoRead;
    setIsEdit: (value: boolean) => void;
    excludeColumns: string[];
}

export const ProductCompositionStaticGrid: FC<ProductCompositionStaticGridProps> = memo((props) => {
    const { kit, setIsEdit, excludeColumns } = props;

    const { pageParams, updatePageParams } = usePageURLParams({
        pageName: PRODUCT_COMPOSITION_GRID_NAME,
        paramsDescription: productCompositionFiltersDescription,
    });

    const { gridData, filtered } = useGridData(kit, pageParams);

    const onPageSizeChanged = useCallback(
        (size: number) => {
            updatePageParams({
                limit: size,
                page: 1,
            });
        },
        [updatePageParams]
    );

    const onPageChanged = useCallback(
        (page: number) => {
            updatePageParams({
                page,
            });
        },
        [updatePageParams]
    );

    const onSearch = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            updatePageParams({
                search: event.target.value,
                page: undefined,
            });
        },
        [updatePageParams]
    );

    return (
        <Card
            className={classNames('product-composition-grid-card')}
            title={
                <GridHeader
                    TitleContent={
                        <div style={{ height: '38px', marginLeft: '26px' }} className={pageParams.search ? 'rr-kit-components-search' : ''}>
                            <Input
                                className={classNames('product-components-search', 'rr-input-gray', {
                                    highlighted: pageParams.search,
                                })}
                                prefix={<Icon type={'search'} />}
                                placeholder="Поиск"
                                maxLength={255}
                                allowClear={true}
                                autoFocus={true}
                                onChange={onSearch}
                                value={pageParams.search}
                            />
                        </div>
                    }
                    Button={
                        <RoundButton
                            colorScheme={'defaultSecondary'}
                            style={{ marginRight: 16 }}
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            <Icon component={IconIconsSolid} />
                            <span>{localize(LocalizationEnum.PAGE__ACTIONS__CUSTOMIZE_COMPONENTS)}</span>
                        </RoundButton>
                    }
                />
            }
        >
            <Grid
                excludeColumns={excludeColumns}
                columns={productCompositionColumns}
                data={gridData}
                filtered={filtered}
                indexFieldName={'id'}
                selectable={false}
                entityType={PRODUCT_COMPOSITION_GRID_NAME}
                defaultSorted={'memberOrder'}
                defaultSortDesc
                gridName={PRODUCT_COMPOSITION_GRID_NAME}
                onPageChanged={onPageChanged}
                onPageSizeChanged={onPageSizeChanged}
                pageSize={pageParams.limit ?? 10}
                currentPage={pageParams.page}
            />
        </Card>
    );
});
