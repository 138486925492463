import React, {FC, ReactElement} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {ArchiveBadge} from "../../../archiveBadge/archiveBadge";
import {HeaderNavigationBlock} from "../../../page/headerNavigationBlock/HeaderNavigationBlock";

export interface HeaderTitleLink {
    name: string | ReactElement;
    link: string;
    bold?: boolean;
}

interface HeaderTitleProps {
    icon: ReactElement;
    archive?: boolean;
    title: string;
    badge?: ReactElement;
    links?: HeaderTitleLink[]
}

export const HeaderTitle: FC<HeaderTitleProps> = ({title, archive, badge, links, icon}) => {
    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        {<span className={'icon'}>{icon}</span>}
                        <span className={'title'} style={{maxWidth}}>
                            {title}
                        </span>
                        {badge}
                    </div>
                    <div className={'bottom-section'}>
                        {
                            links?.map((link, index, arr) => {
                                return [
                                    <Link to={link.link} className={classNames('link', link.bold && 'bold')}>
                                        {link.name}
                                    </Link>,
                                    index < arr.length - 1 && <span>/</span>
                                ];
                            })
                        }
                        {archive && <ArchiveBadge style={{marginLeft: 4}}/>}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
}