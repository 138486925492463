import React from 'react';
import Main1Module from './main1';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { LoginModule } from '../login/login';
import { LocalizationEnum, localize } from '../../localization';
import { Route, Switch } from 'react-router-dom';
import { CompanyRegistration } from '../companyRegistration/companyRegistration';

interface IProps extends StateProps {}

class MainModule extends React.PureComponent<IProps> {
    render() {
        const { userLoggedIn, isLoadingUser, location } = this.props;

        return isLoadingUser ? (
            <div>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</div>
        ) : userLoggedIn ? (
            <Switch>
                <Route path="/companyRegistration" component={CompanyRegistration} exact />
                <Route path="/" component={Main1Module} />
            </Switch>
        ) : (
            <LoginModule />
        );
    }
}

const mapStateToProps = ({ oidc, router }: IRootState) => ({
    isLoadingUser: oidc.isLoadingUser,
    userLoggedIn: oidc.user && oidc.user.expired === false,
    location: router.location,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(MainModule);
