import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import moment from "moment";
import {CustomRangePicker} from "../../../../../components/datePicker/CustomRangePicker";
import {OperationElement, setRentPeriodForElement, TimeTable, updateAnyTimetables} from "../../reducers/operationForm.reducer";
import {OperationTypeCodeEnum, RentStateCodeEnum} from "../../../../../server";
import {findTimeTable, getLeftoverInstances, isOrderOperation} from "../../utils/utils";
import {ProductUtils} from "../../../../../shared/util/productUtils";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../../shared/reducers";
import {getShiftCountFromDates} from "../../../../../shared/util/utils";
import {getStore} from "../../../../../../index";
import {NomenclatureOnInterval, rentElementToNomenclatureBaseObj, loadNomenclaturesOnIntervalsIfNeed, getDateIntervalsForCalendar} from "../../utils/nomenclaturesUtils";

interface Props {
    children: ReactNode;
    value?: any;
    onEditing?: (isEditing: boolean) => void;
    canBeEdit: boolean;
    element: OperationElement;
}

export const InlinePeriodPicker = (props: Props) => {
    const [editable, setEditable] = useState<boolean|undefined>(undefined);
    const [beforeEditValue, setBeforeEditValue] = useState<[Date, Date] | undefined>();
    const [beforeEditShiftCount, setBeforeEditShiftCount] = useState<number | undefined>();

    const setPrevValues = useCallback(()=>{
        if(beforeEditValue) getStore().dispatch(setRentPeriodForElement(props.element.id, beforeEditValue, beforeEditShiftCount));
        setEditable(false);
    }, [beforeEditValue, beforeEditShiftCount, props.element.id]);

    useEffect(() => {
        if(editable !== undefined){
            props.onEditing?.(editable);
        }
    }, [editable]);

    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === "Escape" && editable) {
                setPrevValues();
            }
        }
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [editable, setPrevValues]);

    let hasOwnShiftLength = props.element.hasOwnShiftLength;
    let shiftLengthInMin = props.element.shiftLengthInMinutes;
    let requiredTimeIndentBetweenElementsInMinutes = props.element.requiredTimeIndentBetweenElementsInMinutes;
    let productHasOwnRequiredTimeIndentBetweenElements = props.element.productHasOwnRequiredTimeIndentBetweenElements;
    let shiftCount = props.element.shiftCount;

    const operationTypeCode = useSelector((state: IRootState) => state.operationForm.typeCode);
    const operationTypeCorrectionCode = useSelector((state: IRootState) => state.operationForm.targetStateCode);
    const isSubrentOperation = useSelector((state: IRootState) => state.operationForm.isSubrent);
    const timeTables = useSelector((state: IRootState) => state.operationForm.timeTables);
    const recalculateShiftCount = useSelector((state: IRootState) => state.userSettings.operationFormRecalculateShiftCount);
    const shiftCountRoundingType = useSelector((state: IRootState) => state.operationForm.shiftCountRoundingType);

    let intervals = getIntervals(props.element, operationTypeCode, operationTypeCorrectionCode, isSubrentOperation, timeTables);

    const [from, until] = getDateIntervalsForCalendar(props.value[0], props.value[1]);

    useEffect(() => {
        if(editable){
            const n:NomenclatureOnInterval = {...rentElementToNomenclatureBaseObj(props.element), from, until};
            loadNomenclaturesOnIntervalsIfNeed([n]).then((data)=>{
                getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            }).catch((error)=>{
                console.error(error);
            });
        }
    }, [editable, from, until]);

    const setRentPeriod = (dates) => {
        let startDate = dates[0] ? dates[0].toDate() : undefined;
        let endDate = dates[1] ? dates[1].toDate() : undefined;
        let shiftLengthInMin = props.element.shiftLengthInMinutes;
        let diff = startDate && endDate ? getShiftCountFromDates(moment(startDate), moment(endDate), shiftLengthInMin, shiftCountRoundingType) : 0;
        getStore().dispatch(setRentPeriodForElement(props.element.id, [startDate, endDate], recalculateShiftCount ? diff : undefined));
    };

    return (
        <CustomRangePicker
            disabled={!props.canBeEdit}
            allowClear={false}
            intervals={intervals}
            showTime
            value={[moment(props.value[0]), moment(props.value[1])]}
            onChange={(dates, datesStrings) => {
                setRentPeriod(dates);
            }}
            open={editable}
            shiftLengthInMin={shiftLengthInMin}
            hasOwnShiftLength={hasOwnShiftLength}
            requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
            productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}
            shiftCount={shiftCount}
            onOpenChange={(open)=>{
                setEditable(open);
                if(open){
                    setBeforeEditValue([props.value[0], props.value[1]]);
                    setBeforeEditShiftCount(props.element.shiftCount);
                }
            }}
            align={{points: ['bl', 't'], offset: [0, -6]}}
        >
            <div className={props.canBeEdit ? 'rr-inline-editable' : undefined}>{props.children}</div>
        </CustomRangePicker>
    );
};

const getIntervals = (elementRecord: OperationElement, operationTypeCode: OperationTypeCodeEnum | undefined, operationTypeCorrectionCode: RentStateCodeEnum | undefined, isSubrentOperation: boolean | undefined, timeTables: TimeTable[]) => {
    let rentPeriodStartDate = elementRecord.rentPeriodStartDate,
        rentPeriodEndDate = elementRecord.rentPeriodEndDate;
    let intervals: [number, number][] = [];
    // Кол-во экземпляров
    let instanceCount = elementRecord.instanceCount;
    let timeTable = findTimeTable(timeTables, {
        productId: elementRecord.productId,
        variantId: elementRecord.variantId
    });

    let yyy = isSubrentOperation ? -1 : 1;

    if (timeTable && rentPeriodStartDate && rentPeriodEndDate) {
        let map = isOrderOperation(operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;

        intervals = map;

        let rentPeriodStartDate1 = rentPeriodStartDate;
        let rentPeriodEndDate1 = rentPeriodEndDate;

        if (elementRecord && elementRecord.id < 0) {
            // Для НОВОГО нужно компенсировать что вычли из карты
            intervals = ProductUtils.getNewIntervals(intervals, [
                // Компенсируем что было в начале
                [yyy * -elementRecord.instanceCount, elementRecord.rentPeriodStartDate.getTime(), elementRecord.rentPeriodEndDate.getTime()],
            ]);

            //let availableIntervals = ProductUtils.getNewIntervals(intervals, []);

            // Редактирование нового
            //availableInstanceCount = ProductUtils.getMinimumValueInInterval(availableIntervals, rentPeriodStartDate1.getTime() - requiredIndent, rentPeriodEndDate1.getTime() + requiredIndent);

            // Вычитаем столько сколько хотим добавить
            intervals = ProductUtils.getNewIntervals(intervals, [[yyy * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()]]);
        } else if (elementRecord && elementRecord.rentPeriodStartDateOriginal && elementRecord.rentPeriodEndDateOriginal) {
            // Редактирование существующего
            // Для заявки и черновика нужно компенсировать что пришло с сервера

            let elementIntervals: [number, number, number, string][] = [];

            let xxx = elementRecord.stateCode === RentStateCodeEnum.BOOKED ||
                elementRecord.stateCode === RentStateCodeEnum.ORDERED ||
                elementRecord.stateCode === RentStateCodeEnum.RENT ||
                elementRecord.stateCode === RentStateCodeEnum.RETURNED ||
                elementRecord.stateCode === RentStateCodeEnum.RETURNEDBROKEN ||
                elementRecord.stateCode === RentStateCodeEnum.LOSTDURINGRENT ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENT
            ;

            let xxx1 = elementRecord.stateCode === RentStateCodeEnum.BOOKED ||
                //this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED ||
                elementRecord.stateCode === RentStateCodeEnum.RENT ||
                elementRecord.stateCode === RentStateCodeEnum.RETURNED ||
                elementRecord.stateCode === RentStateCodeEnum.RETURNEDBROKEN ||
                elementRecord.stateCode === RentStateCodeEnum.LOSTDURINGRENT ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
                elementRecord.stateCode === RentStateCodeEnum.SUBRENT
            ;

            let isCorrectCancelOperation = operationTypeCode === OperationTypeCodeEnum.CORRECT && operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

            let leftoverInstances = getLeftoverInstances(elementRecord.instanceIdsOriginal, elementRecord.instanceIds);
            elementIntervals.push([yyy * (isCorrectCancelOperation ? 0 : 1) * -elementRecord.instanceCount, elementRecord.rentPeriodStartDate.getTime(), elementRecord.rentPeriodEndDate.getTime(), 'Компенсируем текущее значение']);
            let leftover = Math.max(0, elementRecord.instanceCountOriginal - elementRecord.instanceCount, leftoverInstances.length);
            if (elementRecord.keepLeftover) elementIntervals.push([yyy * ((xxx1 || elementRecord.stateCode === RentStateCodeEnum.ORDERED) && elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0) * -leftover, elementRecord.rentPeriodStartDateOriginal.getTime(), elementRecord.rentPeriodEndDateOriginal.getTime(), 'Компенсируем остаток' + [yyy, xxx, (elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0)]]);

            intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);
            elementIntervals = [];
            elementIntervals.push([yyy * (isCorrectCancelOperation ? 0 : 1) * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime(), 'Вычитаем текущее значение']);
            leftoverInstances = getLeftoverInstances(elementRecord.instanceIdsOriginal, elementRecord.instanceIds); //TODO было this.state.selectedInstances
            leftover = Math.max(0, elementRecord.instanceCountOriginal - instanceCount, leftoverInstances.length);
            if (elementRecord.keepLeftover) elementIntervals.push([yyy * ((xxx1/* || this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED*/) && elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0) * leftover, elementRecord.rentPeriodStartDateOriginal.getTime(), elementRecord.rentPeriodEndDateOriginal.getTime(), 'Вычитаем остаток' + [elementRecord.instanceCountOriginal - instanceCount, leftoverInstances.length, yyy, xxx, elementRecord.stateCode]]);
            intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);
        }
    }

    return intervals;
};
