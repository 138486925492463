import React, { FC } from 'react';
import { Icon, Radio } from 'antd';
import { IconClose } from '../../../../../../components/icons';
import './YesNoCheckbox.less';

interface CheckboxProps {
    value: boolean;
    onChange: (value?: boolean) => void;
}

export const YesNoCheckbox: FC<CheckboxProps> = (props) => {
    const { value, onChange } = props;

    return (
        <div className={'radio-buttons-group-container'}>
            <Radio.Group
                className={'radio-buttons-group'}
                value={value}
                onChange={(event) => {
                    onChange(event.target.value as boolean);
                }}
            >
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
            {value != null && (
                <Icon
                    className={'icon-close'}
                    component={IconClose}
                    onClick={() => {
                        onChange(undefined);
                    }}
                />
            )}
        </div>
    );
};
