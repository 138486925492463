import React from 'react';
import './Table.less';
import './Table3.less';
import { EntityActionType, EntityType } from '../../../index';
import { TableColumn } from './Table';
import { ReactTable } from './grid/ReactTable';
import { ReactTableController } from './grid/ReactTableController';
import { DndProps } from './grid/ReactTableBody';

interface IState {}

interface IProps<T extends object> {
    columns: Array<TableColumn<T>>;
    excludeColumns: Array<string>;
    data: any[] | null;
    selectable: boolean;
    indexFieldName?: string;
    entityType: EntityType;
    rowPopoverComponent?: any;
    rowPopoverComponentProps?: any;
    defaultSortDesc?: boolean;
    defaultSorted?: string;
    name: string;
    rowId: string;

    onSortedChange?(id: string, desc: boolean): void;

    onSelectionChanged?(selection: any[]): void;

    getRowProps?(state, rowInfo, column): void;

    onRowAction?(entity: any, action: string, instanceCount?: number): void;
    onRowEditAction?(entity: any, action: EntityActionType, instanceCount?: number): void;

    onRowClick?(entity: any): void;
    onCellAction?(dataIndex: string, record: Record<string, any>): void;

    onColumnRemove?(id?: string): void;

    getUniqId?(data: any): string;

    alwaysRedraw: boolean;
    page: number;
    onPage: number;
    selectWidth?: number;

    dndProps?: DndProps;
}

export class Table<T extends object> extends React.Component<IProps<T>, IState> {
    private clearSelectionFunction;
    private tableController: ReactTableController | undefined;

    public static defaultProps = {
        indexFieldName: 'id',
        excludeColumns: [],
        defaultSortDesc: false,
    };

    clearSelection = () => {
        if (this.clearSelectionFunction) this.clearSelectionFunction();
        if (this.tableController) this.tableController.resetRowSelection();
    };

    readyCB = (clearSelectionFunction) => {
        this.tableController = clearSelectionFunction;
    };

    shouldComponentUpdate(nextProps: Readonly<IProps<T>>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (
            JSON.stringify(nextProps.columns.map((column) => column.dataIndex)) ===
                JSON.stringify(this.props.columns.map((column) => column.dataIndex)) &&
            nextProps.data === this.props.data &&
            nextProps.defaultSortDesc === this.props.defaultSortDesc &&
            nextProps.defaultSorted === this.props.defaultSorted &&
            nextProps.entityType === this.props.entityType &&
            JSON.stringify(nextProps.excludeColumns) === JSON.stringify(this.props.excludeColumns) &&
            nextProps.getRowProps === this.props.getRowProps &&
            nextProps.indexFieldName === this.props.indexFieldName &&
            nextProps.name === this.props.name &&
            nextProps.onColumnRemove === this.props.onColumnRemove &&
            nextProps.onRowAction === this.props.onRowAction &&
            nextProps.onSelectionChanged === this.props.onSelectionChanged &&
            nextProps.onSortedChange === this.props.onSortedChange &&
            nextProps.rowId === this.props.rowId &&
            nextProps.rowPopoverComponent === this.props.rowPopoverComponent &&
            nextProps.rowPopoverComponentProps === this.props.rowPopoverComponentProps &&
            nextProps.selectable === this.props.selectable &&
            nextProps.selectWidth === this.props.selectWidth
        ) {
            return false;
        }
        return true;
    }

    render = () => {
        return this.props.data ? (
            <ReactTable
                data={this.props.data || []}
                columns={this.props.columns}
                name={this.props.name}
                excludeColumns={this.props.excludeColumns}
                onColumnRemove={this.props.onColumnRemove}
                rowPopoverComponent={this.props.rowPopoverComponent}
                rowPopoverComponentProps={this.props.rowPopoverComponentProps}
                onRowAction={this.props.onRowAction}
                onRowEditAction={this.props.onRowEditAction}
                entityType={this.props.entityType}
                selectable={this.props.selectable}
                onSelectionChanged={this.props.onSelectionChanged}
                onSortedChange={this.props.onSortedChange}
                defaultSorted={this.props.defaultSorted}
                defaultSortDesc={this.props.defaultSortDesc}
                rowId={this.props.rowId}
                getRowProps={this.props.getRowProps}
                readyCB={this.readyCB}
                getUniqId={this.props.getUniqId}
                alwaysRedraw={this.props.alwaysRedraw}
                page={this.props.page}
                onPage={this.props.onPage}
                selectWidth={this.props.selectWidth}
                onRowClick={this.props.onRowClick}
                onCellAction={this.props.onCellAction}
                dndProps={this.props.dndProps}
            />
        ) : null;
    };
}
