import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CounterpartyActivityTypeCodeEnum, InstanceStateCodeEnum } from '../../../../server/api';
import React, { ReactNode } from 'react';
import { Select } from 'antd';
import { getStoreState } from '../../../../../index';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';

export const filters = [
    {
        fields: [
            {
                id: 'productId',
                type: FormItemType.Hidden,
            },
            {
                id: 'isVariants',
                type: FormItemType.Hidden,
            },
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'variantId',
                className: 'rr-select-gray',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listProductVariants',
                        nameField: 'name',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductVariantById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId'),
                        // productId: 19651,
                        showSearch: true,
                        renderer: (data, index) => {
                            return (
                                <Select.Option key={data['id']}>
                                    {data['data']['standard']}
                                    {data['name']}
                                </Select.Option>
                            );
                        },
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                visible: (getFieldValue) => getFieldValue('isVariants'),
            },
            {
                label: 'Статус учета',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: () => {
                    const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());
                    const items: { value: string | undefined; name: ReactNode | undefined }[] = [
                        {
                            value: InstanceStateCodeEnum.NEW,
                            name: (
                                <div style={{ display: 'flex' }}>
                                    <div className="product-instance-popover-status">
                                        <div className="rr-product-instance-circle-NEW"></div>
                                    </div>
                                    {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__NOT_IN_STOCK)}
                                </div>
                            ),
                        },
                        {
                            value: InstanceStateCodeEnum.ACTIVE,
                            name: (
                                <div style={{ display: 'flex' }}>
                                    <div className="product-instance-popover-status">
                                        <div className="rr-product-instance-circle-ACTIVE"></div>
                                    </div>
                                    {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__ACTIVE)}
                                </div>
                            ),
                        },
                        {
                            value: InstanceStateCodeEnum.UNDERMAINTENANCE,
                            name: (
                                <div style={{ display: 'flex' }}>
                                    <div className="product-instance-popover-status">
                                        <div className="rr-product-instance-circle-UNDERMAINTENANCE"></div>
                                    </div>
                                    {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__UNDERMAINTENANCE)}
                                </div>
                            ),
                        },
                        {
                            value: InstanceStateCodeEnum.DECOMMISSIONED,
                            name: (
                                <div style={{ display: 'flex' }}>
                                    <div className="product-instance-popover-status">
                                        <div className="rr-product-instance-circle-DECOMMISSIONED"></div>
                                    </div>
                                    {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__DECOMMISSIONED)}
                                </div>
                            ),
                        },
                    ];

                    if (subrentModuleEnabled) {
                        items.push(
                            { value: undefined, name: undefined },
                            {
                                value: InstanceStateCodeEnum.SUPPLIERACTIVE,
                                name: (
                                    <div style={{ display: 'flex' }}>
                                        <div className="product-instance-popover-status">
                                            <div className={`rr-product-instance-circle-${InstanceStateCodeEnum.SUPPLIERACTIVE}`}></div>
                                        </div>
                                        Активный чужой
                                    </div>
                                ),
                            },
                            {
                                value: InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED,
                                name: (
                                    <div style={{ display: 'flex' }}>
                                        <div className="product-instance-popover-status">
                                            <div
                                                className={`rr-product-instance-circle-${InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED}`}
                                            ></div>
                                        </div>
                                        Списан чужой
                                    </div>
                                ),
                            }
                        );
                    }
                    return items;
                },
            },
            {
                label: 'Поставщик',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'supplierId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: {
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    getEntityOperationName: 'getRenterById',
                    showSearch: true,
                    filters: [
                        `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.SUPPLIERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
                        'archive;EQ;false',
                    ],
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                id: 'hideArchive',
                label: 'Скрыть',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Списанные', value: 'decomissioned' },
                    { name: 'Чужие', value: 'foreign' },
                    { name: 'Чужие и списанные', value: 'foreign+decomissioned' },
                ],
            },
        ],
    },
];
