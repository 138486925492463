import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    CustomFieldRecordList,
    PricingSchemeRecord,
    PricingSchemeRecordList,
    PricingSchemeTypeCodeEnum,
    serverApi,
    ServerError,
} from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { getServerError, getStateFromPath2 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import { pricingSchemesModuleListColums } from '../pricingSchemesModuleListColums';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { PricingSchemeParamsUtils } from '../../../../../core/utils/pricingSchemeUtils';

const ACTION_TYPES = {
    LOAD_ENTITIES: 'pricingSchemes/LOAD_ENTITIES',
    RESET: 'pricingSchemes/RESET',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'name' as string | undefined,
    sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as PricingSchemeTypeCodeEnum | undefined,
    page: 1,
    limit: 10,
    hideStandardFields: undefined as boolean | undefined,
    mode: undefined as string | undefined,
    id: undefined as number | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<PricingSchemeRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

export type PricingSchemesState = Readonly<typeof initialState>;

// Reducer

export default (state: PricingSchemesState = initialState, action): PricingSchemesState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp = /\/(\d+)\/settings\/pricingSchemes/;

            if (regexp.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        typeCode: params.typeCode !== undefined ? params.typeCode : initialParamsState.typeCode,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideStandardFields:
                            params.hideStandardFields !== undefined ? params.hideStandardFields : initialParamsState.hideStandardFields,
                        mode: params.mode !== undefined ? params.mode : initialParamsState.mode,
                        id: params.id !== undefined ? +params.id : initialParamsState.id,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as PricingSchemeRecordList).records,
                filteredCount: (action.payload.data as CustomFieldRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: PricingSchemesState = (getState() as IRootState).pricingSchemes;
        let { params } = state;
        const filters: string[] = PricingSchemeParamsUtils.createRequestFilters(params);
        const sortBy = getAvailableGridSortById(params.sortBy, pricingSchemesModuleListColums);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listPricingSchemes(
                businessAccountId,
                params.search,
                params.limit,
                (params.page - 1) * params.limit,
                sortBy,
                params.sortOrder,
                params.typeCode
            ),
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
