import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { ListTransportationsParams } from '../api/transportations.api.types';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { ProjectRecord, ProjectStateCodeEnum, TransportationStateCodeEnum, VehicleTypeCodeEnum } from '../../../../../../server';
import React, { ReactNode } from 'react';
import {
    FiltersDateTimeRangePicker,
    FiltersDateTimeRangePickerProps,
} from '../../../../../../components/datePicker/FiltersDateTimeRangePicker';
import { EntityRemoteSelect, EntityRemoteSelectProps } from '../../../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Select } from 'antd';
import { Dot } from '../../../../../../components/dot/dot';

export const transportationsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListTransportationsParams>([
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    {
                        name: 'Запланирована',
                        value: TransportationStateCodeEnum.PLANNED,
                    },
                    {
                        name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                        value: TransportationStateCodeEnum.INPROGRESS,
                    },
                    {
                        name: 'Завершена',
                        value: TransportationStateCodeEnum.FINISHED,
                    },
                    {
                        name: 'Отменена',
                        value: TransportationStateCodeEnum.CANCELED,
                    },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Погрузка с',
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'dateTimeOfLoading',
                type: FormItemType.Component,
                component: FiltersDateTimeRangePicker,
                componentProps: (): FiltersDateTimeRangePickerProps => ({
                    utc: true,
                    theme: 'gray',
                }),
            },
            {
                label: 'Скрыть отменённые',
                id: 'hideCancelled',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Грузоотправитель',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'shipperId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                style: { minWidth: 180 },
                componentProps: (): EntityRemoteSelectProps => {
                    return {
                        operationName: 'listRenters',
                        nameField: 'shortName',
                        getEntityOperationName: 'getRenterById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ['archive;EQ;false'],
                        withExtraOption: true,
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Адрес погрузки',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'loadingLocationId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                style: { minWidth: 180 },
                componentProps: () => {
                    return {
                        operationName: 'listLocations',
                        nameField: 'shortName',
                        getEntityOperationName: 'getLocationById',
                        sortBy: 'lastUpdateDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ['archive;EQ;false'],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Выгрузка с',
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'dateTimeOfUnloading',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
            {
                label: 'Грузополучатель',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'consigneeId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: () => {
                    return {
                        operationName: 'listRenters',
                        nameField: 'shortName',
                        getEntityOperationName: 'getRenterById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ['archive;EQ;false'],
                        withExtraOption: true,
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Адрес разгрузки',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'unloadingLocationId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: () => {
                    return {
                        operationName: 'listLocations',
                        nameField: 'shortName',
                        getEntityOperationName: 'getLocationById',
                        sortBy: 'lastUpdateDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ['archive;EQ;false'],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: [
                            `stateCode;IN;${[ProjectStateCodeEnum.NEW, ProjectStateCodeEnum.INPROGRESS].join(';')}`,
                            'archive;EQ;false',
                        ],
                        renderer: (data: { name: ReactNode; id: number; data?: ProjectRecord }) => {
                            const isNewStatus = data.data?.stateCode === ProjectStateCodeEnum.NEW;
                            const disabled = isNewStatus && form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS;
                            return (
                                <Select.Option key={data.id} disabled={disabled}>
                                    {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
                                    {data['name']}
                                </Select.Option>
                            );
                        },
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Автомобиль',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'carId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: () => {
                    return {
                        operationName: 'listVehicles',
                        nameField: 'shortName',
                        getEntityOperationName: 'getVehicleById',
                        sortBy: 'lastUpdateDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: [
                            `type;IN;${VehicleTypeCodeEnum.TRACTORTRUCK};${VehicleTypeCodeEnum.STRAIGHTTRUCK};${VehicleTypeCodeEnum.MINIBUS}`,
                            'archive;EQ;false',
                        ],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Прицеп',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'trailerId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: () => {
                    return {
                        operationName: 'listVehicles',
                        nameField: 'shortName',
                        getEntityOperationName: 'getVehicleById',
                        sortBy: 'lastUpdateDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: [`type;IN;${VehicleTypeCodeEnum.SEMITRAILER};${VehicleTypeCodeEnum.TRAILER}`, 'archive;EQ;false'],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Водитель',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'driverId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                component: EntityRemoteSelect,
                componentProps: () => {
                    return {
                        operationName: 'listContacts',
                        nameField: 'personShortName',
                        getEntityOperationName: 'getContactById',
                        sortBy: 'lastUpdateDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ['archive;EQ;false'],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
        ],
    },
]);
