import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { CrewMemberRecord, InstanceRecord } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import DashboardDate from '../../../../../../components/dashboardDate/dashboardDate';
import React from 'react';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { isDefined } from '../../../../../../shared/util/utils';
import { tableCellMoneyRenderer } from '../../../../../../components/grid/renderers/tableCellMoneyRenderer';

const personShortNameSizes = GridColumnCreator.getColumnSizes(60);

export const crewMembersListColumns: TableColumn<CrewMemberRecord>[] = [
    GridColumnCreator.createShortNameColumn<CrewMemberRecord>(
        'crewMember',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.personShortName,
        }),
        {
            title: 'ФИО',
            dataIndex: 'personShortName',
            minWidth: personShortNameSizes.min,
            width: personShortNameSizes.width,
            maxWidth: personShortNameSizes.max,
            resizable: true,
            dontHide: true,
        }
    ),
    GridColumnCreator.createStatusColumn<CrewMemberRecord>(),
    {
        title: 'Число специальностей',
        dataIndex: 'professionCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            if (isDefined(value)) {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', width: 'unset', color: !value ? '#aaaaaa' : undefined }}>
                        {value}
                    </div>
                );
            } else {
                return undefined;
            }
        },
    },
    {
        title: 'Ставка за смену',
        dataIndex: 'defaultRates.ratePerShift',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            return tableCellMoneyRenderer(value);
        },
    },
    {
        title: 'Ставка за час',
        dataIndex: 'defaultRates.ratePerHour',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            return tableCellMoneyRenderer(value);
        },
    },
    GridColumnCreator.createAttachmentsCountColumn<CrewMemberRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentsCount',
        minWidth: 120,
    }),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    GridColumnCreator.createActionsColumn<CrewMemberRecord>(),
];
