import {
    CustomFieldDataTypeCodeEnum,
    CustomFieldGroupObjRead,
    CustomFieldProcessingTypeCodeEnum,
    CustomFieldWithValueObjRead,
    SimpleEntityRecord,
} from '../../server';
import { FormFieldsGroup, FormItemType, IFormField } from '../../components/dynamicForm/DynamicForm';
import moment from 'moment';
import React from 'react';
import { tableCellBooleanRenderer } from '../../components/grid/renderers/tableCellBooleanRenderer';
import { tableCellDateRenderer } from '../../components/grid/renderers/tableCellDateRenderer';
import { LocalizationEnum, localize } from '../../localization';
import { formatDate } from './formatDate';
import { getStoreState } from '../../../index';
import { canViewFinancialData } from './permissionUtils';
import { UserSelect } from '../../components/v2/select/userSelect/userSelect';
import flatten from 'lodash/flatten';
import { formatMoney } from './formatMoney';
import { tableCellMoneyRenderer } from '../../components/grid/renderers/tableCellMoneyRenderer';
import { ContactSelect } from '../../components/v2/select/contactSelect/contactSelect';
import { RenterSelect } from '../../components/v2/select/renterSelect/renterSelect';
import { ProjectSelect } from '../../components/v2/select/projectSelect/projectSelect';
import { SubrentSelect } from '../../components/v2/select/subrentSelect/subrentSelect';

export const customFieldObjToFormFieldObj = (
    field: CustomFieldWithValueObjRead,
    value: string | SimpleEntityRecord | SimpleEntityRecord[],
    index: number = 0
): IFormField => {
    let label = field.name;
    let id = 'customField_' + field.customFieldId + (field.multipleValues ? `[${index}]` : '');
    let type: FormItemType = customFieldTypeToFormFieldType(field);
    let v: any;

    if (type === FormItemType.Switch) {
        v = value === 'true';
    } else if (type === FormItemType.Date) {
        v = value ? moment(typeof value === 'string' ? value : value['title']) : '';
    } else if (type === FormItemType.Float) {
        v = +value;
    } else if (type === FormItemType.Integer) {
        v = +value;
    } else if (type === FormItemType.Select) {
        const component = {
            [CustomFieldProcessingTypeCodeEnum.RENTERLINK]: RenterSelect,
            [CustomFieldProcessingTypeCodeEnum.PROJECTLINK]: ProjectSelect,
            [CustomFieldProcessingTypeCodeEnum.SUBRENTLINK]: SubrentSelect,
            [CustomFieldProcessingTypeCodeEnum.CONTACTLINK]: ContactSelect,
            [CustomFieldProcessingTypeCodeEnum.USERLINK]: UserSelect,
        };

        const isMultiple = Boolean(field.multipleValues);

        return {
            label: label,
            id: id,
            type: FormItemType.Component,
            getInitialValue: (value) => ({ key: value, label: '' }),
            defaultValue:
                isMultiple && Array.isArray(value)
                    ? value?.length
                        ? value.map((value) => value.id)
                        : undefined
                    : value
                    ? (value as SimpleEntityRecord).id
                    : undefined,
            component: component[field.processingType],
            componentProps: {
                multiple: isMultiple,
                getInitialValue: (value) => ({ key: value, label: '' }),
                filters: ['archive;EQ;false'],
            },
            visible: () => {
                const p = getStoreState().permissions.permissions;
                return !field.financial || canViewFinancialData(p);
            },
        };
    } else {
        v = value;
    }

    const bigStepTypes = [FormItemType.Float, FormItemType.Integer, FormItemType.Money];

    return {
        label: label,
        id: id,
        type: type,
        defaultValue: v || undefined,
        multipleValues: field.multipleValues,
        step: bigStepTypes.includes(type) ? 50 : undefined,
        maxLength: type === FormItemType.String ? 255 : undefined,
        visible: () => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p) || field.financial;
        },
        componentProps:
            field.typeCode === CustomFieldDataTypeCodeEnum.DOUBLE || field.typeCode === CustomFieldDataTypeCodeEnum.LONG
                ? {
                      flexible: true,
                  }
                : undefined,
        validationFunction:
            type === FormItemType.Money
                ? (fieldName: string, value: any, cb: Function) => {
                      cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                  }
                : undefined,
    };
};

const customFieldTypeToFormFieldType = (field?: CustomFieldWithValueObjRead): FormItemType => {
    const { typeCode, processingType } = field ?? {};
    let type: FormItemType = FormItemType.String;
    if (typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN) {
        type = FormItemType.Checkbox;
    } else if (typeCode === CustomFieldDataTypeCodeEnum.DOUBLE) {
        type = FormItemType.Float;
    } else if (typeCode === CustomFieldDataTypeCodeEnum.LONG) {
        if (processingType === CustomFieldProcessingTypeCodeEnum.MONEYSUM) {
            type = FormItemType.Money;
        } else {
            type = FormItemType.Integer;
        }
    } else if (typeCode === CustomFieldDataTypeCodeEnum.DATE) {
        type = FormItemType.Date;
    } else if (typeCode === CustomFieldDataTypeCodeEnum.ENTITY) {
        type = FormItemType.Select;
    }

    return type;
};

// Ищем в группах нужное кастомное поле
export const findFieldInGroupsById = (groups: FormFieldsGroup[], fieldId: string): IFormField | null => {
    let result: IFormField | null = null;
    groups.forEach((group) => {
        group.fields.forEach((field) => {
            if (field.id === fieldId) result = field;
        });
    });
    return result;
};

export const findFieldGroupIndexById = (groups: FormFieldsGroup[], fieldId: string): number | null => {
    let result: number | null = null;
    groups.forEach((group, index) => {
        group.fields.forEach((field) => {
            if (field.id === fieldId) result = index;
        });
    });
    return result;
};

export const getCustomFieldIndexByFieldId = (fieldId): number | null => {
    let result = fieldId.match(/\[(\d+)\]/g);
    if (result) {
        return +result[result.length - 1].toString().replace('[', '').replace(']', '');
    }
    return null;
};

/**
 * Форматирует значение кастомного поля для вывода в грид, характеристики и т.д.
 * @param value Значение
 * @param typeCode Тип кастомного поля
 * @param processingType
 * @param forGrid Вывод для грида или нет (может отличаться в BOOLEAN и DATE полях)
 */
export const formatCustomFieldValue = ({
    value,
    typeCode,
    processingType,
    forGrid = false,
}: {
    value: string;
    typeCode: CustomFieldDataTypeCodeEnum | undefined;
    processingType?: CustomFieldProcessingTypeCodeEnum;
    forGrid?: boolean;
}): string | React.ReactNode => {
    let result: string | React.ReactNode = value;
    if (typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN) {
        if (forGrid) result = tableCellBooleanRenderer(value);
        else {
            if (value === 'true') result = localize(LocalizationEnum.ASPECT__GLOBAL__YES);
            else if (value === 'false') result = localize(LocalizationEnum.ASPECT__GLOBAL__NO);
        }
    } else if (typeCode === CustomFieldDataTypeCodeEnum.DATE) {
        if (forGrid) result = tableCellDateRenderer(value);
        else result = formatDate(moment(value), 'D MMMM YYYY');
    } else if (typeCode === CustomFieldDataTypeCodeEnum.LONG && processingType === CustomFieldProcessingTypeCodeEnum.MONEYSUM) {
        if (forGrid) tableCellMoneyRenderer(Number(value));
        result = formatMoney(Number(value));
    }

    return result;
};

/**
 * Форматирует значениЯ кастомного поля для вывода в грид, характеристики и т.д.
 * @param values Значения
 * @param typeCode Тип кастомного поля
 * @param processingType
 * @param forGrid Вывод для грида или нет (может отличаться в BOOLEAN и DATE полях)
 * @param separator Разделитель значений
 */
export const formatCustomFieldValues = ({
    values,
    typeCode,
    processingType,
    forGrid = false,
    separator = ', ',
}: {
    values: string[] | undefined;
    typeCode: CustomFieldDataTypeCodeEnum | undefined;
    processingType?: CustomFieldProcessingTypeCodeEnum;
    forGrid?: boolean;
    separator?: React.ReactNode | string;
}): React.ReactNode => {
    return values ? (
        <>
            {values.map((value, index, arr) => {
                return (
                    <React.Fragment key={index}>
                        {formatCustomFieldValue({
                            value,
                            typeCode,
                            forGrid,
                            processingType,
                        })}
                        {index < arr.length - 1 ? separator : null}
                    </React.Fragment>
                );
            })}
        </>
    ) : undefined;
};

// Метод удаляет кастомные поля и добавляет вместо них одно поле: customFields
export const setCustomFieldsinFormData = (values) => {
    let customFields: any[] = [];

    for (let k in values) {
        if (k.indexOf('customField_') === 0) {
            let val = values[k];
            val = getCustomFieldValuesFromForm(val);
            customFields.push({
                customFieldId: +k.substr('customField_'.length),
                values: val,
            });
            delete values[k];
        }
    }
    values.customFields = customFields;
    return values;
};

export const setCustomFieldGroupsinFormData = (customFieldGroups: Array<CustomFieldGroupObjRead> | undefined, values) => {
    const groups = customFieldGroups
        ? customFieldGroups.map((group, index) => {
              return {
                  name: group.name,
                  main: group.main,
                  fields: group.fields.map((field) => {
                      let val: unknown[] = [];

                      for (let k in values) {
                          if (k === 'customField_' + field.customFieldId) {
                              val = getCustomFieldValuesFromForm(values[k]);
                              delete values[k];
                          }
                      }

                      return {
                          customFieldId: field.customFieldId,
                          values: val.every((val) => Array.isArray(val)) ? flatten(val) : val,
                      };
                  }),
              };
          })
        : [];

    values.customFieldGroups = groups;

    return values;
};

export const getCustomFieldValuesFromForm = (val) => {
    if (Array.isArray(val)) {
        val = val
            .map((item, index) => {
                if (item == null) return undefined;

                let value = item['__' + index];
                if (value !== undefined && value !== null && !moment.isMoment(value)) {
                    value = '' + value;
                    return value;
                }

                if (Array.isArray(item) && item[0] != null) {
                    if (typeof item[0] === 'object' && 'key' in item[0]) {
                        return item.map((item) => item.key);
                    }

                    if (typeof item[0] === 'number') return item;
                }

                return value;
            })
            .filter(Boolean);
    } else {
        if (val === undefined || val === null) {
            val = [];
        } else {
            if (typeof val === 'object' && 'key' in val) val = val.key;
            if (!moment.isMoment(val)) val = '' + val;
            val = [val];
        }
    }

    return flatten(val);
};
