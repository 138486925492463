import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from './shared/reducers';
import { Route, Switch } from 'react-router-dom';
import CallbackModule from './modules/callback/callback';
import MainModule from './modules/main/main';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './config/store';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import { locales } from './localization/locales';
import { DEFAULT_LOCALE } from './config/constants';
import ShowInvitation from './modules/invitation/show.invitation';
import { HelpTooltipContext } from './components/helpTooltip/HelpTooltip';

interface IProps extends StateProps {}

export const hidePreloader = () => {
    setTimeout(() => {
        let document = window.document;
        let element = document.getElementById('rr-app-preloader');
        if (element) {
            element.classList.add('rr-app-preloader-hide');
            setTimeout(() => {
                if (element?.parentNode) element.parentNode.removeChild(element);
                document.getElementsByTagName('body')[0].classList.remove('rr-app-overflow-hidden');
            }, 300);
        }
    }, 200);
};

const getLocalizationData = (userLocale?: string) => {
    let locale = /*this.props.*/ (userLocale /*|| navigator.language*/ || DEFAULT_LOCALE).replace('-', '_');
    let allLocalesCodes = locales.map((obj) => obj.code);

    if (!allLocalesCodes.includes(locale)) {
        locale = locale.substr(0, 2);
        locale = allLocalesCodes.find((l) => l.indexOf(locale) === 0) || DEFAULT_LOCALE;
    }
    const localeObj = locales.find((obj) => obj.code === locale);
    const antd_locale = localeObj ? localeObj.antd_locale : {};
    const messages = localeObj ? localeObj.messages : {};
    const antLocaleCode = localeObj ? localeObj.locale : '';

    moment.locale(locale); // Это нужно для установки локали глобально для  moment.js

    const cache = createIntlCache();

    const intl = createIntl(
        {
            locale: antLocaleCode,
            messages: messages,
            textComponent: 'span',
        },
        cache
    );

    return {
        intl,
        antd_locale,
    };
};

let _intl = getLocalizationData().intl;

export const getIntl = () => {
    return _intl;
};

class App extends React.PureComponent<IProps> {
    private data = { showTooltips: true };

    render() {
        const localizationData = getLocalizationData(this.props.userLocale);
        _intl = localizationData.intl;
        return (
            <ConfigProvider locale={localizationData.antd_locale}>
                <RawIntlProvider value={_intl}>
                    <HelpTooltipContext.Provider value={this.data}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path="/callback" component={CallbackModule} exact />
                                <Route path="/invitations" component={ShowInvitation} />
                                <Route path="/" component={MainModule} />
                            </Switch>
                        </ConnectedRouter>
                    </HelpTooltipContext.Provider>
                </RawIntlProvider>
            </ConfigProvider>
        );
    }
}

const mapStateToProps = ({ router, oidc }: IRootState) => ({
    userLocale:
        oidc && oidc.user && oidc.user.profile.locale ? oidc.user.profile.locale : navigator.language.replace('-', '_').substr(0, 2),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(App);
