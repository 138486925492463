import { ServerUtils } from '../../../../core/utils/serverUtils';
import { ExpenseRecord, ExpenseStateCodeEnum } from '../../../../server';
import { ListExpensesArgs } from './expenses.api.types';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../components/page/entityGrid/types/api';

export abstract class ExpensesUtils {
    static createRequestFilters = (params: ListExpensesArgs['params'], projectId?: number) => {
        return ServerUtils.createRequestFilters<ExpenseRecord>([
            params.projectId ? ['projectId', 'EQ', params.projectId] : undefined,
            params.hideCancelled ? ['state', 'IN', [ExpenseStateCodeEnum.TAKENINTOACCOUNT, ExpenseStateCodeEnum.DRAFT]] : undefined,
            params.internalFinalTotalPrice?.[0] !== undefined
                ? ['internalFinalTotalPrice', 'GE', params.internalFinalTotalPrice[0] * 100]
                : undefined,
            params.internalFinalTotalPrice?.[1] !== undefined
                ? ['internalFinalTotalPrice', 'LE', params.internalFinalTotalPrice[1] * 100]
                : undefined,
            params.externalFinalTotalPrice?.[0] !== undefined
                ? ['externalFinalTotalPrice', 'GE', params.externalFinalTotalPrice[0] * 100]
                : undefined,
            params.externalFinalTotalPrice?.[1] !== undefined
                ? ['externalFinalTotalPrice', 'LE', params.externalFinalTotalPrice[1] * 100]
                : undefined,
            params.authorId ? ['lastUpdateAuthorId', 'EQ', params.authorId] : undefined,
            params.screenLeft ? ['lastUpdateDate', 'GE', params.screenLeft] : undefined,
            params.screenRight ? ['lastUpdateDate', 'LE', params.screenRight] : undefined,
            projectId != null ? ['projectId', 'EQ', projectId] : undefined,
        ]);
    };

    static pricePerShiftIntervalMetricsMap: IntervalFiltersMetricsMap<ExpenseRecord> = {
        internalFinalTotalPrice: (value) => value / 100,
        externalFinalTotalPrice: (value) => value / 100,
    };
    static pricePerShiftIntervalFormatMap: IntervalFiltersFormatsMap<ExpenseRecord> = {
        internalFinalTotalPrice: 'currency',
        externalFinalTotalPrice: 'currency',
    };
}
