import {Icon} from 'antd';
import {FC, ReactNode} from 'react';
import './GridHeader.less';
import {IconIconsSolid} from '../../../../../../../../../components/icons';
import {Styles} from '../../../../../../../../../styles';
import {LocalizationEnum, localize} from '../../../../../../../../../localization';

interface GridHeaderProps {
    Button: ReactNode;
    TitleContent?: ReactNode;
}

export const GridHeader: FC<GridHeaderProps> = (props) => {
    const { TitleContent, Button } = props;

    return (
        <div className="product-composition-header">
            <div className="title-container">
                <Icon component={IconIconsSolid} style={{ color: '#979cbf' }} className={Styles.ICON_26} />
                <div style={{ marginLeft: '10px' }}>{localize(LocalizationEnum.PAGE__KITS__COMPONENTS_TAB__LABEL__COMPONENTS_LIST)}</div>
                {TitleContent}
            </div>

            <div className="button-container">{Button}</div>
        </div>
    );
};
