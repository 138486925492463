import React from 'react';
import {Moment} from "moment";
import {DatePickerModified, DatePickerModifiedProps} from "./DatePickerModified";

export class DatePicker1 extends React.PureComponent<DatePickerModifiedProps> {
    render() {
        return (
            <DatePickerModified
                {...this.props}
                onChange={(date: Moment | null, dateString: string) => {
                    if (date) date = date.clone().hour(0).minutes(0).seconds(0).milliseconds(0);
                    if (this.props.onChange) this.props.onChange(date, dateString);
                }}
                {...{ align: { points: ['bl', 't'], offset: [0, -6] } }}
            />
        );
    }
}
