import React from 'react';
import Highlight from 'react-highlighter';
import { Col, Row, Tree } from 'antd';
import { CategoriesBlockProps, Category, CategoryData } from '../categories-block';
import { TreeProps } from 'antd/lib/tree';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { useDispatch } from 'react-redux';
import { ICategoriesState, loadCategory, selectCategory } from '../reducers/categories.reducer';
import { WrappedComponentProps } from 'react-intl';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../store/hooks';

interface TreeByNameProps extends Pick<CategoriesBlockProps, 'searchString' | 'typeCode'>, WrappedComponentProps {
    availableCategories: Category[];
    expandedKeys: string[];
    categoryData: CategoryData;
    onExpand: TreeProps['onExpand'];
    editMode: ICategoriesState['editMode'];
}

export const TreeByName: React.FC<TreeByNameProps> = ({
    availableCategories,
    categoryData,
    searchString,
    expandedKeys,
    onExpand,
    intl,
    typeCode,
    editMode,
}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useDispatch();

    const renderTreeNodes = (categories: Category[], level: number = 1) => {
        return categories.map((item) => {
            const dataRef = {
                ...item,
                level: level,
            };
            if (item.children) {
                return (
                    <Tree.TreeNode
                        title={
                            <Highlight matchElement={'span'} matchStyle={{ color: 'red' }} search={searchString}>
                                {item.title}
                            </Highlight>
                        }
                        key={String(item.key)}
                        dataRef={dataRef}
                        icon={null}
                    >
                        {renderTreeNodes(item.children, level + 1)}
                    </Tree.TreeNode>
                );
            }
            return <Tree.TreeNode {...item} key={String(item.key)} children={[]} />;
        });
    };

    const onSelect: TreeProps['onSelect'] = (selectedKeys: string[]) => {
        let selectedCategoryId = selectedKeys[0];
        if (selectedCategoryId === undefined) return;

        // TODO Тут подумать как выводить предупреждение... С заголовком, без, С каким заголовком, с каким текстом?
        if (editMode) {
            showConfirm(intl, localizeIntl(intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT), undefined, () => {
                loadCategory(businessAccountId, typeCode, +selectedCategoryId)(dispatch);
                dispatch(selectCategory(typeCode, selectedCategoryId));
            });
        } else {
            loadCategory(businessAccountId, typeCode, +selectedCategoryId)(dispatch);
            dispatch(selectCategory(typeCode, selectedCategoryId));
        }
    };

    const getCategoryColumns = () => {
        // Создаем массив массивов категорий, группируя их по первой букве
        let groupedCategories: Category[][] = [];

        let prevLetter = '';
        availableCategories.forEach(function (item) {
            let letter = item.title[0].toUpperCase();
            // Проверяем, является ли первый символ буквой
            if (!/[A-ZА-Я]/.test(letter) && !/[A-ZА-Я]/.test(prevLetter)) {
                if (groupedCategories.length === 0) groupedCategories.push([item]);
                else groupedCategories[0].push(item);
            } else if (prevLetter === letter) {
                groupedCategories[groupedCategories.length - 1].push(item);
            } else {
                groupedCategories.push([item]);
            }
            prevLetter = letter;
        });

        let totalCategories = 0;
        let maxCategoryCount = 0;

        // Вычисление общего количества категорий и максимального количества категорий в группе
        groupedCategories.forEach((item) => {
            totalCategories += item.length + 1;
            maxCategoryCount = Math.max(maxCategoryCount, item.length + 1);
        });

        // Вычисление количества столбцов и максимальной высоты в каждом столбце
        let targetRowCount = Math.max(Math.ceil(totalCategories / 4), maxCategoryCount);
        let columns: Array<Category[]>[] = [[], [], [], []];
        let currentColumn = 0;
        let currenColumnLength = 0;

        groupedCategories.forEach((item) => {
            console.log('+', currenColumnLength + item.length, targetRowCount);
            if (currenColumnLength + item.length + 1 <= targetRowCount || currentColumn === 4 - 1) {
            } else {
                currentColumn += 1;
                currenColumnLength = 0;
            }
            console.log('___', currentColumn, currenColumnLength);
            columns[currentColumn].push(item);
            currenColumnLength += item.length + 1;
        });

        return columns;
    };

    const categoryColumns: Array<Category[]>[] = getCategoryColumns();

    return (
        <>
            <div>
                <Row gutter={16}>
                    {categoryColumns.map((item, i) => {
                        return (
                            <Col span={6} key={i}>
                                {item.map((item, j) => {
                                    return (
                                        <React.Fragment key={j}>
                                            <h5>
                                                {item && item[0] && item[0].title
                                                    ? /[A-ZА-Я]/.test(item[0].title[0].toUpperCase())
                                                        ? item[0].title[0].toUpperCase()
                                                        : '#'
                                                    : null}
                                            </h5>
                                            <Tree
                                                defaultExpandAll={false}
                                                draggable={false}
                                                showLine
                                                showIcon={false}
                                                onSelect={onSelect}
                                                key={j}
                                                selectedKeys={['' + categoryData.selectedCategoryId || '']}
                                                defaultExpandedKeys={['' + categoryData.selectedCategoryId || '']}
                                                onExpand={onExpand}
                                                expandedKeys={expandedKeys}
                                            >
                                                {renderTreeNodes(item)}
                                            </Tree>
                                        </React.Fragment>
                                    );
                                })}
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </>
    );
};
