import React, { FC } from 'react';
import { CustomRangePicker, CustomRangePickerProps } from '../../../../components/datePicker/CustomRangePicker';
import { Moment } from 'moment';
import { CustomRangeCalendarProps } from '../../../../components/datePicker/CustomRangeCalendar';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

export interface RangePickerProps {
    value?: [Moment, Moment];
    defaultValue?: RangePickerValue;
    onChange?: (a, b) => void;
    shiftLengthInMin?: number;
    shiftCount?: number;
    getPopupContainer?: CustomRangePickerProps['getPopupContainer'];
    onShiftsChange?: (shiftCount: number) => void;
    storeKey: CustomRangeCalendarProps['storeKey'];
    hasOwnShiftLength?: boolean;
}

const RangePickerFC: FC<RangePickerProps> = (props) => {
    const { value, onChange, shiftLengthInMin, hasOwnShiftLength, shiftCount, getPopupContainer, onShiftsChange, storeKey, defaultValue } =
        props;

    return (
        <React.Fragment>
            <CustomRangePicker
                style={{ width: 478 }}
                align={{ points: ['bc', 'tc'], offset: [0, -6] }}
                allowClear={false}
                defaultValue={defaultValue}
                value={value}
                showTime
                onChange={(dates, dateStrings) => {
                    onChange?.(dates, dateStrings);
                }}
                shiftLengthInMin={shiftLengthInMin}
                shiftLengthHint={!hasOwnShiftLength ? '(По компании)' : ''}
                productHasOwnRequiredTimeIndentBetweenElements={false}
                shiftCount={shiftCount}
                getPopupContainer={getPopupContainer}
                onShiftCountChange={onShiftsChange}
                storeKey={storeKey}
                placement={'top'}
            />
        </React.Fragment>
    );
};

export class RangePicker extends React.PureComponent<RangePickerProps> {
    render() {
        return <RangePickerFC {...this.props} />;
    }
}
