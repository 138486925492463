import {createBrowserHistory} from 'history';
//import {configureStore} from "@reduxjs/toolkit";
//import {setupListeners} from "@reduxjs/toolkit/query";

export const history = createBrowserHistory();
//
// const defaultMiddlewares = [
//     thunk,
//     errorMiddleware,
//     promiseMiddleware,
//     routerMiddleware(history)
// ];
//
// const composedMiddlewares = (middlewares:any) =>
//     compose(applyMiddleware(...defaultMiddlewares, ...middlewares));
//
// const initialize = (initialState?: IRootState, middlewares = []) => createStore(
//     reducer(history),
//     initialState,
//     composeWithDevTools(
//         composedMiddlewares(middlewares)
//     )
// );

// const initialize = (initialState?: IRootState, middlewares = []) => {
//     const store = configureStore({
//         reducer: reducer(history),
//         middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//             serializableCheck: false, // TODO Избавиться от этого
//             immutableCheck: false // TODO Избавиться от этого
//         }).concat([
//             thunk,
//             errorMiddleware,
//             promiseMiddleware,
//             routerMiddleware(history)
//         ]),
//         preloadedState: initialState,
//         devTools: true
//     });
//     setupListeners(store.dispatch);
//     return store;
// };
//
// // export type AppDispatch = typeof store.dispatch
// // export type RootState = ReturnType<typeof store.getState>
// // export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
// //     RootState,
// //     unknown,
// //     Action<string>>

//export default initialize;
