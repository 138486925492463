import React, { ReactNode } from 'react';
import { Button, Icon, Popover } from 'antd';
import {
    IconArchive,
    IconBoxSolid,
    IconCalendarPlus,
    IconCashRegisterSolid,
    IconCheck,
    IconClose,
    IconControlPointDuplicateBlack,
    IconDollySolid,
    IconEdit,
    IconEraser,
    IconFlagCheckered,
    IconLevelUp,
    IconMedkit,
    IconPlus,
    IconReply,
    IconRocket,
    IconShippingFastSolid,
    IconTimesCircle,
    IconToolsSolid,
    IconTrash,
    IconUnlock,
    IconUnlockAlt,
} from '../icons';

import {
    CounterpartyActivityTypeCodeEnum,
    InstanceTrackingTypeCodeEnum,
    InstanceTransitionCodeEnum,
    PricingSchemeInfoRead,
    ProjectStateCodeEnum,
    SimpleTransitionCodeEnum,
    TransportationTransitionCodeEnum,
    VariantRecord,
} from '../../server';
import { EntityActionType, EntityType, getStoreState } from '../../../index';
import { LocalizationEnum, localize } from '../../localization';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import _ from 'lodash';

interface IProps {
    entity: VariantRecord | any;

    onAction(entity: any, actionType: EntityActionType): void;

    entityType: EntityType;
    children?: () => ReactNode;
}

interface IState {
    visible: boolean;
}

class ActionsPopover extends React.PureComponent<IProps, IState> {
    state = {
        visible: false,
    };

    handleVisibleChange = (visible: boolean) => {
        this.setState({ visible });
    };

    render() {
        let entity = this.props.entity || {};
        let onAction = this.props.onAction;
        let entityType = this.props.entityType;
        let currentProduct = getStoreState().product.entity;

        if (entityType === 'pricingScheme') {
            entity.deleteable = (entity as PricingSchemeInfoRead).linkedProductCount === 0;
        }

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', lineHeight: 0 }}>
                    <div>
                        {entityType === 'kit-composition' || entityType === 'crew-member-composition' ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Icon
                                    className={'rr-grid-table-actions-icon3'}
                                    onClick={() => onAction({ ...entity }, 'delete')}
                                    component={IconClose}
                                />
                            </div>
                        ) : (
                            <Popover
                                onVisibleChange={this.handleVisibleChange}
                                visible={this.state.visible}
                                overlayClassName={'rr-grid-actions-popover'}
                                autoAdjustOverflow
                                arrowPointAtCenter
                                placement="bottomLeft"
                                trigger="click"
                                content={
                                    <>
                                        <div className={'rr-grid-actions-popover-header1'} style={{ marginBottom: '0px' }}>
                                            {/*<div>*/}
                                            {/*    <span style={{marginRight: 8}}>{localize(LocalizationEnum.ASPECT__GRID__POPUP__TITLE__ACTIONS)}</span>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className={'rr-grid-actions-popover-content'}>
                                            {entityType === 'project' || entityType === 'subrent' ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'pay');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon
                                                        style={{ color: entityType === 'project' ? '#ffcd43' : '#ffcd43' }}
                                                        component={IconCashRegisterSolid}
                                                    />
                                                    <span>
                                                        {entityType === 'project'
                                                            ? localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT)
                                                            : 'Произвести оплату'}
                                                    </span>
                                                </Button>
                                            ) : null}

                                            {entityType === 'template' ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'createProjectFromTemplate');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#34bfa3' }} component={IconMedkit} />
                                                    <span>
                                                        {localize(LocalizationEnum.PAGE__TEMPLATES__MODAL__CREATE_PROJECT_FROM_TEMPLATE)}
                                                    </span>
                                                </Button>
                                            ) : null}

                                            {entityType === 'renter' &&
                                            !entity.archive &&
                                            [
                                                CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                                CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                                            ].includes(entity.activityTypeCode) ? (
                                                entity.stateCode !== 'BLOCKED' ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'createProject');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#34bfa3' }} component={IconMedkit} />
                                                        <span>{localize(LocalizationEnum.ASPECT__ACTION__CREATE_PROJECT)}</span>
                                                    </Button>
                                                ) : (
                                                    <HelpTooltip
                                                        content={
                                                            LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_CREATE_PROJECT_IMPOSSIBLE
                                                        }
                                                    >
                                                        <Button block disabled={true}>
                                                            <Icon style={{ color: '#aaaaaa' }} component={IconMedkit} />
                                                            <span>{localize(LocalizationEnum.ASPECT__ACTION__CREATE_PROJECT)}</span>
                                                        </Button>
                                                    </HelpTooltip>
                                                )
                                            ) : null}

                                            {entityType === 'renter' &&
                                            !entity.archive &&
                                            [
                                                CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                                CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                                            ].includes(entity.activityTypeCode) ? (
                                                entity.stateCode !== 'BLOCKED' ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'createSupply');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#34bfa3' }} component={IconDollySolid} />
                                                        <span>Создать поставку</span>
                                                    </Button>
                                                ) : (
                                                    <HelpTooltip content={'Нельзя создать поставку в заблокированном контрагенте'}>
                                                        <Button block disabled={true}>
                                                            <Icon style={{ color: '#aaaaaa' }} component={IconDollySolid} />
                                                            <span>Создать поставку</span>
                                                        </Button>
                                                    </HelpTooltip>
                                                )
                                            ) : null}

                                            {entityType !== 'element' ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'edit');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#383f77' }} component={IconEdit} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT)}</span>
                                                </Button>
                                            ) : null}
                                            {_.includes<EntityType>(
                                                [
                                                    'product',
                                                    'kits-product',
                                                    'project',
                                                    'subrent',
                                                    'template',
                                                    'variant',
                                                    'product-instances',
                                                    'transportation',
                                                    'renter',
                                                ],
                                                entityType
                                            ) ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'copy');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#34bfa3' }} component={IconControlPointDuplicateBlack} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE)}</span>
                                                </Button>
                                            ) : null}
                                            {/* Варианты: Задать количество ******************************/}
                                            {entityType === 'variant' &&
                                            currentProduct &&
                                            (currentProduct.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                                                currentProduct.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK) ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'setQuantity');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#00afee' }} component={IconBoxSolid} />
                                                    <span>{localize(LocalizationEnum.PAGE__PRODUCTS__ACTIONS__SET_AMOUNT)}</span>
                                                </Button>
                                            ) : null}

                                            {entityType === 'variant' &&
                                            currentProduct &&
                                            (currentProduct.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                                                currentProduct.instanceTrackingTypeCode ===
                                                    InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) /*entity.tracked*/ ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'addInstance');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#34bfa3' }} component={IconPlus} />
                                                    <span>{localize(LocalizationEnum.PAGE__INSTANCES__CREATE_INSTANCE)}</span>
                                                </Button>
                                            ) : null}

                                            {entityType !== 'project' &&
                                            entityType !== 'subrent' &&
                                            entityType !== 'product-instances' &&
                                            (entity.stateCode === 'NEW' || entity.state === 'NEW') &&
                                            entity.availableTransitionCodes &&
                                            entity.availableTransitionCodes.includes(SimpleTransitionCodeEnum.ACTIVATE) ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#21bfa4' }} component={IconCheck} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE)}</span>
                                                </Button>
                                            ) : null}

                                            {(entityType === 'project' || entityType === 'subrent') &&
                                            entity.stateCode === 'NEW' &&
                                            entity.availableTransitionCodes &&
                                            entity.availableTransitionCodes.includes('ACTIVATE') ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    {/*<Icon style={{color: '#ffcd43'}}*/}
                                                    <Icon style={{ color: '#FC8C2F' }} component={IconRocket} />
                                                    <span>{localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__START)}</span>
                                                </Button>
                                            ) : null}

                                            {entityType !== 'project' &&
                                            entityType !== 'subrent' &&
                                            entityType !== 'product-instances' &&
                                            entity.availableTransitionCodes &&
                                            entity.availableTransitionCodes.includes(SimpleTransitionCodeEnum.BLOCK) ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#9f9f9f' }} component={IconUnlock} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__BLOCK)}</span>
                                                </Button>
                                            ) : null}

                                            {(entityType === 'project' || entityType === 'subrent') &&
                                            entity.stateCode === 'IN_PROGRESS' &&
                                            entity.availableTransitionCodes &&
                                            entity.availableTransitionCodes.includes('FINISH') ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#21bfa4' }} component={IconFlagCheckered} />
                                                    <span>{localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH)}</span>
                                                </Button>
                                            ) : null}

                                            {(entity.state === 'NOT_AVAILABLE' || entity.stateCode === 'NOT_AVAILABLE') &&
                                            entity.availableTransitionCodes &&
                                            entity.availableTransitionCodes.includes(SimpleTransitionCodeEnum.ACTIVATE) ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#21bfa4' }} component={IconUnlockAlt} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__UNLOCK)}</span>
                                                </Button>
                                            ) : null}

                                            {entity.stateCode === 'BLOCKED' || entity.state === 'BLOCKED' ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'lock');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#21bfa4' }} component={IconUnlockAlt} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__UNLOCK)}</span>
                                                </Button>
                                            ) : null}

                                            {
                                                /* Для проектов */
                                                (entityType === 'project' || entityType === 'subrent') &&
                                                entity.stateCode === 'FINISHED' &&
                                                entity.availableTransitionCodes &&
                                                entity.availableTransitionCodes.indexOf('ACTIVATE') > -1 ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'lock');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        {/*<Icon style={{color: '#ffcd43'}}*/}
                                                        <Icon style={{ color: '#FC8C2F' }} component={IconReply} />
                                                        <span>{localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__RESUME)}</span>
                                                    </Button>
                                                ) : null
                                            }

                                            {
                                                /* Для проектов */
                                                entityType === 'project' &&
                                                entity.stateCode === ProjectStateCodeEnum.CANCELED &&
                                                entity.availableTransitionCodes &&
                                                entity.availableTransitionCodes.indexOf('ACTIVATE') > -1 ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'resume');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        {/*<Icon style={{color: '#ffcd43'}}*/}
                                                        <Icon style={{ color: '#FC8C2F' }} component={IconReply} />
                                                        <span>{localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__RESUME)}</span>
                                                    </Button>
                                                ) : null
                                            }

                                            {
                                                //
                                                entityType === 'project' && entity.availableTransitionCodes.includes('CANCEL') ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'cancel');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        <Icon className={'rr-operation-status-color-CANCEL'} component={IconEraser} />
                                                        <span>Отменить</span>
                                                    </Button>
                                                ) : null
                                            }

                                            {/*****************************/}

                                            {entity.archivable ? (
                                                entityType !== 'element' &&
                                                entityType !== 'product-instances' &&
                                                entityType !== 'customField' &&
                                                !entity.archive &&
                                                entity.archivable ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (onAction) onAction(entity, 'archive');
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive} />
                                                        <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}</span>
                                                    </Button>
                                                ) : null
                                            ) : null}

                                            {entityType !== 'element' &&
                                            entityType !== 'product-instances' &&
                                            entityType !== 'customField' &&
                                            entity.archive ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'archive');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                                </Button>
                                            ) : null}

                                            {/* Экземпляры продукта: Переходы ******************************/}
                                            {entityType === 'product-instances' && entity.availableTransitionCodes
                                                ? entity.availableTransitionCodes.map((code: InstanceTransitionCodeEnum, num: number) => {
                                                      return (
                                                          <div key={num}>
                                                              <Button
                                                                  block
                                                                  onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      if (onAction) onAction(entity, code);
                                                                      this.setState({ visible: false });
                                                                  }}
                                                              >
                                                                  {code === InstanceTransitionCodeEnum.ACTIVATE ? (
                                                                      <>
                                                                          <Icon style={{ color: '#34BFA3' }} component={IconCheck} />
                                                                          <span>{localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE)}</span>
                                                                      </>
                                                                  ) : null}
                                                                  {code === InstanceTransitionCodeEnum.SENDUNDERMAINTENANCE ? (
                                                                      <>
                                                                          <Icon style={{ color: '#525993' }} component={IconToolsSolid} />
                                                                          <span>
                                                                              {localize(
                                                                                  LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__SEND_UNDER_MAINTENANCE
                                                                              )}
                                                                          </span>
                                                                      </>
                                                                  ) : null}
                                                                  {code === InstanceTransitionCodeEnum.DECOMMISSION ? (
                                                                      <>
                                                                          <Icon style={{ color: '#a0a0a0' }} component={IconTimesCircle} />
                                                                          <span>
                                                                              {localize(
                                                                                  LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__DECOMISSION
                                                                              )}
                                                                          </span>
                                                                      </>
                                                                  ) : null}
                                                                  {code === InstanceTransitionCodeEnum.SUPPLIERDECOMMISSION ? (
                                                                      <>
                                                                          <Icon style={{ color: '#a0a0a0' }} component={IconTimesCircle} />
                                                                          <span>Списать чужой</span>
                                                                      </>
                                                                  ) : null}
                                                                  {code === InstanceTransitionCodeEnum.SUPPLIERACTIVATE ? (
                                                                      <>
                                                                          <Icon style={{ color: '#34BFA3' }} component={IconCheck} />
                                                                          <span>Активировать чужой</span>
                                                                      </>
                                                                  ) : null}
                                                              </Button>
                                                          </div>
                                                      );
                                                  })
                                                : null}

                                            {/* Транспортировки */}
                                            {entityType === 'transportation' ? (
                                                <>
                                                    {entity.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.PLAN) ? (
                                                        <Button
                                                            block
                                                            title={'Запланировать'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (onAction) onAction(entity, TransportationTransitionCodeEnum.PLAN);
                                                                this.setState({ visible: false });
                                                            }}
                                                        >
                                                            <Icon style={{ color: '#04b0f1' }} component={IconCalendarPlus} />
                                                            Запланировать
                                                        </Button>
                                                    ) : undefined}
                                                    {entity.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.START) ? (
                                                        <Button
                                                            block
                                                            title={LocalizationEnum.PAGE__PROJECTS__ACTIONS__START}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (onAction) onAction(entity, TransportationTransitionCodeEnum.START);
                                                                this.setState({ visible: false });
                                                            }}
                                                        >
                                                            <Icon style={{ color: '#FC8C2F' }} component={IconShippingFastSolid} />
                                                            {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__START, 'span')}
                                                        </Button>
                                                    ) : undefined}
                                                    {entity.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.FINISH) ? (
                                                        <Button
                                                            block
                                                            title={LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (onAction) onAction(entity, TransportationTransitionCodeEnum.FINISH);
                                                                this.setState({ visible: false });
                                                            }}
                                                        >
                                                            <Icon style={{ color: '#27a48b' }} component={IconFlagCheckered} />
                                                            {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH, 'span')}
                                                        </Button>
                                                    ) : undefined}
                                                    {entity.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.CANCEL) ? (
                                                        <Button
                                                            block
                                                            title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (onAction) onAction(entity, TransportationTransitionCodeEnum.CANCEL);
                                                                this.setState({ visible: false });
                                                            }}
                                                        >
                                                            <Icon style={{ color: '#a0a0a0' }} component={IconEraser} />
                                                            {localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL, 'span')}
                                                        </Button>
                                                    ) : undefined}
                                                </>
                                            ) : undefined}

                                            {/* Кастомное поле: Удаление ******************************/}
                                            {entityType === 'customField' ? (
                                                <Button
                                                    block
                                                    disabled={entity.entitiesLinkedNumber}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'delete');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <SystemIcon type={SystemIconType.delete} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__DELETE)}</span>
                                                </Button>
                                            ) : null}

                                            {entity.deleteable !== undefined ? (
                                                <Button
                                                    disabled={!entity.deleteable}
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (onAction) onAction(entity, 'delete');
                                                        this.setState({ visible: false });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                    <span>Удалить</span>
                                                </Button>
                                            ) : null}
                                        </div>
                                    </>
                                }
                            >
                                {this.props.children ? (
                                    this.props.children()
                                ) : (
                                    <SystemIcon
                                        type={SystemIconType.otherActions}
                                        style={{ fontSize: 28 }}
                                        className={'rr-grid-table-actions-icon'}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                )}
                            </Popover>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ActionsPopover;
