import React, { FC } from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { DocumentTypeCodeEnum, FileTypeCodeEnum, ProjectInfoRead } from '../../../../../../../../server';
import { Col, Row } from 'antd';
import { FormValue } from '../../../../../../../../components';
import { formatDate } from '../../../../../../../../shared/util/formatDate';
import moment from 'moment';
import { ExportContractPopover } from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportContractPopover/ExportContractPopover';
import { DownloadDocumentButton } from '../../../../../../../../components/buttons/downloadDocumentButton/downloadDocumentButton';
import { DownloadDocumentUtils } from '../../../../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { showNotification } from '../../../../../../../../components/notification/showNotification';
import { ContractDocumentOnDownload } from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportContractPopover/utils/data';

interface ProjectPageDescriptionContractBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionContractBlock: FC<ProjectPageDescriptionContractBlockProps> = ({ data }) => {
    const generateContractCb: ContractDocumentOnDownload = async ({ fileType, templateId, isCommonTemplate, legalDetailId }) => {
        const projectName = data.shortName || data.fullName;
        const fileName = `Договор${projectName ? ' ' + projectName : ''}`;
        await DownloadDocumentUtils.contractGenerateAndDownload(
            {
                businessAccountId: data.businessAccountId,
                projectId: data.id,
                documentTemplateId: templateId || 0,
                common: Boolean(isCommonTemplate),
                legalDetailId,
            },
            fileName,
            fileType as FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
        ).catch((e) => {
            showNotification('error', 'Ошибка генерации файла');
        });
    };

    return (
        <Row gutter={16} style={{ padding: '32px 24px', borderBottom: '1px solid #eaeaea' }}>
            <Col span={12} className={'rr-form-item'}>
                <div className={'rr-label'}>Номер договора</div>
                <div className={'rr-value'}>
                    <FormValue value={data?.contractNumber} emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)} />
                </div>
            </Col>
            <Col span={12} className={'rr-form-item'}>
                <div className={'rr-label'}>Дата договора</div>
                <div className={'rr-value'}>
                    <FormValue
                        value={data.contractDate ? formatDate(moment(data.contractDate), 'D MMMM YYYY') : undefined}
                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                    />
                </div>
            </Col>
            <Col span={12} className={'rr-form-item'}></Col>
            <Col span={12} className={'rr-form-item'}>
                <ExportContractPopover onDownload={generateContractCb}>
                    <DownloadDocumentButton documentType={DocumentTypeCodeEnum.CONTRACT} />
                </ExportContractPopover>
            </Col>
        </Row>
    );
};
