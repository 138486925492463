import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import { ExpenseRecord, ExpenseTransitionCodeEnum } from '../../../../../server';
import React from 'react';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';
import { EntityType } from '../../../../../../index';
import { GRID_ACTIONS_COLUMN_WIDTH, MAX_WIDTH } from '../../../../../config/constants';
import { ExpenseStateBadge } from '../../components/expenseStateBadge';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { ActionButtonsPopover } from '../../../../../components/v2/actionButtons/actionButtonsPopover/actionButtonsPopover';
import { expenseEntityActionButtons, ExpenseEntityActionType } from '../../data/expensesData';

export const expensesColumns: TableColumn<ExpenseRecord>[] = [
    GridColumnCreator.createShortNameColumn<ExpenseRecord>(
        'project',
        (row) => ({
            baId: row.businessAccountId,
            name: row.projectShortName,
            id: row.projectId,
            stopPropagation: true,
        }),
        {
            title: 'Проект',
            dataIndex: 'projectId',
        }
    ),

    {
        title: 'Номер в проекте',
        dataIndex: 'numberInProject',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, rowData: ExpenseRecord) => {
            return (
                <a>
                    <strong className={'main-value'}>#{rowData.numberInProject}</strong>
                </a>
            );
        },
    },

    GridColumnCreator.createShortNameColumn<ExpenseRecord>(
        'expense',
        (row) => ({
            name: row.name,
        }),
        {
            title: 'Наименование',
            dataIndex: 'name',
        }
    ),

    GridColumnCreator.createStatusColumn<ExpenseRecord>({
        title: 'Статус',
        dataIndex: 'state',
        type: ColumnTypes.CustomRender,
        width: 160,
        maxWidth: MAX_WIDTH(160),
        render: (value: any, rowData: ExpenseRecord, onAction, entityType: EntityType) => {
            return <ExpenseStateBadge type={rowData.state} style={{ width: '100%' }} />;
        },
    }),

    {
        title: 'Внутренняя стоимость',
        dataIndex: 'internalFinalTotalPrice',
        type: ColumnTypes.Money,
        minWidth: 150,
        resizable: false,
        className: 'money',
    },

    {
        title: 'Внешняя стоимость',
        dataIndex: 'externalFinalTotalPrice',
        type: ColumnTypes.Money,
        minWidth: 150,
        resizable: false,
        className: 'money',
    },

    GridColumnCreator.createUserColumn<ExpenseRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор изменения',
            dataIndex: 'lastUpdateAuthorShortName',
            minWidth: 150,
        }
    ),

    GridColumnCreator.createLastUpdateDateColumn<ExpenseRecord>({
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
    }),

    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.CustomRender,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: ExpenseRecord, rowAction, entityType) => {
            return (
                <ActionButtonsPopover
                    data={expenseEntityActionButtons}
                    actions={[
                        ExpenseEntityActionType.Edit,
                        ExpenseEntityActionType.Copy,
                        rowData.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.TODRAFT) && ExpenseEntityActionType.ToDraft,
                        rowData.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.TAKEINTOACCOUNT) &&
                            ExpenseEntityActionType.TakeIntoAccount,
                        rowData.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.CANCEL) && ExpenseEntityActionType.Cancel,
                    ]}
                    onAction={(action) => {
                        rowAction(rowData, action);
                    }}
                >
                    <SystemIcon
                        type={SystemIconType.otherActions}
                        style={{ fontSize: 28 }}
                        className={'rr-grid-table-actions-icon'}
                        onClick={(e) => e.stopPropagation()}
                    />
                </ActionButtonsPopover>
            );
        },
    },
];
