import React from "react";
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import './roundButton.less';
import {CustomIconComponentProps} from "antd/lib/icon";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {LocalizationEnum, localizeIntl} from '../../localization';
import {OperationTypeCodeEnum} from "../../server/api";

type CustomButtonProps = Omit<ButtonProps, 'icon'>;

interface IProps extends WrappedComponentProps, CustomButtonProps {
    colorScheme: 'default' | 'danger' | 'danger2' | 'success' | 'success2' | 'info' | 'brandSecond' | 'brandSecond2' | 'TO_ARCHIVE' | 'FROM_ARCHIVE' | 'blocked' | 'inRent' | 'PROLONG' | 'RETURN' | 'RETURN_BROKEN' | 'LOST_NO_RETURN' | 'CORRECT' | 'RENT' | 'CANCEL' | 'ORDER' | 'BOOK' | 'DRAFT' | 'UNDER_MAINTENANCE' | 'defaultSecondary' | 'successSecondary' | OperationTypeCodeEnum
    icon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    title?: string | LocalizationEnum;
}

/**
 * Это замена старой кнопке RoundButton
 * Эта теперь поддерживает иконку
 * Встроенную локализацию
 * Локализацию title
 */
class _Component extends React.Component<IProps> {

    render() {
        let {props} = this;
        let title = props.title ? localizeIntl(props.intl, props.title as LocalizationEnum) as string : undefined;

        return <Button
            //title={title}
            href={props.href}
            target={props.target}
            block={props.block}
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.onClick}
            style={props.style}
            shape="round"
            size={props.size ? props.size : 'large'}
            className={(props.className ? props.className + ' ' : '') + 'rr-round-btn rr-round-btn-' + props.colorScheme}
        >
            {props.icon ? <Icon component={props.icon}/> : null}
            {title}
        </Button>;
    }
}

export const RRoundButton = injectIntl(_Component);
