import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import { Icon } from 'antd';
import { IconBell } from '../../../../components/icons';
import React from 'react';
import { LocalizationEnum, localize } from '../../../../localization';
import DashboardDate from '../../../../components/dashboardDate/dashboardDate';
import { GRID_PROBLEM_COLUMN_WIDTH } from '../../../../config/constants';
import { getStoreState } from '../../../../../index';
import { GroupedRentElementRecord, RentActivityFrameTypeCodeEnum, RentStateCodeEnum } from '../../../../server/api';
import { CurrentIndicatorsProblemIcons } from '../../../../components/currentRentIndicators/currentIndicatorsProblemIcons';
import { GridColumnCreator } from '../../../../components/grid/gridColumnCreator';
import { Link } from 'react-router-dom';
import moment from 'moment';

// GRID COLUMNS
const dashboardColumns: TableColumn<GroupedRentElementRecord>[] = [
    {
        title: <Icon style={{ fontSize: 21, paddingTop: 3 }} component={IconBell} />,
        dataIndex: 'problemSeverity' as any,
        type: ColumnTypes.CustomRender,
        minWidth: GRID_PROBLEM_COLUMN_WIDTH,
        width: GRID_PROBLEM_COLUMN_WIDTH,
        maxWidth: GRID_PROBLEM_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        render: (value: Date, rowData: GroupedRentElementRecord) => {
            return (
                <CurrentIndicatorsProblemIcons
                    problemsAndWarnings={rowData?.problemsAndWarnings}
                    entityType={'elements'}
                    typeRent={'all'}
                    id={rowData.activityFrameId}
                    entity={undefined}
                />
            );
        },
    },
    {
        title: 'Дата и время',
        dataIndex: 'date',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        sortable: true,
        render: (value: Date, rowData: GroupedRentElementRecord) => {
            return null;
        },
    },
    {
        title: 'День',
        dataIndex: 'dayOfWeek' as any,
        type: ColumnTypes.CustomRender,
        minWidth: 60,
        defaultHidden: true,
        resizable: false,
        sortable: false,
        render: (value: Date, rowData: GroupedRentElementRecord) => {
            const dayOfWeek = moment(rowData.date).format('dd').toUpperCase();
            return dayOfWeek;
        },
    },
    {
        title: <span>Число обяз.</span>,
        dataIndex: 'rentElementCount',
        type: ColumnTypes.CustomRender,
        minWidth: 86,
        resizable: false,
        sortable: true,
        render: (value: any, rowData: GroupedRentElementRecord) => {
            return <span style={{ fontWeight: 600 }}>{value}</span>;
        },
    },
    {
        dataIndex: 'activityFrameShortName',
    },
    GridColumnCreator.createCounterpartyColumn<GroupedRentElementRecord>(
        (row) => ({
            baId: getStoreState().system.businessAccountId,
            id: row.counterpartyId,
            name: row.counterpartyShortName,
        }),
        {
            dataIndex: 'counterpartyShortName',
            defaultHidden: true,
        }
    ),
    {
        title: <span>Кол-во экз.</span>,
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 86,
        resizable: false,
        sortable: true,
        defaultHidden: true,
        render: (value: any, rowData: GroupedRentElementRecord) => {
            return value;
        },
    },
    {
        title: <span>{localize(LocalizationEnum.ASPECT__GRID__COLUMN__SUM)}</span>,
        dataIndex: 'finalTotalSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 120,
        resizable: false,
        sortable: true,
        defaultHidden: true,
    },
];

const createRentColumns = (isReturn: boolean) => {
    return dashboardColumns
        .map((column) => {
            return { ...column };
        })
        .map((column) => {
            if (column.dataIndex === 'counterpartyShortName') {
                column.title = localize(LocalizationEnum.ASPECT__ENTITY_TYPE__RENTER);
            } else if (column.dataIndex === 'activityFrameShortName') {
                return GridColumnCreator.createActivityFrameColumn<GroupedRentElementRecord>(
                    (row) => ({
                        baId: getStoreState().system.businessAccountId,
                        id: row.activityFrameId,
                        name: row.activityFrameShortName,
                        activityFrameTypeCode: RentActivityFrameTypeCodeEnum.PROJECT,
                    }),
                    {
                        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT),
                        dataIndex: column.dataIndex,
                        className: 'rr-grid-shortName-cell',
                    }
                );
            } else if (column.dataIndex === 'date') {
                column.render = (value: Date, rowData: GroupedRentElementRecord) => {
                    const date = moment(rowData.date);
                    const link = `/${getStoreState().system.businessAccountId}/projects/production/${
                        rowData.activityFrameId
                    }?tab=elements&${isReturn ? 'endDate' : 'startDate'}=${date.valueOf()}%2C${date.valueOf()}&typeCode=${
                        isReturn ? RentStateCodeEnum.RENT : RentStateCodeEnum.BOOKED
                    }`;
                    return value ? (
                        <Link to={link} style={{ color: '#343f9a', fontWeight: 600 }}>
                            <DashboardDate value={value} dayBold={true} />
                        </Link>
                    ) : null;
                };
            }
            return column;
        });
};

const createSubrentColumns = (isReturn: boolean) => {
    return dashboardColumns
        .map((column) => {
            return { ...column };
        })
        .map((column) => {
            if (column.dataIndex === 'counterpartyShortName') {
                column.title = <span>Поставщик</span>;
            } else if (column.dataIndex === 'activityFrameShortName') {
                return GridColumnCreator.createActivityFrameColumn<GroupedRentElementRecord>(
                    (row) => ({
                        baId: getStoreState().system.businessAccountId,
                        id: row.activityFrameId,
                        name: row.activityFrameShortName,
                        activityFrameTypeCode: RentActivityFrameTypeCodeEnum.SUBRENT,
                    }),
                    {
                        title: <span>Поставка</span>,
                        dataIndex: column.dataIndex,
                        className: 'rr-grid-shortName-cell',
                    }
                );
            } else if (column.dataIndex === 'date') {
                column.render = (value: Date, rowData: GroupedRentElementRecord) => {
                    const date = moment(rowData.date);
                    const link = `/${getStoreState().system.businessAccountId}/subrent/shippings/${rowData.activityFrameId}?tab=elements&${
                        isReturn ? 'endDate' : 'startDate'
                    }=${date.valueOf()}%2C${date.valueOf()}&typeCode=${
                        isReturn ? RentStateCodeEnum.SUBRENT : RentStateCodeEnum.SUBRENTSHIPMENTBOOKED
                    }`;
                    return value ? (
                        <Link to={link} style={{ color: '#343f9a', fontWeight: 600 }}>
                            <DashboardDate value={value} dayBold={true} />
                        </Link>
                    ) : null;
                };
            }
            return column;
        });
};

export const dashboardRentColumnLeft = createRentColumns(false);

export const dashboardRentColumnRight = createRentColumns(true);

export const dashboardSubRentColumnLeft = createSubrentColumns(false);

export const dashboardSubRentColumnRight = createSubrentColumns(true);
