import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { OperationRecord, OperationRecordList, serverApi, ServerError } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { IRootState } from '../../../../shared/reducers';
import {
    getDatesWithTimeFromParamsString,
    getServerError,
    getStateFromPath2,
    getStringServerProblem,
    test1,
} from '../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { MoneyUtils } from '../../../../core/utils/moneyUtils';
import { getAvailableGridSortById } from '../../../../shared/util/getAvailableGridSortById';
import { columns as operationsListColumns } from '../operationsListColumns';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProblemEnum } from '../../../../types';
import { ProjectPageTabsEnum } from '../../../../shared/constants/tabEnums';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'operations/LOAD_ENTITIES',
    RESET: 'operations/RESET',
    CHANGE_ARCHIVE: 'operations/CHANGE_ARCHIVE',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'creationDate' as string | undefined,
    sortOrder: 'DESC' as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as string | undefined,
    page: 1,
    limit: 10,
    hideAuto: undefined as boolean | undefined, //false,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    renterId: undefined as string | undefined,
    subrentId: undefined as string | undefined,
    projectId: undefined as string | undefined,
    templateId: undefined as string | undefined,
    fromDate: undefined as number | undefined,
    toDate: undefined as number | undefined,
    finalTotalPrice: undefined as number[] | undefined,
    problem: undefined as string | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    authorId: undefined as string | undefined,
    screenLeft: undefined as number | undefined,
    screenRight: undefined as number | undefined,
};

const initialState = {
    loading: true,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<OperationRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type OperationsState = Readonly<typeof initialState>;

// Reducer

export default (state: OperationsState = initialState, action): OperationsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (
                /\/\d+\/history\/operations\/?/.test(pathName) ||
                /\/\d+\/operations\/?/.test(pathName) ||
                (/\/\d+\/subrent\/shippings\/(\d+)/.test(pathName) && params.tab === ProjectPageTabsEnum.OPERATIONS) ||
                (/\/\d+\/projects\/production\/(\d+)/.test(pathName) && params.tab === ProjectPageTabsEnum.OPERATIONS) ||
                (/\/\d+\/projects\/offers\/(\d+)/.test(pathName) && params.tab === ProjectPageTabsEnum.OPERATIONS) ||
                (/\/\d+\/projects\/templates\/(\d+)/.test(pathName) && params.tab === ProjectPageTabsEnum.OPERATIONS) ||
                (/\/\d+\/crm\/counterparties\/(\d+)/.test(pathName) && params.tab === 'operations')
            ) {
                if (/\/\d+\/projects\/production\/(\d+)/.test(pathName)) {
                    let arr = /\/\d+\/projects\/production\/(\d+)/.exec(pathName);
                    if (arr && arr[1]) {
                        params.projectId = +arr[1];
                    }
                } else if (/\/\d+\/projects\/offers\/(\d+)/.test(pathName)) {
                    let arr = /\/\d+\/projects\/offers\/(\d+)/.exec(pathName);
                    if (arr && arr[1]) {
                        params.projectId = +arr[1];
                    }
                } else if (/\/\d+\/projects\/templates\/(\d+)/.test(pathName)) {
                    let arr = /\/\d+\/projects\/templates\/(\d+)/.exec(pathName);
                    if (arr && arr[1]) {
                        params.templateId = +arr[1];
                    }
                } else if (/\/\d+\/subrent\/shippings\/(\d+)/.test(pathName)) {
                    let arr = /\/\d+\/subrent\/shippings\/(\d+)/.exec(pathName);
                    if (arr && arr[1]) {
                        params.subrentId = +arr[1];
                    }
                }

                let arr2 = /\/\d+\/crm\/counterparties\/(\d+)/.exec(pathName);
                if (arr2 && arr2[1]) {
                    params.renterId = +arr2[1];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        typeCode: params.typeCode !== undefined ? params.typeCode : initialParamsState.typeCode,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideAuto: params.hideAuto !== undefined ? params.hideAuto : initialParamsState.hideAuto,
                        shortage: params.shortage !== undefined ? params.shortage : initialParamsState.shortage,
                        delay: params.delay !== undefined ? params.delay : initialParamsState.delay,
                        renterId: params.renterId !== undefined ? params.renterId : initialParamsState.renterId,
                        projectId: params.projectId !== undefined ? params.projectId : initialParamsState.projectId,
                        subrentId: params.subrentId !== undefined ? params.subrentId : initialParamsState.subrentId,
                        templateId: params.templateId !== undefined ? params.templateId : initialParamsState.templateId,
                        finalTotalPrice: test1(params.finalTotalPrice, initialParamsState.finalTotalPrice)?.map((price) =>
                            Math.round(price)
                        ),
                        problem: params.problem !== undefined ? params.problem : initialParamsState.problem,
                        startDate: getDatesWithTimeFromParamsString(params.startDate, initialParamsState.startDate),
                        authorId: params.authorId !== undefined ? params.authorId : initialParamsState.authorId,
                        screenLeft: params.screenLeft !== undefined ? params.screenLeft : initialParamsState.screenLeft,
                        screenRight: params.screenRight !== undefined ? params.screenRight : initialParamsState.screenRight,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as OperationRecordList).records,
                filteredCount: (action.payload.data as OperationRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

// Actions

// Загрузить список операций
export const loadEntities = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: OperationsState = (getState() as IRootState).operations;
        let subrentModuleEnabled = subrentModuleEnabledSelector(getState());
        let params = state.params;
        const sortBy = getAvailableGridSortById(
            params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy,
            operationsListColumns,
            ['problemsAndWarnings.severity'],
            initialParamsState.sortBy
        );
        let filters: string[] = getOperationsListFilters(params, false, subrentModuleEnabled);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listOperations(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__OPERATIONS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const getOperationsListFilters = (
    params: typeof initialParamsState,
    ignoreFinalTotalPrice: boolean = false,
    subrentModuleEnabled: boolean | undefined
): string[] => {
    let filters: string[] = [];

    if (params.typeCode) filters.push('typeCode;EQ;' + params.typeCode);
    if (params.hideAuto === true)
        filters.push('typeCode;IN;ORDER;BOOK;RENT;PROLONG;RETURN;LOST_NO_RETURN;DRAFT;CANCEL;CORRECT;RETURN_BROKEN');

    let problem = params.problem;
    if (
        !subrentModuleEnabled &&
        (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
    )
        problem = undefined;
    if (problem) filters.push(getStringServerProblem(problem));

    if (params.renterId !== undefined) filters.push('counterpartyId;EQ;' + params.renterId); // renterId
    if (params.projectId !== undefined) filters.push('projectId;EQ;' + params.projectId);
    if (subrentModuleEnabled && params.subrentId !== undefined) filters.push('subrentId;EQ;' + params.subrentId);
    if (params.templateId !== undefined) filters.push('templateId;EQ;' + params.templateId);
    if (params.authorId !== undefined) filters.push('creationAuthorId;EQ;' + params.authorId);
    if (params.screenLeft !== undefined) filters.push('creationDate;GE;' + params.screenLeft);
    if (params.screenRight !== undefined) filters.push('creationDate;LE;' + params.screenRight);

    if (!ignoreFinalTotalPrice) {
        if (params.finalTotalPrice) {
            if (params.finalTotalPrice[0] !== undefined)
                filters.push('operationIndicators.finalTotalPrice;GE;' + MoneyUtils.toCents(params.finalTotalPrice[0]));
            if (params.finalTotalPrice[1] !== undefined)
                filters.push('operationIndicators.finalTotalPrice;LE;' + MoneyUtils.toCents(params.finalTotalPrice[1]));
        }
    }

    if (params.startDate !== undefined && params.startDate[0] !== undefined) filters.push('creationDate;GE;' + params.startDate[0]);
    if (params.startDate !== undefined && params.startDate[1] !== undefined) filters.push('creationDate;LE;' + params.startDate[1]);

    return filters;
};
