import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { TimetableTypeCodeEnum } from '../../../server/api';
import { Popover } from '../../../components/popover/Popover';
import { ProductCalendarPopoverContent } from './OperationFormProductCalendarPopoverContent';

interface IProps extends DispatchProps {
    children: (() => ReactNode) | ReactNode;
    productId?: number;
    variantId?: number;
    kitId?: number;
    typeCode: TimetableTypeCodeEnum;
    instanceId?: number;
}

interface IState {}

class _ProductCalendarPopover extends React.Component<IProps, IState> {
    render() {
        return (
            <Popover
                trigger={'click'}
                destroyTooltipOnHide
                overlayClassName={'rr-calendar-popover'}
                content={
                    <ProductCalendarPopoverContent
                        productId={this.props.productId}
                        variantId={this.props.variantId}
                        typeCode={this.props.typeCode}
                        kitId={this.props.kitId}
                        instanceId={this.props.instanceId}
                    />
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}

const mapDispatchToProps = {};
type DispatchProps = typeof mapDispatchToProps;

export const ProductCalendarPopover = connect(undefined, mapDispatchToProps)(_ProductCalendarPopover);
