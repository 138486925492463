import React from 'react';
import {StateBadgeData} from "../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../core/types/color";
import {ProjectStateCodeEnum} from "../../../../../server";

export type ProjectStateCode = ProjectStateCodeEnum.NEW | ProjectStateCodeEnum.INPROGRESS | ProjectStateCodeEnum.FINISHED | ProjectStateCodeEnum.CANCELED;

export const projectEntityStateBadgeData: StateBadgeData<(ProjectStateCode)> = {
    [ProjectStateCodeEnum.NEW]: {
        color: Color.Azure, // Голубой
        name: 'Новый',
    },
    [ProjectStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1, // Оранжевый
        name: 'В работе',
    },
    [ProjectStateCodeEnum.FINISHED]: {
        color: Color.Green, // Зеленый
        name: 'Завершен',
    },
    [ProjectStateCodeEnum.CANCELED]: {
        color: Color.Grey2, // Серый
        name: 'Отменен',
    },
};
