import React, { ReactNode } from 'react';
import { Icon, Tooltip } from 'antd';
import {
    IconBoxesSolid,
    IconClockO,
    IconEllipsisHSolid,
    IconHourglassEndSolid,
    IconShippingFastSolid,
    IconStickyNoteO,
    IconStopwatchSolid,
} from '../icons';
import './currentRentIndicatorsBlock.less';
import { Link } from 'react-router-dom';
import { LocalizationEnum } from '../../localization';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { getCapture } from './currentRentIndicatorsProblems';
import {ElementsCalendarPageParams, linkToCalendar, linkToCalendarFromShortage} from '../../shared/util/createLinkPath';
import { RentElementsGridItem } from '../../types';
import classNames from 'classnames';
import { NomenclatureEntityTypeCodeEnum } from '../../server';

import { CalendarPageTabsEnum } from '../../shared/constants/tabEnums';

// TODO Типизировать потом это все!!!
interface IProps {
    problemsAndWarnings;
    entityType;
    typeRent;
    id;
    entity;
    isPopup: boolean;
}

const linkParamsToDelayForElements = (entity, entityType) => {
    let linkParams:ElementsCalendarPageParams|undefined;
    if(entityType === 'elements' && entity){
        linkParams = {
            elementId: entity?.id,
            renterId: entity?.renterId,
            projectId: entity?.projectId,
            subrentId: entity?.subrentId,
            group: 'project',
            capture: 'allWithNow',
        }
    }
    return linkParams;
}


export class CurrentIndicatorsProblemIcons extends React.PureComponent<IProps> {
    public static defaultProps = {
        isPopup: false,
    };

    render() {
        const { problemsAndWarnings, entityType, typeRent, id, entity, isPopup } = this.props;

        const MAX_COUNT_ICONS = 2;

        let entityUrl;

        if (entityType === 'project') {
            entityUrl = { projectId: id };
        } else if (entityType === 'subrent') {
            entityUrl = { subrentId: id };
        } else if (entityType === 'renter') {
            entityUrl = { renterId: id };
        } else if (entityType === 'operation') {
            entityUrl = {
                operationId: id,
                projectId: entity?.projectId,
                subrentId: entity?.subrentId,
                renterId: entity?.counterpartyId,
            };
        } else if (entityType === 'elements') {
            entityUrl = { elementId: id, projectId: entity?.projectId, subrentId: entity?.subrentId };
        } else if (entityType === 'kits-product') {
            entityUrl = { kitId: id };
        } else if (entityType === 'product') {
            if (entity.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.PRODUCT) {
                entityUrl = { productId: id };
            }
            if (entity.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.VARIANT) {
                entityUrl = { variantId: id, productId: entity?.productId };
            }
        } else if (entityType === 'variant') {
            entityUrl = { variantId: id, productId: entity?.productId };
        } else if (entityType === 'product-instances') {
            entityUrl = { instanceId: id, productId: entity?.productId, variantId: entity?.variantId };
        }

        let typeProblemColor;

        let returnDelayCount,
            bookShortageCount,
            giveawayDelayCount,
            orderShortageCount,
            subrentShipmentDelayCount,
            subrentReturnToShipperDelayCount,
            countProblems = 0;

        if (problemsAndWarnings) {
            if (typeof problemsAndWarnings.returnDelay === 'boolean') {
                returnDelayCount = problemsAndWarnings.returnDelay;
                bookShortageCount = problemsAndWarnings.bookShortage;
                giveawayDelayCount = problemsAndWarnings.giveawayDelay;
                orderShortageCount = problemsAndWarnings.orderShortage;

                subrentShipmentDelayCount = problemsAndWarnings.subrentShipmentDelay;
                subrentReturnToShipperDelayCount = problemsAndWarnings.subrentReturnToShipperDelay;
            } else {
                returnDelayCount = problemsAndWarnings.returnDelay.elementsCount;
                bookShortageCount = problemsAndWarnings.bookShortage.elementsCount;
                giveawayDelayCount = problemsAndWarnings.giveawayDelay.elementsCount;
                orderShortageCount = problemsAndWarnings.orderShortage.elementsCount;

                subrentShipmentDelayCount = problemsAndWarnings.subrentShipmentDelay.elementsCount;
                subrentReturnToShipperDelayCount = problemsAndWarnings.subrentReturnToShipperDelay.elementsCount;
            }
            if (bookShortageCount || subrentShipmentDelayCount || returnDelayCount) {
                typeProblemColor = '#F4516c';
            } else if (giveawayDelayCount || subrentReturnToShipperDelayCount || orderShortageCount) {
                typeProblemColor = '#FC8C2F';
            }

            if (bookShortageCount && typeRent !== 'subrent') {
                countProblems++;
            }
            if (subrentShipmentDelayCount && typeRent !== 'rent') {
                countProblems++;
            }
            if (returnDelayCount && typeRent !== 'subrent') {
                countProblems++;
            }
            if (giveawayDelayCount && typeRent !== 'subrent') {
                countProblems++;
            }
            if (subrentReturnToShipperDelayCount && typeRent !== 'rent') {
                countProblems++;
            }
            if (orderShortageCount && typeRent !== 'subrent') {
                countProblems++;
            }
        }

        let listIcons: ReactNode[] = [];

        if (bookShortageCount && typeRent !== 'subrent') {
            let linkFromElements: string | undefined;
            if (entityType === 'elements' && entity) {
                const _entity = entity as RentElementsGridItem;
                const {
                    kitId,
                    productId,
                    variantId,
                    rentTerms: { rentPeriodStartDate, rentPeriodEndDate },
                    stateCode,
                    id: elementId,
                    rentElementsKitId: kit,
                } = _entity;
                linkFromElements = linkToCalendarFromShortage({
                    kitId,
                    productId,
                    variantId,
                    rentPeriodStartDate,
                    rentPeriodEndDate,
                    stateCode,
                    elementId,
                    kit,
                });
            }

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__BOOK_SHORTAGE}>
                        <Link
                            to={
                                linkFromElements ||
                                linkToCalendar(
                                    {
                                        problem: 'BOOK_SHORTAGE',
                                        group: 'project',
                                        capture: getCapture('all'),
                                        ...entityUrl,
                                    },
                                    CalendarPageTabsEnum.ELEMENTS
                                )
                            }
                        >
                            <Icon
                                className={classNames(
                                    'current-indicators-problem-icons',
                                    'current-indicators-problem-icons-color-hard',
                                    'current-indicators-problem-icons-hover'
                                )}
                                component={IconBoxesSolid}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className={classNames('current-indicators-problem-icons', 'current-indicators-problem-icons-color-hard')}
                        component={IconBoxesSolid}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (subrentShipmentDelayCount && typeRent !== 'rent') {

            let linkParams = linkParamsToDelayForElements(entity, entityType);

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__SUBRENT_SHIPMENT_DELAY}>
                        <Link
                            to={linkToCalendar(linkParams || {
                                problem: 'SUBRENT_SHIPMENT_DELAY',
                                group: 'project',
                                capture: getCapture('allWithNow'),
                                ...entityUrl,
                            })}
                        >
                            <Icon
                                className="current-indicators-problem-icons current-indicators-problem-icons-color-hard current-indicators-problem-icons-hover"
                                component={IconShippingFastSolid}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                        component={IconShippingFastSolid}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (returnDelayCount && typeRent !== 'subrent') {

            let linkParams = linkParamsToDelayForElements(entity, entityType);

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__RETURN_DELAY}>
                        <Link
                            to={linkToCalendar(linkParams || {
                                problem: 'RETURN_DELAY',
                                group: 'project',
                                capture: getCapture('allWithNow'),
                                ...entityUrl,
                            })}
                        >
                            <Icon
                                className="current-indicators-problem-icons current-indicators-problem-icons-color-hard current-indicators-problem-icons-hover"
                                component={IconHourglassEndSolid}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                        component={IconHourglassEndSolid}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (giveawayDelayCount && typeRent !== 'subrent') {

            let linkParams = linkParamsToDelayForElements(entity, entityType);

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__GIVEAWAY_DELAY}>
                        <Link
                            to={linkToCalendar(linkParams || {
                                problem: 'GIVEAWAY_DELAY',
                                group: 'project',
                                capture: getCapture('allWithNow'),
                                ...entityUrl,
                            })}
                        >
                            <Icon
                                className="current-indicators-problem-icons current-indicators-problem-icons-color-lite current-indicators-problem-icons-hover"
                                component={IconClockO}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                        component={IconClockO}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (subrentReturnToShipperDelayCount && typeRent !== 'rent') {

            let linkParams = linkParamsToDelayForElements(entity, entityType);

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__SUBRENT_RETURN_TO_SHIPPER_DELAY}>
                        <Link
                            to={linkToCalendar(linkParams || {
                                problem: 'SUBRENT_RETURN_TO_SHIPPER_DELAY',
                                group: 'project',
                                capture: getCapture('allWithNow'),
                                ...entityUrl,
                            })}
                        >
                            <Icon
                                className="current-indicators-problem-icons current-indicators-problem-icons-color-lite current-indicators-problem-icons-hover"
                                component={IconStopwatchSolid}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                        component={IconStopwatchSolid}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (orderShortageCount && typeRent !== 'subrent') {
            let linkFromElements: string | undefined;
            if (entityType === 'elements' && entity) {
                const _entity = entity as RentElementsGridItem;
                const {
                    kitId,
                    productId,
                    variantId,
                    rentTerms: { rentPeriodStartDate, rentPeriodEndDate },
                    stateCode,
                    id: elementId,
                    rentElementsKitId: kit,
                } = _entity;
                linkFromElements = linkToCalendarFromShortage({
                    kitId,
                    productId,
                    variantId,
                    rentPeriodStartDate,
                    rentPeriodEndDate,
                    stateCode,
                    elementId,
                    kit,
                });
            }

            listIcons.push(
                !isPopup ? (
                    <HelpTooltip content={LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__ORDER_SHORTAGE}>
                        <Link
                            to={
                                linkFromElements ||
                                linkToCalendar({
                                    problem: 'ORDER_SHORTAGE',
                                    group: 'project',
                                    capture: getCapture('all'),
                                    ...entityUrl,
                                })
                            }
                        >
                            <Icon
                                className="current-indicators-problem-icons current-indicators-problem-icons-color-lite current-indicators-problem-icons-hover"
                                component={IconStickyNoteO}
                            />
                        </Link>
                    </HelpTooltip>
                ) : (
                    <Icon
                        className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                        component={IconStickyNoteO}
                        style={{ marginRight: 0 }}
                    />
                )
            );
        }

        if (listIcons.length) {
            if (countProblems > MAX_COUNT_ICONS) {
                return (
                    <Tooltip
                        autoAdjustOverflow
                        arrowPointAtCenter
                        overlayClassName="rr-grid-problem-icons"
                        placement="right"
                        title={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: entityType !== 'variant' && entityType !== 'product-instances' ? '4px' : '0px',
                                }}
                            >
                                {listIcons}
                            </div>
                        }
                        overlayStyle={{ borderBlockColor: '#fff' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', lineHeight: 1 }}>
                            <Icon
                                style={{ color: typeProblemColor }}
                                className="rr-current-indicators-all-problem-icons"
                                component={IconEllipsisHSolid}
                            />
                        </div>
                    </Tooltip>
                );
            } else {
                return <div style={{ display: 'flex', justifyContent: 'center', marginRight: '4px', lineHeight: 1 }}>{listIcons}</div>;
            }
        } else return null;
    }
}
