import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import './HeaderNavigationBlock.less';

const className = 'header-navigation-block-container';

interface HeaderNavigationBlockProps {
    children: (maxWidth: number | undefined) => ReactNode;
}

export const HeaderNavigationBlock: FC<HeaderNavigationBlockProps> = (props) => {
    const { children } = props;
    const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);
    const containerRef = useRef<HTMLDivElement>(null);

    const recalculateMaxWidth = () => {
        const containerNode = containerRef.current;
        const headerNode = containerNode?.parentNode as Element | undefined;
        if (headerNode == null || containerNode == null) return;
        const width = Array.from(headerNode.children).reduce((acc, item) => {
            if (item.className === className || item.className === 'divider') return acc;
            return acc + item.clientWidth;
        }, 0);

        setMaxWidth(headerNode.clientWidth - width - 300);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        recalculateMaxWidth();
    });

    useEffect(() => {
        window.addEventListener('resize', recalculateMaxWidth, true);

        return () => {
            window.removeEventListener('resize', recalculateMaxWidth, true);
        };
    }, []);

    return (
        <div ref={containerRef} className={className}>
            {children(maxWidth)}
        </div>
    );
};
