import React, {FC, memo, useState} from 'react';
import {ProductCompositionStaticGrid} from './components/grid/StaticGrid/ProductCompositionStaticGrid';
import {KitIndicatorsBlock} from '../../kit-indicators-block';
import {KitInfoRead} from '../../../../../../server';
import {canViewFinancialDataSelector} from '../../../../../../shared/reducers/permissions.reducer';
import {useAppSelector} from '../../../../../../store/hooks';
import {useGridStatistics} from './hooks/useGridStatistics';
import './ProductComposition.less';
import {ProductCompositionEditGrid} from './components/grid/EditGrid/ProductCompositionEditGrid';

interface ProductCompositionProps {
    kit: KitInfoRead;
}

export const PRODUCT_COMPOSITION_GRID_NAME = 'kit-composition';

export const ProductComposition: FC<ProductCompositionProps> = memo((props) => {
    const { kit } = props;
    const [isEdit, setIsEdit] = useState(false);

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const gridStatistics = useGridStatistics(kit);

    const excludeColumns = kit.mainMemberOptionEnabled ? [] : ['main'];
    if (!canViewFinancialData) {
        excludeColumns.push('pricePerShift', 'discount', 'finalInstancePrice', 'finalTotalPrice');
    }

    return (
        <>
            <div className="grid-container">
                {isEdit ? (
                    <ProductCompositionEditGrid kit={kit} setIsEdit={setIsEdit} />
                ) : (
                    <ProductCompositionStaticGrid kit={kit} setIsEdit={setIsEdit} excludeColumns={excludeColumns} />
                )}
                <KitIndicatorsBlock
                    instanceCount={gridStatistics.instanceCount}
                    price={gridStatistics.price}
                    discount={gridStatistics.discount}
                    discountPercent={gridStatistics.discountPercent}
                    finalTotalPrice={gridStatistics.finalTotalPrice}
                />
            </div>
        </>
    );
});
