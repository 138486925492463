import React from 'react';
import NumberFormat from 'react-number-format';
import { CurrentCurrency } from '../../components/currentCurrency/currentCurrency';

// TODO мб потом избавиться от NumberFormat!
export const formatMoney = (value: number | null | undefined, withCurrencySign: boolean = true): React.ReactElement => {
    return value !== null && value !== undefined ? (
        <>
            <NumberFormat
                decimalScale={2}
                fixedDecimalScale={value % 100 !== 0}
                value={value / 100}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator={'.'}
            />
            {withCurrencySign ? ' ' : null}
            {withCurrencySign ? <CurrentCurrency /> : null}
        </>
    ) : (
        <></>
    );
};

// TODO мб потом отрефакторить, т.к. от formatMoney отличается по ходу только одним параметром!
export const formatMoneyWithFixedDecimalScale = (
    value: number | null | undefined,
    withCurrencySign: boolean = true
): React.ReactElement => {
    return value !== null && value !== undefined ? (
        <>
            <NumberFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={value / 100}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator={'.'}
            />
            {withCurrencySign ? ' ' : null}
            {withCurrencySign ? <CurrentCurrency /> : null}
        </>
    ) : (
        <></>
    );
};
