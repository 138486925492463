import { EntityActionsGridButtonsHook } from '../../../../components/page/entityGrid/hooks/useActionButtonsProps';
import { ProjectRecord, ProjectTransitionCodeEnum, TagEntityTypeCode } from '../../../../server';
import { useSetStatusForOffersCallback } from './useOffersSetStatusForOffersCallback';
import { useIncludeOrExcludeOfferInProjectPriceForOffersCallback } from './useIncludeOrExcludeOfferInProjectPriceForOffersCallback';
import { useAppSelector } from '../../../../store/hooks';
import { canUpdateFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import React, { useMemo } from 'react';
import { ActionButtonsGroup } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { offerActionButtons, offerActionToTransitionCodeMap, OfferActionType } from '../data/offersData';
import {isActionAvailable} from "../../workPlannings/utils/isActionAvailable";
import { NestedAdditionalEntityActionsForTags } from '../../../../components/additionalEntityActions/NestedAdditionalEntityActionsForTags/NestedAdditionalEntityActionsForTags';

export const useEntityActionsButtons: EntityActionsGridButtonsHook<ProjectRecord> = ({ selectedRecords, gridRef, refetchFunction }) => {
    const setStatusForOffersCallback = useSetStatusForOffersCallback(selectedRecords, gridRef);
    const includeOrExcludeOfferInProjectPriceForOffersCallback = useIncludeOrExcludeOfferInProjectPriceForOffersCallback(
        selectedRecords,
        gridRef
    );
    const canUpdateFinancialData = useAppSelector(canUpdateFinancialDataSelector);
    return useMemo(
        () => [
            <ActionButtonsGroup
                data={offerActionButtons}
                mainActions={[
                    isActionAvailable(selectedRecords, ProjectTransitionCodeEnum.APPROVE, OfferActionType.approve),
                    isActionAvailable(selectedRecords, ProjectTransitionCodeEnum.RETURNTONEW, OfferActionType.returnToNew),
                    isActionAvailable(selectedRecords, ProjectTransitionCodeEnum.CANCEL, OfferActionType.cancel),
                    canUpdateFinancialData && selectedRecords.every((record) => !record.partOfThePrice)
                        ? OfferActionType.includeOfferInProjectPrice
                        : undefined,
                    canUpdateFinancialData && selectedRecords.every((record) => record.partOfThePrice)
                        ? OfferActionType.excludeOfferInProjectPrice
                        : undefined,
                ]}
                onAction={(action) => {
                    const code = offerActionToTransitionCodeMap[action];
                    if (code) setStatusForOffersCallback(code);
                    else if (
                        action === OfferActionType.includeOfferInProjectPrice ||
                        action === OfferActionType.excludeOfferInProjectPrice
                    ) {
                        includeOrExcludeOfferInProjectPriceForOffersCallback();
                    }
                }}
            />,
            <NestedAdditionalEntityActionsForTags
                entities={selectedRecords}
                tagType={TagEntityTypeCode.PROJECT}
                clearSelection={() => {
                    gridRef.current?.clearSelection();
                }}
                onSuccess={() => {
                    refetchFunction?.();
                }}
            />,
        ],
        [
            canUpdateFinancialData,
            gridRef,
            includeOrExcludeOfferInProjectPriceForOffersCallback,
            refetchFunction,
            selectedRecords,
            setStatusForOffersCallback,
        ]
    );
};
