import React, { useCallback } from 'react';
import { ProjectRecord, ProjectStateCodeEnum, ProjectTypeCodeEnum } from '../../../../../../server';
import { Icon } from 'antd';
import { IconCrosshairsSolid, IconMoneyCheckAltSolid } from '../../../../../../components/icons';
import { ProjectStateBadge } from '../../../../projects/production/components/ProjectStateBadge/ProjectStateBadge';
import { ProjectStateCode } from '../../../../projects/production/data/projectData';

export const useCustomChangeData = (
    stateCodes: {
        active: boolean;
        finished: boolean;
    },
    copy: boolean,
    projectId?: number
) => {
    return useCallback(
        (records: ProjectRecord[]) => {
            const { active, finished } = stateCodes;

            return records.map((record) => {
                const { renterShortName } = record;
                const isOffer = record.projectType === ProjectTypeCodeEnum.OFFER;
                const isProject = record.projectType === ProjectTypeCodeEnum.BASE;
                const isMoveAction = !copy;
                const isSameProject = record.id === projectId;

                const Bar = (
                    <span>
                        {record.shortName} <span style={{ fontWeight: 200 }}>{renterShortName}</span>
                    </span>
                );
                const MoneyIcon = <Icon style={{ fontSize: 20, marginRight: 8 }} component={IconMoneyCheckAltSolid} />;
                const SameProjectIcon = <Icon style={{ fontSize: 20, color: '#ababab', marginRight: 8 }} component={IconCrosshairsSolid} />;

                if (isMoveAction && isSameProject) {
                    const name = (
                        <div onClick={(e) => e.stopPropagation()} className={'select-project-popover-disabled'}>
                            {SameProjectIcon}
                            {Bar}
                        </div>
                    );

                    return { ...record, name };
                }

                const isDisabledByActive =
                    active && ([ProjectStateCodeEnum.NEW, ProjectStateCodeEnum.FINISHED].includes(record.stateCode) || isOffer);
                const isDisabledByFinished = finished && ([ProjectStateCodeEnum.NEW].includes(record.stateCode) || isOffer);
                let isDisabled = isDisabledByActive || isDisabledByFinished;
                if(copy && isOffer) isDisabled = false;
                if(copy && !isOffer && record.stateCode === ProjectStateCodeEnum.NEW) isDisabled = false;

                if (isDisabled) {
                    const icon = isOffer ? (
                        MoneyIcon
                    ) : isProject ? (
                        <ProjectStateBadge dot hideLabel type={record.stateCode as ProjectStateCode} />
                    ) : (
                        SameProjectIcon
                    );

                    const name = (
                        <div onClick={(e) => e.stopPropagation()} className={'select-project-popover-disabled'}>
                            {icon}
                            {Bar}
                        </div>
                    );

                    return { ...record, name };
                }

                const icon = isOffer ? MoneyIcon : null;
                let name = (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {icon}
                        {Bar}
                    </div>
                );

                return { ...record, name };
            });
        },
        [copy, projectId, stateCodes]
    );
};
