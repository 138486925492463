import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { KitRecord, KitRecordList, serverApi, VersionedEntityObj, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getStateFromPath2, getStringServerProblem, test1 } from '../../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { changeCategoryBinding } from '../../../categories/reducers/categories.reducer';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProblemEnum } from '../../../../../types';
import { kitsApi } from '../api/kits.api';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'kits/LOAD_ENTITIES',
    DELETE_ENTITIES: 'kits/DELETE_ENTITIES',
    RESET: 'kits/RESET',
    CHANGE_ARCHIVE: 'kits/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'kits/CHANGE_STATUS',
    CHANGE_LOADING: 'kits/CHANGE_LOADING',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'lastActivityDate' as string | undefined,
    sortOrder: 'DESC' as 'DESC' | 'ASC' | undefined,
    status: undefined as string | undefined,
    page: 1,
    limit: 10,
    hideArchive: undefined as boolean | undefined,
    categoryIds: undefined as string | undefined,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    finalTotalPrice: undefined as number[] | undefined,
    discount: undefined as number[] | undefined,
    problem: undefined as string | undefined,
    productId: undefined as number | undefined,
    variantId: undefined as number | undefined,
};

const initialState = {
    loading: true,
    loadingError: null,
    entities: null as Array<KitRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type KitsState = Readonly<typeof initialState>;

// Reducer

export default (state: KitsState = initialState, action): KitsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (/\/\d+\/inventory\/kits/.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        status: params.status !== undefined ? params.status : initialParamsState.status,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : initialParamsState.hideArchive,
                        categoryIds: params.categoryIds !== undefined ? params.categoryIds : initialParamsState.categoryIds,
                        shortage: params.shortage !== undefined ? params.shortage : initialParamsState.shortage,
                        delay: params.delay !== undefined ? params.delay : initialParamsState.delay,
                        finalTotalPrice: test1(params.finalTotalPrice, initialParamsState.finalTotalPrice)?.map((price) =>
                            Math.round(price)
                        ),
                        discount: test1(params.discount, initialParamsState.discount),
                        problem: params.problem !== undefined ? params.problem : initialParamsState.problem,
                        productId: params.productId !== undefined ? params.productId : initialParamsState.productId,
                        variantId: params.variantId !== undefined ? params.variantId : initialParamsState.variantId,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as KitRecordList).records,
                filteredCount: (action.payload.data as KitRecordList).listAttributes.filteredCount,
            };
        case changeCategoryBinding.pending.type:
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case changeCategoryBinding.rejected.type:
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case changeCategoryBinding.fulfilled.type:
            return {
                ...state,
                loading: false,
                loadingError: null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case ACTION_TYPES.CHANGE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: KitsState = (getState() as IRootState).kits;
        let subrentModuleEnabled = subrentModuleEnabledSelector(getState() as IRootState);
        let params = state.params;
        let filters: string[] = getKitsListFilters(params, subrentModuleEnabled);

        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listKits(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__FAILED_TO_GET_KIT_LIST));
            } else {
            }
        });
    };
};

export const deleteEntities = (intl, businessAccountId: number, objects: VersionedEntityObj[]) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: serverApi.deleteKitsBulk(businessAccountId, { entities: objects }),
        }).then((result) => {
            let isMany = objects.length > 1;
            if (result instanceof Error) {
                showNotification('error', isMany ? 'Наборы не удалены' : 'Набор не удален');
            } else {
                showNotification('success', isMany ? 'Наборы удалены' : 'Набор удален');
                dispatch(kitsApi.util?.invalidateTags(['KitsList']));
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitKitWorkflowBulk(businessAccountId, { directives: directives }), // TODO Тут должен быть метод для Продуктов, а не для Арендаторов
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification(
                    'success',
                    directives.length === 1
                        ? localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_UPDATED)
                        : localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KITS_UPDATES)
                );
                dispatch(kitsApi.util?.invalidateTags(['KitsList']));
            }
        });
    };
};

export const setStatus = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitKitWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус арендатора не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Статус арендатора изменен
                showNotification(
                    'success',
                    directives.length === 1
                        ? localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__STATE_CHANGED)
                        : localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_STATES_CHANGED)
                );
                dispatch(kitsApi.util?.invalidateTags(['KitsList']));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const getKitsListFilters = (params: typeof initialParamsState, subrentModuleEnabled: boolean | undefined): string[] => {
    let filters: string[] = [];

    if (params.status) filters.push('stateCode;EQ;' + params.status);
    if (params.hideArchive === true) filters.push('archive;EQ;false');

    if (params.categoryIds !== undefined) filters.push('categoryIds;IN;' + params.categoryIds);

    if (params.finalTotalPrice) {
        if (params.finalTotalPrice[0] !== undefined) filters.push('finalTotalPrice;GE;' + MoneyUtils.toCents(params.finalTotalPrice[0]));
        if (params.finalTotalPrice[1] !== undefined) filters.push('finalTotalPrice;LE;' + MoneyUtils.toCents(params.finalTotalPrice[1]));
    }
    if (params.discount) {
        if (params.discount[0] !== undefined) filters.push('totalDiscount;GE;' + params.discount[0]);
        if (params.discount[1] !== undefined) filters.push('totalDiscount;LE;' + params.discount[1]);
    }

    let problem: string | undefined = params.problem;
    if (
        !subrentModuleEnabled &&
        (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
    )
        problem = undefined;
    if (problem) filters.push(getStringServerProblem(problem));

    if (params.productId !== undefined) filters.push('productIds;IN;' + params.productId);
    if (params.variantId !== undefined) filters.push('variantIds;IN;' + params.variantId);

    return filters;
};
