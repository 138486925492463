import React from 'react';
import { Alert, Button, Icon, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { loadEntity, reset, setStatusAndArchive } from './reducers/template.reducer';
import { IRootState } from '../../../../shared/reducers';
import { RoundButton, Spin } from '../../../../components';
import {
    IconArchive,
    IconBorderStyleSolid,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconLevelUp,
    IconMedkit,
} from '../../../../components/icons';
import { TemplateCreateModal } from './templateCreateModal';
import TemplatePageDescriptionTabPane from './templatePageDescription';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { getPathFromState, getShiftCountFromDates, getStateFromPath, isStringInEnum } from '../../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../components/pageHeader/pageHeader';
import { removeConcurrentOperation, resetOperationForm, startNewOperation } from '../../operationForm/reducers/operationForm.reducer';
import { logEvent } from '../../../../analytics/analytics';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import { OperationTypeCodeEnum, RentActivityFrameTypeCodeEnum, RentStateCodeEnum } from '../../../../server/api';
import { showNotification } from '../../../../components/notification/showNotification';
import { createNewDraftOperation, createOperation } from '../../elements/create-operation-util';
import { TemplatePageElementsTabPane } from './templatePageElements';
import moment from 'moment';
import { ProjectCreateModal } from '../production/modal/project-create-modal';
import { OperationsList } from '../../operations/operationsList';
import { RentElementsGridItem } from '../../../../types';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { listConcurrentOperationsAndShowConfirm } from '../../operationForm/listConcurrentOperationsAndShowConfirm';
import { createProjectModalFilters } from '../production/modal/projectsPageData';
import { createTemplateModalFilters, editTemplateModalFilters } from './templatePageData';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';

import { TemplatePageTabsEnum } from '../../../../shared/constants/tabEnums';
import { TemplatePageHeaderNavigationBlock } from './components/HeaderNavigationBlock/TemplatePageHeaderNavigationBlock';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = TemplatePageTabsEnum.ELEMENTS;

class TemplatePage extends BasePage<IProps, IState> {
    private selectedTab: TemplatePageTabsEnum = defaultTab;
    private tabs = {};

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    async componentDidMount() {
        super.componentDidMount();

        await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
    }

    async componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.currentTab) saveCurrentTabToStore('template', this.currentTab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onTabsChange = (key: string, replace: boolean = false) => {
        const urlMethod = replace ? this.props.replace : this.props.push;

        this.tabs[this.selectedTab] = this.props.location.search;
        this.selectedTab = key as TemplatePageTabsEnum;
        let searchString = this.getSearchString(key as TemplatePageTabsEnum);

        saveCurrentTabToStore('template', key);
        urlMethod(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
        this.logViewEvent();
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/templates/${this.props.entityId}/edit?tab=description`);
    };

    createOperation = async (
        code: OperationTypeCodeEnum,
        selectedItems: Array<RentElementsGridItem>,
        targetStateCode?: RentStateCodeEnum,
        instanceCount?: number
    ) => {
        if (this.props.entity) {
            let canCreate = true;

            let kitIds: number[] = [];
            let kitElements: RentElementsGridItem[] = [];

            selectedItems.forEach((item) => {
                if (item.rentElementsKitId) {
                    if (!kitIds.includes(item.rentElementsKitId)) kitIds.push(item.rentElementsKitId);
                }
            });

            selectedItems = selectedItems.concat(kitElements);

            let shiftCount =
                this.props.entity.startDate && this.props.entity.targetFinishDate
                    ? getShiftCountFromDates(
                          moment(this.props.entity.startDate),
                          moment(this.props.entity.targetFinishDate),
                          this.props.shiftLengthInMinutes,
                          this.props.shiftCountRoundingType
                      )
                    : 7;
            let discount = this.props.entity.defaultDiscount;

            if (this.props.operationFormMnemoKey) {
                canCreate = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
                );
            }

            if (canCreate) {
                try {
                    canCreate = await listConcurrentOperationsAndShowConfirm(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.entity.id,
                        RentActivityFrameTypeCodeEnum.TEMPLATE
                    );
                } catch (e) {
                    this.setState({ operationIsLoading: false });
                    return;
                }
            }

            if (canCreate) {
                try {
                    if (this.props.currentOperationUUID) {
                        //await this.props.removeConcurrentOperation(this.props.currentOperationUUID);
                    }
                    if (selectedItems.length > 0)
                        await createOperation(
                            this.props.intl,
                            true,
                            this.props.startNewOperation,
                            this.props.businessAccountId,
                            code,
                            selectedItems,
                            targetStateCode,
                            instanceCount
                        );
                    else
                        await createNewDraftOperation(
                            OperationTypeCodeEnum.DRAFT,
                            true,
                            this.props.startNewOperation,
                            this.props.businessAccountId,
                            this.props.entity.id,
                            this.props.entity.shortName,
                            undefined,
                            undefined,
                            discount,
                            shiftCount
                        );
                    this.setState({ operationIsLoading: false });
                    this.props.push(`/${this.props.businessAccountId}/operation?tab=${selectedItems.length > 0 ? 'elements' : 'products'}`);
                } catch (e) {
                    console.log(e);
                    this.setState({ operationIsLoading: false });
                    showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                }
            } else {
                this.setState({ operationIsLoading: false });
            }
        }
    };

    onEditButtonClick1 = () => {
        this.createOperation(OperationTypeCodeEnum.DRAFT, []);
    };

    onCreateProjectFromTemplate = () => {
        this.props.push(
            `/${this.props.businessAccountId}/projects/templates/${this.props.entityId}/createProjectFromTemplate?tab=description`
        );
    };

    onArchiveButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            if (!entity.archive) {
                //TODO Нормальный текст предупреждения придумать
                showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__TEMPLATES__MODAL__QUESTIONS__SEND_TO_ARCHIVE),
                    undefined,
                    () => {
                        if (entity)
                            this.props.setStatusAndArchive(
                                this.props.intl,
                                this.props.businessAccountId,
                                this.props.entityId,
                                entity.businessVersion,
                                undefined,
                                true
                            );
                    }
                );
            } else {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    entity.businessVersion,
                    undefined,
                    false
                );
            }
        }
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/templates/${this.props.entityId}/copy?tab=description`);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity } = this.props;

        return {
            archive: !!(entity && entity.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: !(entity && entity.fullName),
            businessAccountId: this.props.businessAccountId,
            parentPagePath: entity ? `/${this.props.businessAccountId}/projects/templates` : undefined,
            NavigationBlock: <TemplatePageHeaderNavigationBlock template={entity || undefined} />,
        };
    }

    logViewEvent = () => {
        logEvent({ type: 'view project', data: { 'entity id': this.props.entityId, tab: this.selectedTab } });
    };

    getSearchString(key: TemplatePageTabsEnum) {
        return key === TemplatePageTabsEnum.ELEMENTS || key === TemplatePageTabsEnum.OPERATIONS || key === TemplatePageTabsEnum.STATISTICS
            ? this.tabs[this.selectedTab] || ''
            : '';
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let { entity, loading, updating } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const key = isStringInEnum(this.props.tabFromStore, TemplatePageTabsEnum, defaultTab);
            return <Redirect to={getPathFromState(this.props.location.pathname, this.getSearchString(key), { tab: key })} />;
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        return (
            <>
                <Spin spinning={loading || updating}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <>
                                <div className="card-container">
                                    <Tabs
                                        defaultActiveKey={TemplatePageTabsEnum.ELEMENTS}
                                        activeKey={this.props.tab}
                                        onChange={this.onTabsChange}
                                        className={'rr-tabs'}
                                        type="line"
                                        animated={false}
                                        tabBarExtraContent={
                                            <>
                                                <RoundButton title={''} colorScheme={'DRAFT'} onClick={this.onEditButtonClick1}>
                                                    <Icon component={IconBorderStyleSolid} />
                                                    {localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__DRAFT, 'span')}
                                                </RoundButton>

                                                <RoundButton colorScheme={'default'} onClick={this.onEditButtonClick}>
                                                    <Icon component={IconEdit} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                                                </RoundButton>

                                                <RoundButton colorScheme={'success'} onClick={this.onCreateProjectFromTemplate}>
                                                    <Icon component={IconMedkit} />
                                                    <span>
                                                        {localize(
                                                            LocalizationEnum.PAGE__TEMPLATES__MODAL__CREATE_PROJECT_FROM_TEMPLATE,
                                                            'span'
                                                        )}
                                                    </span>
                                                </RoundButton>

                                                <span style={{ marginLeft: '16px' }}>
                                                    <AdditionalEntityActions
                                                        disabled={false}
                                                        content={
                                                            <>
                                                                <Button className={'ant-btn-page-actions'} onClick={this.onCopyButtonClick}>
                                                                    <Icon
                                                                        component={IconControlPointDuplicateBlack}
                                                                        style={{ color: '#34bfa3' }}
                                                                    />
                                                                    {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                                </Button>

                                                                {entity.archivable ? (
                                                                    !entity.archive ? (
                                                                        <Button
                                                                            className={'ant-btn-page-actions'} //#c4c5d6
                                                                            onClick={this.onArchiveButtonClick}
                                                                        >
                                                                            <Icon component={IconArchive} style={{ color: '#c4c5d6' }} />
                                                                            {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            className={'ant-btn-page-actions'} //#00c5dc
                                                                            onClick={this.onArchiveButtonClick}
                                                                        >
                                                                            <Icon component={IconLevelUp} style={{ color: '#00c5dc' }} />
                                                                            {localize(
                                                                                LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE,
                                                                                'span'
                                                                            )}
                                                                        </Button>
                                                                    )
                                                                ) : null}
                                                            </>
                                                        }
                                                    >
                                                        <AdditionalEntityActionsButton />
                                                    </AdditionalEntityActions>
                                                </span>
                                            </>
                                        }
                                    >
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                            key={TemplatePageTabsEnum.DESCRIPTION}
                                        >
                                            <TemplatePageDescriptionTabPane />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENTS)}
                                            key={TemplatePageTabsEnum.ELEMENTS}
                                        >
                                            <TemplatePageElementsTabPane />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATIONS)}
                                            key={TemplatePageTabsEnum.OPERATIONS}
                                        >
                                            <OperationsList
                                                gridName={'projectTemplate-operations'}
                                                location={this.props.location}
                                                renterId={entity.id}
                                                parentEntityType={'projectTemplate'}
                                            />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </>
                        ) : null}
                    </div>
                </Spin>

                {this.props.editMode ? (
                    <TemplateCreateModal
                        title={localize(LocalizationEnum.PAGE__TEMPLATES__FORM__UPDATE_FORM_LABEL)}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity as { [k: string]: any }}
                        filters={editTemplateModalFilters}
                        modalName={'edit-template'}
                    />
                ) : null}

                {entity && this.props.copyMode ? (
                    <TemplateCreateModal
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__ADD)}
                        validateAfterCreate={true}
                        title={localize(LocalizationEnum.PAGE__TEMPLATES__FORM__CREATION_FORM_LABEL)}
                        copyMode={true}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity}
                        filters={createTemplateModalFilters}
                        modalName={'create-template'}
                    />
                ) : null}

                {entity && this.props.copyModeProject ? (
                    <ProjectCreateModal
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__ADD)}
                        validateAfterCreate={true}
                        title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
                        copyMode={true}
                        copyModeProject={this.props.copyModeProject}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={{
                            ...entity,
                            taxBaseType: this.props.businessAccountPreferences?.taxBaseType,
                            taxRate: this.props.businessAccountPreferences?.defaultTaxRate,
                        }}
                        filters={createProjectModalFilters}
                        modalName={'create-project'}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.template.entity,
        renter: storeState.renter.entity,
        loading: storeState.template.loading,
        updating: storeState.template.updating,

        loadingError: storeState.template.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.template.entity && ownProps.match.params['action'] === 'edit'),
        payMode: !!(storeState.template.entity && ownProps.match.params['action'] === 'pay'),

        operationFormMnemoKey: storeState.operationForm.mnemoKey,

        copyMode: !!(storeState.template.entity && ownProps.match.params['action'] === 'copy'),

        copyModeProject: !!(storeState.template.entity && ownProps.match.params['action'] === 'createProjectFromTemplate'),
        tab:
            searchParams.tab &&
            (searchParams.tab === TemplatePageTabsEnum.DESCRIPTION ||
                searchParams.tab === TemplatePageTabsEnum.OPERATIONS ||
                searchParams.tab === TemplatePageTabsEnum.ELEMENTS) /* || searchParams.tab === TabsEnum.STATISTICS*/
                ? searchParams.tab
                : TemplatePageTabsEnum.ELEMENTS,

        shiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        businessAccountPreferences: storeState.businessAccountPreferences.preferences,
        tabFromStore: getCurrentTabsSelector(storeState)?.['template'],
    };
};

const mapDispatchToProps = {
    loadEntity,
    setStatusAndArchive,
    reset,
    push,
    replace,
    resetOperationForm,
    startNewOperation,
    removeConcurrentOperation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
