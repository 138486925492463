import React, { useCallback } from 'react';
import { useEntitySelection } from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import {
    ExcelExportTemplateTypeCodeEnum,
    KitFinancialFieldsTypeCode, KitNotFinancialFieldsTypeCode,
} from '../../../../../server';
import { useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import {ExportOffloadingPopover} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {useIntl} from "react-intl";
import {canViewFinancialDataSelector} from "../../../../../shared/reducers/permissions.reducer";
import {useEntityGridContext} from "../../../../../components/page/entityGrid/components/context/GridRefContext";
import {KitsListQueryParams} from "../api/kits.api.types";
import {KitsApiUtils} from "../api/kits.api.utils";

export const useExportBlock = (pageParams: KitsListQueryParams) => {
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const entitySelection = useEntitySelection();
    const gridRef = useEntityGridContext();

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = KitsApiUtils.getRequestFilters(pageParams, subrentModuleEnabled);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.KITTEMPLATE,
                fileName: 'Экспорт наборов',
                fileType,
                filters,
                financialFieldsEnum: KitFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: KitNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return <ExportOffloadingPopover storageKey={'kitsOffloading'} onDownload={onOffloadingDownload} />;
};
