import {
    ReportChartVisualizationRecord,
    ReportChartVisualizationRecordList,
    ReportTypeCodeEnum,
    serverApi,
    ServerError,
    VisualizationTypeEnum,
} from '../../../../server';
import { LOCATION_CHANGE } from 'connected-react-router';
import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { getServerError, getStateFromPath2 } from '../../../../shared/util/utils';
import { IRootState } from '../../../../shared/reducers';
import { getAvailableGridSortById } from '../../../../shared/util/getAvailableGridSortById';
import { analyticsPageColumns } from '../analyticsPageColumns';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

const ACTION_TYPES = {
    LOAD_ENTITIES: 'analyticsList/LOAD_ENTITIES',
    RESET: 'analyticsList/RESET',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'reportConfigurationName' as string | undefined,
    sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
    page: 1,
    limit: 10,
    reportType: undefined as ReportTypeCodeEnum | undefined,
    visualizationType: undefined as VisualizationTypeEnum | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<ReportChartVisualizationRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

export type AnalyticsListState = Readonly<typeof initialState>;

export default (state: AnalyticsListState = initialState, action): AnalyticsListState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp = /\/(\d+)\/reports/;

            if (regexp.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        visualizationType:
                            params.visualizationType !== undefined
                                ? (params.visualizationType as VisualizationTypeEnum)
                                : initialParamsState.visualizationType,
                        reportType:
                            params.reportType !== undefined ? (params.reportType as ReportTypeCodeEnum) : initialParamsState.reportType,
                    },
                };
            }
            return state;
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loading: false,
                loadingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as any).records,
                filteredCount: (action.payload.data as ReportChartVisualizationRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            console.log('ACTION RESET', initialState);
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const loadEntities = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: AnalyticsListState = (getState() as IRootState).analyticsList;
        let params = state.params;
        let { visualizationType, reportType } = params;
        let filters: Array<string> = [];
        if (visualizationType) {
            filters.push(`visualizationType;EQ;${visualizationType}`);
        }
        if (reportType) {
            filters.push(`reportType;EQ;${reportType}`);
        }
        const sortBy = getAvailableGridSortById(params.sortBy, analyticsPageColumns, undefined, 'name');

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listBusinessAccountReportChartVisualizations(
                businessAccountId,
                params.search,
                params.limit,
                (params.page - 1) * params.limit,
                sortBy,
                params.sortOrder === undefined ? 'ASC' : params.sortOrder,
                filters
            ),
        });
    };
};

export const reset = () => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.RESET,
        });
    };
};
