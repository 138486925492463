import React from 'react';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {modalShow} from "../../../core/modals/modals";
import { DatePicker } from 'antd';
import {RangePicker} from "../../../components/v2/calendar/rangePicker/rangePicker";
//import {Picker0} from "./rangePickerNew/picker/picker";
import {RangePickerValue} from "antd/lib/date-picker/interface";
import moment from "moment";
import {DateTimePicker} from "../../../components/dateTimePicker/dateTimePicker";
import {RangeCalendar} from "../../../components/v2/calendar/rangePicker/components/rangeCalendar/rangeCalendar";

interface DevPageProps extends IBasePageProps {
}

interface State {
    dates:any;
}

export class DevPage extends BasePage<DevPageProps, State> {

    constructor(props) {
        super(props);

        this.state = {
            dates: [moment('2024-07-20'), moment('2024-07-29')]
        };
    }

    renderContent() {

        //const { MonthPicker, RangePicker } = DatePicker;

        return (
            <>
                <button onClick={()=>{
                    modalShow('location.view:1');
                }}>location</button>

                <button onClick={()=>{
                    modalShow('inventoryMovement.view:17476120');
                }}>inventoryMovement</button>

                <RangePicker disabled={true} />

                <RangePicker
                    //disabled={true}
                    //placeholder={['Дата выдачи', 'Дата возврата']}
                    onChange={(dates)=>{
                        console.log('onChange', dates);
                        this.setState({dates: dates})
                    }}
                    value={this.state.dates}
                    showTime={true}
                    allowClear={true}
                />

                <DateTimePicker />

                {/*<RangeCalendar*/}
                {/*    value={this.state.dates}*/}
                {/*    onChange={(dates)=>{*/}
                {/*        this.setState({dates: dates})*/}
                {/*    }}*/}
                {/*/>*/}

                <RangePicker />

                {/*<Picker0 calendar={<div style={{backgroundColor: '#FFFFCC', width: 500, height: 500}}>XXXZZZYYY</div>}>*/}
                {/*    <span>526</span>*/}
                {/*</Picker0>*/}

                {/*<RangePicker*/}

            </>
        );
    }
}
