import React from 'react';
import { RenterSelectFC, RenterSelectProps } from '../renterSelect/renterSelect';
import {
    CounterpartyCreateModalNew,
    CounterpartyCreateModalNewProps,
} from '../../../../modules/main/renters/tabs/counterparties/modal/CounterpartyCreateModalNew';
import { SelectAndCreate } from '../selectAndCreate';

export interface CounterpartySelectAndCreateProps extends RenterSelectProps {
    counterpartyType?: CounterpartyCreateModalNewProps['counterpartyType'];
}

export class CounterpartySelectAndCreate extends React.Component<CounterpartySelectAndCreateProps> {
    render() {
        const { props } = this;
        const { counterpartyType, onChange } = props;

        return (
            <SelectAndCreate
                select={() => <RenterSelectFC {...props} />}
                modal={({ close }) => {
                    return (
                        <CounterpartyCreateModalNew
                            counterpartyType={counterpartyType}
                            onSuccess={(data) => {
                                onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}
