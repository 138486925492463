import { FAILURE, REQUEST, SUCCESS } from './action-type.util';
import {
    BusinessAccountModuleCodeEnum,
    BusinessAccountPreferencesInfoRead,
    BusinessAccountPreferencesInfoUpdate,
    serverApi,
    ServerError,
} from '../../server';
import { getServerError } from '../util/utils';
import { IRootState } from './index';
import { setBAPreferences } from '../../modules/main/operationForm/reducers/operationForm.reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppDispatch } from '../../../index';

export const ACTION_TYPES = {
    GET_BUSINESS_ACCOUNT_PREFERENCES: 'businessAccountPreferences/GET_BUSINESS_ACCOUNT_PREFERENCES',
    UPDATE_BUSINESS_ACCOUNT_PREFERENCES: 'businessAccountPreferences/UPDATE_BUSINESS_ACCOUNT_PREFERENCES',
    RESET_BUSINESS_ACCOUNT_PREFERENCES_ERROR: 'businessAccountPreferences/RESET_BUSINESS_ACCOUNT_PREFERENCES_ERROR',
    ENABLE_OR_DISABLE_BUSINESS_ACCOUNT_MODULE: 'businessAccountPreferences/ENABLE_OR_DISABLE_BUSINESS_ACCOUNT_MODULE',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    loadingLastTime: undefined as undefined | Date,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    preferences: undefined as BusinessAccountPreferencesInfoRead | undefined,
};

export type BusinessAccountPreferencesState = Readonly<typeof initialState>;

// Reducer

export default (state: BusinessAccountPreferencesState = initialState, action): BusinessAccountPreferencesState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
                loadingLastTime: new Date(),
            };

        case FAILURE(ACTION_TYPES.GET_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
                loadingLastTime: undefined,
            };

        case SUCCESS(ACTION_TYPES.GET_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                preferences: action.payload.data,
                loading: false,
                loadingLastTime: new Date(),
            };

        case REQUEST(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                updatingError: undefined,
                updating: true,
            };

        case FAILURE(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                updatingError: getServerError(action.payload),
                updating: false,
            };

        case SUCCESS(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_PREFERENCES):
            return {
                ...state,
                preferences: action.payload.data,
                updating: false,
                loadingLastTime: new Date(),
            };

        case ACTION_TYPES.RESET_BUSINESS_ACCOUNT_PREFERENCES_ERROR:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined,
            };

        default:
            return state;
    }
};

// Actions

// Получение настроек для БА
export const getBusinessAccountPreferences = (businessAccountId: number, refresh: boolean = false) => {
    return (dispatch, getState) => {
        let state: BusinessAccountPreferencesState = (getState() as IRootState).businessAccountPreferences;
        if (!state.loadingLastTime || Date.now() > state.loadingLastTime.getTime() + 10 * 1000 || refresh) {
            return dispatch({
                type: ACTION_TYPES.GET_BUSINESS_ACCOUNT_PREFERENCES,
                payload: serverApi.getBusinessAccountPreferences(businessAccountId),
            });
        }
    };
};

// Обновление настроек
export const updateBusinessAccountPreferences = (businessAccountId: number, data: BusinessAccountPreferencesInfoUpdate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_PREFERENCES,
            payload: serverApi.updateBusinessAccountPreferences(businessAccountId, data),
        }).then((result) => {
            console.log('RRR', result);
            if (result instanceof Error) {
                //
                throw result;
            } else {
                dispatch(
                    setBAPreferences(
                        (result.value.data as BusinessAccountPreferencesInfoRead).shiftLengthInMinutes,
                        (result.value.data as BusinessAccountPreferencesInfoRead).shiftCountRoundingType,
                        (result.value.data as BusinessAccountPreferencesInfoRead).requiredTimeIndentBetweenElementsInMinutes
                    )
                );
            }
        });
    };
};

interface EnableOrDisableBusinessAccountModuleArgs {
    businessAccountId: number;
    moduleCode: BusinessAccountModuleCodeEnum;
    enable: boolean;
    businessVersion: number;
}

export const enableOrDisableBusinessAccountModuleThunk = createAsyncThunk(
    ACTION_TYPES.ENABLE_OR_DISABLE_BUSINESS_ACCOUNT_MODULE,
    async (args: EnableOrDisableBusinessAccountModuleArgs) => {
        const { businessAccountId, moduleCode, enable, businessVersion } = args;
        try {
            const response = await serverApi.enableOrDisableBusinessAccountModule(businessAccountId, {
                moduleCode: moduleCode,
                enable: enable,
                businessVersion: businessVersion,
            });
            return response.data;
        } catch (err) {
            return err as AxiosError;
        }
    }
);

export const enableOrDisableBusinessAccountModule = (args: EnableOrDisableBusinessAccountModuleArgs) => (dispatch: AppDispatch) => {
    return dispatch(enableOrDisableBusinessAccountModuleThunk(args))
        .unwrap()
        .then((result) => {
            if (!(result instanceof Error)) {
                //showNotification('success', args.enable ? 'Модуль подключен' : 'Модуль отключен');
                return;
            } else {
                //showNotification('error', args.enable ? 'Не удалось подключить модуль' : 'Не удалось отключить модуль');
                throw result;
            }
        });
};

// Сброс ошибки
export const resetBusinessAccountPreferencesError = () => ({
    type: ACTION_TYPES.RESET_BUSINESS_ACCOUNT_PREFERENCES_ERROR,
});

export const subrentModuleEnabledSelector = (state: IRootState) =>
    state.businessAccountPreferences.preferences?.modules.subrentModuleEnabled;
export const statisticsModuleEnabledSelector = (state: IRootState) =>
    state.businessAccountPreferences.preferences?.modules.statisticsModuleEnabled;
export const logisticsModuleEnabledSelector = (state: IRootState) =>
    state.businessAccountPreferences.preferences?.modules.logisticsModuleEnabled;
export const crewModuleEnabledSelector = (state: IRootState) => state.businessAccountPreferences.preferences?.modules.crewModuleEnabled;
export const expensesModuleEnabledSelector = (state: IRootState) =>
    state.businessAccountPreferences.preferences?.modules.expensesModuleEnabled;
export const offersModuleEnabledSelector = (state: IRootState) => state.businessAccountPreferences.preferences?.modules.offerModuleEnabled;

export const preferencesPaymentMethodRequirementTypeSelector = (state: IRootState) =>
    state.businessAccountPreferences.preferences?.paymentMethodRequirementType;
