import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../store/rtqQueryApi';
import {
    AvailableFiltersValuesListObj,
    AvailableFiltersValuesWithCustomFieldsListObj, CustomFieldObjRead,
    InstanceStateCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord, RentStateCodeEnum,
    serverApi,
    TimetableTypeCodeEnum
} from '../../../../server';
import {getTargetStackTypeCodes, instanceToSimpleObj, NomenclaturesOnIntervalList,} from "../utils/nomenclaturesUtils";
import {
    NomenclatureRecordCustom,
    ProductsAvailableFiltersArgs
} from "../../inventory/products/api/products.api.types";
import {IRootState} from "../../../../shared/reducers";
import {businessAccountIdSelector} from "../../../../shared/reducers/system.reducer";
import {subrentModuleEnabledSelector} from "../../../../shared/reducers/businessAccountPreferences.reducer";
import {OperationFormApiUtils} from "./operationFormApiUtils";
import {AxiosError} from "axios";
import {ACTION_TYPES} from "../../inventory/products/reducers/products.reducer";
import {QueryHookData} from "../../../../components/page/entityGrid/types/api";
import {ProblemEnum} from "../../../../types";
import {ListParams, URLDrawerParams} from "../../../../components/page/entityGrid/types/params";
import {isDefined} from "../../../../shared/util/utils";
import {InstancePropertiesEntityRecordCustom} from "../reducers/operationForm.reducer";

export interface GetNomenclatureOnIntervalArgs {
    businessAccountId: number;
    from: number;
    until: number;
    id: number;
    type: NomenclatureEntityTypeCodeEnum;
    targetStackTypeCodes: Array<TimetableTypeCodeEnum>;
    possibleDelayedRentElementIds?: number[];
}

export interface ListNomenclaturesOnIntervalFilters {
    search?: string;
    problem?: string;
    categoryIds?: string;
    finalTotalPrice?: [number|undefined,number|undefined];
    nomenclatureEntityTypeCode?: 'kit' | 'product+variant';
}

export interface NomenclatureRecordExtended extends NomenclatureRecord {
    availableInstanceCount: number;
    stockInstanceCount: number;
    orderedInstanceCount: number;
}

export interface ListAllInstancesOnIntervalForProductOrVariantArgs {
    businessAccountId: number;
    from: number;
    until: number;
    possibleDelayedRentElementIds?: number[];
    isTemplate: boolean;
    productId: number;
    variantId?: number;
    supplierId?: number;
    creationOrNewElement: boolean;
}

export interface NomenclatureGridRecord extends InstancePropertiesEntityRecordCustom {
    id: number;
}

export const listAllInstancesOnIntervalForProductOrVariantFunction = async ({from, until, productId, variantId, supplierId, creationOrNewElement, isTemplate, possibleDelayedRentElementIds, ...args}:ListAllInstancesOnIntervalForProductOrVariantArgs) => {
    const targetStackTypeCodes = getTargetStackTypeCodes(isTemplate);
    let listFilters: string[] = [];
    listFilters.push(`productId;EQ;${productId}`);
    if(variantId) listFilters.push(`variantId;EQ;${variantId}`);
    if(creationOrNewElement) listFilters.push(`stateCode;IN;${InstanceStateCodeEnum.ACTIVE};${InstanceStateCodeEnum.UNDERMAINTENANCE};${InstanceStateCodeEnum.SUPPLIERACTIVE};${InstanceStateCodeEnum.DECOMMISSIONED}`);
    if(supplierId) listFilters.push(`supplierId;EQ;${supplierId}`);

    const res = await serverApi.listInstancesOnInterval(
        args.businessAccountId,
        from,
        until,
        {
            possibleDelayedRentElementIds,
            listFilters
        },
        undefined,
        0,
        undefined,
        undefined,
        undefined,
        targetStackTypeCodes
    );
    return {
        data: {
            records: res.data.records.map(record => instanceToSimpleObj(record, from, until)),
            elementsDelayedReturnDates: res.data.elementsDelayedReturnDates
        }
    };
};

export type NomenclaturesListData = QueryHookData<NomenclatureRecordCustom>['data'];

export interface ListNomenclaturesFunctionArgs {
    params: NomenclaturesListQueryParams;
    customFiltersRequestFilters: string[];
    availableFiltersValues?: AvailableFiltersValuesListObj;
    customFieldMarkers?: CustomFieldObjRead[];
}

export interface NomenclaturesListFilters {
    search?: string;
    //status?: NomenclatureStateCodeEnum;
    //hideArchive?: boolean;
    //hideVariants?: boolean;
    problem?: ProblemEnum;
    categoryIds?: number | number[];
    pricePerShift?: [number | undefined, number | undefined];
    nomenclatureEntityTypeCode?: NomenclatureEntityTypeCodeEnum;
    //instanceId?: number | number[];

    //from: number;
    //until: number;
}

export interface NomenclaturesListQueryParams extends ListParams, URLDrawerParams, NomenclaturesListFilters {
    from: number;
    until: number;
}

export const operationFormApi = createApi({
    reducerPath: 'operationFormApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Nomenclatures', 'Nomenclature', 'ProductsList', 'ProductsAvailableFiltersList'],
    endpoints: (builder) => ({
        listAllInstancesOnIntervalForProductOrVariant: builder.query<NomenclaturesOnIntervalList, ListAllInstancesOnIntervalForProductOrVariantArgs>({
            queryFn: listAllInstancesOnIntervalForProductOrVariantFunction,
            providesTags: [],
        }),

        listNomenclaturesOnInterval: builder.query<NomenclaturesListData, ListNomenclaturesFunctionArgs>({
            // transformResponse: ((response) => {
            //         return response.data;
            // }) as any,
            queryFn: async (
                { params, availableFiltersValues, customFieldMarkers, customFiltersRequestFilters },
                { getState, dispatch }
            ) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;
                const entitiesRequestFilters = OperationFormApiUtils.createEntityRequestFilters(params, subrentModuleEnabled);
                const filters = [...entitiesRequestFilters, ...customFiltersRequestFilters];

                let possibleDelayedRentElementIds = state.operationForm.elements.entities
                    .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
                    .map((item) => item.id);

                try {
                    const { data: nomenclatureData } = await serverApi.listNomenclatureOnInterval(
                        businessAccountId,
                        params.from,
                        params.until,
                        {
                            listFilters: filters.length > 0 ? filters : undefined,
                            possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                        },
                        params.limit,
                        (params.page - 1) * params.limit,
                        sortBy,
                        params.sortOrder,
                        params.search,
                        state.operationForm.projectTemplate
                            ? [TimetableTypeCodeEnum.STOCK]
                            : [
                                TimetableTypeCodeEnum.ORDERAVAILABLE,
                                TimetableTypeCodeEnum.AVAILABLE,
                                TimetableTypeCodeEnum.STOCK,
                                TimetableTypeCodeEnum.ORDER,
                            ]
                    );

                    const records = nomenclatureData.records.map(
                        (record): NomenclatureGridRecord => ({
                            ...record,
                            id: Number(record.uuid!.split('_')[1]),
                            availableInstanceCount: 0,
                            stockInstanceCount: 0,
                            orderedInstanceCount: 0
                        })
                    );

                    return {
                        data: {
                            entitiesData: {
                                listAttributes: nomenclatureData.listAttributes,
                                records: records,
                            },
                            availableIntervalFiltersData: availableFiltersValues
                                ? {
                                    values: availableFiltersValues,
                                    maps: {
                                        metrics: OperationFormApiUtils.productsIntervalMapFunction,
                                        formats: OperationFormApiUtils.productsIntervalFormatStyleMap,
                                    },
                                }
                                : undefined,
                            customFieldMarkers: customFieldMarkers,
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                } finally {
                    dispatch({
                        type: ACTION_TYPES.CHANGE_LOADING,
                        payload: false,
                    });
                }
            },
            providesTags: ['ProductsList'],
        }),
        listNomenclaturesAvailableFiltersValues: builder.query<AvailableFiltersValuesWithCustomFieldsListObj, ProductsAvailableFiltersArgs>({
            queryFn: async ({ search }, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const urlParams = new URLSearchParams(search);

                const availableFiltersRequestFilters = OperationFormApiUtils.createAvailableFiltersRequestFilters(urlParams);

                try {
                    const { data } = await serverApi.listNomenclatureAvailableFiltersValues(
                        businessAccountId,
                        availableFiltersRequestFilters
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['ProductsAvailableFiltersList'],
        }),

    }),
});

/*
export const loadProducts = (showError: boolean = true) => {
    return (dispatch, getState: () => IRootState) => {
        let rootState: IRootState = getState();
        let state = rootState.operationForm;
        let params = state.products.params;

        let filters: string[] = [];
        if (params.problem) filters.push(getStringServerProblem(params.problem));
        if (params.categoryIds !== undefined && params.categoryIds !== '') filters.push('categoryIds;IN;' + params.categoryIds);

        let from = moment(params.startDate || Date.now()).startOf('month').subtract(1, 'month').valueOf();
        let until = moment(params.endDate || Date.now()).endOf('month').add(1, 'month').valueOf();

        if (params.finalTotalPrice && initialProductsParamsState.finalTotalPrice) {
            if (params.finalTotalPrice[0] !== undefined) filters.push('pricePerShift;GE;' + params.finalTotalPrice[0] * 100);
            if (params.finalTotalPrice[1] !== undefined) filters.push('pricePerShift;LE;' + params.finalTotalPrice[1] * 100);
        }

        if (params.nomenclatureEntityTypeCode) {
            if (params.nomenclatureEntityTypeCode === 'kit')
                filters.push('nomenclatureEntityTypeCode;IN;' + NomenclatureEntityTypeCodeEnum.KIT);
            else if (params.nomenclatureEntityTypeCode === 'product+variant')
                filters.push(
                    'nomenclatureEntityTypeCode;IN;' + NomenclatureEntityTypeCodeEnum.PRODUCT + ';' + NomenclatureEntityTypeCodeEnum.VARIANT
                );
        }

        filters.push('visible;EQ;true');

        let possibleDelayedRentElementIds = rootState.operationForm.elements.entities
            .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
            .map((item) => item.id);

        return dispatch({
            type: ACTION_TYPES.LOAD_PRODUCTS,
            payload: serverApi.listNomenclatureOnInterval(
                rootState.system.businessAccountId,
                from,
                until,
                {
                    possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                    listFilters: filters
                },
                params.limit,
                params.page === undefined || params.limit === undefined ? 0 : (params.page - 1) * params.limit,
                params.sortBy,
                params.sortOrder,
                params.search && params.search.length > 0 ? params.search : undefined,
                rootState.operationForm.projectTemplate
                    ? [TimetableTypeCodeEnum.STOCK]
                    : [
                        TimetableTypeCodeEnum.ORDERAVAILABLE,
                        TimetableTypeCodeEnum.AVAILABLE,
                        TimetableTypeCodeEnum.STOCK,
                        TimetableTypeCodeEnum.ORDER,
                    ]
            ),
        } as LoadProductsAction).then((result) => {
            if (result instanceof Error) {
                if (showError) showNotification('error', 'Не удалось загрузить список продуктов');
            } else {
                gridDataChangedSignal();
            }
        });
    };
};
*/

export const { useListNomenclaturesOnIntervalQuery, useListNomenclaturesAvailableFiltersValuesQuery } = operationFormApi;
