import { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import moment from 'moment';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { isGiveawayDelayProblemForItemElement } from '../../../../../../../../../../tabs/elements/utils/checks/isGiveawayDelayProblemForRentElement';

interface SubrentShipmentDelayBorderProps {
    width: number;
}

export const SubrentShipmentDelayBorder: FC<SubrentShipmentDelayBorderProps> = (props) => {
    const { width } = props;
    const { item } = useContext(ElementItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const { stateCode, end_time } = item;

    const startsAfterLeftBoundary = +item.start_time > visibleTimeStart;
    const endsBeforeRightBoundary = +item.end_time < visibleTimeEnd;

    const baseBorder = `2px solid ${CalendarColors.staticColors.DELAY_COLOR}`;
    const baseBorderRadius = CALENDAR_LINE_ROUND_RADIUS;
    const tailBorder = `2px solid ${CalendarColors.elementsStateCodeMap[stateCode]}`;
    const tailBorderRadius = CALENDAR_LINE_ROUND_RADIUS;

    const isGiveawayDelayProblem = isGiveawayDelayProblemForItemElement(item);

    return (
        <>
            <div
                className={'calendar-line-border'}
                style={{
                    border: baseBorder,
                    borderRadius: baseBorderRadius,
                    borderLeft: startsAfterLeftBoundary ? baseBorder : 'none',
                    borderTopLeftRadius: startsAfterLeftBoundary ? baseBorderRadius : 0,
                    borderBottomLeftRadius: startsAfterLeftBoundary ? baseBorderRadius : 0,
                    borderRight: endsBeforeRightBoundary ? baseBorder : 'none',
                    borderTopRightRadius: endsBeforeRightBoundary && !isGiveawayDelayProblem ? baseBorderRadius : 0,
                    borderBottomRightRadius: endsBeforeRightBoundary && !isGiveawayDelayProblem ? baseBorderRadius : 0,
                    width,
                }}
            />
            {+end_time > +moment() && (
                <div
                    className={'calendar-line-border'}
                    style={{
                        border: tailBorder,
                        borderRadius: tailBorderRadius,
                        borderLeft: startsAfterLeftBoundary ? tailBorder : 'none',
                        borderTopLeftRadius: startsAfterLeftBoundary ? tailBorderRadius : 0,
                        borderBottomLeftRadius: startsAfterLeftBoundary ? tailBorderRadius : 0,
                        borderRight: endsBeforeRightBoundary ? tailBorder : 'none',
                        borderTopRightRadius: endsBeforeRightBoundary ? tailBorderRadius : 0,
                        borderBottomRightRadius: endsBeforeRightBoundary ? tailBorderRadius : 0,
                        width,
                    }}
                />
            )}
        </>
    );
};
