import { KitInfoUpdate, KitMemberObjWrite } from '../../../../../../../../server';
import { updateKitComposition } from '../../../../reducers/kit.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { useCallback } from 'react';
import { ProductCompositionColumnsObj } from '../../columns/productCompositionColumns.types';

export const useSaveGrindHandler = (editData: ProductCompositionColumnsObj[], setIsEdit: (value: boolean) => void) => {
    const dispatch = useAppDispatch();
    const kitEntity = useAppSelector((store) => store.kit.entity);
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return useCallback(() => {
        if (kitEntity == null) return;

        const newMembersData: KitMemberObjWrite[] = editData.map((item) => ({
            productId: item.productId,
            main: Boolean(item.main),
            discount: item.discount,
            instanceCount: item.instanceCount,
            linksProductVariant: item.linksProductVariant,
            requiredInKit: item.requiredInKit,
            variantId: item.variantId,
        }));

        const kitInfoUpdate: KitInfoUpdate = {
            ...kitEntity,
            members: newMembersData,
            mainMemberOptionEnabled: editData.some((item) => Boolean(item.main)),
            categoryIds: kitEntity.categories?.map((category) => category.id),
        };

        delete kitInfoUpdate['categories'];

        dispatch(
            updateKitComposition({
                businessAccountId,
                id: kitEntity.id,
                kitInfoUpdate,
                successCallback: () => setIsEdit(false),
            })
        );
    }, [businessAccountId, dispatch, editData, kitEntity, setIsEdit]);
};
