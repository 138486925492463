import React from "react";
import {Avatar as OriginalAvatar} from "antd";
import {AvatarProps} from "antd/lib/avatar";

interface IProps extends AvatarProps {
}

export const Avatar = (props:IProps) => {
    // Если есть props.src, то фон не выводить
    let style = props.style ? {...props.style} : {};
    if(props.src){
        style.backgroundColor = 'unset';
    }
    return (
        <OriginalAvatar {...props} style={style}>
            {props.children}
        </OriginalAvatar>
    );
};
