import React from 'react';
import {Link} from 'react-router-dom';
import {Icon} from 'antd';
import {ProductCompositionColumnsObj} from './productCompositionColumns.types';
import {LocalizationEnum, localize} from '../../../../../../../localization';
import {ColumnTypes, TableColumn} from '../../../../../../../components/grid/Table';
import {IconStarSolid} from '../../../../../../../components/icons';
import {GRID_IMAGE_COLUMN_WIDTH} from '../../../../../../../config/constants';
import {getBusinessAccountId} from '../../../../../../../../index';
import {KitMemberObjRead} from '../../../../../../../server';
import {ProductVariantPopover} from '../../../../variants/components/productVariantPopover/productVariantPopover';

export const productCompositionColumns: TableColumn<ProductCompositionColumnsObj>[] = [
    {
        title: localize(LocalizationEnum.PAGE__KITS__ORDER),
        dataIndex: 'memberOrder',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        sortable: false,
        render: (value: Number, rowData: any) => {
            return (
                <div title={rowData.shortName}>
                    {rowData.main ? (
                        <Icon style={{ marginBottom: '1px', marginRight: '4px', fontSize: '18px' }} component={IconStarSolid} />
                    ) : null}
                    #{+value + 1}
                </div>
            );
        },
        dontHide: true,
    },

    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
        dataIndex: 'image',
        type: ColumnTypes.Image,
        width: GRID_IMAGE_COLUMN_WIDTH,
        maxWidth: GRID_IMAGE_COLUMN_WIDTH,
        sortable: false,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        sortable: false,
        render: (value: String) => {
            return (
                <div className={'rr-grid-shortName-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
                    {value}
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PRODUCT),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        width: 250,
        resizable: true,
        sortable: false,
        render: (value: String, rowData: any) => {
            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.shortName}>
                    <Link to={`/${getBusinessAccountId()}/inventory/products/${rowData.productId}`} style={{ color: 'unset' }}>
                        {value}
                    </Link>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        width: 200,
        resizable: true,
        sortable: false,
        render: (value: String, rowData: KitMemberObjRead) => {
            return rowData.variantId ? (
                <div className={'rr-grid-shortName-cell'} onClick={(e) => e.stopPropagation()}>
                    <ProductVariantPopover productId={rowData.productId} variantId={rowData.variantId}>
                        <span className="popover-header-name grid-column-variant">{rowData.variantName}</span>
                    </ProductVariantPopover>
                </div>
            ) : null;
        },
    },

    {
        title: localize(LocalizationEnum.PAGE__KITS__PRODUCT_STATE),
        dataIndex: 'productStateCode',
        type: ColumnTypes.Status,
        minWidth: 130,
        resizable: false,
        sortable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 150,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'discount',
        type: ColumnTypes.Percents,
        minWidth: 100,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__PRICE_WHEN_IN_KIT),
        dataIndex: 'finalInstancePrice',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 150,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__TOTAL_PRICE_WHEN_IN_KIT),
        dataIndex: 'finalTotalPrice',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 170,
        resizable: false,
        sortable: false,
    },
];
