import { ItemContext } from 'react-calendar-timeline';
import moment, { type Moment } from 'moment';
import {
    CALENDAR_GROUP_HEIGHT_BASE_COMPACT,
    CALENDAR_GROUP_HEIGHT_BASE_NORMAL,
    CALENDAR_GROUP_HEIGHT_LARGE_COMPACT,
    CALENDAR_GROUP_HEIGHT_LARGE_NORMAL,
    CALENDAR_LINE_FONT_SIZE_COMPACT,
    CALENDAR_LINE_FONT_SIZE_NORMAL,
    CALENDAR_LINE_HEIGHT_COMPACT,
    CALENDAR_LINE_HEIGHT_NORMAL,
    CalendarDisplayType,
} from '../../Calendar/utils/data/calendarConstants';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { CalendarItem, ElementsCalendarItem } from '../../Calendar/types/items';

export abstract class CalendarLineUtils {
    static measures = {
        lineBoundary: (
            item: CalendarItem,
            visibleTimeStart: number
        ): {
            startDate: Moment;
            endDate: Moment;
        } => {
            const { start_time, end_time } = item;

            if (+start_time < visibleTimeStart) {
                return {
                    startDate: moment(visibleTimeStart),
                    endDate: end_time,
                };
            }

            return {
                startDate: start_time,
                endDate: end_time,
            };
        },
        placement: (
            item: CalendarItem<ElementsCalendarItem>,
            itemContext: ItemContext,
            visibleTimeStart: number,
            visibleTimeEnd: number
        ): TooltipPlacement | undefined => {
            const { kitId } = item;

            if (kitId) return undefined;

            if (
                (visibleTimeStart >= itemContext.dimensions.collisionLeft &&
                    visibleTimeEnd <= itemContext.dimensions.collisionLeft + itemContext.dimensions.collisionWidth) ||
                (visibleTimeStart <= itemContext.dimensions.collisionLeft &&
                    visibleTimeEnd >= itemContext.dimensions.collisionLeft + itemContext.dimensions.collisionWidth) ||
                ((visibleTimeEnd - visibleTimeStart) * 0.2 >
                    visibleTimeEnd - (itemContext.dimensions.collisionLeft + itemContext.dimensions.collisionWidth) &&
                    visibleTimeStart >= itemContext.dimensions.collisionLeft)
            ) {
                return 'bottom';
            } else if (visibleTimeStart > itemContext.dimensions.collisionLeft) {
                return 'right';
            } else if (visibleTimeEnd < itemContext.dimensions.collisionLeft + itemContext.dimensions.collisionWidth) {
                return 'left';
            }
        },
    };
    static difference = {
        fromStartToNow: (item: CalendarItem, visibleTimeStart: number): number => {
            const { startDate } = this.measures.lineBoundary(item, visibleTimeStart);
            return +moment() - +startDate;
        },
        fromEndToNow: (item: CalendarItem, visibleTimeStart: number, visibleTimeEnd: number): number => {
            const { endDate } = this.measures.lineBoundary(item, visibleTimeStart);

            const now = moment();
            const rightBoundary = +now > visibleTimeEnd ? visibleTimeEnd : +now;

            return rightBoundary - +endDate;
        },
        lineBoundaryDifference: (item: CalendarItem, visibleTimeStart: number): number => {
            const { startDate, endDate } = this.measures.lineBoundary(item, visibleTimeStart);
            return +endDate - +startDate;
        },
    };
    static ratio = {
        sublineWidth: (item: CalendarItem, itemContext: ItemContext, visibleTimeStart: number, sublineDifference: number): number => {
            const lineWidth = itemContext.dimensions.width;
            const lineDifference = this.difference.lineBoundaryDifference(item, visibleTimeStart);
            return (sublineDifference / lineDifference) * lineWidth;
        },
    };
    static map = {
        lineHeightFromDisplayType: {
            [CalendarDisplayType.NORMAL]: CALENDAR_LINE_HEIGHT_NORMAL,
            [CalendarDisplayType.COMPACT]: CALENDAR_LINE_HEIGHT_COMPACT,
        } satisfies Record<CalendarDisplayType, number>,
        fontSizeFromDisplayType: {
            [CalendarDisplayType.NORMAL]: CALENDAR_LINE_FONT_SIZE_NORMAL,
            [CalendarDisplayType.COMPACT]: CALENDAR_LINE_FONT_SIZE_COMPACT,
        } satisfies Record<CalendarDisplayType, number>,
        groupHeightFromDisplayType: {
            base: {
                [CalendarDisplayType.NORMAL]: CALENDAR_GROUP_HEIGHT_BASE_NORMAL,
                [CalendarDisplayType.COMPACT]: CALENDAR_GROUP_HEIGHT_BASE_COMPACT,
            } satisfies Record<CalendarDisplayType, number>,
            large: {
                [CalendarDisplayType.NORMAL]: CALENDAR_GROUP_HEIGHT_LARGE_NORMAL,
                [CalendarDisplayType.COMPACT]: CALENDAR_GROUP_HEIGHT_LARGE_COMPACT,
            } satisfies Record<CalendarDisplayType, number>,
        },
    };
}
