import { DeepKeys } from '@tanstack/react-table';
import { NomenclatureRecordCustom } from '../../../../../inventory/products/reducers/products.reducer';
import { useAppSelector } from '../../../../../../../store/hooks';
import { ProductsListFilters } from '../../../../../inventory/products/api/products.api.types';
import { useMemo } from 'react';
import {canViewFinancialDataSelector} from "../../../../../../../shared/reducers/permissions.reducer";
import {InstancePropertiesEntityRecordCustom} from "../../../../reducers/operationForm.reducer";

export const useExcludedValues = (pageParams: ProductsListFilters) => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const projectTemplate = useAppSelector((store) => store.operationForm.projectTemplate);

    return useMemo(() => {
        const excludeColumns: DeepKeys<InstancePropertiesEntityRecordCustom>[] = projectTemplate
            ? ['orderedInstanceCount', 'availableInstanceCount']
            : ['availableInstanceCountTemplate'];
        const filtersExcludeFields: DeepKeys<InstancePropertiesEntityRecordCustom>[] = [];

        if (!canViewFinancialData) {
            excludeColumns.push('pricePerShift');
            filtersExcludeFields.push('pricePerShift'); // TODO !!! finalTotalPrice переименовываем в pricePerShift
        }

        return {
            excludeColumns,
            filtersExcludeFields,
        };
    }, [pageParams.hideVariants, canViewFinancialData, projectTemplate]);
};
