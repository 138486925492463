import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from '../../../../components';
import { Grid } from '../../../../components/grid/Grid';
import { Card } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { showNotification } from '../../../../components/notification/showNotification';
import {
    ListInventoryMovementsFilters,
    ListInventoryMovementsParams,
    useListInventoryMovementsAvailableFiltersValuesQuery,
    useListInventoryMovementsQuery,
} from '../api/inventoryMovementsApi';
import { inventoryMovementsListColumns } from './inventoryMovementsListColumns';
import { inventoryMovementsListFilters } from './inventoryMovementsListFilters';
import { IFormField } from '../../../../components/dynamicForm/DynamicForm';
import {
    convertAvailableFiltersToObj,
    getFilteredParams,
    getPathFromState,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../../shared/util/utils';
import debounce from 'lodash/debounce';
import {
    ExcelExportTemplateTypeCodeEnum,
    InstanceTrackingTypeCodeEnum,
    InventoryMovementEventTypeCodeEnum, InventoryMovementFinancialFieldsTypeCode, InventoryMovementNotFinancialFieldsTypeCode,
    ProductInfoRead
} from '../../../../server';
import { setGridStorageDataFilters } from '../../../../components/grid/utils';
import { replace } from 'connected-react-router';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import { InventoryMovementUpdateEvent, modalAddEventListener, modalRemoveEventListener, modalShow } from '../../../../core/modals/modals';
import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { GridName } from '../../../../components/grid/utils/types';
import { usePageURLParams } from '../../../../core/hooks/urlParams/usePageURLParams';
import {ExportOffloadingPopover} from "../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {useIntl} from "react-intl";
import {canViewFinancialDataSelector} from "../../../../shared/reducers/permissions.reducer";
import {InventoryMovementsApiUtils} from "../api/inventoryMovementsApi.utils";

const pageFiltersDescription: PageURLParamDescriptionObject<ListInventoryMovementsFilters> = {
    eventType: PageURLParamDescriptions.arrayOfEnumParam(InventoryMovementEventTypeCodeEnum),
    eventDate: PageURLParamDescriptions.dateTimeIntervals,
    productId: PageURLParamDescriptions.numbersSelectParam,
    variantId: PageURLParamDescriptions.numbersSelectParam,
    instanceId: PageURLParamDescriptions.numbersSelectParam,
    totalSum: PageURLParamDescriptions.numberInterval,
    counterpartyId: PageURLParamDescriptions.numbersSelectParam,
    projectId: PageURLParamDescriptions.numbersSelectParam,
    outOfOrder: PageURLParamDescriptions.booleanParam,
    causedByRenter: PageURLParamDescriptions.booleanParam,
    authorId: PageURLParamDescriptions.numberParam,
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
};

const pageParamsDescription: PageURLParamDescriptionObject<ListInventoryMovementsParams> = {
    ...PageURLParamDescriptions.basePageParams,
    ...pageFiltersDescription,
};

interface InventoryMovementsListProps {
    product?: ProductInfoRead;
    gridName: GridName;
}

export const InventoryMovementsList: FC<InventoryMovementsListProps> = ({ product, gridName }) => {

    const gridRef = useRef(null);
    const dispatch = useAppDispatch();
    const match = useRouteMatch<{ businessAccountId: string; id?: string }>();
    const location = useLocation();
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = +match.params.businessAccountId;
    const [totalSumMinMax, setTotalSumMinMax] = useState<number[] | undefined>();
    const isSkipLocationChange = product
        ? !location.search.includes('inventoryMovements')
        : !location.pathname.includes('inventoryMovements');
    const _initialValues: Partial<ListInventoryMovementsParams> = useMemo(() => {
        return {
            sortBy: 'eventDate',
            sortOrder: 'DESC',
        };
    }, []);
    const { pageParams, setPageParams } = usePageURLParams<ListInventoryMovementsParams>({
        paramsDescription: pageParamsDescription,
        pageName: gridName,
        initialValues: _initialValues,
        skip: isSkipLocationChange,
    });

    const { data: availableFiltersData } = useListInventoryMovementsAvailableFiltersValuesQuery(
        { businessAccountId },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const { data, isError, error, isLoading, isFetching, refetch } = useListInventoryMovementsQuery(
        {
            businessAccountId,
            params: { ...pageParams, productId: product?.id || pageParams.productId },
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const [filterData] = useState({ ...inventoryMovementsListFilters });

    useEffect(() => {
        const listener = () => {
            refetch();
        };
        modalAddEventListener(InventoryMovementUpdateEvent, listener);
        // слушаем событие из дровера, что б обновить список
        return () => {
            // удаляем слушатель
            modalRemoveEventListener(InventoryMovementUpdateEvent, listener);
        };
    }, [refetch]);

    useEffect(() => {
        if (availableFiltersData) {
            const availableFilters = convertAvailableFiltersToObj(availableFiltersData.filters);
            let filter = availableFilters['totalSum'];
            if (filter) {
                const newFilterData = { ...inventoryMovementsListFilters };
                let field = (newFilterData[1].fields as IFormField[]).find((item) => item.id === 'totalSum');
                if (field) {
                    const min = roundMinFilterValue(filter[0] / 100);
                    const max = roundMaxFilterValue(filter[1] / 100);
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min,
                        max,
                    };
                    if (!pageParams.totalSum) {
                        const form = getFiltersForm();
                        setTimeout(() => {
                            form?.setFieldsValue({ totalSum: [min, max] });
                            setTotalSumMinMax([min, max]);
                        }, 50);
                    }
                }
            }
        }
    }, [availableFiltersData, pageParams.totalSum]);

    // Обработка ошибки
    useEffect(() => {
        if (isError && error) {
            if (error.message === 'Network Error') {
                // Ошибка сети
            } else {
                // Другая ошибка
            }
            showNotification('error', 'Ошибка');
        }
    }, [isError, error]);

    // Сортировка изменилась
    const onSortedChange = useCallback(
        (id: string, desc: boolean) => {
            setPageParams({ sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' });
        },
        [setPageParams]
    );

    // Страница изменилась
    const onPageChanged = useCallback(
        (page: number) => {
            setPageParams({ page });
        },
        [setPageParams]
    );

    // Кол-во на странице изменилось
    const onPageSizeChanged = useCallback(
        (size: number) => {
            setPageParams({ limit: size, page: 1 });
        },
        [setPageParams]
    );

    // Обновить фильтры
    const updateFilters = debounce((data: any) => {
        if (data.totalSum && totalSumMinMax && data.totalSum[0] === totalSumMinMax[0] && data.totalSum[1] === totalSumMinMax[1])
            data.totalSum = undefined;

        data.page = 1;

        setPageParams({ ...data });
    }, 200);

    // Фильтры изменились
    const onFiltersChange = useCallback(
        (data: any) => {
            updateFilters(data);
        },
        [updateFilters]
    );

    // Фильтры сбросили
    const resetFilters = useCallback(() => {
        const filters = {};
        for (const p in pageFiltersDescription) {
            filters[p] = undefined;
        }
        setPageParams(filters);
        const form = getFiltersForm();
        form?.setFieldsValue(filters);
    }, [setPageParams]);

    const filtersRef = useRef<any>();
    const getFiltersForm = () => {
        return filtersRef?.current?.props?.form as WrappedFormUtils | null | undefined;
    };

    const initialValues = {
        ...pageParams,
        parentEntityType: product?.id ? 'product' : 'history',
        parentProductId: product?.id,
        productId: product?.id || pageParams.productId,
        product: product,
    };

    useEffect(() => {
        const form = getFiltersForm();
        form?.setFieldsValue(pageParams);
    }, [pageParams]);

    let excludeColumns: string[] | undefined;
    let excludeFilters: string[] | undefined;
    if (product) {
        excludeColumns = ['productShortName'];
        if (
            product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED &&
            product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.VARIANTBULK
        ) {
            excludeColumns.push('variantName');
        }

        // if (
        //     product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED &&
        //     product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.INSTANCETRACKED
        // ) {
        //     excludeColumns.push('instanceId');
        // }

        if (
            product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED &&
            product.instanceTrackingTypeCode !== InstanceTrackingTypeCodeEnum.INSTANCETRACKED
        ) {
            excludeColumns.push('instances');
        }

        excludeFilters = [...excludeColumns];
    } else {
        excludeFilters = ['eventDate'];
    }

    const fetchEntityErrorCallback = useCallback(
        (id: number) => {
            const { filteredParams, deletedCount } = getFilteredParams(pageParams, id);

            if (deletedCount > 0) {
                setGridStorageDataFilters(gridName, filteredParams);
                dispatch(replace(getPathFromState(location.pathname, '', filteredParams)));
                getFiltersForm()?.setFieldsValue(filteredParams);
            }
        },
        [dispatch, gridName, location.pathname, pageParams]
    );

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder } = pageParams;
            const filters = InventoryMovementsApiUtils.createRequestFilters(pageParams);
            const sortBy = InventoryMovementsApiUtils.getSortBy(pageParams.sortBy);

            if(product){
                filters.push(`productId;EQ;${product.id}`);
            }

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.INVENTORYMOVEMENTTEMPLATE,
                fileName: 'Экспорт движения инвентаря',
                fileType,
                filters,
                financialFieldsEnum: InventoryMovementFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: InventoryMovementNotFinancialFieldsTypeCode,
                selection: [],
                values: options,
                sortOrder,
                sortBy,
                excludeColumns: excludeColumns
            });
        },
        [businessAccountId, canViewFinancialData, gridRef, intl, pageParams, product]
    );

    return (
        <Card>
            <Spin spinning={isLoading || isFetching}>
                <Grid
                    columns={inventoryMovementsListColumns}
                    currentPage={pageParams.page}
                    data={data?.records}
                    defaultSortDesc={pageParams.sortOrder === 'DESC'}
                    defaultSorted={pageParams.sortBy}
                    excludeColumns={excludeColumns}
                    filtered={data?.listAttributes.filteredCount}
                    filtersCurrentValues={{ ...pageParams }}
                    filtersData={filterData}
                    filtersDefaultValues={{}}
                    filtersExcludeFields={excludeFilters}
                    filtersGetFiltersFormRef={filtersRef as any}
                    filtersInitialValues={initialValues}
                    filtersOnChange={onFiltersChange}
                    filtersResetFiltersCb={resetFilters}
                    gridName={gridName}
                    ref={gridRef}
                    indexFieldName={'id'}
                    onPageChanged={onPageChanged}
                    onPageSizeChanged={onPageSizeChanged}
                    onRowClick={(entity) => {
                        //setSelectedId(entity.id);
                        modalShow(`inventoryMovement:${entity.id}`);
                    }}
                    onSortedChange={onSortedChange}
                    pageSize={pageParams.limit}
                    selectable={false}
                    fetchEntityErrorCallback={fetchEntityErrorCallback}
                    exportBlock={<ExportOffloadingPopover storageKey={'inventoryMovementsOffloading'} onDownload={onOffloadingDownload} />}
                />
            </Spin>
        </Card>
    );
};
