import React, {FC} from 'react';
import {Moment} from "moment";
import {Button} from "antd";
import {RecalculateBlock} from "../recalculateBlock/recalculateBlock";
import {LocalizationEnum, localize} from "../../../../../../localization";
import {TimePicker} from "../../../../../dateTimePicker/timePicker";
import {RecalculateShiftCountBlockProps, RequiredTimeIndentProps} from "../../rangePicker.types";
import './calendar.less';

export interface CalendarFooterProps {
    value?: Moment;
    onChange?: (value?: Moment) => void;
    onOk?: () => void;
    showTime?: boolean;
    recalculateShiftCountBlockProps?: RecalculateShiftCountBlockProps;
    requiredTimeIndentProps?: RequiredTimeIndentProps;
}

export const CalendarFooter:FC<CalendarFooterProps> = (props) => {

    return (
        <>
            <TimePicker
                onChange={(date)=>{
                    if(date) props.onChange?.(date);
                }}
                value={props.value}
                style={{width: 114, marginRight: 20}}
            />
            {
                props.recalculateShiftCountBlockProps && (
                    <RecalculateBlock
                        value={props.recalculateShiftCountBlockProps?.recalculateShiftCount}
                        onChange={(checked)=>{
                            props.recalculateShiftCountBlockProps?.recalculateShiftCountOnChange?.(checked);
                        }}
                        recalculateShiftCountBlockProps={props.recalculateShiftCountBlockProps}
                    />
                )
            }
            <div style={{flexGrow: 1, display: "flex", flexDirection: 'column', alignItems: 'end'}}>
                <Button type="primary" onClick={() => {props.onOk?.();}}>
                    {localize(LocalizationEnum.ASPECT__GLOBAL__OK)}
                </Button>
            </div>
        </>
    );
};