import {getIsCorrectCancelOperation, getIsElementReducesAvailability} from "./operationUtils";
import {findTimeTable, getLeftoverInstances, isOrderOperation, isSubrentOperation} from "./utils";
import {
    InstanceRecord,
    InstanceTrackingTypeCodeEnum,
    NomenclatureRecord,
    RentStateCodeEnum,
    TimetableTypeCodeEnum
} from "../../../../server";
import {ProductUtils} from "../../../../shared/util/productUtils";
import {OperationElement, OperationFormState} from "../reducers/operationForm.reducer";
import {instanceTimetableToNormalState} from "./instanceTimetablesUtils";

export const xxx20 = (element:OperationElement, intervals:[number, number][], state:OperationFormState) => {
    let isSubrent = state.isSubrent;//isSubrentOperation(state.typeCode, state.targetStateCode);
    let availabilityFactor = isSubrent ? -1 : 1;

    let availableIntervals = intervals;
    //elements.forEach((element) => {
        // Уменьшает ли доступность
        let isElementReducesAvailability = getIsElementReducesAvailability(element);
        let isCorrectCancelOperation = getIsCorrectCancelOperation(state.typeCode, state.targetStateCode);

        // let timeTable = findTimeTable(state.timeTables, {
        //     productId: element.productId,
        //     kitId: element.kitId,
        //     variantId: element.variantId,
        // });

        //if (timeTable) {
            //let availableIntervals = intervals;//isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1;

            let elementIntervals: [number, number, number, string][] = [];
            //
            if (element.id < 0) {
                // Это новый (продукт, набор), просто вычитаем по нему
                elementIntervals.push([
                    availabilityFactor * element.instanceCount,
                    element.rentPeriodStartDate.getTime(),
                    element.rentPeriodEndDate.getTime(),
                    'Это новый (продукт, набор), просто вычитаем по нему',
                ]);
            } else {
                // Это существующий элемент
                if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
                    // Компенсируем
                    if (isElementReducesAvailability && element.variantId === element.variantIdOriginal) {
                        let endDate = element.rentPeriodEndDateOriginal.getTime();
                        if (element.stateCode === RentStateCodeEnum.RENT && state.elementsDelayedReturnDates[element.id]) {
                            endDate = state.elementsDelayedReturnDates[element.id].getTime();
                        }
                        elementIntervals.push([
                            availabilityFactor * -element.instanceCountOriginal,
                            element.rentPeriodStartDateOriginal.getTime(),
                            endDate,
                            'Компенсируем',
                        ]);
                    }

                    // Т.к. теперь отдельная доступность по заявке нужно компенсировать обязательства в статусе заявки над которыми проводим операцию заявки (они в этой карте ORDER_AVAILABLE занимают доступность)
                    if (
                        element.stateCode === RentStateCodeEnum.ORDERED &&
                        isOrderOperation(state.targetStateCode || state.typeCode) &&
                        element.variantId === element.variantIdOriginal
                    ) {
                        elementIntervals.push([
                            availabilityFactor * -element.instanceCountOriginal,
                            element.rentPeriodStartDateOriginal.getTime(),
                            element.rentPeriodEndDateOriginal.getTime(),
                            'Т.к. теперь отдельная доступность по заявке нужно компенсировать обязательства в статусе заявки над которыми проводим операцию заявки (они в этой карте ORDER_AVAILABLE занимают доступность)',
                        ]);
                    }


                    //////////
                    // Соответственно из внешнего контекста оно полностью компенсируется, и не вычитается т.к. полностью отменено.
                    if (element.isCancelled !== true) {
                        // Теперь вычитаем текущее значение!!!
                        elementIntervals.push([
                            availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * element.instanceCount,
                            element.rentPeriodStartDate.getTime(),
                            element.rentPeriodEndDate.getTime(),
                            'Теперь вычитаем текущее значение!!!',
                        ]);
                        //
                        // Теперь вычитаем остаток!!!
                        if (
                            element.keepLeftover &&
                            isElementReducesAvailability /*|| element.stateCode === RentStateCodeEnum.ORDERED*/ &&
                            element.variantId === element.variantIdOriginal
                        ) {
                            let leftoverInstances = getLeftoverInstances(element.instanceIdsOriginal, element.instanceIds);
                            elementIntervals.push([
                                availabilityFactor * Math.max(0, element.instanceCountOriginal - element.instanceCount, leftoverInstances.length),
                                element.rentPeriodStartDateOriginal.getTime(),
                                element.rentPeriodEndDateOriginal.getTime(),
                                'Теперь вычитаем остаток!!!',
                            ]);
                        }
                    }
                }
            }

            let elementIntervalsNew: [number, number, number][] = [];
            elementIntervalsNew = elementIntervals.map((item) => {
                return [item[0], item[1], item[2]];
            });

            availableIntervals = ProductUtils.getNewIntervals(availableIntervals, elementIntervalsNew);

            // elementIntervals.forEach((item) => {
            //     logStackMap({
            //         productId: element.productId,
            //         kitId: element.kitId,
            //         variantId: element.variantId,
            //         intervals: [[item[0], item[1], item[2]]],
            //         comment: item[3],
            //     });
            // });

            // logStackMap({
            //     productId: element.productId,
            //     kitId: element.kitId,
            //     variantId: element.variantId,
            //     map: availableIntervals,
            //     comment: 'Результирующая карта',
            // });

            // if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = availableIntervals;
            // else timeTable.available1 = availableIntervals;
        //}
    //});
    return availableIntervals;
};

export const xxx21 = (element:OperationElement, intervals0:[number, number][], state:OperationFormState) => {
    let isSubrent = state.isSubrent;//isSubrentOperation(state.typeCode, state.targetStateCode);
    let availabilityFactor = isSubrent ? -1 : 1;
    let availableIntervals = intervals0;

    //elements.forEach((element) => {
        let isElementReducesAvailability = getIsElementReducesAvailability(element);
        //////////
        //Тут такая тема... Если изменили вариант, то нужно найти ту карту старого варианта
        //if (element.variantId !== element.variantIdOriginal) {
            if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
                // let timeTable = findTimeTable(state.timeTables, {
                //     productId: element.productId,
                //     kitId: element.kitId,
                //     variantId: element.variantIdOriginal,
                // });
                //if (timeTable) {
                    let intervals: [number, number, number][] = [];
                    if (isElementReducesAvailability) {
                        intervals.push([
                            availabilityFactor * -element.instanceCountOriginal,
                            element.rentPeriodStartDateOriginal.getTime(),
                            element.rentPeriodEndDateOriginal.getTime(),
                        ]);
                        let leftoverInstances = getLeftoverInstances(element.instanceIdsOriginal, element.instanceIds);
                        intervals.push([
                            availabilityFactor * Math.max(0, element.instanceCountOriginal - element.instanceCount, leftoverInstances.length),
                            element.rentPeriodStartDateOriginal.getTime(),
                            element.rentPeriodEndDateOriginal.getTime(),
                        ]);
                        // if (isOrderOperation(state.typeCode))
                        //     timeTable.orderAvailable = ProductUtils.getNewIntervals(timeTable.orderAvailable, intervals);
                        // else timeTable.available1 = ProductUtils.getNewIntervals(timeTable.available1, intervals);
                        availableIntervals = ProductUtils.getNewIntervals(availableIntervals, intervals);
                    }
                //}
            }
        //}
    //});
    return availableIntervals;
};


export const getNomenclatureAvailability = (record:NomenclatureRecord, state:OperationFormState) => {
    let availability;
    if(state.projectTemplate){
        availability = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.STOCK);
    }else{
        availability = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, isOrderOperation(state.typeCode) ? TimetableTypeCodeEnum.ORDERAVAILABLE : TimetableTypeCodeEnum.AVAILABLE);
    }

    // тут отфильтровать по номенклатуре
    filterOperationElementsForNomenclature(record, state.elements.entities).forEach((element) => {
        availability = xxx20(element, availability, state);
    });

    // Тут отфильтровать только по этому варианту
    state.elements.entities.forEach((element) => {
        if (element.variantId !== element.variantIdOriginal && record.variantId === element.variantIdOriginal) {
            availability = xxx21(element, availability, state);
        }
    });
    return availability;
};

export const getInstanceAvailability = (record:InstanceRecord, state:OperationFormState) => {
    let availability;
    if(state.projectTemplate){
        availability = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.STOCK);
    }else{
        availability = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, isOrderOperation(state.typeCode) ? TimetableTypeCodeEnum.ORDERAVAILABLE : TimetableTypeCodeEnum.AVAILABLE);
    }
    const _isSubrentOperation = state.isSubrent || false;//isSubrentOperation(state.typeCode, state.targetStateCode);
    availability = instanceTimetableToNormalState(availability, record.id, state.elements.entities, _isSubrentOperation, state.typeCode, state.targetStateCode, state.elementsDelayedReturnDates);
    return availability;
};

export const filterOperationElementsForNomenclature = (n: NomenclatureRecord, elements: OperationElement[]) => {
    if(n.kitId){
        return elements.filter((el) => el.kitId === n.kitId);
    }else if(n.productId && n.variantId){
        return elements.filter((el) => el.productId === n.productId && el.variantId === n.variantId);
    }else if(n.productId && !n.variantId){
        return elements.filter((el) => el.productId === n.productId && !el.variantId);
    }
    return [];
};