import React from 'react';
import { Button, Card, Icon, Popover } from 'antd';
import {
    exportExcel,
    getElementsListFilters,
    initialParamsState,
    loadEntities,
    reset as resetElements,
    updatePriceFromCatalog,
} from './reducers/elements.reducer';
import { reset as resetElement } from './reducers/element.reducer';
import {
    addElement,
    fullResetOperation,
    OperationElement,
    removeConcurrentOperation,
    startNewOperation,
} from '../operationForm/reducers/operationForm.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import RoundButton from '../../../components/button/roundButton';
import {
    IconArrowRightSolid,
    IconBorderStyleSolid,
    IconCheckSquare,
    IconCopy,
    IconCopyAnonymous,
    IconDollySolid,
    IconEraser,
    IconHandStopO,
    IconHourglassStart,
    IconMagicSolid,
    IconReply,
    IconStickyNoteO,
    IconSyncSolid,
    IconTimesCircle,
    IconWrench,
} from '../../../components/icons';
import { Spin } from '../../../components';
import {
    cbOnValueDefined,
    convertAvailableFiltersToObj,
    getPathFromState,
    getStateFromPath,
    getStateFromPath2,
    isDefined,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../shared/util/utils';
import {
    CategoryTypeCodeEnum,
    DocumentTypeCodeEnum,
    FileTypeCodeEnum,
    InventoryMovementEventTypeCodeEnum,
    NomenclatureRecord,
    NomenclatureStateCodeEnum,
    OperationInfoRead,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    ProjectTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
    serverApi,
    TagEntityTypeCode,
    TimetableTypeCodeEnum,
    VersionedEntityObj,
    VersionedEntityObjList,
} from '../../../server';
import every from 'lodash/every';
import { columns } from './elements-list-columns';
import { Location } from 'history';
import { ElementsElementPopover } from './elements-element-popover';
import { filters } from './elements-list-filters';
import debounce from 'lodash/debounce';
import { IFormField } from '../../../components/dynamicForm/DynamicForm';
import { logEvent } from '../../../analytics/analytics';
import { createOperation, createOperationElementFrom } from './create-operation-util';
import { showConfirm } from '../../../components/confirm/showConfirm';
import { showNotification } from '../../../components/notification/showNotification';
import { getFormattedMessageComponent, getRentElementStateByCode, localize, localizeIntl } from '../../../localization/localizationUtils';
import { LocalizationEnum } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '../../../components/grid/Grid';
import {
    getGridCategoriesStorageData,
    getGridStorageData,
    setGridCategoriesStorageData,
    setGridStorageDataFilters,
} from '../../../components/grid/utils';
import { PageUtils } from '../../../shared/util/pageUtils';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { RentElementsGridItem } from '../../../types';
import AdditionalEntityActions from '../../../components/additionalEntityActions/additionalEntityActions';
import { AddProjectPopover, IValue } from './components/AddProjectPopover/AddProjectPopover';
import { resetStatusWasChangedFlag } from '../projects/production/reducers/project.reducer';
import { RRoundButton } from '../../../components/button/rRoundButton';
import { AdditionalEntityActionsButton } from '../../../components/additionalEntityActions/additionalEntityActionsButton';
import { AxiosResponse } from 'axios';
import { canCreateCorrectionOperation, canUpdateFinancialData, canViewFinancialData } from '../../../shared/util/permissionUtils';
import { MoneyUtils } from '../../../core/utils/moneyUtils';
import { listConcurrentOperationsAndShowConfirm } from '../operationForm/listConcurrentOperationsAndShowConfirm';
import moment from 'moment';
import { ActionTypeEnum } from '../../../core/types/ActionTypeEnum';
import { InventoryMovementCreateModal } from '../inventoryMovements/modal/inventoryMovementCreateModal';
import { EntityActionType } from '../../../../index';
import { loadCategories } from '../../../shared/reducers/entities.reducer';
import { DownloadDocumentUtils } from '../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { ElementsAggregationsPopover } from './components/AggregationsPopover/ElementsAggregationsPopover';
import { GridCategoriesTree } from '../../../components/categories/GridCategoriesTree/GridCategoriesTree';
import { subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';
import { ServerUtils } from '../../../core/utils/serverUtils';
import { TagsChangeAction } from '../../../components/additionalEntityActions/NestedAdditionalEntityActionsForTags/TagsChangeAction/TagsChangeAction';
import { GridName } from '../../../components/grid/utils/types';
import { IDownloadDocumentParamsItem } from '../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import { ExportEstimatePopover } from '../../../components/exportPopovers/downloadDocument/instances/ExportEstimatePopover/ExportEstimatePopover';
import { ExportActPopover } from '../../../components/exportPopovers/downloadDocument/instances/ExportActPopover/ExportActPopover';
import { ExportToExcellPopover } from '../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/ExportToExcellPopover';
import {
    ActDocumentOnDownload,
    ExportActPopoverOptions,
} from '../../../components/exportPopovers/downloadDocument/instances/ExportActPopover/utils/data';
import {
    EstimateDocumentOnDownload,
    ExportEstimatePopoverOptions,
} from '../../../components/exportPopovers/downloadDocument/instances/ExportEstimatePopover/utils/data';
import {
    ExportToExcellOnDownload,
    ExportToExcellPopoverOptions,
} from '../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/utils/data';

export interface MoveElementsToOtherProjectFunction {
    (
        otherProject: IValue,
        targetProjectType: ProjectTypeCodeEnum | undefined,
        copy: boolean,
        copyWithoutInstances: boolean | undefined,
        showConcurrentOperationsPopup?: boolean
    ): Promise<void>;
}

interface IState {
    selection: number[];
    operationIsLoading: boolean;
    correctionPopoverVisible: boolean;
    openProjectForMove: boolean;
    closeMoveElementPopupVisible: boolean;
    loading: boolean;
    selectedRow?: { actionType: ActionTypeEnum; row: any } | null;
}

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    operationEntityType?: 'project' | 'projectTemplate' | 'shipping';
    visible?: boolean;
    gridName: GridName;
    excludeColumns?: string[];
    excludeFields?: string[];
    parentProjectEntity?: ProjectInfoRead;
    exportActOptions?: IDownloadDocumentParamsItem[];
    exportEstimateOptions?: IDownloadDocumentParamsItem[];
}

class Component extends React.Component<ElementsListProps, IState> {
    static defaultProps = {
        visible: true,
    };
    private grid;
    private filtersForm: WrappedFormUtils | undefined;
    private fromFilters;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = [
        'sortBy',
        'sortOrder',
        'search',
        'typeCode',
        'hideInactive',
        'hide',
        'delay',
        'shortage',
        'startDate',
        'endDate',
        'discount',
        'shiftCount',
        'finalTotalPrice',
        'categoryIds',
        'tags',
    ];

    constructor(props: ElementsListProps) {
        super(props);

        this.state = {
            selection: [],
            operationIsLoading: false,
            correctionPopoverVisible: false,
            openProjectForMove: false,
            closeMoveElementPopupVisible: false,
            loading: false,
        };
        this.updateFilters = debounce(this.updateFilters, 250);
    }

    componentDidMount = async () => {
        console.log('ElementsList componentDidMount()');

        await this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.PRODUCT);

        let rentOrSubrent: 'rent' | 'subrent' | 'all' = 'all';
        let parentEntityType;

        if (this.props.parentType === 'project') {
            rentOrSubrent = 'rent';
            parentEntityType = 'project';
        } else if (this.props.parentType === 'shipping') {
            parentEntityType = 'shipping';
        } else if (this.props.parentType === 'projectTemplate') {
            parentEntityType = 'projectTemplate';
        } else if (
            this.props.operation?.typeCode === OperationTypeCodeEnum.DRAFT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.ORDER ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.BOOK ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.PROLONG ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RETURN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.CANCEL
        ) {
            rentOrSubrent = 'rent';
            parentEntityType = 'project';
        } else if (
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTCANCEL
        ) {
            rentOrSubrent = 'subrent';
            parentEntityType = 'shipping';
        }

        if (this.props.operationEntityType) parentEntityType = this.props.operationEntityType;

        this.initialValues = { ...this.props.pageParams, rentOrSubrent, parentEntityType };

        const filtersFromParentType: Record<typeof this.props.parentType, 'projectId' | 'operationId' | 'subrentId' | 'templateId'> = {
            project: 'projectId',
            operation: 'operationId',
            projectTemplate: 'templateId',
            shipping: 'subrentId',
        };
        const currentFilterField = filtersFromParentType[this.props.parentType];

        const queryFilters =
            this.props.parentType === 'operation'
                ? ServerUtils.createRequestFilters([['operationIds', 'IN', [this.props.parentId]]])
                : ServerUtils.createRequestFilters([[currentFilterField, 'EQ', this.props.parentId]]);

        try {
            let availableFiltersValues = await serverApi.listBusinessAccountElementsAvailableFiltersValues(
                this.props.businessAccountId,
                queryFilters
            );
            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.data.filters);
            let fieldsValues = {};

            let filter = this.availableFilters['finalTotalPrice'];
            if (filter) {
                let field = (filters[1].fields as IFormField[]).find((item) => item.id === 'finalTotalPrice');
                if (field) {
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min: roundMinFilterValue(filter[0] / 100),
                        max: roundMaxFilterValue(filter[1] / 100),
                    };
                    if (!this.props.pageParams.finalTotalPrice) {
                        fieldsValues['finalTotalPrice'] = [roundMinFilterValue(filter[0] / 100), roundMaxFilterValue(filter[1] / 100)];
                    }
                }
            }

            filter = this.availableFilters['rentTerms.discount'];
            if (filter) {
                let field = (filters[1].fields as IFormField[]).find((item) => item.id === 'discount');
                if (field) {
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'percents',
                        min: filter[0],
                        max: filter[1],
                    };
                    if (!this.props.pageParams.discount) {
                        fieldsValues['discount'] = [filter[0], filter[1]];
                    }
                }
            }

            filter = this.availableFilters['rentTerms.shifts.shiftCount'];
            if (filter) {
                let field = (filters[1].fields as IFormField[]).find((item) => item.id === 'shiftCount');
                if (field) {
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'number',
                        min: filter[0],
                        max: filter[1],
                    };
                    if (!this.props.pageParams.shiftCount) {
                        fieldsValues['shiftCount'] = [filter[0], filter[1]];
                    }
                }
            }

            filter = this.availableFilters['tags'];
            if (filter) {
                let field = (filters[0].fields as IFormField[]).find((item) => item.id === 'tags');
                if (field) {
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        tagType: TagEntityTypeCode.RENTELEMENT,
                        parentEntity: {
                            id: this.props.parentId,
                            entityType: this.props.parentType,
                        },
                    };
                }
            }

            cbOnValueDefined(
                () => this.filtersForm,
                () => {
                    if (this.filtersForm) this.filtersForm.setFieldsValue(fieldsValues);
                }
            );
        } catch (e) {
            console.error(e);
        }

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );

        let { pageParams, filteredCount } = this.props;
        logEvent({
            type: 'list elements',
            data: {
                'num filtered': filteredCount,
                'num per page': pageParams.limit,
                'num of the page': pageParams.page,
                'filter search': pageParams.search,
                'filter archive': !pageParams.hideInactive,
                'filter type code': pageParams.typeCode?.toString(),
                'filter renter id': pageParams.renterId,
                'filter project id': pageParams.projectId,
                'filter creation author id': pageParams.creationAuthorId,
                'filter start date from': pageParams.startDate ? pageParams.startDate[0] : undefined,
                'filter start date to': pageParams.startDate ? pageParams.startDate[1] : undefined,
                'filter end date from': pageParams.endDate ? pageParams.endDate[0] : undefined,
                'filter end date to': pageParams.endDate ? pageParams.endDate[1] : undefined,
                'filter discount from': pageParams.discount ? pageParams.discount[0] : undefined,
                'filter discount to': pageParams.discount ? pageParams.discount[1] : undefined,
                'filter shift count from': pageParams.shiftCount ? pageParams.shiftCount[0] : undefined,
                'filter shift count to': pageParams.shiftCount ? pageParams.shiftCount[1] : undefined,
                'filter final total price from': pageParams.finalTotalPrice ? pageParams.finalTotalPrice[0] : undefined,
                'filter final total price to': pageParams.finalTotalPrice ? pageParams.finalTotalPrice[1] : undefined,
            },
        });
    };

    componentDidUpdate = (prevProps: ElementsListProps) => {
        if (this.props.projectStatusWasChangedFlag && this.props.projectStatusWasChangedFlag !== prevProps.projectStatusWasChangedFlag) {
            setTimeout(() => {
                this.loadDataAndSetFilterValues();
            }, 1500);
            this.props.resetStatusWasChangedFlag();
            return;
        }

        if (
            (this.props.parentType === 'project' && prevProps.pageParams.projectId !== this.props.pageParams.projectId) ||
            (this.props.parentType === 'project' && String(this.props.pageParams.projectId) === String(this.props.parentId)) ||
            this.props.parentType !== 'project'
        ) {
            PageUtils.update(
                prevProps.pageParams,
                this.props.pageParams,
                this.updatingAfterSetFromStorage,
                this.fromFilters,
                this.setSavedParams,
                this.loadDataAndSetFilterValues,
                this.filters,
                () => {
                    this.updatingAfterSetFromStorage = false;
                }
            );
        }
    };

    componentWillUnmount = () => {
        console.log('ElementsList componentWillUnmount()');
        this.props.resetElements();
        this.props.resetElement();
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(this.props.gridName);
        let gridCategoriesData = getGridCategoriesStorageData(this.props.gridName);
        this.updatingAfterSetFromStorage = true;

        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
                categoryIds: gridCategoriesData.selectedIds ? gridCategoriesData.selectedIds.join(';') : undefined,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentType);
        if (!this.fromFilters && this.filtersForm) {
            let values = { ...initialParamsState, ...this.props.pageParams };

            if (this.availableFilters) {
                if (!values.finalTotalPrice) {
                    if (this.availableFilters['finalTotalPrice']) {
                        values.finalTotalPrice = [
                            roundMinFilterValue(this.availableFilters['finalTotalPrice'][0] / 100),
                            roundMaxFilterValue(this.availableFilters['finalTotalPrice'][1] / 100),
                        ];
                    }
                }
                if (!values.discount) {
                    if (this.availableFilters['rentTerms.discount']) {
                        values.discount = [this.availableFilters['rentTerms.discount'][0], this.availableFilters['rentTerms.discount'][1]];
                    }
                }
                if (!values.shiftCount) {
                    if (this.availableFilters['rentTerms.shifts.shiftCount']) {
                        values.shiftCount = [
                            this.availableFilters['rentTerms.shifts.shiftCount'][0],
                            this.availableFilters['rentTerms.shifts.shiftCount'][1],
                        ];
                    }
                }
            }
            setTimeout(() => {
                this.filtersForm?.setFieldsValue(values);
            }, 200);
        }
        this.fromFilters = false;
    };

    onCorrectionPopoverVisibleChange = (visible: boolean) => {
        this.setState({ correctionPopoverVisible: visible });
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onRowAction = (
        item: RentElementsGridItem,
        action: EntityActionType | ActionTypeEnum,
        instanceCount: number,
        targetStateCode?: RentStateCodeEnum
    ) => {
        if (action === ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT || action === ActionTypeEnum.CREATE_LOSS_INVENTORY_MOVEMENT) {
            this.setState({ selectedRow: { actionType: action, row: item } });
        } else {
            this.createOperation(action as OperationTypeCodeEnum, [item], targetStateCode, instanceCount);
        }
    };

    getListForMove = () => {
        let projectId = this.props.entities ? this.props.entities[0].projectId : 0;
        let subrentId = this.props.entities ? this.props.entities[0].subrentId : 0;
        let elementsFromKits: Array<VersionedEntityObj> = [];

        let versionedEntityObjList: Array<VersionedEntityObj> = this.props.entities
            ?.filter((item) => {
                let kitSelect: Array<VersionedEntityObj> = [];
                if (item.subRows) {
                    kitSelect = item.subRows
                        .filter((kitItem) => this.state.selection.includes(kitItem.id))
                        .map((kitItem) => {
                            return {
                                id: kitItem.id,
                                businessVersion: kitItem.businessVersion,
                            };
                        });
                    elementsFromKits.push(...kitSelect);
                }
                return this.state.selection.includes(item.id);
            })
            .map((item) => {
                if (!projectId) {
                    projectId = item.projectId as number;
                }
                return {
                    id: item.id,
                    businessVersion: item.businessVersion,
                };
            }) as Array<VersionedEntityObj>;

        if (!elementsFromKits) {
            elementsFromKits = [];
        }
        if (!versionedEntityObjList) {
            versionedEntityObjList = [];
        }

        return {
            elements: [...versionedEntityObjList, ...elementsFromKits],
            projectId,
            subrentId,
        };
    };

    getElementsForMove = () => {
        let projectId = 0;
        let subrentId = 0;
        let elementsFromKits: Array<any> = [];

        let versionedEntityObjList: Array<any> = this.props.entities
            ?.filter((item) => {
                let kitSelect: Array<any> = [];
                if (item.subRows) {
                    kitSelect = item.subRows
                        .filter((kitItem) => this.state.selection.includes(kitItem.id))
                        .map((kitItem) => {
                            return { ...kitItem };
                        });
                    elementsFromKits.push(...kitSelect);
                }
                return this.state.selection.includes(item.id);
            })
            .map((item) => {
                return { ...item };
            }) as Array<any>;

        if (!elementsFromKits) {
            elementsFromKits = [];
        }
        if (!versionedEntityObjList) {
            versionedEntityObjList = [];
        }

        const elements = [...versionedEntityObjList, ...elementsFromKits];
        if (elements.length > 0) {
            projectId = elements[0].projectId as number;
            subrentId = elements[0].subrentId as number;
        }

        return {
            elements: [...versionedEntityObjList, ...elementsFromKits],
            projectId,
            subrentId,
        };
    };

    moveProjectElementsToOtherProject: MoveElementsToOtherProjectFunction = async (
        otherProject,
        targetProjectType: ProjectTypeCodeEnum | undefined,
        copy: boolean,
        copyWithoutInstances?: boolean,
        showConcurrentOperationsPopup: boolean = true
    ) => {
        if (showConcurrentOperationsPopup) {
            const actionAuthorized = await listConcurrentOperationsAndShowConfirm(
                this.props.intl,
                this.props.businessAccountId,
                this.props.parentId,
                RentActivityFrameTypeCodeEnum.PROJECT,
                false
            );
            if (!actionAuthorized) return;
        }

        this.setState({ loading: true });
        await serverApi
            .moveProjectElementsToOtherProject(
                this.props.businessAccountId,
                this.getListForMove().projectId as number,
                otherProject.id,
                copy,
                { entities: this.getListForMove().elements },
                copyWithoutInstances
            )
            .then(() => {
                this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentType);
                this.clearSelection();

                const isOffer = targetProjectType === ProjectTypeCodeEnum.OFFER;
                const path = isOffer
                    ? `/${this.props.businessAccountId}/projects/offers/${otherProject.id}?tab=elements`
                    : `/${this.props.businessAccountId}/projects/production/${otherProject.id}?tab=elements`;

                showNotification(
                    'success',
                    <span className={'rr-operation-page__operation-created-message'}>
                        {copy ? 'Обязательства успешно скопированы в' : 'Обязательства успешно перенесены в'}&nbsp;
                        <a
                            onClick={() => {
                                this.props.push(path);
                            }}
                        >
                            {isOffer ? 'смету' : 'проект'}
                        </a>
                    </span>
                );
            })
            .catch(() => {
                showNotification(
                    'error',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__FAILED_TO_MOVE_ELEMENTS_TO_PROJECT)
                );
            });
        this.setState({ loading: false });
    };

    moveSubrentElementsToOtherSubrent: MoveElementsToOtherProjectFunction = async (
        otherProject,
        targetProjectType: ProjectTypeCodeEnum | undefined,
        copy: boolean,
        copyWithoutInstances?: boolean,
        showConcurrentOperationsPopup: boolean = true
    ) => {
        if (showConcurrentOperationsPopup) {
            const actionAuthorized = await listConcurrentOperationsAndShowConfirm(
                this.props.intl,
                this.props.businessAccountId,
                this.props.parentId,
                RentActivityFrameTypeCodeEnum.SUBRENT,
                false
            );
            if (!actionAuthorized) return;
        }

        let selectedItems = this.getSelectedItems();
        let isSubrent = selectedItems && selectedItems[0] && isDefined(selectedItems[0].subrentId);
        this.setState({ loading: true });
        await serverApi
            .moveSubrentElementsToOtherSubrent(
                this.props.businessAccountId,
                (isSubrent ? this.getListForMove().subrentId : this.getListForMove().projectId) as number,
                otherProject.id,
                copy,
                { entities: this.getListForMove().elements },
                copyWithoutInstances
            )
            .then(() => {
                this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentType);
                this.clearSelection();
                showNotification(
                    'success',
                    <span className={'rr-operation-page__operation-created-message'}>
                        {copy ? 'Обязательства успешно скопированы в' : 'Обязательства успешно перенесены в'}&nbsp;
                        <a
                            onClick={() => {
                                this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${otherProject.id}?tab=elements`);
                            }}
                        >
                            поставку
                        </a>
                    </span>
                );
            })
            .catch(() => {
                showNotification('error', 'Перенос обязательств в поставку выполнить не удалось');
            });
        this.setState({ loading: false });
    };

    closeMoveElementPopup = () => {
        this.setState({
            closeMoveElementPopupVisible: true,
        });
    };

    createOperation = async (
        code: OperationTypeCodeEnum,
        selectedItems: Array<RentElementsGridItem>,
        targetStateCode?: RentStateCodeEnum,
        instanceCount?: number
    ) => {
        let canCreate = true;
        await this.setState({ correctionPopoverVisible: false, operationIsLoading: true });

        let kitIds: number[] = [];
        let kitElements: RentElementsGridItem[] = [];

        selectedItems.forEach((item) => {
            if (item.rentElementsKitId) {
                if (!kitIds.includes(item.rentElementsKitId)) kitIds.push(item.rentElementsKitId);
            }
        });

        if (this.props.entities) {
            kitElements = this.props.entities.filter((item) => kitIds.includes(item.id));
        }

        selectedItems = selectedItems.concat(kitElements);

        if (this.props.operationFormMnemoKey) {
            canCreate = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
        }

        if (canCreate) {
            try {
                const { activityFrameId, activityFrameType } = this.getActivityFrameData();

                if (activityFrameId && activityFrameType) {
                    canCreate = await listConcurrentOperationsAndShowConfirm(
                        this.props.intl,
                        this.props.businessAccountId,
                        activityFrameId,
                        activityFrameType
                    );
                }
            } catch (e) {
                this.setState({ operationIsLoading: false });
                return;
            }
        }

        if (canCreate) {
            try {
                await createOperation(
                    this.props.intl,
                    this.props.parentType === 'projectTemplate' || isDefined(selectedItems[0].templateId),
                    this.props.startNewOperation,
                    this.props.businessAccountId,
                    code,
                    selectedItems,
                    targetStateCode,
                    instanceCount
                );
                this.setState({ operationIsLoading: false });
                this.props.push(`/${this.props.businessAccountId}/operation?tab=${'elements'}`);
            } catch (e) {
                this.setState({ operationIsLoading: false });
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            }
        } else {
            this.setState({ operationIsLoading: false });
        }
    };

    addElementsToCurrentOperation = async () => {
        let ok = true;
        const { operationTypeCode } = this.props;
        if (this.props.operationFormMnemoKey && operationTypeCode !== OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT) {
            ok = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
            if (ok) {
                await this.props.fullResetOperation();
            }
        } else if (!this.props.operationFormMnemoKey) {
            await this.props.fullResetOperation();
        }

        if (ok) {
            let selectedItems = this.getSelectedItems();

            let kitIds: number[] = [];
            let kitElements: RentElementsGridItem[] = [];

            selectedItems.forEach((item) => {
                if (item.rentElementsKitId) {
                    if (!kitIds.includes(item.rentElementsKitId)) kitIds.push(item.rentElementsKitId);
                }
            });

            if (this.props.entities) {
                kitElements = this.props.entities.filter((item) => kitIds.includes(item.id));
            }

            selectedItems = selectedItems.concat(kitElements);

            let operationsWithShortages = selectedItems.filter((item) => {
                return item.problemsAndWarnings?.anyShortage;
            });

            let elements: OperationElement[] = [];
            operationsWithShortages.forEach((item) => {
                const element = createOperationElementFrom(
                    selectedItems,
                    item,
                    item.rentTerms.rentPeriodStartDate,
                    item.rentTerms.rentPeriodEndDate,
                    1,
                    operationTypeCode
                );
                element.instanceCount = item.shortageInstanceCount;
                element.anonymousInstanceCount = item.shortageInstanceCount;
                element.rentPeriodChanged = true;
                element.shiftCountChanged = true;
                element.instanceIds = [];
                element.instanceIdsOriginal = [];
                elements.push(element);
            });

            try {
                let filters = [
                    'productId;IN;' +
                        elements
                            .map((item) => item.productId)
                            .filter((v, i, a) => a.indexOf(v) === i)
                            .join(';'),
                ];
                if (elements.filter((item) => item.variantId).length > 0) {
                    filters.push(
                        'variantId;IN;' +
                            elements
                                .map((item) => item.variantId || -1)
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .join(';')
                    );
                }

                filters.push('identifiesRentElementAvailability;EQ;true');

                let possibleDelayedRentElementIds = elements
                    .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
                    .map((item) => item.id);

                let operationRentPeriodStartDate = this.props.operationRentPeriodStartDate?.getTime() || 0;
                let operationRentPeriodEndDate = this.props.operationRentPeriodEndDate?.getTime() || 0;

                let minStartDate = Number.MAX_VALUE;
                let maxEndDate = 0;
                if (!operationRentPeriodStartDate && !operationRentPeriodEndDate) {
                    elements.forEach((element) => {
                        minStartDate = Math.min(minStartDate, element.rentPeriodStartDate.getTime());
                        maxEndDate = Math.max(maxEndDate, element.rentPeriodEndDate.getTime());
                    });
                    operationRentPeriodStartDate = minStartDate;
                    operationRentPeriodEndDate = maxEndDate;
                }

                let productsResponse = await serverApi.listNomenclatureOnInterval(
                    this.props.businessAccountId,
                    operationRentPeriodStartDate - 365 * 1 * 24 * 60 * 60 * 1000,
                    operationRentPeriodEndDate + 365 * 2 * 24 * 60 * 60 * 1000,
                    {
                        possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                        listFilters: filters,
                    },
                    1000,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [
                        TimetableTypeCodeEnum.ORDERAVAILABLE,
                        TimetableTypeCodeEnum.AVAILABLE,
                        TimetableTypeCodeEnum.STOCK,
                        TimetableTypeCodeEnum.ORDER,
                    ]
                );

                let products = productsResponse.data.records;

                elements.forEach((element) => {
                    let p: NomenclatureRecord | undefined;
                    products.forEach((product) => {
                        if (element.productId === product.productId && element.variantId === product.variantId) {
                            p = product;
                        }
                    });
                    if (p) {
                        element.shiftLengthInMinutes = p.shiftLengthInMinutes;
                        element.hasOwnShiftLength = p.productHasOwnShiftLength;

                        element.requiredTimeIndentBetweenElementsInMinutes = p.requiredTimeIndentBetweenElementsInMinutes;
                        element.productHasOwnRequiredTimeIndentBetweenElements = p.productHasOwnRequiredTimeIndentBetweenElements;

                        element.rentPeriodStartDate = new Date(
                            element.rentPeriodStartDate.getTime() - (element.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000
                        );
                        element.rentPeriodEndDate = new Date(
                            element.rentPeriodEndDate.getTime() + (element.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000
                        );
                    }
                });

                this.props.addElement(elements, products, operationTypeCode !== OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT, true);
                this.props.push(`/${this.props.businessAccountId}/operation?tab=${'elements'}`);
            } catch (e) {}
        }
    };

    updatePricesFromTheCatalog = async (selectedItems: RentElementsGridItem[]) => {
        const ok = await showConfirm(this.props.intl, 'Вы уверены, что хотите обновить цены и установить актуальные из каталога?');
        if (!ok) return;

        const { activityFrameId, activityFrameType } = this.getActivityFrameData();
        if (activityFrameId == null || activityFrameType == null) return;
        const versionedEntityObjList: VersionedEntityObjList = {
            entities: selectedItems.map(({ id, businessVersion }) => ({ id, businessVersion })),
        };

        this.clearSelection();
        this.props.updatePriceFromCatalog(
            this.props.intl,
            this.props.businessAccountId,
            activityFrameId,
            activityFrameType,
            versionedEntityObjList,
            this.props.parentType
        );
    };

    getSelectedItems = () => {
        let selectedItems: Array<RentElementsGridItem> = [];
        if (this.props.entities) {
            this.props.entities.forEach((element) => {
                if (this.state.selection.includes(element.id)) {
                    selectedItems.push(element);
                }
                if (element['subRows']) {
                    element['subRows'].forEach((subRow) => {
                        if (this.state.selection.includes(subRow.id)) {
                            selectedItems.push(subRow);
                        }
                    });
                }
            });
        }
        return selectedItems;
    };

    getActionButtons = () => {
        let selectedItems = this.getSelectedItems();
        const { subrentModuleEnabled } = this.props;

        let operationsWithShortages = selectedItems.filter((item) => {
            return item.problemsAndWarnings?.anyShortage;
        });

        let isSubrent = selectedItems && selectedItems[0] && isDefined(selectedItems[0].subrentId);

        const createCorrectionButton = (code: RentStateCodeEnum, key: number) => {
            let create = false;
            if (this.props.parentType !== 'shipping' || (this.props.parentType === 'shipping' && this.props.subrentModuleEnabled)) {
                if (every(selectedItems, (item: RentElementsGridItem) => item.availableTargetCorrectionStates?.includes(code)))
                    create = true;
            } else {
                if (
                    code === RentStateCodeEnum.SUBRENTCANCELED ||
                    code === RentStateCodeEnum.SUBRENTDRAFT ||
                    code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER
                )
                    create = true;
            }
            return create ? (
                <Button key={key} block onClick={(e) => this.createOperation(OperationTypeCodeEnum.CORRECT, selectedItems, code)}>
                    <div className={`rr-dot rr-status-bg-${code}`}></div>
                    <span>{getRentElementStateByCode(code)}</span>
                </Button>
            ) : null;
        };

        let hasNotAnonymousInstances: boolean | undefined;
        if (isSubrent && selectedItems.find((item) => item.instanceIds && item.instanceIds.length > 0)) {
            hasNotAnonymousInstances = true;
        }

        return [
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT)
            ) ? (
                <RRoundButton
                    key={30}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_BOOK_SHIPMENT}
                    icon={IconCheckSquare}
                    colorScheme={OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT)
            ) ? (
                <RRoundButton
                    key={20}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_ACCEPT_SHIPMENT}
                    icon={IconHandStopO}
                    colorScheme={OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTPROLONG)
            ) ? (
                <RRoundButton
                    key={21}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_PROLONG}
                    icon={IconHourglassStart}
                    colorScheme={OperationTypeCodeEnum.SUBRENTPROLONG}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTPROLONG, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER)
            ) ? (
                <RRoundButton
                    key={50}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_RETURN_TO_SHIPPER}
                    icon={IconReply}
                    colorScheme={OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTCANCEL)
            ) ? (
                <RRoundButton
                    key={40}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_CANCEL}
                    icon={IconEraser}
                    colorScheme={OperationTypeCodeEnum.SUBRENTCANCEL}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTCANCEL, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTDRAFT)
            ) ? (
                <RRoundButton
                    key={10}
                    title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_DRAFT}
                    icon={IconBorderStyleSolid}
                    colorScheme={OperationTypeCodeEnum.SUBRENTDRAFT}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.SUBRENTDRAFT, selectedItems)}
                />
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.ORDER)) ? (
                <RoundButton
                    key={60}
                    title={''}
                    colorScheme={'ORDER'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.ORDER, selectedItems)}
                >
                    <Icon component={IconStickyNoteO} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__ORDER)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.BOOK)) ? (
                <RoundButton
                    key={70}
                    title={''}
                    colorScheme={'BOOK'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.BOOK, selectedItems)}
                >
                    <Icon component={IconCheckSquare} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__BOOK)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.RENT)) ? (
                <RoundButton
                    key={80}
                    title={''}
                    colorScheme={'RENT'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.RENT, selectedItems)}
                >
                    <Icon component={IconHandStopO} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__GIVE_AWAY)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.PROLONG)) ? (
                <RoundButton
                    key={90}
                    title={''}
                    colorScheme={'PROLONG'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.PROLONG, selectedItems)}
                >
                    <Icon component={IconHourglassStart} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__PROLONG)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.RETURN)) ? (
                <RoundButton
                    key={100}
                    title={''}
                    colorScheme={'RETURN'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.RETURN, selectedItems)}
                >
                    <Icon component={IconReply} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.RETURNBROKEN)
            ) ? (
                <RoundButton
                    key={110}
                    title={''}
                    colorScheme={'RETURN_BROKEN'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.RETURNBROKEN, selectedItems)}
                >
                    <Icon component={IconWrench} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN_BROKEN)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) =>
                item.availableTransitionCodes?.includes(OperationTypeCodeEnum.LOSTNORETURN)
            ) ? (
                <RoundButton
                    key={120}
                    title={''}
                    colorScheme={'LOST_NO_RETURN'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.LOSTNORETURN, selectedItems)}
                >
                    <Icon component={IconTimesCircle} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__LOST_NO_RETURN)}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.CORRECT)) &&
            canCreateCorrectionOperation(this.props.userPermissions) ? (
                <Popover
                    key={130}
                    visible={this.state.correctionPopoverVisible}
                    onVisibleChange={this.onCorrectionPopoverVisibleChange}
                    overlayClassName={'rr-grid-actions-popover'}
                    autoAdjustOverflow
                    arrowPointAtCenter
                    placement="left"
                    trigger="click"
                    content={
                        <>
                            <div className={'rr-grid-actions-popover-header'}>
                                <span style={{ marginRight: 7 }}>
                                    {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE)}
                                </span>
                            </div>
                            <div className={'rr-grid-actions-popover-content'}>
                                {[
                                    selectedItems[0] && !selectedItems[0].projectFinished ? RentStateCodeEnum.ORDERED : null,
                                    selectedItems[0] && !selectedItems[0].projectFinished ? RentStateCodeEnum.BOOKED : null,
                                    selectedItems[0] && !selectedItems[0].projectFinished ? RentStateCodeEnum.RENT : null,
                                    RentStateCodeEnum.RETURNED,
                                    RentStateCodeEnum.RETURNEDBROKEN,
                                    RentStateCodeEnum.LOSTDURINGRENT,
                                    RentStateCodeEnum.CANCELED,
                                    RentStateCodeEnum.DRAFT,
                                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                                    RentStateCodeEnum.SUBRENT,
                                    RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                                    RentStateCodeEnum.SUBRENTCANCELED,
                                    RentStateCodeEnum.SUBRENTDRAFT,
                                ].map((item, index) => item && createCorrectionButton(item, index))}
                            </div>
                        </>
                    }
                >
                    <RoundButton title={''} colorScheme={'CORRECT'}>
                        <Icon component={IconMagicSolid} />
                        <span>{getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CORRECT)}</span>
                    </RoundButton>
                </Popover>
            ) : null,

            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.CANCEL)) ? (
                <RoundButton
                    key={8}
                    title={''}
                    colorScheme={'CANCEL'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.CANCEL, selectedItems)}
                >
                    <Icon component={IconEraser} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL)}
                </RoundButton>
            ) : null,

            every(selectedItems, (item: RentElementsGridItem) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.DRAFT)) ? (
                <RoundButton
                    key={9}
                    title={''}
                    colorScheme={'DRAFT'}
                    onClick={() => this.createOperation(OperationTypeCodeEnum.DRAFT, selectedItems)}
                >
                    <Icon component={IconBorderStyleSolid} />
                    {getFormattedMessageComponent(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__DRAFT)}
                </RoundButton>
            ) : null,
            this.props.parentProjectEntity && this.props.parentProjectEntity.projectType === ProjectTypeCodeEnum.OFFER ? (
                <RoundButton
                    colorScheme={'success'}
                    onClick={() => {
                        if (this.props.parentProjectEntity) {
                            this.moveProjectElementsToOtherProject(
                                { id: this.props.parentProjectEntity.mainProjectId } as any,
                                ProjectTypeCodeEnum.BASE,
                                true,
                                false
                            );
                        }
                    }}
                >
                    <Icon component={IconCopy} style={{ color: '#FFFFFF' }} />
                    <span>В основной проект</span>
                </RoundButton>
            ) : null,
            (this.props.parentType !== 'shipping' || (this.props.parentType === 'shipping' && subrentModuleEnabled)) &&
            selectedItems &&
            selectedItems[0] &&
            !isDefined(selectedItems[0].templateId) ? (
                <span key={140} style={{ marginLeft: '16px' }}>
                    <AdditionalEntityActions
                        disabled={this.state.closeMoveElementPopupVisible}
                        isElement
                        overlayStyle={{ minWidth: 340 }}
                        content={
                            <div>
                                {
                                    <TagsChangeAction
                                        entities={selectedItems}
                                        tagType={TagEntityTypeCode.RENTELEMENT}
                                        clearSelection={this.clearSelection}
                                        onSuccess={() => {
                                            this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentType);
                                        }}
                                        parentEntity={{
                                            id: this.props.parentId,
                                            entityType: this.props.parentType,
                                        }}
                                    />
                                }
                                {subrentModuleEnabled && operationsWithShortages.length > 0 ? (
                                    <Button
                                        block
                                        onClick={() => {
                                            this.addElementsToCurrentOperation();
                                        }}
                                    >
                                        <Icon component={IconDollySolid} style={{ color: '#ffb822' }} />
                                        <span>Субаренда нехватки</span>
                                    </Button>
                                ) : null}
                                {
                                    <AddProjectPopover
                                        projectStateCode={this.props.projectStateCode}
                                        moveProjectElementsToOtherProject={
                                            isSubrent ? this.moveSubrentElementsToOtherSubrent : this.moveProjectElementsToOtherProject
                                        }
                                        stateCode={NomenclatureStateCodeEnum.ACTIVE}
                                        closeMoveElementPopup={this.closeMoveElementPopup}
                                        selectElementsForMove={this.getElementsForMove}
                                        copy={false}
                                        isSubrent={isSubrent}
                                        counterpartyId={
                                            this.props.entities && this.props.entities[0] ? this.props.entities[0].renterId : undefined
                                        }
                                        hasNotAnonymousInstances={hasNotAnonymousInstances}
                                    >
                                        <Button block>
                                            <Icon component={IconArrowRightSolid} style={{ color: '#8d75ca' }} />
                                            <span>{'Переместить'}</span>
                                        </Button>
                                    </AddProjectPopover>
                                }
                                {!this.props.parentProjectEntity && (
                                    <AddProjectPopover
                                        projectStateCode={this.props.projectStateCode}
                                        moveProjectElementsToOtherProject={
                                            isSubrent ? this.moveSubrentElementsToOtherSubrent : this.moveProjectElementsToOtherProject
                                        }
                                        stateCode={NomenclatureStateCodeEnum.ACTIVE}
                                        closeMoveElementPopup={this.closeMoveElementPopup}
                                        selectElementsForMove={this.getElementsForMove}
                                        copy={true}
                                        isSubrent={isSubrent}
                                        counterpartyId={
                                            this.props.entities && this.props.entities[0] ? this.props.entities[0].renterId : undefined
                                        }
                                        hasNotAnonymousInstances={hasNotAnonymousInstances}
                                        copyWithoutInstances={false}
                                        showConcurrentOperationsPopup={false}
                                    >
                                        <Button block>
                                            <Icon component={IconCopy} style={{ color: '#57d6b9' }} />
                                            <span>{'Скопировать'}</span>
                                        </Button>
                                    </AddProjectPopover>
                                )}
                                {
                                    <AddProjectPopover
                                        projectStateCode={this.props.projectStateCode}
                                        moveProjectElementsToOtherProject={
                                            isSubrent ? this.moveSubrentElementsToOtherSubrent : this.moveProjectElementsToOtherProject
                                        }
                                        stateCode={NomenclatureStateCodeEnum.ACTIVE}
                                        closeMoveElementPopup={this.closeMoveElementPopup}
                                        selectElementsForMove={this.getElementsForMove}
                                        copy={true}
                                        isSubrent={isSubrent}
                                        counterpartyId={
                                            this.props.entities && this.props.entities[0] ? this.props.entities[0].renterId : undefined
                                        }
                                        hasNotAnonymousInstances={hasNotAnonymousInstances}
                                        copyWithoutInstances={true}
                                        showConcurrentOperationsPopup={false}
                                    >
                                        <Button block>
                                            <Icon component={IconCopyAnonymous} style={{ color: '#04b0f1' }} />
                                            <span>{'Скопировать без экземпляров'}</span>
                                        </Button>
                                    </AddProjectPopover>
                                }
                                {this.props.canUpdateFinancialData && (
                                    <Button
                                        className={'ant-btn-page-actions'}
                                        onClick={() => this.updatePricesFromTheCatalog(selectedItems)}
                                    >
                                        <Icon component={IconSyncSolid} style={{ color: '#ffcd43' }} />
                                        Обновить цены из каталога
                                    </Button>
                                )}
                            </div>
                        }
                    >
                        <AdditionalEntityActionsButton
                            onClick={() => {
                                this.setState({ closeMoveElementPopupVisible: false });
                            }}
                        />
                    </AdditionalEntityActions>
                </span>
            ) : null,
        ];
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        data.renterId = data.renterId && data.renterId.key ? data.renterId.key : data.renterId ? data.renterId : undefined;
        data.projectId = data.projectId && data.projectId.key ? data.projectId.key : data.projectId ? data.projectId : undefined;
        data.creationAuthorId =
            data.creationAuthorId && data.creationAuthorId.key
                ? data.creationAuthorId.key
                : data.creationAuthorId
                ? data.creationAuthorId
                : undefined;

        if (data.startDate) {
            const startDate = data.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) data.startDate = undefined;
            else if (!startDate[1]) data.startDate = [startDate[0]];
            else data.startDate = startDate;
        }

        if (data.endDate) {
            const endDate = data.endDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!endDate[0] && !endDate[1]) data.endDate = undefined;
            else if (!endDate[1]) data.endDate = [endDate[0]];
            else data.endDate = endDate;
        }

        if (this.availableFilters) {
            if (data.discount) {
                if (
                    data.discount.toString() ===
                    [this.availableFilters['rentTerms.discount'][0], this.availableFilters['rentTerms.discount'][1]].toString()
                ) {
                    data.discount = undefined;
                }
            }

            if (data.shiftCount) {
                if (
                    data.shiftCount.toString() ===
                    [
                        this.availableFilters['rentTerms.shifts.shiftCount'][0],
                        this.availableFilters['rentTerms.shifts.shiftCount'][1],
                    ].toString()
                ) {
                    data.shiftCount = undefined;
                }
            }

            if (data.finalTotalPrice) {
                if (
                    data.finalTotalPrice.toString() ===
                    [
                        roundMinFilterValue(this.availableFilters['finalTotalPrice'][0] / 100),
                        roundMaxFilterValue(this.availableFilters['finalTotalPrice'][1] / 100),
                    ].toString()
                ) {
                    data.finalTotalPrice = undefined;
                }
            }
        }

        if (data.hideInactive === false) data.hideInactive = undefined;

        data.page = 1;

        this.fromFilters = true;

        if (Array.isArray(data.typeCode) && data.typeCode.length === 0) {
            data.typeCode = undefined;
        }
        if (Array.isArray(data.tags) && data.tags.length === 0) {
            data.tags = undefined;
        }

        data.rentOrSubrent = undefined;
        data.parentEntityType = undefined;

        setGridStorageDataFilters(this.props.gridName, data);

        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    getExportSettings: ExportToExcellOnDownload = async ({ options }) => {
        let mnemoProject = '';
        if (this.props.parentType === 'project') {
            await serverApi.getProjectById(this.props.businessAccountId, this.props.parentId).then((res) => {
                mnemoProject = res.data.shortName;
            });
        } else if (this.props.parentType === 'projectTemplate') {
            await serverApi.getTemplateById(this.props.businessAccountId, this.props.parentId).then((res) => {
                mnemoProject = res.data.shortName;
            });
        } else if (this.props.parentType === 'operation') {
            await serverApi.getOperationById(this.props.businessAccountId, this.props.parentId).then((res) => {
                mnemoProject = res.data.mnemoKey;
            });
        } else {
            await serverApi.getSubrentById(this.props.businessAccountId, this.props.parentId).then((res) => {
                mnemoProject = res.data.mnemoKey;
            });
        }

        let columns = ['All'];
        if (!options.includes(ExportToExcellPopoverOptions.ALL_COLUMNS)) {
            columns = this.grid.getVisibleColumns();
        }

        await this.props.exportExcel(
            this.props.intl,
            this.props.businessAccountId,
            this.props.parentType,
            columns,
            options.includes(ExportToExcellPopoverOptions.EXPAND_KITS),
            mnemoProject,
            this.state.selection
        );
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            typeCode: undefined,
            hideInactive: undefined,
            hide: undefined,
            problem: undefined,
            categoryIds: undefined,

            startDate: undefined,
            endDate: undefined,

            discount: undefined,
            shiftCount: undefined,
            finalTotalPrice: undefined,
            tags: undefined,
        };

        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
        setGridCategoriesStorageData(this.props.gridName, { selectedIds: undefined });
    };

    generateEstimateCb: EstimateDocumentOnDownload = async ({ fileType, options, templateId, isCommonTemplate, period, legalDetailId }) => {
        await this.generateDocument(
            fileType,
            options,
            templateId || 0,
            isCommonTemplate,
            DocumentTypeCodeEnum.ESTIMATE,
            period,
            legalDetailId
        );
    };

    generateActCb: ActDocumentOnDownload = async ({ fileType, options, templateId, isCommonTemplate, period, legalDetailId }) => {
        await this.generateDocument(fileType, options, templateId || 0, isCommonTemplate, DocumentTypeCodeEnum.ACT, period, legalDetailId);
    };

    generateDocument = async (
        fileType: FileTypeCodeEnum,
        values: (ExportEstimatePopoverOptions | ExportActPopoverOptions)[],
        templateId: number,
        isCommonTemplate: boolean | undefined,
        documentType: DocumentTypeCodeEnum,
        period?: [string, string],
        legalDetailId?: number
    ) => {
        if (!this.props.parentId) return;

        let freeItems = values && values.includes(ExportEstimatePopoverOptions.FREE_ITEMS);
        let projectName: string | undefined = '';

        if (this.props.parentType === 'project') {
            let projectData: AxiosResponse<ProjectInfoRead> | undefined;
            try {
                projectData = await serverApi.getProjectById(this.props.businessAccountId, this.props.parentId);
            } catch (e) {
                console.log(e);
            }
            if (projectData?.data) projectName = projectData.data.shortName || projectData.data.fullName;
        } else if (this.props.parentType === 'operation') {
            let projectData: AxiosResponse<OperationInfoRead> | undefined;
            try {
                projectData = await serverApi.getOperationById(this.props.businessAccountId, this.props.parentId);
            } catch (e) {
                console.log(e);
            }
            if (projectData?.data) projectName = projectData.data.projectShortName;
        }

        let filters: string[] = getElementsListFilters(this.props.pageParams, !freeItems);

        if (!freeItems) {
            if (
                this.props.pageParams.finalTotalPrice &&
                (this.props.pageParams.finalTotalPrice[0] !== undefined || this.props.pageParams.finalTotalPrice[1] !== undefined)
            ) {
                if (this.props.pageParams.finalTotalPrice[0] !== undefined)
                    filters.push('finalTotalPrice;GE;' + Math.max(1, MoneyUtils.toCents(this.props.pageParams.finalTotalPrice[0])));
                if (this.props.pageParams.finalTotalPrice[1] !== undefined)
                    filters.push('finalTotalPrice;LE;' + MoneyUtils.toCents(this.props.pageParams.finalTotalPrice[1]));
            } else {
                filters.push('finalTotalPrice;GT;0');
            }
        }

        const selectedIdsFilter = this.state.selection.length > 0 ? `id;IN;${this.state.selection.join(';')}` : '';
        if (selectedIdsFilter) filters.push(selectedIdsFilter);

        try {
            if (documentType === DocumentTypeCodeEnum.ACT) {
                const fileName = `Акт${projectName ? ' ' + projectName : ''}`;
                await DownloadDocumentUtils.actGenerateAndDownload(
                    {
                        businessAccountId: this.props.businessAccountId,
                        parentId: this.props.parentId,
                        common: Boolean(isCommonTemplate),
                        documentTemplateId: templateId,
                        expandKits: values && values.includes(ExportEstimatePopoverOptions.EXPAND_KITS),
                        joinSimilarRows: values && values.includes(ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS),
                        groupByCategories: values && values.includes(ExportActPopoverOptions.GROUP_BY_CATEGORIES),
                        search: this.props.pageParams.search,
                        filters: filters,
                        includeWorkPlannings: values && values.includes(ExportActPopoverOptions.WORK_PLANNINGS),
                        includeExpenses: values && values.includes(ExportActPopoverOptions.EXPENSES),
                        period,
                        legalDetailId,
                    },
                    this.props.parentType,
                    fileName,
                    fileType as FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
                ).catch((e) => {
                    showNotification('error', 'Ошибка генерации файла');
                });
            } else {
                const fileName = `Смета${projectName ? ' ' + projectName : ''}`;
                await DownloadDocumentUtils.estimateGenerateAndDownload(
                    {
                        businessAccountId: this.props.businessAccountId,
                        parentId: this.props.parentId,
                        common: Boolean(isCommonTemplate),
                        documentTemplateId: templateId,
                        expandKits: values && values.includes(ExportEstimatePopoverOptions.EXPAND_KITS),
                        joinSimilarRows: values && values.includes(ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS),
                        search: this.props.pageParams.search,
                        filters: filters,
                        includeWorkPlannings: values && values.includes(ExportEstimatePopoverOptions.WORK_PLANNINGS),
                        includeExpenses: values && values.includes(ExportEstimatePopoverOptions.EXPENSES),
                        period,
                        legalDetailId,
                    },
                    this.props.parentType,
                    fileName,
                    fileType as FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
                ).catch((e) => {
                    showNotification('error', 'Ошибка генерации файла');
                });
            }
        } catch (err) {}
    };

    getActivityFrameData = () => {
        let activityFrameType: RentActivityFrameTypeCodeEnum | undefined;
        let activityFrameId: number | undefined;
        if (this.props.parentType === 'operation') {
            activityFrameId = this.props.operation?.projectId || this.props.operation?.subrentId || this.props.operation?.templateId;
            if (this.props.operation?.projectId) activityFrameType = RentActivityFrameTypeCodeEnum.PROJECT;
            else if (this.props.operation?.subrentId) activityFrameType = RentActivityFrameTypeCodeEnum.SUBRENT;
            else if (this.props.operation?.templateId) activityFrameType = RentActivityFrameTypeCodeEnum.TEMPLATE;
        } else if (this.props.parentType === 'project') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.PROJECT;
        } else if (this.props.parentType === 'shipping') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.SUBRENT;
        } else if (this.props.parentType === 'projectTemplate') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.TEMPLATE;
        }

        return {
            activityFrameId,
            activityFrameType,
        };
    };

    onCategoriesTreeChanged = (selectedKeys) => {
        const { locationPathname, locationSearchParams } = this.props;
        const params = {
            ...Object.fromEntries(new URLSearchParams(locationSearchParams).entries()),
            categoryIds: selectedKeys.join(';'),
            page: 1,
        };
        this.props.push(getPathFromState(locationPathname, '', params));
        setGridStorageDataFilters(this.props.gridName, params);
        this.clearSelection();
    };

    render() {
        console.log('ElementsList render()', this.props);
        let { entities, operationsLoading, filteredCount, canViewFinancialData } = this.props;

        let canGenerateEstimate = false;
        if ((this.props.parentType === 'project' || this.props.parentType === 'operation') && this.props.parentId)
            canGenerateEstimate = true;
        if (this.props.operationEntityType === 'projectTemplate' || this.props.operationEntityType === 'shipping')
            canGenerateEstimate = false;
        let canGenerateAct = canGenerateEstimate;

        let excludeColumns = this.props.excludeColumns
            ? this.props.excludeColumns
            : this.props.parentType === 'project'
            ? ['lastOperationId']
            : this.props.parentType === 'projectTemplate'
            ? ['problemSeverity', 'rentTerms.rentPeriodStartDate', 'rentTerms.rentPeriodEndDate']
            : [];
        excludeColumns = [...excludeColumns];
        if (this.props.parentType !== 'shipping') excludeColumns.push('subrentWorkedShifts.shiftCount');

        if (
            this.props.parentType === 'shipping' ||
            (this.props.parentType === 'operation' && this.props.operationEntityType === 'shipping')
        ) {
            excludeColumns.push('subrentedInstanceCount');
        }

        let filtersExcludeFields = this.props.excludeFields
            ? this.props.excludeFields
            : this.props.parentType === 'projectTemplate'
            ? ['problem', 'startDate', 'endDate']
            : undefined;
        filtersExcludeFields = [...(filtersExcludeFields || [])];

        if (!canViewFinancialData) {
            excludeColumns.push('effectivePrice', 'rentTerms.discount', 'finalInstancePrice', 'finalTotalPrice');
            filtersExcludeFields.push('discount', 'finalTotalPrice');
        }

        return this.initialValues ? (
            <Spin
                spinning={operationsLoading || this.state.operationIsLoading || this.state.loading}
                delay={this.state.operationIsLoading || this.state.loading ? 0 : undefined}
            >
                <Card bordered={false}>
                    <Grid
                        filtersData={filters}
                        filtersInitialValues={this.initialValues}
                        filtersCurrentValues={this.props.pageParams}
                        filtersDefaultValues={initialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersResetFiltersCb={this.resetFilters}
                        filtersExcludeFields={filtersExcludeFields}
                        excludeColumns={excludeColumns}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onRowAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={this.props.pageParams.limit}
                        currentPage={this.props.pageParams.page}
                        columns={columns}
                        data={entities}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        categoriesFilter
                        categoriesComponent={GridCategoriesTree}
                        categoriesType={'PRODUCT'}
                        categoriesFilterOffsetBottom={131}
                        categoriesSelectedKeys={
                            this.props.categoryIds
                                ? this.props.categoryIds.split(';')
                                : this.props.pageParams.categoryIds
                                ? (this.props.pageParams.categoryIds + '').split(';')
                                : undefined
                        }
                        categoriesOnSelect={this.onCategoriesTreeChanged}
                        entityType={'element'}
                        rowPopoverComponent={ElementsElementPopover}
                        defaultSorted={this.props.pageParams.sortBy}
                        defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                        hideArchive={this.props.pageParams.hideInactive}
                        gridName={this.props.gridName}
                        exportBlock={
                            <>
                                <ElementsAggregationsPopover entityType={this.props.parentType} />
                                {canGenerateAct ? (
                                    <ExportActPopover onDownload={this.generateActCb} options={this.props.exportActOptions} />
                                ) : null}
                                {canGenerateEstimate ? (
                                    <ExportEstimatePopover
                                        onDownload={this.generateEstimateCb}
                                        options={this.props.exportEstimateOptions}
                                    />
                                ) : null}
                                {
                                    <ExportToExcellPopover
                                        storageKey={'elementsExcell'}
                                        onDownload={this.getExportSettings}
                                        options={[
                                            {
                                                key: ExportToExcellPopoverOptions.EXPAND_KITS,
                                                label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
                                            },
                                            {
                                                key: ExportToExcellPopoverOptions.ALL_COLUMNS,
                                                label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_COLUMNS),
                                            },
                                        ]}
                                    />
                                }
                            </>
                        }
                        onPageSelect={[5, 10, 20, 50, 100, 200, 500]}
                    />
                </Card>

                {this.state.selectedRow &&
                    (this.state.selectedRow.actionType === ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT ||
                        this.state.selectedRow.actionType === ActionTypeEnum.CREATE_LOSS_INVENTORY_MOVEMENT) && (
                        <InventoryMovementCreateModal
                            eventType={
                                this.state.selectedRow.actionType === ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT
                                    ? InventoryMovementEventTypeCodeEnum.DAMAGE
                                    : InventoryMovementEventTypeCodeEnum.LOSS
                            }
                            rentElement={this.state.selectedRow.row}
                            onClose={(success) => {
                                this.setState({ selectedRow: null });
                                if (success) this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentType);
                            }}
                        />
                    )}
            </Spin>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => {
    let pageParams = getStateFromPath(storeState.router.location.search),
        categoryIds = pageParams['categoryIds'] || undefined;

    return {
        entities: storeState.elements.entities,
        operationsLoading: storeState.elements.loading,
        operation: storeState.operation.entity,
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        categoryIds: categoryIds,
        pageParams: storeState.elements.params,
        filteredCount: storeState.elements.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        operationTypeCode: storeState.operationForm.typeCode,
        userPermissions: storeState.permissions.permissions,
        projectStatusWasChangedFlag: storeState.project.statusWasChangedFlag,
        operationCorrectionTypeCode: storeState.operationForm.targetStateCode,
        operationRentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
        operationRentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
        projectStateCode:
            storeState.operation.entity?.projectStateCode ||
            storeState.operation.entity?.subrentStateCode ||
            storeState.project.entity?.stateCode ||
            storeState.subrent.entity?.stateCode,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    loadEntities,
    exportExcel,
    resetElements,
    resetElement,
    push,
    replace,
    startNewOperation,
    resetStatusWasChangedFlag,
    addElement,
    fullResetOperation,
    removeConcurrentOperation,
    loadCategories,
    updatePriceFromCatalog,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ElementsList = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);
