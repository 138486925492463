import { FAILURE, REQUEST, SUCCESS } from './action-type.util';
import { RolesAndPermissionsObj, serverApi, ServerError } from '../../server';
import { getServerError } from '../util/utils';
import { UserPermissionEnum } from '../UserPermission';
import { IRootState } from './index';
import { canUpdateFinancialData, canViewFinancialData } from '../util/permissionUtils';

export const ACTION_TYPES = {
    GET_CURRENT_ROLES_AND_PERMISSIONS: 'permissions/GET_CURRENT_ROLES_AND_PERMISSIONS',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    permissions: [] as UserPermissionEnum[],
    loadingLastTime: undefined as undefined | Date,
};

export type PermissionsState = Readonly<typeof initialState>;

// Reducer

export default (state: PermissionsState = initialState, action): PermissionsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_CURRENT_ROLES_AND_PERMISSIONS):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
                loadingLastTime: new Date(),
            };

        case FAILURE(ACTION_TYPES.GET_CURRENT_ROLES_AND_PERMISSIONS):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
                loadingLastTime: undefined,
            };

        case SUCCESS(ACTION_TYPES.GET_CURRENT_ROLES_AND_PERMISSIONS):
            let data = action.payload.data as RolesAndPermissionsObj;
            let permissions = [...data.globalPermissions, ...(data.businessAccountPermissions || [])];
            return {
                ...state,
                permissions: permissions,
                loading: false,
                loadingLastTime: new Date(),
            };

        default:
            return state;
    }
};

// Actions

// Получение прав
export const getCurrentRolesAndPermissions = (businessAccountId: number) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.GET_CURRENT_ROLES_AND_PERMISSIONS,
            payload: serverApi.getCurrentRolesAndPermissions(businessAccountId),
        });
    };
};

export const canViewFinancialDataSelector = (state: IRootState) => canViewFinancialData(state.permissions.permissions);

export const canUpdateFinancialDataSelector = (state: IRootState) => canUpdateFinancialData(state.permissions.permissions);