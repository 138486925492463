import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from '../../../../../components/popover/Popover';
import './SelectProjectPopover.less';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { Button } from 'antd';
import { LocalizationEnum, localize } from '../../../../../localization';
import { ServerUtils } from '../../../../../core/utils/serverUtils';
import { ProjectRecord, ProjectStateCodeEnum } from '../../../../../server';
import ReactDOM from 'react-dom';
import { useCustomChangeData } from './hooks/useCustomChangeData';

interface SelectProjectPopoverProps {
    closeParentElement: () => void;
    stateCodes: {
        active: boolean;
        finished: boolean;
    };
    projectId?: number;
    copy: boolean;
    onOkCallback: (targetProject: ProjectRecord) => void;
}

export const SelectProjectPopover: FC<SelectProjectPopoverProps> = memo((props) => {
    const { children, projectId, stateCodes, copy, closeParentElement, onOkCallback } = props;
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState<ProjectRecord | undefined>(undefined);
    const selectRef = useRef(null);

    const onVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
        if (!visible) setValue(undefined);
    }, []);

    let filters = ServerUtils.createRequestFilters<ProjectRecord>([
        ['archive', 'EQ', false],
        [
            'stateCode',
            'IN',
            [ProjectStateCodeEnum.NEW, ProjectStateCodeEnum.FINISHED, ProjectStateCodeEnum.INPROGRESS, ProjectStateCodeEnum.APPROVED],
        ],
    ]);

    const onChange = useCallback((value?: { data: ProjectRecord }) => {
        setValue(value?.data);
    }, []);

    const onOk = useCallback(() => {
        if (value == null) return;

        onOkCallback(value);
        closeParentElement?.();
        setValue(undefined);
        onVisibleChange(false);
    }, [closeParentElement, onOkCallback, onVisibleChange, value]);

    const customChangeData = useCustomChangeData(stateCodes, copy, projectId);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(selectRef.current);
                if (node != null) {
                    const event = new MouseEvent('click', { bubbles: true });
                    node.dispatchEvent(event);
                }
            }, 250);
        }
    }, [visible]);

    return (
        <Popover
            visible={visible}
            onVisibleChange={onVisibleChange}
            headerClassName={'select-project-popover-header'}
            overlayClassName={'select-project-popover-overlay'}
            header={
                <>
                    <EntityRemoteSelect
                        _ref={selectRef}
                        style={{ width: '100%', maxWidth: 314 }}
                        placeholder={localize(LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__PLACEHOLDER__SELECT_PROJECT)}
                        nameField={'name'}
                        onChange={onChange}
                        className={'rr-select-gray'}
                        showSearch
                        operationName={'listProjects'}
                        value={value as any}
                        filters={filters}
                        sortOrder={'DESC'}
                        sortBy={'lastActivityDate'}
                        customChangeData={customChangeData as any}
                    />
                    <Button className={'rr-btn-blue'} style={{ marginLeft: 8 }} onClick={onOk} disabled={!value}>
                        OK
                    </Button>
                </>
            }
        >
            {children}
        </Popover>
    );
});
