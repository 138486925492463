import { FormItemType } from '../../../components/dynamicForm/DynamicForm';
import { FiltersDateTimeRangePicker } from '../../../components/datePicker/FiltersDateTimeRangePicker';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum } from '../../../localization';
import { ProblemsFilter, ProblemsFilterProps } from '../../../components/select/problemsFilter';
import { SliderRangeWithManualInput } from '../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { StatusesFilter, StatusesFilterProps } from '../../../components/select/StatusesFilters/v1/StatusesFilter';
import { TagSelect } from '../../../components/select/TagSelect/TagSelect';
import { TagEntityTypeCode } from '../../../server';

export const filters = [
    {
        fields: [
            {
                id: 'rentOrSubrent',
                type: FormItemType.Hidden,
            },
            {
                id: 'parentEntityType',
                type: FormItemType.Hidden,
            },
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Component,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                component: StatusesFilter,
                componentProps: (form: WrappedFormUtils): StatusesFilterProps => {
                    return {
                        type: form.getFieldValue('parentEntityType'),
                        getPopupContainer: () => document.body,
                    };
                },
            },
            {
                label: 'Теги',
                placeholder: 'Любые',
                id: 'tags',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: TagSelect,
                componentProps: {
                    tagType: TagEntityTypeCode.RENTELEMENT,
                },
            },
            {
                label: 'Скрыть',
                id: 'hide',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                values: (form: WrappedFormUtils) => {
                    if (form.getFieldValue('parentEntityType') === 'project' || form.getFieldValue('parentEntityType') === 'shipping') {
                        return [
                            { name: 'Неактивные', value: 'inactive' },
                            { name: 'Отмененные', value: 'cancelled' },
                            { name: 'Отмененные и черновики', value: 'cancelled+draft' },
                        ];
                    } else if (form.getFieldValue('parentEntityType') === 'projectTemplate') {
                        return [{ name: 'Отмененные', value: 'cancelled' }];
                    } else {
                        return [
                            { name: 'Неактивные', value: 'inactive' },
                            { name: 'Отмененные', value: 'cancelled' },
                            { name: 'Отмененные и черновики', value: 'cancelled+draft' },
                            { name: undefined, value: undefined },
                            { name: 'Все по аренде', value: 'rent' },
                            { name: 'Все по субаренде', value: 'subrent' },
                        ];
                    }
                },
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: form.getFieldValue('rentOrSubrent'),
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__START_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__END_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'endDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT,
                id: 'discount',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'percents',
                    min: 0,
                    max: 100,
                    step: 1,
                },
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT,
                id: 'shiftCount',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'number',
                    min: 0,
                    max: 100,
                    step: 1,
                },
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE,
                id: 'finalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
        ],
    },
];
