import React, { ComponentProps, FC } from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { CalendarLine } from '../../../types/items';

interface ElementsCountProps extends ComponentProps<'span'> {
    items: CalendarLine[];
    filteredCount: number;
}

export const ElementsCount: FC<ElementsCountProps> = (props) => {
    const { items, filteredCount, ...spanProps } = props;

    return (
        <span {...spanProps}>
            {items.length > 0 ? (
                <>
                    {localize(LocalizationEnum.PAGE__CALENDAR__ROWS_PER_PAGE)}{' '}
                    <span className="visible-items-count">
                        1-
                        {
                            items.filter((item) => {
                                if (item.elementType === 'group') return false;
                                if (item.elementType === 'activityProject' || item.elementType === 'activitySubrent') return true;
                                if (item.elementType === 'rent' || item.elementType === 'subrent') return !item.isKitChild;

                                return true;
                            }).length
                        }{' '}
                        {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)} {filteredCount}
                    </span>
                </>
            ) : (
                localize(LocalizationEnum.ASPECT__GRID__EMPTY)
            )}
        </span>
    );
};
