import React, {ReactNode} from "react";
import {Icon, notification} from "antd";
import {IconBarcodeSolid} from "../../components/icons";
import classNames from "classnames";
import {ScanError, ScanErrorType, ScanSuccessMessageType} from "../reducers/scanner.reducer";
import {LocalizationEnum, localizeIntl} from "../../localization";
import {getIntl} from "../../App";

export const showBarcodeScannerSuccessNotification = (type: ScanSuccessMessageType, count: number, key: string, onClose: () => void) => {
    notification.success({
        className: classNames('rr-scanner-notice', 'rr-scanner-success-notice'),
        message: printScannerSuccessMessage(type, count),
        duration: 15,
        key: key,
        onClose: onClose,
        icon: <Icon component={IconBarcodeSolid}/>,
    });
};

export const showBarcodeScannerErrorNotification = (errors: ScanError[], onClose: () => void) => {
    const intl = getIntl();
    let titleMessage;
    if (errors.length > 1) titleMessage = `${errors.length} ${(localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__SCAN_ERROR, undefined, {value: errors.length}) as string).toLowerCase()}`;
    else titleMessage = localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__SCAN_ERROR, undefined, {value: 1}) as string || '';
    const title = <span style={{fontWeight: 800}}>{titleMessage}</span>;
    const content = (
        <>
            {errors.map((error, index, arr) => <div
                style={{marginBottom: index < arr.length - 1 ? 8 : undefined}}>{printScannerError(error)}</div>)}
        </>
    );

    notification.error({
        className: classNames('rr-scanner-notice', 'rr-scanner-error-notice', errors.length > 1 && 'rr-scanner-error-notice-multiple'),
        message: title,
        duration: null,
        key: 'barcodeScannerErrorNotification',
        onClose,
        icon: <Icon component={IconBarcodeSolid}/>,
        description: content,
    });

    const el = document.querySelector('.ant-notification-notice-description');
    if (el) el.scrollTo(0, el.scrollHeight);
};

const printScannerError = (scanError: ScanError): ReactNode => {
    const printName = (name: string, variantName?: string): ReactNode => {
        return <span style={{fontWeight: 800}}>{name}{variantName ? `: ${variantName}` : ''}</span>;
    }

    switch (scanError.type) {
        case ScanErrorType.LoadingError: {
            return `Не удалось загрузить данные`;
        }
        case ScanErrorType.CodeNotFound: {
            return <>Штриховой код {printName(scanError.code)} не найден в системе</>;
        }
        case ScanErrorType.ScanningOnAddedTabError: {
            return `Сканирование на вкладке "Добавлено" поддерживает только замену анонимных экземпляров именованными`;
        }
        case ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded: {
            return <>Вся найденная в текущей выборке номенклатура {printName(scanError.productName, scanError.variantName)} уже
                добавлена</>;
        }
        case ScanErrorType.NomenclatureNotFoundInCurrentSelection: {
            return <>Номенклатура {printName(scanError.productName, scanError.variantName)} не найдена в текущей выборке</>;
        }
        case ScanErrorType.NomenclatureInInactiveStatus: {
            return <>Номенклатура {printName(scanError.productName, scanError.variantName)} в неактивном статусе</>;
        }
        case ScanErrorType.NomenclatureNotAvailable: {
            return <>Номенклатура {printName(scanError.productName, scanError.variantName)} недоступна</>;
        }
        case ScanErrorType.NomenclatureWithAnonymousInstanceNotFound: {
            return <>Номенклатура {printName(scanError.productName)} с анонимным экз. не найдена</>;
        }
        case ScanErrorType.NomenclatureNotFound: {
            return <>Номенклатура {printName(scanError.productName)} не найдена</>;
        }
        case ScanErrorType.InstanceAlreadyAdded: {
            return <>Экземпляр {printName(scanError.instanceName)} уже добавлен</>;
        }
        case ScanErrorType.InstanceAndNomenclatureNotFoundInProject: {
            return <>Экземляр {printName(scanError.instanceName)} и его
                номенклатура {printName(scanError.productName, scanError.variantName)} не найдены в проекте</>;
        }
        case ScanErrorType.ProductAddingNotPossible: {
            return <>Добавление продукта {printName(scanError.productName)} невозможно, отсканируйте нужный вариант</>;
        }
    }
    return '';
};

const printScannerSuccessMessage = (type: ScanSuccessMessageType, count: number): ReactNode => {
    const intl = getIntl();
    switch (type) {
        case ScanSuccessMessageType.NomenclatureAdded:
        case ScanSuccessMessageType.InstanceAdded: {
            let message;
            if (type === ScanSuccessMessageType.NomenclatureAdded) message = localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__INSTANCE_ADDED, undefined, {value: count});
            else if (type === ScanSuccessMessageType.InstanceAdded) message = localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__NOMENCLATURE_ADDED, undefined, {value: count});
            return (
                <>
                    <span style={{fontWeight: 800}}>{count}</span>{' '}{message}
                </>
            );
        }
    }
    return '';
};
