import { CONTENT_SERVER_BASE_PATH } from '../../config/config';
import {
    AvailableFilterValuesObj,
    CategoryTreeNode,
    ImageObj,
    PersonFullName,
    ServerError,
    ShiftCountRoundingTypeCodeEnum,
} from '../../server';
import moment from 'moment';
import { LocalizationEnum, localize } from '../../localization';
import pull from 'lodash/pull';
import { isNumber } from './isNumber';
import { ProblemEnum } from '../../types';
import { ProductCategory } from '../reducers/entities.reducer';
import _ from 'lodash';

export const printUserName = (data?: PersonFullName, withSecondName: boolean = false) => {
    let name = '';
    if (data) {
        if (data.lastname) name += data.lastname;
        if (data.firstname) name += (name ? ' ' : '') + data.firstname;
        if (withSecondName && data.secondname) name += (name ? ' ' : '') + data.secondname;
    }
    return name;
};

/**
 *
 * @param locationSearch
 * @param params
 */
export const getStateFromPath = (locationSearch: string, params: { [k: string]: any } | undefined = undefined): { [k: string]: any } => {
    let searchParams: URLSearchParams = new URLSearchParams(locationSearch);
    let state = {};
    searchParams.forEach((value, key) => {
        let newValue: any = value;
        if (value === 'false') newValue = false;
        else if (value === 'true') newValue = true;
        else if (value === 'undefined') newValue = undefined;
        state[key] = newValue;
    });
    if (params) return { ...state, ...params };
    return state;
};

export const getStateFromPath2 = (locationSearch: string, params: { [k: string]: any } | undefined = undefined): { [k: string]: any } => {
    let searchParams: URLSearchParams = new URLSearchParams(locationSearch);
    let state = {};
    searchParams.forEach((value, key) => {
        let newValue: any = value;
        if (value === 'false') newValue = false;
        else if (value === 'true') newValue = true;
        else if (value === 'undefined') newValue = undefined;
        else if (!isNaN(value as any) && key !== 'search') newValue = +value;

        state[key] = newValue;
    });
    if (params) return { ...state, ...params };
    return state;
};

export const getPathFromState = (path: string, locationSearch: string, params: { [k: string]: any } | undefined = undefined) => {
    let searchParams: URLSearchParams = new URLSearchParams(locationSearch);
    if (params) {
        Object.keys(params).forEach(function (key) {
            let value = params[key];
            if (value !== undefined && value !== '') {
                searchParams.set(key, value);
            } else {
                searchParams.delete(key);
            }
        });
    }
    return path + (searchParams.toString() ? '?' + searchParams.toString() : '');
};

export const getFilteredParams = <Params extends Record<string, any>, FilterValue>(
    params: Params = {} as Params,
    filterValue: FilterValue
) => {
    const filteredParams: Partial<Params> = { ...params };
    let deletedCount = 0;

    for (const key in filteredParams) {
        if (_.isEqual(filteredParams[key], filterValue)) {
            filteredParams[key] = undefined;
            deletedCount++;
        }
    }

    return {
        filteredParams,
        deletedCount,
    };
};

export const test1 = (str: string, defaultValue: any): number[] => {
    if (str === undefined || str === 'undefined') return defaultValue;
    return str.split(',').map((item) => +item);
};

export const test2 = (str: string, defaultValue: any): string[] => {
    if (str === undefined || str === 'undefined') return defaultValue;
    return str.split(',').map((item) => item);
};

export const getDatesFromParamsString = (str: string, defaultValue: any): number[] | undefined => {
    let res = defaultValue;
    if (str) {
        str = '' + str;
        const lastActivityDate = str
            .split(',')
            .map((item) => (item ? moment(+item).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf() : undefined));
        if (lastActivityDate[1]) lastActivityDate[1] += 24 * 60 * 60 * 1000 - 1;
        if (!lastActivityDate[0] && !lastActivityDate[1]) res = undefined;
        else if (!lastActivityDate[1]) res = [lastActivityDate[0]];
        else res = lastActivityDate;
    }
    return res;
};

export const getDatesWithTimeFromParamsString = (str: string, defaultValue: any): number[] | undefined => {
    let res = defaultValue;
    if (str) {
        str = '' + str;
        const lastActivityDate = str.split(',').map((item) => (item ? moment(+item).valueOf() : undefined));
        if (!lastActivityDate[0] && !lastActivityDate[1]) res = undefined;
        else if (!lastActivityDate[1]) res = [lastActivityDate[0]];
        else res = lastActivityDate;
    }
    return res;
};

/**
 * Метод, возвращает строку для отправки на сервер типа проблемы
 * @param problem
 */
export const getStringServerProblem = (problem: string): string => {
    if (problem === ProblemEnum.RETURN_DELAY) {
        return 'problemsAndWarnings.returnDelay;EQ;true';
    } else if (problem === ProblemEnum.GIVEAWAY_DELAY) {
        return 'problemsAndWarnings.giveawayDelay;EQ;true';
    } else if (problem === ProblemEnum.BOOK_SHORTAGE) {
        return 'problemsAndWarnings.bookShortage;EQ;true';
    } else if (problem === ProblemEnum.ORDER_SHORTAGE) {
        return 'problemsAndWarnings.orderShortage;EQ;true';
    } else if (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY) {
        return 'problemsAndWarnings.subrentShipmentDelay;EQ;true';
    } else if (problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY) {
        return 'problemsAndWarnings.subrentReturnToShipperDelay;EQ;true';
    } else if (problem === ProblemEnum.ANY_DELAY) {
        return 'problemsAndWarnings.anyDelay;EQ;true';
    } else if (problem === ProblemEnum.ANY_SHORTAGE) {
        return 'problemsAndWarnings.anyShortage;EQ;true';
    } else if (problem === ProblemEnum.ANY_PROBLEM) {
        return 'problemsAndWarnings.severity;GT;4';
    } else if (problem === ProblemEnum.ANY_PROBLEM_OR_WARNING) {
        return 'problemsAndWarnings.severity;GT;0';
    } else return '';
};

/**
 * Метод, возвращает путь к картинке
 * @param image
 * @param size
 */
export const getImagePath = (image?: ImageObj, size: 'original' | '40' | '158' | '-x200' | '-x400' = 'original'): string => {
    return image && image.externalId ? `${CONTENT_SERVER_BASE_PATH}/images/${size}/${image.externalId}` : '';
};

export const getServerError = (err) => {
    if (!err) return;

    console.dir(err);

    let status;
    let title = localize(LocalizationEnum.ASPECT__GLOBAL__ERROR);
    let message = err && err.message ? err.message : undefined;

    if (err && err.response && err.response.status) status = err.response.status;
    if (err && err.response && err.response.data && err.response.data.message) message = err.response.data.message;

    if (message === 'Network Error') {
        title = localize(LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__CONNECTION_ERROR);
        if (window.navigator.onLine) {
            message = 'Cервер недоступен'; //localize(LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__NO_INTERNET_OR_SERVER_UNAVAILABLE); // Сервер
        } else {
            message = 'Нет подключения к интернету'; //localize(LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__NO_INTERNET_OR_SERVER_UNAVAILABLE); // Инет
        }
    }

    if ((status === 404 || status === 409) && err.response && err.response.data && err.response.data.title) {
        title = err.response.data.title;
    }

    if (status === 500) {
        title = localize(LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__INTERNAL_SERVER_ERROR);
    }

    console.log('status=', status);
    console.log('title=', title);
    console.log('message=', message);

    // Когда нет инета,то response == undefined

    let err1: ServerError = { status, message, title };

    return err1;
};

export const categoriesToTreeFormat = (items: CategoryTreeNode[]) => {
    let arr: ProductCategory[] = [];
    for (let i = 0; i < items.length; ++i) {
        let item = items[i];
        arr.push({
            key: item.id,
            title: item.name,
            value: item.id,
            siblingOrder: item.siblingOrder,
            children: item.subcategories ? categoriesToTreeFormat(item.subcategories) : [],
        });
    }
    return arr;
};

export const findCategoryById = (data: ProductCategory[], id: number): ProductCategory | undefined => {
    for (let node of data) {
        if (node.key === id) return node;
        if (node.children) {
            let desiredNode = findCategoryById(node.children, id);
            if (desiredNode) return desiredNode;
        }
    }
    return undefined;
};

export const findParentCategoriesById = (
    data: ProductCategory[],
    id: number,
    parent: ProductCategory[] = []
): ProductCategory[] | undefined => {
    for (let node of data) {
        if (node.key === id) return parent;
        if (node.children) {
            parent.push(node);
            let desiredNode = findParentCategoriesById(node.children, id, parent);
            if (desiredNode) return parent;
        }
    }
    parent.splice(parent.length - 1, 1);
    return undefined;
};

export const getCategoriesChildIds = (categories: ProductCategory[], keys: number[] = []) => {
    categories.forEach((category) => {
        if (category.children) {
            category.children.forEach((childCategory) => {
                keys.push(childCategory.key);
                if (childCategory.children) {
                    getCategoriesChildIds(childCategory.children, keys);
                }
            });
        }
    });
    return keys;
};

//
export const convertAvailableFiltersToObj = (filters: Array<AvailableFilterValuesObj>): { [key: string]: [number, number] } => {
    let obj: { [key: string]: [number, number] } = {};
    filters.forEach((item) => {
        obj[item.fieldName] = [item.minLong || 0, item.maxLong || 0];
    });
    return obj;
};

/**
 * Вызывается cb, как только getValue вернет не нулевое значение
 * @param getValue
 * @param cb
 * @param interval
 */
export const cbOnValueDefined = (getValue: () => any, cb: () => void, interval: number = 50) => {
    if (getValue()) {
        cb();
    } else {
        let intervalId = setInterval(() => {
            if (getValue()) {
                cb();
                clearInterval(intervalId);
            }
        }, interval);
    }
};

/**
 *
 * @param value
 */
export function isDefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
}

/**
 *
 * @param data
 * @param items
 */
export function removeItemsFromArray<T>(data: T[], ...items: T[]): T[] {
    return pull(data, ...items);
}

export const getNumberFromString = (value: string, defaultValue: number, onlyPositive: boolean = true): number => {
    if (isNumber(value) && (!onlyPositive || (onlyPositive && +value >= 0))) {
        return +value;
    } else {
        return defaultValue;
    }
};

export const getStringFromString = (value: string, defaultValue: string | undefined): string | undefined => {
    if (isDefined(value)) {
        return '' + value;
    } else {
        return defaultValue;
    }
};

export const xxxParams = <T>(params: T, newParams: { [k: string]: any }, initialParams: T): T => {
    params = { ...params };

    for (let k in params) {
        params[k] = newParams[k] !== undefined ? newParams[k] : initialParams[k];
    }

    for (let k in newParams) {
        params[k] = newParams[k] !== undefined ? newParams[k] : initialParams[k];
    }

    return params;
};

export const getShiftCountFromDates = (
    start: moment.Moment,
    end: moment.Moment,
    shiftLengthinMin: number | undefined,
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum | undefined
): number => {
    start = start.clone().seconds(0).milliseconds(0);
    end = end.clone().seconds(0).milliseconds(0);
    if (shiftLengthinMin === undefined) shiftLengthinMin = 24 * 60;
    let diff = end.diff(start) / 1000 / 60 / shiftLengthinMin;
    diff = roundShiftCount(diff, shiftCountRoundingType);
    if (diff <= 0) diff = 0;
    return diff;
};

export const roundShiftCount = (
    shiftCount: number | undefined,
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum | undefined
): number => {
    if (shiftCount === undefined) shiftCount = 0;
    if (shiftCountRoundingType === ShiftCountRoundingTypeCodeEnum.UP) shiftCount = Math.ceil(shiftCount);
    else if (shiftCountRoundingType === ShiftCountRoundingTypeCodeEnum.DOWN) shiftCount = Math.floor(shiftCount);
    else if (shiftCountRoundingType === ShiftCountRoundingTypeCodeEnum.FRACTIONSECONDSIGNROUNDHALFUP)
        shiftCount = Math.round(shiftCount * 100) / 100;
    else if (shiftCountRoundingType === ShiftCountRoundingTypeCodeEnum.ROUNDHALFUP) shiftCount = Math.round(shiftCount);
    return shiftCount;
};

export const joinClasses = (...classes: (string | undefined)[]): string | undefined => {
    let res = classes.filter((style) => style).join(' ');
    return res ? res : undefined;
};

/**
 * Для нижней Math.floor(): 100.99 -> 100 и -100.01 -> -101
 * @param value
 */
export const roundMinFilterValue = (value: number): number => {
    return Math.floor(value);
};

/**
 * Для верхней Math.ceil(): 100.01 -> 101 и -100.99 -> -100
 * @param value
 */
export const roundMaxFilterValue = (value: number): number => {
    return Math.ceil(value);
};

/**
 * Проверяет, принадлежит ли строка к заданному enum и возвращает соответствующее значение
 *
 * В случае ненахождения, возвращает значение из enum
 *
 * @template T - Тип enum.
 * @param {string} inputString - Проверяемая строка.
 * @param {T} enumType - Enum, к которому выполняется проверка.
 * @param {T[keyof T]} defaultEnumValue - Значение enum по умолчанию, которое возвращается, если строка не принадлежит enum.
 */
export const isStringInEnum = <T extends object>(
    inputString: string | undefined | null,
    enumType: T,
    defaultEnumValue: T[keyof T]
): T[keyof T] => {
    if (inputString == null) return defaultEnumValue;

    const enumValues = Object.values(enumType);
    if (enumValues.includes(inputString)) {
        return inputString as T[keyof T];
    } else {
        return defaultEnumValue;
    }
};

export const checkRecordField = <T extends object, K extends keyof T>(record: T, field: K): record is T & Required<Pick<T, K>> => {
    return Boolean(record[field]);
};

export const filterRecordsByField = <T extends object, K extends keyof T>(records: T[], field: K) => {
    return records.filter((record): record is T & Required<Pick<T, K>> => checkRecordField(record, field));
};
