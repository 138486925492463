import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { ActivityFramesInnerContent } from './ActivityFramesInnerContent';
import { ActivityFrameItemContext } from '../../../../../../../../context/CalendarItemContext';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { CALENDAR_LINE_BASE_RADIUS, CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';

interface ActivityFramesContentProps {
    sortByValue?: string;
}

export const ActivityFramesContent: FC<ActivityFramesContentProps> = (props) => {
    const { sortByValue } = props;
    const { item, itemContext } = useContext(ActivityFrameItemContext);
    const {
        elementType,
        record: { stateCode },
    } = item;

    const lineWidth = itemContext.dimensions.width;

    const backgroundColor = elementType === 'activitySubrent' ? undefined : CalendarColors.activityFramesStateCodeMap[stateCode];
    const textColor = elementType === 'activitySubrent' ? CalendarColors.staticColors.TEXT_COLOR : '#fff';

    return (
        <div
            className={classNames('line-main-block-container')}
            style={{
                background: backgroundColor,
                color: textColor,
                borderRadius: elementType === 'activitySubrent' ? CALENDAR_LINE_ROUND_RADIUS : CALENDAR_LINE_BASE_RADIUS,
            }}
        >
            <span
                className={classNames('line-main-block', 'forward')}
                style={{
                    width: lineWidth,
                    clipPath: `inset(0 calc(100% - ${lineWidth}px) 0 0)`,
                }}
            >
                {<ActivityFramesInnerContent backgroundColor={backgroundColor} sortByValue={sortByValue} />}
            </span>
            <span
                className={classNames('line-main-block', 'backward')}
                style={{
                    clipPath: `inset(0 0 0 ${lineWidth}px)`,
                }}
            >
                {<ActivityFramesInnerContent backgroundColor={backgroundColor} sortByValue={sortByValue} />}
            </span>
        </div>
    );
};
