import React, {FC, useState} from 'react';
import moment, {Moment} from "moment";
import {Button, Icon} from "antd";
import {IconCrosshairsSolid} from "../icons";
import {TimeRangePickerPanel} from "../timeRangePicker/timeRangePickerPanel";
import {TimeInput} from "./timeInput";
import {LocalizationEnum, localize} from "../../localization";
import Trigger from 'rc-trigger';

interface DateTimePickerFooterProps {
    onSelectedDateChange: (value: Moment | null)=>void;
    onCalendarClose: ()=>void;
    currentDisplayValue?: Moment|undefined;
    getCurrentDate?: () => Moment;
    onChange?: (date: moment.Moment | null, dateString: string) => void;
    selectedDate: Moment | null;
}

const changeOnOk = false;

export const DateTimePickerFooter:FC<DateTimePickerFooterProps> = ({onSelectedDateChange, onCalendarClose, currentDisplayValue, getCurrentDate, onChange, selectedDate}) => {

    const [timePickerVisible, setTimePickerVisible] = useState(false);

    return (
        <>
                            <span
                                className={'rr-dateTimePicker-today-btn'}
                                onClick={(e) => {
                                    const date = getCurrentDate?.() || moment().milliseconds(0).seconds(0);
                                    // this.setState({
                                    //     selectedDate: date,
                                    // });
                                    onSelectedDateChange(date)
                                    //if (!changeOnOk && this.props.onChange) {
                                        onChange?.(date, '');
                                    //}
                                }}
                            >
                                <Icon component={IconCrosshairsSolid} style={{ color: '#00afee', fontSize: 21, marginRight: 5 }} />
                                Сейчас
                            </span>
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Trigger
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
                    popupTransitionName={'slide-up'}
                    destroyPopupOnHide
                    stretch={['width']}
                    popupVisible={timePickerVisible}
                    onPopupVisibleChange={(visible) => {
                        setTimePickerVisible(visible);
                    }}
                    popupAlign={{ points: ['tc', 'bc'], offset: [0, -314] }}
                    action={['click']}
                    popup={
                        <TimeRangePickerPanel
                            value={currentDisplayValue}
                            minutesStep={1}
                            onChange={(value) => {}}
                            onSelect={(value) => {
                                const date = value.milliseconds(0).seconds(0);
                                // this.setState({
                                //     selectedDate: date,
                                // });
                                onSelectedDateChange(date)
                                //if (!changeOnOk && this.props.onChange) {
                                    onChange?.(date, '');
                                //}
                            }}
                            onOk={() => {
                                // клик по кнопке ОК
                                setTimePickerVisible(false);
                            }}
                            onSetCurrentTime={() => {
                                const curDate = moment().milliseconds(0).seconds(0);
                                const date =
                                    selectedDate?.clone().hours(curDate.hours()).minutes(curDate.minutes()) ||
                                    curDate;
                                // this.setState({
                                //     selectedDate: date,
                                // });
                                onSelectedDateChange(date)
                                //if (!changeOnOk && this.props.onChange) {
                                    onChange?.(date, '');
                                //}
                            }}
                        />
                    }
                >
                    <div>
                                        <span className="ant-calendar-picker-input ant-input" style={{ width: 130 }}>
                                            <TimeInput
                                                value={currentDisplayValue}
                                                onChange={(value) => {
                                                    // this.setState({
                                                    //     selectedDate: value || null,
                                                    // });
                                                    onSelectedDateChange(value || null)
                                                    onChange?.(value || null, '');
                                                }}
                                                style={{ paddingRight: 28 }}
                                            />
                                            <span className="ant-time-picker-icon" style={{ fontSize: 20 }}>
                                                <i className="anticon">
                                                    <svg viewBox="0 0 32 32" width="1em" height="1em" fill="currentColor">
                                                        <path d="M 16 4 C 9.3844277 4 4 9.3844277 4 16 C 4 22.615572 9.3844277 28 16 28 C 22.615572 28 28 22.615572 28 16 C 28 9.3844277 22.615572 4 16 4 z M 16 6 C 21.534692 6 26 10.465308 26 16 C 26 21.534692 21.534692 26 16 26 C 10.465308 26 6 21.534692 6 16 C 6 10.465308 10.465308 6 16 6 z M 15 8 L 15 16 L 15 17 L 16 17 L 22 17 L 22 15 L 17 15 L 17 8 L 15 8 z"></path>
                                                    </svg>
                                                </i>
                                            </span>
                                        </span>
                    </div>
                </Trigger>
            </div>
            <Button
                type="primary"
                onClick={() => {
                    onCalendarClose();
                }}
            >
                {localize(LocalizationEnum.ASPECT__GLOBAL__OK)}
            </Button>
        </>
    );
};
