import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { FormUtils, WrappedFormUtilsTyped } from '../../../../core/utils/formUtils';
import { LocalizationEnum } from '../../../../localization';
import React from 'react';
import { WorkPlanningCreateModalFormData } from './create/workPlanningsCreateModal.types';
import { WorkPlanningsStateBadge } from '../components/WorkPlanningsStateBadge/WorkPlanningsStateBadge';
import {
    CrewMemberRecord,
    CrewMemberStateCodeEnum,
    ProfessionRecord,
    ProfessionStateCodeEnum,
    WorkPlanningsStateCodeEnum,
} from '../../../../server';
import { ProfessionSelectProps } from '../../../../components/v2/select/professionSelect/ProfessionSelect';
import { CrewMemberSelect, CrewMemberSelectProps } from '../../../../components/v2/select/crewMemberSelect/crewMemberSelect';
import { ProfessionStateBadge } from '../../crew/tabs/professions/components/professionStateBadge';
import { PriceBlock, PriceBlockProps } from './priceBlock';
import { formatMoneyWithFixedDecimalScale } from '../../../../shared/util/formatMoney';
import { CrewMemberStateBadge } from '../../crew/tabs/crewMembers/components/CrewMemberStateBadge/CrewMemberStateBadge';
import { ShiftCountInput, ShiftCountInputProps } from './shiftCountInput';
import { getShiftCountFromDates, isDefined } from '../../../../shared/util/utils';
import moment, { Moment } from 'moment';
import { getStoreState } from '../../../../../index';
import { RangePicker, RangePickerProps } from './rangePicker';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../shared/util/validateField';
import { SelectItem } from '../../../../components/v2/select/selectItem';
import { Price } from './price';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { ProfessionSelectAndCreate } from '../../../../components/v2/select/professionSelect/ProfessionSelectAndCreate';
import { getWorkPlanningStateCodes } from '../utils/getWorkPlanningsStateCodes';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

const isActiveStatus = (stateCode: WorkPlanningsStateCodeEnum) => {
    return [WorkPlanningsStateCodeEnum.PLANNED, WorkPlanningsStateCodeEnum.INPROGRESS].includes(stateCode);
};

const isBlockedProfession = (profession?: ProfessionRecord) => {
    return profession?.stateCode === ProfessionStateCodeEnum.BLOCKED;
};

const isBlockedCrewMember = (crewMember?: CrewMemberRecord) => {
    return crewMember?.stateCode === CrewMemberStateCodeEnum.BLOCKED;
};

const isSomeBlockedCrewMember = (crewMembers?: CrewMemberRecord[]) => {
    return crewMembers && crewMembers.length > 0 && crewMembers.some((crewMember) => isBlockedCrewMember(crewMember));
};

export const workPlanningsModalFormFields = FormUtils.typedFormFields<WorkPlanningCreateModalFormData>([
    {
        fields: [
            {
                id: 'id',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectId',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectType',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectStateCode',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectStartDate',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectEndDate',
                type: FormItemType.Hidden,
            },
            {
                id: 'pricingScheme',
                type: FormItemType.Hidden,
            },
            {
                id: 'basePrice',
                type: FormItemType.Hidden,
            },
            {
                id: 'crewMembers',
                type: FormItemType.Hidden,
                validateInitialValue: true,
                validationFunction: (fieldName, value: any, cb: Function, form) => {
                    form.validateFields(['crewMemberIds', 'state'], { force: true });
                    return cb();
                },
            },
            {
                id: 'profession',
                type: FormItemType.Hidden,
                validateInitialValue: true,
                validationFunction: (fieldName, value: any, cb: Function, form) => {
                    form.validateFields(['professionId', 'state'], { force: true });
                    return cb();
                },
            },
            {
                label: 'Наименование',
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validateInitialValue: true,
                validationFunction: debounce(
                    (fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<WorkPlanningCreateModalFormData>) => {
                        const id = form.getFieldValue('id');
                        const projectId = form.getFieldValue('projectId');
                        validateField('workPlanning', fieldName, value, projectId, id, 'Наименование уже используется в проекте', cb);
                    },
                    500
                ),
            },
            {
                label: 'Специальность',
                id: 'professionId',
                required: true,
                type: FormItemType.Component,
                component: ProfessionSelectAndCreate,
                placeholder: 'Выберите специальность',
                validateInitialValue: true,
                componentProps: (form): ProfessionSelectProps => ({
                    showSearch: true,
                    getOptionProps: (record) => {
                        const shiftCount = form.getFieldValue('shiftCount');
                        const state = form.getFieldValue('state');

                        const p = getStoreState().permissions.permissions;
                        const _canViewFinancialData = canViewFinancialData(p);

                        return {
                            disabled:
                                (state === WorkPlanningsStateCodeEnum.PLANNED ||
                                    state === WorkPlanningsStateCodeEnum.INPROGRESS) /*state === WorkPlanningsStateCodeEnum.ACTIVE*/ &&
                                record.stateCode === ProfessionStateCodeEnum.BLOCKED,
                            label: (
                                <SelectItem
                                    state={
                                        record.stateCode &&
                                        record.stateCode === ProfessionStateCodeEnum.BLOCKED && (
                                            <ProfessionStateBadge type={record.stateCode} dot={true} hideLabel={true} />
                                        )
                                    }
                                    name={record.shortName}
                                    extra={
                                        _canViewFinancialData && (
                                            <Price
                                                pricingSchemeData={
                                                    record.pricingScheme
                                                        ? {
                                                              pricingScheme: record.pricingScheme,
                                                              basePrise: record.pricePerShift,
                                                              shiftCount: shiftCount,
                                                          }
                                                        : undefined
                                                }
                                                price={<span>{formatMoneyWithFixedDecimalScale(record.pricePerShift)} / см.</span>}
                                            />
                                        )
                                    }
                                />
                            ),
                        };
                    },
                    onDataChange: (data) => {
                        const id = form.getFieldValue('id');
                        const profession = data?.[0];
                        // Только для создания
                        // и для редактирования когда потрогали поле
                        if (!id || (id && form.isFieldTouched('professionId'))) {
                            form.setFieldsValue({
                                pricingScheme: profession?.pricingScheme,
                                basePrice: profession?.pricePerShift,
                                effectivePrice: profession?.pricePerShift,
                            });
                        }
                        form.setFieldsValue({ profession });
                        form.validateFields(['profession']);
                    },
                }),
                validationFunction: (fieldName: string, value: number | undefined, cb: Function, form) => {
                    const state = form.getFieldValue('state');
                    const profession = form.getFieldValue('profession');

                    form.validateFields(['crewMemberIds']);
                    if (isActiveStatus(state) && isBlockedProfession(profession)) {
                        return cb('Выбрана заблокированная специальность при активном статусе работ');
                    }
                    return cb();
                },
            },
            {
                label: 'Статус',
                id: 'state',
                type: FormItemType.Select,
                placeholder: 'Выберите статус',
                required: true,
                validateInitialValue: true,
                values: (form) => {
                    const id = form.getFieldValue('id');
                    const profession = form.getFieldValue('profession');
                    const crewMembers = form.getFieldValue('crewMembers');
                    const projectType = form.getFieldValue('projectType');
                    const projectStateCode = form.getFieldValue('projectStateCode');

                    const stateCodes = projectStateCode ? getWorkPlanningStateCodes(projectType, id, projectStateCode) : [];

                    return stateCodes.map((code) => {
                        return {
                            name: <WorkPlanningsStateBadge type={code} dot />,
                            value: code,
                            disabled: isActiveStatus(code) && (isBlockedProfession(profession) || isSomeBlockedCrewMember(crewMembers)),
                        };
                    });
                },
                defaultValue: WorkPlanningsStateCodeEnum.PLANNED,
                validationFunction: (fieldName: string, value: Array<number> | undefined, cb: Function, form) => {
                    const state = form.getFieldValue('state');
                    const crewMembers = form.getFieldValue('crewMembers');
                    const profession = form.getFieldValue('profession');
                    form.validateFields(['crewMemberIds', 'professionId']);

                    if (isActiveStatus(state) && (isBlockedProfession(profession) || isSomeBlockedCrewMember(crewMembers))) {
                        return cb('Выбран активный статус работ при наличии заблокированной специальности или работнике');
                    }
                    return cb();
                },
            },
            {
                label: 'Количество работников',
                id: 'numberOfCrew',
                type: FormItemType.Integer,
                required: true,
                minLength: 0,
                defaultValue: 0,
                validateInitialValue: true,
                validationFunction: (fieldName: string, value: Array<number> | undefined, cb: Function, form) => {
                    form.validateFields(['crewMemberIds']);
                    return cb();
                },
            },
            {
                label: 'Работники',
                id: 'crewMemberIds',
                type: FormItemType.Component,
                component: CrewMemberSelect,
                placeholder: 'Выберите работников',
                validateInitialValue: true,
                onChange: (value, form) => {
                    const numberOfCrew = form.getFieldValue('numberOfCrew');
                    if (value && Array.isArray(value) && numberOfCrew < value.length) {
                        form.setFieldsValue({
                            numberOfCrew: value.length,
                        });
                    }
                    form.validateFields(['state', 'crewMemberIds']);
                },
                componentProps: (form): CrewMemberSelectProps => {
                    const professionId = form.getFieldValue('professionId');
                    const state = form.getFieldValue('state');
                    return {
                        multiple: true,
                        filters: {
                            hideArchive: true,
                            professionId: professionId,
                        },
                        onDataChange: (data) => {
                            form.setFieldsValue({ crewMembers: data });
                            form.validateFields(['crewMembers']);
                        },
                        getOptionProps: (record) => {
                            return {
                                disabled:
                                    state === WorkPlanningsStateCodeEnum.INPROGRESS /*state === WorkPlanningsStateCodeEnum.ACTIVE*/ &&
                                    record.stateCode === CrewMemberStateCodeEnum.BLOCKED,
                                label: (
                                    <>
                                        {record.stateCode && record.stateCode !== CrewMemberStateCodeEnum.ACTIVE && (
                                            <CrewMemberStateBadge type={record.stateCode} dot={true} hideLabel={true} />
                                        )}
                                        {record.personShortName}
                                    </>
                                ),
                            };
                        },
                    };
                },
                validationFunction: (fieldName: string, value: Array<number> | undefined, cb: Function, form) => {
                    const professionId = form.getFieldValue('professionId');
                    const crewMembers = form.getFieldValue('crewMembers');
                    const state = form.getFieldValue('state');
                    const numberOfCrew = form.getFieldValue('numberOfCrew');
                    const crewMembersCount = value?.length || 0;

                    if (state === WorkPlanningsStateCodeEnum.INPROGRESS || state === WorkPlanningsStateCodeEnum.FINISHED) {
                        if (crewMembersCount !== numberOfCrew) {
                            return cb('Число выбранных работников должно быть равно их общему числу');
                        }
                    }
                    if (crewMembersCount > numberOfCrew) {
                        return cb('Количество выбранных работников должно быть не больше общего числа работников');
                    }
                    if (professionId && crewMembers && crewMembers.length > 0) {
                        if (!crewMembers.every((record) => record.professionIds?.includes(professionId))) {
                            return cb('Работники не относятся к выбранной специальности');
                        }
                    }
                    if (isActiveStatus(state) && isSomeBlockedCrewMember(crewMembers)) {
                        return cb('Выбран заблокированный работник при активном статусе работ');
                    }
                    return cb();
                },
            },
        ],
    },
    {
        fields: [
            {
                label: 'Период работ',
                id: 'dates',
                type: FormItemType.Component,
                component: RangePicker,
                required: true,
                defaultValue: (form: WrappedFormUtilsTyped<WorkPlanningCreateModalFormData>) => {
                    const startDate = form.getFieldValue('projectStartDate');
                    const targetFinishDate = form.getFieldValue('projectEndDate');

                    return form.getFieldValue('id') || (startDate == null && targetFinishDate == null)
                        ? undefined
                        : ([
                              startDate ? moment(startDate) : undefined,
                              targetFinishDate ? moment(targetFinishDate) : undefined,
                          ] as RangePickerValue);
                },
                componentProps: (form): RangePickerProps => {
                    const defaultShiftLengthInMinutes = getStoreState().businessAccountPreferences.preferences?.shiftLengthInMinutes;
                    const shiftCount = form.getFieldValue('shiftCount');
                    const profession = form.getFieldValue('profession');
                    const shiftLengthInMin = isDefined(profession?.shiftLengthInMinutes)
                        ? profession?.shiftLengthInMinutes
                        : defaultShiftLengthInMinutes;
                    const hasOwnShiftLength = isDefined(profession?.shiftLengthInMinutes);

                    return {
                        shiftLengthInMin,
                        shiftCount,
                        hasOwnShiftLength,
                        onShiftsChange: (shiftCount: number) => {
                            form.setFieldsValue({ shiftCount });
                        },
                        storeKey: 'workPlannings',
                    } as RangePickerProps;
                },
            },
            {
                label: 'Количество смен',
                id: 'shiftCount',
                type: FormItemType.Component,
                required: true,
                component: ShiftCountInput,
                defaultValue: (form: WrappedFormUtilsTyped<WorkPlanningCreateModalFormData>) => {
                    const startDate = form.getFieldValue('projectStartDate');
                    const targetFinishDate = form.getFieldValue('projectEndDate');
                    if(form.getFieldValue('id') || (startDate == null && targetFinishDate == null)) return 0;
                    else{
                        const defaultShiftLengthInMinutes = getStoreState().businessAccountPreferences.preferences?.shiftLengthInMinutes;
                        const shiftCountRoundingType = getStoreState().businessAccountPreferences.preferences?.shiftCountRoundingType;
                        let shiftCount =
                            startDate && targetFinishDate
                                ? getShiftCountFromDates(moment(startDate), moment(targetFinishDate), defaultShiftLengthInMinutes, shiftCountRoundingType)
                                : 0;
                        return shiftCount;
                    }
                },
                componentProps: (form): ShiftCountInputProps => {
                    const dates = form.getFieldValue('dates') as [Moment, Moment] | undefined;
                    const startDate = dates?.[0];
                    const endDate = dates?.[1];
                    const defaultShiftLengthInMinutes = getStoreState().businessAccountPreferences.preferences?.shiftLengthInMinutes;
                    const shiftCountRoundingType = getStoreState().businessAccountPreferences.preferences?.shiftCountRoundingType;
                    const profession = form.getFieldValue('profession');
                    const shiftLengthInMinutes = isDefined(profession?.shiftLengthInMinutes)
                        ? profession?.shiftLengthInMinutes
                        : defaultShiftLengthInMinutes;
                    const hasOwnShiftLength = isDefined(profession?.shiftLengthInMinutes);
                    let newShiftCount =
                        startDate && endDate
                            ? getShiftCountFromDates(startDate, endDate, shiftLengthInMinutes, shiftCountRoundingType)
                            : undefined;
                    return {
                        newShiftCount,
                        shiftLengthInMinutes,
                        hasOwnShiftLength,
                    };
                },
            },
        ],
    },
    {
        fields: [
            {
                id: 'effectivePrice',
                type: FormItemType.Component,
                component: PriceBlock,
                componentProps: (form): PriceBlockProps => {
                    const shiftCount = form.getFieldValue('shiftCount');
                    const numberOfCrew = form.getFieldValue('numberOfCrew');
                    const pricingScheme = form.getFieldValue('pricingScheme');
                    const basePrice = form.getFieldValue('basePrice');

                    return {
                        shiftCount: shiftCount || 0,
                        numberOfCrew: numberOfCrew || 0,
                        onPriceChanged: () => {
                            form.setFieldsValue({
                                pricingScheme: undefined,
                            });
                        },
                        pricingScheme: pricingScheme,
                        basePrice: basePrice || 0,
                    };
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
        ],
        visible: (getFieldValue) => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p);
        },
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);
