import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { Icon } from 'antd';
import React from 'react';
import { InstanceRecord, NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import {GRID_ACTIONS_COLUMN_WIDTH, GRID_PROBLEM_COLUMN_WIDTH} from '../../../../../../config/constants';
import DashboardDate from '../../../../../../components/dashboardDate/dashboardDate';
import { PricingSchemePricePopover } from '../../../../settings/pricingSchemes/pricingSchemePricePopover';
import { tableCellMoneyRenderer } from '../../../../../../components/grid/renderers/tableCellMoneyRenderer';
import { SystemIcon, SystemIconType } from '../../../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { PricingSchemeMaps } from '../../../../../../core/utils/pricingSchemeUtils';
import { NomenclatureRecordCustom } from '../../../../inventory/products/api/products.api.types';
import {IconBell, IconCalendarCheck, IconPlusCircle} from "../../../../../../components/icons";
import {is_OperationTimeError} from "../../../utils/utils";
import moment from "moment";
import {getStoreState} from "../../../../../../../index";
import {InstancePropertiesEntityRecordCustom} from "../../../reducers/operationForm.reducer";
import {Link} from "react-router-dom";
import {isDefined} from "../../../../../../shared/util/utils";
import {ProductVariantPopover} from "../../../../inventory/variants/components/productVariantPopover/productVariantPopover";
import {NomenclatureCalendarPopover} from "../../components/nomenclatureCalendarPopover/nomenclatureCalendarPopover";
import {OperationFormUtils} from "../../../utils/operationFormUtils";
import {showNotification} from "../../../../../../components/notification/showNotification";
import {GridInstanceCountBlock} from "../../../components/gridInstanceCountBlock/GridInstanceCountBlock";
import {linkToCalendar} from "../../../../../../shared/util/createLinkPath";
import {OperationElementCreatePopover} from "../../elementCreatePopover/OperationElementCreatePopover";

// export const productsListColumns1: TableColumn<NomenclatureRecordCustom>[] = [
//     GridColumnCreator.createProblemSeverityColumn(),
//     GridColumnCreator.createImageColumn({
//         dataIndex: 'mainImage',
//     }),
//     GridColumnCreator.createShortNameColumn<NomenclatureRecordCustom>(
//         'product',
//         (row) => ({
//             baId: row.businessAccountId,
//             id: row.productId,
//             name: row.productShortName,
//         }),
//         {
//             title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
//             dataIndex: 'productShortName',
//         }
//     ),
//     GridColumnCreator.createProductVariantColumn<NomenclatureRecordCustom>((row) => ({
//         productId: row.productId,
//         productName: row.productShortName,
//         variantId: row.variantId,
//         variantName: row.variantName,
//     })),
//     GridColumnCreator.createExternalCodeColumn<NomenclatureRecordCustom>(NomenclatureEntityTypeCodeEnum.PRODUCT, (row) => ({
//         code: row.externalCode,
//         nomenclatureId: row.productId,
//         baId: row.businessAccountId,
//     })),
//     {
//         title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
//         dataIndex: 'stateCode',
//         type: ColumnTypes.Status,
//         width: 120,
//         resizable: false,
//     },
//     {
//         title: 'Инвентарь',
//         dataIndex: 'warehousePresenceCounters.stockInstanceCount',
//         type: ColumnTypes.String,
//         minWidth: 120,
//         resizable: false,
//     },
//     {
//         title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__MAINTENANCE),
//         dataIndex: 'warehousePresenceCounters.underMaintenanceInstanceCount',
//         type: ColumnTypes.String,
//         minWidth: 150,
//         resizable: false,
//     },
//     {
//         title: 'Субаренда',
//         dataIndex: 'warehousePresenceCounters.subrentedInstanceCount',
//         type: ColumnTypes.String,
//         minWidth: 120,
//         resizable: false,
//     },
//     {
//         title: 'Аренда',
//         dataIndex: 'warehousePresenceCounters.rentedInstanceCount',
//         type: ColumnTypes.String,
//         minWidth: 120,
//         resizable: false,
//     },
//     {
//         title: 'На складе',
//         dataIndex: 'warehousePresenceCounters.warehouseInstanceCount',
//         type: ColumnTypes.String,
//         minWidth: 100,
//         resizable: false,
//         sortable: true,
//         defaultHidden: false,
//     },
//     {
//         title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT),
//         dataIndex: 'pricePerShift',
//         type: ColumnTypes.CustomRender,
//         className: 'money',
//         minWidth: 140,
//         resizable: true,
//         render: (value: any, rowData: NomenclatureRecordCustom) => {
//             let { pricingScheme } = rowData;
//             return (
//                 <>
//                     {pricingScheme ? (
//                         <PricingSchemePricePopover
//                             data={{
//                                 id: pricingScheme.id,
//                                 typeCode: pricingScheme.typeCode,
//                                 label: pricingScheme.name || '',
//                                 price: rowData.pricePerShift,
//                                 steps: pricingScheme.steps || [],
//                             }}
//                         >
//                             <Icon
//                                 component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
//                                 className={'rr-grid-pricingScheme-icon'}
//                             />
//                         </PricingSchemePricePopover>
//                     ) : null}
//                     {tableCellMoneyRenderer(value)}
//                 </>
//             );
//         },
//     },
//     {
//         title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
//         dataIndex: 'lastActivityDate',
//         type: ColumnTypes.CustomRender,
//         minWidth: 130,
//         resizable: false,
//         render: (value: Date, rowData: InstanceRecord) => {
//             return value ? <DashboardDate value={value} days={7} /> : null;
//         },
//     },
//     {
//         title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
//         type: ColumnTypes.Actions,
//         minWidth: GRID_ACTIONS_COLUMN_WIDTH,
//         width: GRID_ACTIONS_COLUMN_WIDTH,
//         maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
//         resizable: false,
//         sortable: false,
//         className: 'actions',
//         headerClassName: 'actions',
//     },
// ];


const addIcon = () => <Icon className={'rr-grid-table-double-right-icon'} component={IconPlusCircle} />;

const addIconDisabled = () => (
    <Icon className={'rr-grid-table-double-right-icon rr-grid-table-double-right-icon-disabled'} component={IconPlusCircle} />
);

const isRentDatesError = () => {
    return is_OperationTimeError(
        moment(getStoreState().operationForm.products.params.startDate).valueOf(),
        moment(getStoreState().operationForm.products.params.endDate).valueOf()
    );
};

// GRID COLUMNS
export const nomenclaturesListColumns: TableColumn<any>[] = [ // InstancePropertiesEntityRecordCustom
    {
        title: <Icon style={{ fontSize: 21 }} component={IconBell} />,
        dataIndex: 'problemsAndWarnings.severity',
        type: ColumnTypes.Problem3,
        width: GRID_PROBLEM_COLUMN_WIDTH,
        maxWidth: GRID_PROBLEM_COLUMN_WIDTH,
        minWidth: GRID_PROBLEM_COLUMN_WIDTH,
        resizable: false,
        sortable: true,
    },
    GridColumnCreator.createImageColumn({ dataIndex: 'mainImage' }),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => (
            <div className={'rr-grid-shortName-cell'} title={rowData.productShortName}>
                <Link
                    to={
                        !isDefined(rowData.kitId)
                            ? `/${rowData.businessAccountId}/inventory/products/${rowData.productId}`
                            : `/${rowData.businessAccountId}/inventory/kits/${rowData.kitId}`
                    }
                >
                    {rowData.productShortName}
                </Link>
            </div>
        ),
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) =>
            rowData.variantId ? (
                <div className={'rr-grid-shortName-cell'} style={{ fontWeight: 400 }}>
                    <ProductVariantPopover productId={rowData.productId} variantId={rowData.variantId}>
                        <span className={'app-link-underline'}>{rowData.variantName}</span>
                    </ProductVariantPopover>
                </div>
            ) : null,
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
        dataIndex: 'externalCode',
        type: ColumnTypes.CustomRender,
        minWidth: 110,
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            let linkTo = rowData.productId
                ? `/${rowData.businessAccountId}/inventory/products/${rowData.productId}`
                : `/${rowData.businessAccountId}/inventory/kits/${rowData.kitId}`;
            return rowData.externalCode ? (
                <div className={'rr-grid-text-cell-center'}>
                    <Link to={linkTo} style={{ color: 'unset' }}>
                        {rowData.externalCode}
                    </Link>
                </div>
            ) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        className: 'money',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            let pricingScheme = rowData.pricingScheme;
            return (
                <>
                    {pricingScheme ? (
                        <PricingSchemePricePopover
                            data={{
                                id: pricingScheme.id,
                                typeCode: pricingScheme.typeCode,
                                label: pricingScheme.name || '',
                                price: rowData.pricePerShift,
                                steps: pricingScheme.steps || [],
                            }}
                        >
                            <Icon
                                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                className={'rr-grid-pricingScheme-icon'}
                            />
                        </PricingSchemePricePopover>
                    ) : null}
                    {tableCellMoneyRenderer(value)}
                </>
            );
        },
    },
    {
        title: 'В инвентаре',
        dataIndex: 'availableInstanceCountTemplate' as any, // TODO
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        //maxWidth: 120,
        sortable: false,
        resizable: false,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            let stockInstanceCount = rowData.stockInstanceCount;
            let _isRentDatesError = isRentDatesError();
            return _isRentDatesError ? '?' : stockInstanceCount;
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__NEW_OPERATION__TAB__WAREHOUSE__GRID__COLUMN__REQUESTED_COUNT),
        dataIndex: 'orderedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        sortable: false,
        resizable: false,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            let orderedInstanceCount = rowData.orderedInstanceCount;
            let _isRentDatesError = isRentDatesError();
            if (!isDefined(rowData.kitId)) return _isRentDatesError ? '?' : orderedInstanceCount;
            else return null;
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__AVAILABLE_NEUTER_COUNT),
        dataIndex: 'availableInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        sortable: false,
        resizable: false,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            return (
                <NomenclatureCalendarPopover
                    nomenclatureId={rowData.kitId || rowData.variantId || rowData.productId}
                    nomenclatureType={rowData.nomenclatureEntityTypeCode}
                    availableInstanceCount={rowData.availableInstanceCount}
                    stockInstanceCount={rowData.stockInstanceCount}
                    nomenclature={rowData}
                />
            );
        },
    },
    {
        title: 'Добавлено',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        sortable: false,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            const instanceCount = rowData['instanceAdded'];
            const instanceExisted = rowData['instanceExisted'];

            let addDisabled = false;

            let removeDisabled = instanceCount <= 0;
            if (instanceCount <= instanceExisted) removeDisabled = true;

            const addInstance = async () => {
                try{
                    await OperationFormUtils.addItemFromProducts(rowData);
                }catch(error){
                    showNotification('error', 'Ошибка');
                    console.error(error);
                }
            };

            const removeInstance = () => {
                OperationFormUtils.removeItemFromProducts(rowData);
            };

            return (
                <GridInstanceCountBlock
                    instanceCount={instanceCount}
                    addInstance={addDisabled ? undefined : addInstance}
                    removeInstance={removeDisabled ? undefined : removeInstance}
                />
            );
        },
    },
    {
        title: 'Действия',
        type: ColumnTypes.CustomRender,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            const isKit = isDefined(rowData.kitId);
            const availableInstanceCount = rowData.availableInstanceCount;

            let disabled = false;

            let state = getStoreState().operationForm;
            let calendarPath = linkToCalendar({
                productId: rowData.productId,
                kitId: rowData.kitId,
                variantId: rowData.variantId,
                screenLeft: state.rentPeriodStartDate?.valueOf(),
                screenRight: state.rentPeriodEndDate?.valueOf(),
            });

            return (
                <div className="rr-grid-table-actions-group">
                    <div>
                        {!isKit ? (
                            <OperationElementCreatePopover
                                disabled={disabled}
                                productRecord={rowData}
                                mode={'create'}
                                availableInstanceCount={availableInstanceCount}
                                instanceCount={1}
                            >
                                {disabled ? addIconDisabled : addIcon}
                            </OperationElementCreatePopover>
                        ) : null}
                    </div>
                    <div>
                        <Link to={calendarPath}>
                            <Icon className={'rr-grid-table-calendar-icon'} component={IconCalendarCheck} />
                        </Link>
                    </div>
                </div>
            );
        },
    },
];
