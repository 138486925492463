import React, { ReactNode } from 'react';
import { RangePickerProps, RangePickerValue } from 'antd/lib/date-picker/interface';
import Picker from 'rc-calendar/lib/Picker';
import { CustomRangeCalendar, CustomRangeCalendarProps } from './CustomRangeCalendar';
import { Icon } from 'antd';
import { IconCalendar, IconClose } from '../icons';
import { isDefined } from '../../shared/util/utils';

export interface CustomRangePickerProps extends RangePickerProps {
    onChange?: (dates: RangePickerValue, dateStrings: [string, string]) => void;
    intervals?: [number, number][];
    instanceCount?: number;
    onOpenChange?: (open: boolean) => void;

    startDateLabel?: any;
    endDateLabel?: any;

    calendarValue?: RangePickerValue;
    hideShiftRecalculateBlock?: boolean;
    align?: object;

    shiftLengthInMin?: number;
    hasOwnShiftLength?: boolean;
    shiftLengthHint?: ReactNode;

    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;

    getPopupContainer?: any;

    startDatePlaceholder?: string;
    endDatePlaceholder?: string;

    shiftCount?: number;

    children?: ReactNode;

    onShiftCountChange?: (shiftCount: number) => void;
    storeKey?: CustomRangeCalendarProps['storeKey'];
    placement?: any;
    showRequiredTimeIndentBetweenElementsInMinutesIcon?: boolean;
}

interface IState {
    open: boolean;
    selectedValue?: RangePickerValue;
}

const formatStr = 'DD.MM.YY - HH:mm';
const formatStr1 = 'DD.MM.YY';

export class CustomRangePicker extends React.PureComponent<CustomRangePickerProps, IState> {
    private _ref;

    static getDerivedStateFromProps(nextProps: CustomRangePickerProps) {
        if (nextProps && nextProps.value) {
            return {
                selectedValue: nextProps.value,
            };
        } else return null;
    }

    constructor(props) {
        super(props);

        let selectedValue = props.value || props.defaultValue || [];

        this.state = {
            open: props.open || false,
            selectedValue,
        };
    }

    format = (v) => (v ? v.format(this.props.showTime ? formatStr : formatStr1) : '');

    onOpenChange = (open: boolean) => {
        this.setState({ open: open });

        if (this.props.onOpenChange) this.props.onOpenChange(open);
    };

    onChange1 = (dates: RangePickerValue, dateStrings: [string, string]) => {
        if (dates && dates[0]) dates[0] = dates[0].clone().seconds(0).milliseconds(0);
        if (dates && dates[1]) dates[1] = dates[1].clone().seconds(0).milliseconds(0);

        if (this.props.onChange) this.props.onChange(dates, ['', '']);
        this.setState({
            selectedValue: dates,
        });
    };

    onCancelClick = (e) => {
        e.stopPropagation();
        if (this.props.onChange) this.props.onChange([], ['', '']);
        this.setState({
            selectedValue: undefined,
        });
    };

    componentDidUpdate = (_, prevState) => {
        if (!prevState.open && this.state.open) {
            if (this._ref) {
                this._ref.focus();
            }
        }
        if (_.open !== this.props.open && isDefined(this.props.open)) {
            this.setState({ open: this.props.open });
        }
    };

    render() {
        let value1 = this.state.selectedValue;

        const calendar = (
            <CustomRangeCalendar
                disabledDate={this.props.disabledDate}
                selectedValue={this.props.calendarValue || value1}
                intervals={this.props.intervals}
                instanceCount={this.props.instanceCount}
                showTime={this.props.showTime}
                onChange={this.onChange1}
                onOpenChange={this.onOpenChange}
                hideShiftRecalculateBlock={this.props.hideShiftRecalculateBlock}
                shiftLengthInMin={this.props.shiftLengthInMin}
                hasOwnShiftLength={this.props.hasOwnShiftLength}
                shiftLengthHint={this.props.shiftLengthHint}
                requiredTimeIndentBetweenElementsInMinutes={this.props.requiredTimeIndentBetweenElementsInMinutes}
                productHasOwnRequiredTimeIndentBetweenElements={this.props.productHasOwnRequiredTimeIndentBetweenElements}
                shiftCount={this.props.shiftCount}
                onShiftCountChange={this.props.onShiftCountChange}
                storeKey={this.props.storeKey}
                showRequiredTimeIndentBetweenElementsInMinutesIcon={this.props.showRequiredTimeIndentBetweenElementsInMinutesIcon}
            />
        );

        return (
            <Picker
                ref={(ref) => {
                    if (!this._ref) this._ref = ref;
                }}
                open={this.state.open}
                onOpenChange={this.onOpenChange}
                value={this.props.calendarValue || value1}
                animation="slide-up"
                prefixCls={'ant-calendar-picker-container'}
                calendar={calendar}
                disabled={this.props.disabled}
                align={this.props.align}
                getCalendarContainer={this.props.getPopupContainer}
                placement={this.props.placement}
            >
                {this.props.children
                    ? () => this.props.children
                    : (data) => {
                          let value = value1;
                          return (
                              <span style={this.props.style} className={'ant-calendar-picker ' + this.props.className} tabIndex={0}>
                                  <span
                                      className={
                                          'ant-calendar-picker-input ant-input ' + (this.props.disabled ? 'ant-input-disabled' : undefined)
                                      }
                                  >
                                      {this.props.startDateLabel ? (
                                          <div style={{ display: 'inline-block' }} className={'ant-calendar-range-picker-input'}>
                                              {this.props.startDateLabel}
                                          </div>
                                      ) : (
                                          <input
                                              disabled={this.props.disabled}
                                              readOnly={true}
                                              placeholder={this.props.startDatePlaceholder || 'Начальная дата'}
                                              className="ant-calendar-range-picker-input"
                                              value={
                                                  this.props.startDateLabel ? this.props.startDateLabel : value ? this.format(value[0]) : ''
                                              }
                                          />
                                      )}
                                      <span className="ant-calendar-range-picker-separator"> → </span>
                                      {this.props.endDateLabel ? (
                                          <div style={{ display: 'inline-block' }} className={'ant-calendar-range-picker-input'}>
                                              {this.props.endDateLabel}
                                          </div>
                                      ) : (
                                          <input
                                              disabled={this.props.disabled}
                                              readOnly={true}
                                              placeholder={this.props.endDatePlaceholder || 'Конечная дата'}
                                              className="ant-calendar-range-picker-input"
                                              value={this.props.endDateLabel ? this.props.endDateLabel : value ? this.format(value[1]) : ''}
                                          />
                                      )}

                                      <Icon
                                          component={IconCalendar}
                                          className={'anticon-calendar ant-calendar-picker-icon'}
                                          tabIndex={-1}
                                      />
                                      {this.props.allowClear ? (
                                          <Icon
                                              component={IconClose}
                                              className={'anticon-close-circle ant-calendar-picker-clear'}
                                              tabIndex={-1}
                                              onClick={this.onCancelClick}
                                          />
                                      ) : null}
                                  </span>
                              </span>
                          );
                      }}
            </Picker>
        );
    }
}
