import { IModalFormFilters } from '../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../components/modalForm/components/Header/types/displayData';
import { ProjectInfoRead } from '../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof ProjectInfoRead | 'copyParams'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['copyParams', 'shortName', 'mainProjectId', 'partOfThePrice']],
    },
];

export const createOfferModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editOfferModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};
