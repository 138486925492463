import { ModalActionData } from '../../../components/modalForm/utils';
import { CategoriesExpandedKeysData, CategoriesSortOrderData } from '../../../modules/main/categories/utils';
import { GridStorageData } from '../../../components/grid/utils';
import { AttachmentsBlockName } from '../../../components/attachmentsBlock/attachmentsBlock';
import { ContactsBlockParentEntityTypeCode } from '../../../components/contactsBlock/contactsBlock';
import { getStore } from '../../../../index';
import { IRootState } from '../index';
import { GridName } from '../../../components/grid/utils/types';
import { DownloadPeriodValues } from '../../../components/exportPopovers/downloadDocument/components/Period/hooks/useSelectOptions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

export type RecalculateShiftsCountData = {
    [key in 'rent' | 'return' | 'subrentAcceptShipment' | 'subrentReturnToShipper' | 'workPlannings' | 'prolong']?: boolean;
};

export interface ExportSettings {
    params: string[];
    discount: number | undefined;
    template: any | undefined;
    period: DownloadPeriodValues | RangePickerValue | undefined;
    legalDetail: number | undefined;
}

export type ExportSettingsData = Record<string, ExportSettings>;

export type GridSettingsData = {
    [key in GridName]?: GridStorageData;
};

export type CalendarSettingsData = Record<
    string,
    {
        style: 1 | 2;
        sort: string;
    }
>;

export interface AttachmentsBlockSettings {
    collapsed?: boolean;
}

export interface RentIndicatorsBlockSettings {
    collapsed?: boolean;
}

export interface ContactsBlockSettings {
    collapsed?: boolean;
}

export type AttachmentsBlockSettingsData = {
    [Key in AttachmentsBlockName]?: AttachmentsBlockSettings;
};

export type RentIndicatorsBlockName =
    | 'project-rent'
    | 'counterparty-rent'
    | 'counterparty-subrent'
    | 'counterparty-legalAttributes'
    | 'counterparty-legalDetails'
    | `businessAccountId-legalDetails-${number}`;

export type RentIndicatorsBlockSettingsData = {
    [Key in RentIndicatorsBlockName]?: RentIndicatorsBlockSettings;
};

export type ContactsBlockSettingsData = {
    [Key in ContactsBlockParentEntityTypeCode]?: ContactsBlockSettings;
};

const ACTION_TYPES = {
    SET_SIDEBAR_COLLAPSED: 'userSettings/SET_SIDEBAR_COLLAPSED',
    SET_ADD_KIT_MEMBERS_WHEN_INSTANCE_SCAN: 'userSettings/SET_ADD_KIT_MEMBERS_WHEN_INSTANCE_SCAN',
    SET_RECALCULATE_SHIFT_COUNT: 'userSettings/SET_RECALCULATE_SHIFT_COUNT',
    SET_GRID_VIEW_MODE: 'userSettings/SET_GRID_VIEW_MODE',
    SET_SETTINGS: 'userSettings/SET_SETTINGS',
    SET_BLOCKED_URL: 'userSettings/BLOCKED_URL',
    UPDATE_MODAL_FILTERS: 'userSettings/UPDATE_MODAL_FILTERS',
    UPDATE_CATEGORIES_SORT_ORDER: 'userSettings/UPDATE_CATEGORIES_SORT_ORDER',
    UPDATE_CATEGORIES_EXPANDED_KEYS: 'userSettings/UPDATE_CATEGORIES_EXPANDED_KEYS',
    UPDATE_RECALCULATE_SHIFT_COUNT: 'userSettings/UPDATE_RECALCULATE_SHIFT_COUNT',
    UPDATE_GRID_SETTINGS: 'userSettings/UPDATE_GRID_SETTINGS',
    UPDATE_EXPORT_SETTINGS: 'userSettings/UPDATE_EXPORT_SETTINGS',
    UPDATE_CALENDAR_SETTINGS: 'userSettings/UPDATE_CALENDAR_SETTINGS',
    UPDATE_CALENDAR_BOTTOM_NAVIGATION_COLLAPSED: 'userSettings/UPDATE_CALENDAR_BOTTOM_NAVIGATION_COLLAPSED',
    UPDATE_PAYMENT_MODAL_INDICATORS_COLLAPSED: 'userSettings/UPDATE_PAYMENT_MODAL_INDICATORS_COLLAPSED',
    UPDATE_ATTACHMENTS_BLOCK_SETTINGS: 'userSettings/UPDATE_ATTACHMENTS_BLOCK_SETTINGS',
    UPDATE_RENT_INDICATORS_BLOCK_SETTINGS: 'userSettings/UPDATE_RENT_INDICATORS_BLOCK_SETTINGS',
    UPDATE_CONTACTS_BLOCK_SETTINGS: 'userSettings/UPDATE_CONTACTS_BLOCK_SETTINGS',
    UPDATE_CURRENT_TABS: 'userSettings/UPDATE_CURRENT_TABS',
} as const;

export interface UserSettingsState {
    sidebarCollapsed: boolean;
    addKitMembersWhenInstanceScan: boolean;
    operationFormRecalculateShiftCount: boolean;
    gridViewMode: 'normal' | 'compact';
    modalFilters: ModalActionData;
    categoriesSortOrder?: CategoriesSortOrderData;
    categoriesExpandedKeys?: CategoriesExpandedKeysData;
    recalculateShiftsCount: RecalculateShiftsCountData;
    gridSettings: GridSettingsData;
    calendarBottomNavigationCollapsed?: boolean;
    paymentModalIndicatorsCollapsed?: boolean;
    attachmentsBlockSettings: AttachmentsBlockSettingsData;
    rentIndicatorsBlockSettings: RentIndicatorsBlockSettingsData;
    contactsBlockSettings: AttachmentsBlockSettingsData;
    currentTabs: Record<Tabs, string>;
    blockedUrl?: string;
    calendarSettings?: CalendarSettingsData;
    exportSettings: Record<string, ExportSettings>;
}

const initialState: UserSettingsState = {
    sidebarCollapsed: false,
    addKitMembersWhenInstanceScan: false,
    operationFormRecalculateShiftCount: false,
    gridViewMode: 'normal',
    modalFilters: {},
    categoriesSortOrder: {},
    categoriesExpandedKeys: {},
    recalculateShiftsCount: {},
    gridSettings: {},
    paymentModalIndicatorsCollapsed: true,
    attachmentsBlockSettings: {},
    rentIndicatorsBlockSettings: {},
    contactsBlockSettings: {},
    currentTabs: {} as UserSettingsState['currentTabs'],
    exportSettings: {},
};

export default (state: UserSettingsState = initialState, action: ActionType): UserSettingsState => {
    switch (action.type) {
        case ACTION_TYPES.SET_SIDEBAR_COLLAPSED:
            return {
                ...state,
                sidebarCollapsed: action.payload,
            };
        case ACTION_TYPES.SET_ADD_KIT_MEMBERS_WHEN_INSTANCE_SCAN:
            return {
                ...state,
                addKitMembersWhenInstanceScan: action.payload,
            };
        case ACTION_TYPES.SET_RECALCULATE_SHIFT_COUNT:
            return {
                ...state,
                operationFormRecalculateShiftCount: action.payload,
            };
        case ACTION_TYPES.SET_GRID_VIEW_MODE:
            return {
                ...state,
                gridViewMode: action.payload,
            };
        case ACTION_TYPES.SET_BLOCKED_URL:
            return {
                ...state,
                blockedUrl: action.payload,
            };
        case ACTION_TYPES.UPDATE_MODAL_FILTERS:
            return {
                ...state,
                modalFilters: {
                    ...state.modalFilters,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CATEGORIES_SORT_ORDER:
            return {
                ...state,
                categoriesSortOrder: {
                    ...state.categoriesSortOrder,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CATEGORIES_EXPANDED_KEYS:
            return {
                ...state,
                categoriesExpandedKeys: {
                    ...state.categoriesExpandedKeys,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_RECALCULATE_SHIFT_COUNT:
            return {
                ...state,
                recalculateShiftsCount: {
                    ...state.recalculateShiftsCount,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_GRID_SETTINGS:
            return {
                ...state,
                gridSettings: {
                    ...state.gridSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_EXPORT_SETTINGS:
            return {
                ...state,
                exportSettings: {
                    ...state.exportSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CALENDAR_SETTINGS:
            return {
                ...state,
                calendarSettings: {
                    ...state.calendarSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CALENDAR_BOTTOM_NAVIGATION_COLLAPSED:
            return {
                ...state,
                calendarBottomNavigationCollapsed: action.payload,
            };
        case ACTION_TYPES.UPDATE_PAYMENT_MODAL_INDICATORS_COLLAPSED:
            return {
                ...state,
                paymentModalIndicatorsCollapsed: action.payload,
            };
        case ACTION_TYPES.UPDATE_ATTACHMENTS_BLOCK_SETTINGS:
            return {
                ...state,
                attachmentsBlockSettings: {
                    ...state.attachmentsBlockSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_RENT_INDICATORS_BLOCK_SETTINGS:
            return {
                ...state,
                rentIndicatorsBlockSettings: {
                    ...state.rentIndicatorsBlockSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CONTACTS_BLOCK_SETTINGS:
            return {
                ...state,
                contactsBlockSettings: {
                    ...state.contactsBlockSettings,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.UPDATE_CURRENT_TABS:
            return {
                ...state,
                currentTabs: {
                    ...state.currentTabs,
                    ...action.payload,
                },
            };
        case ACTION_TYPES.SET_SETTINGS:
            return { ...state, ...action.payload };
        default:
            // Проверка на использование всех экшенов
            const _: never = action;
            return state;
    }
};

type ActionCreate<Type extends ObjectValues<typeof ACTION_TYPES>, Payload> = {
    type: Type;
    payload: Payload;
};

type SetSidebarCollapsedAction = ActionCreate<typeof ACTION_TYPES.SET_SIDEBAR_COLLAPSED, boolean>;
type SetScanModeAction = ActionCreate<typeof ACTION_TYPES.SET_ADD_KIT_MEMBERS_WHEN_INSTANCE_SCAN, boolean>;
type SetRecalculateShiftCountAction = ActionCreate<typeof ACTION_TYPES.SET_RECALCULATE_SHIFT_COUNT, boolean>;
type SetGridViewModeAction = ActionCreate<typeof ACTION_TYPES.SET_GRID_VIEW_MODE, UserSettingsState['gridViewMode']>;
type SetSettingsAction = ActionCreate<typeof ACTION_TYPES.SET_SETTINGS, UserSettingsState>;
type SetBlockedUrlAction = ActionCreate<typeof ACTION_TYPES.SET_BLOCKED_URL, string | undefined>;
type UpdateModalFiltersAction = ActionCreate<typeof ACTION_TYPES.UPDATE_MODAL_FILTERS, ModalActionData>;
type UpdateCategoriesSortOrderAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CATEGORIES_SORT_ORDER, CategoriesSortOrderData>;
type UpdateCategoriesExpandedKeysAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CATEGORIES_EXPANDED_KEYS, CategoriesExpandedKeysData>;
type UpdateRecalculateShiftsCountAction = ActionCreate<typeof ACTION_TYPES.UPDATE_RECALCULATE_SHIFT_COUNT, RecalculateShiftsCountData>;
type UpdateGridSettingsAction = ActionCreate<typeof ACTION_TYPES.UPDATE_GRID_SETTINGS, GridSettingsData>;
type UpdateExportsSettingsAction = ActionCreate<typeof ACTION_TYPES.UPDATE_EXPORT_SETTINGS, ExportSettingsData>;
type UpdateCalendarSettingsAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CALENDAR_SETTINGS, CalendarSettingsData>;
type UpdateCalendarBottomNavigationCollapsedAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CALENDAR_BOTTOM_NAVIGATION_COLLAPSED, boolean>;
type UpdatePaymentModalIndicatorsCollapsedAction = ActionCreate<typeof ACTION_TYPES.UPDATE_PAYMENT_MODAL_INDICATORS_COLLAPSED, boolean>;
type UpdateAttachmentsBlockSettingsAction = ActionCreate<
    typeof ACTION_TYPES.UPDATE_ATTACHMENTS_BLOCK_SETTINGS,
    AttachmentsBlockSettingsData
>;
type UpdateRentIndicatorsBlockSettingsAction = ActionCreate<
    typeof ACTION_TYPES.UPDATE_RENT_INDICATORS_BLOCK_SETTINGS,
    RentIndicatorsBlockSettingsData
>;
type UpdateContactsBlockSettingsAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CONTACTS_BLOCK_SETTINGS, ContactsBlockSettingsData>;
type UpdateCurrentTabsAction = ActionCreate<typeof ACTION_TYPES.UPDATE_CURRENT_TABS, typeof initialState.currentTabs>;

type ObjectValues<T> = T extends {
    [K in string]: infer U;
}
    ? U
    : never;

type ActionType =
    | SetSidebarCollapsedAction
    | SetScanModeAction
    | SetRecalculateShiftCountAction
    | SetGridViewModeAction
    | UpdateModalFiltersAction
    | UpdateCategoriesSortOrderAction
    | UpdateCategoriesExpandedKeysAction
    | UpdateRecalculateShiftsCountAction
    | UpdateGridSettingsAction
    | UpdateExportsSettingsAction
    | UpdateCalendarBottomNavigationCollapsedAction
    | UpdatePaymentModalIndicatorsCollapsedAction
    | UpdateAttachmentsBlockSettingsAction
    | UpdateRentIndicatorsBlockSettingsAction
    | UpdateContactsBlockSettingsAction
    | UpdateCurrentTabsAction
    | SetSettingsAction
    | SetBlockedUrlAction
    | UpdateCalendarSettingsAction;

export const setSettings = (value: UserSettingsState): SetSettingsAction => ({
    type: ACTION_TYPES.SET_SETTINGS,
    payload: value,
});

export const setSidebarCollapsed = (value: boolean): SetSidebarCollapsedAction => ({
    type: ACTION_TYPES.SET_SIDEBAR_COLLAPSED,
    payload: value,
});

export const setAddKitMembersWhenInstanceScan = (value: boolean): SetScanModeAction => ({
    type: ACTION_TYPES.SET_ADD_KIT_MEMBERS_WHEN_INSTANCE_SCAN,
    payload: value,
});

export const setRecalculateShiftCount = (value: boolean): SetRecalculateShiftCountAction => ({
    type: ACTION_TYPES.SET_RECALCULATE_SHIFT_COUNT,
    payload: value,
});

export const setGridViewMode = (value: UserSettingsState['gridViewMode']): SetGridViewModeAction => ({
    type: ACTION_TYPES.SET_GRID_VIEW_MODE,
    payload: value,
});

export const setBlockedUrl = (value?: string): SetBlockedUrlAction => ({
    type: ACTION_TYPES.SET_BLOCKED_URL,
    payload: value,
});

export const updateModalFilters = (value: ModalActionData): UpdateModalFiltersAction => ({
    type: ACTION_TYPES.UPDATE_MODAL_FILTERS,
    payload: value,
});

export const updateCategoriesSortOrder = (value: CategoriesSortOrderData): UpdateCategoriesSortOrderAction => ({
    type: ACTION_TYPES.UPDATE_CATEGORIES_SORT_ORDER,
    payload: value,
});

export const updateCategoriesExpandedKeys = (value: CategoriesExpandedKeysData): UpdateCategoriesExpandedKeysAction => ({
    type: ACTION_TYPES.UPDATE_CATEGORIES_EXPANDED_KEYS,
    payload: value,
});

export const updateRecalculateShiftsCount = (value: RecalculateShiftsCountData): UpdateRecalculateShiftsCountAction => ({
    type: ACTION_TYPES.UPDATE_RECALCULATE_SHIFT_COUNT,
    payload: value,
});

export const updateGridSettings = (value: GridSettingsData): UpdateGridSettingsAction => ({
    type: ACTION_TYPES.UPDATE_GRID_SETTINGS,
    payload: value,
});

export const updateExportSettings = (value: ExportSettingsData): UpdateExportsSettingsAction => ({
    type: ACTION_TYPES.UPDATE_EXPORT_SETTINGS,
    payload: value,
});

export const updateCalendarSettings = (value: CalendarSettingsData): UpdateCalendarSettingsAction => ({
    type: ACTION_TYPES.UPDATE_CALENDAR_SETTINGS,
    payload: value,
});

export const updateCalendarBottomNavigationCollapsed = (value: boolean): UpdateCalendarBottomNavigationCollapsedAction => ({
    type: ACTION_TYPES.UPDATE_CALENDAR_BOTTOM_NAVIGATION_COLLAPSED,
    payload: value,
});

export const updatePaymentModalIndicatorsCollapsed = (value: boolean): UpdatePaymentModalIndicatorsCollapsedAction => ({
    type: ACTION_TYPES.UPDATE_PAYMENT_MODAL_INDICATORS_COLLAPSED,
    payload: value,
});

export const updateAttachmentsBlockSettings = (value: AttachmentsBlockSettingsData): UpdateAttachmentsBlockSettingsAction => ({
    type: ACTION_TYPES.UPDATE_ATTACHMENTS_BLOCK_SETTINGS,
    payload: value,
});

export const updateRentIndicatorsBlockSettings = (value: RentIndicatorsBlockSettingsData): UpdateRentIndicatorsBlockSettingsAction => ({
    type: ACTION_TYPES.UPDATE_RENT_INDICATORS_BLOCK_SETTINGS,
    payload: value,
});

export const updateContactsBlockSettings = (value: ContactsBlockSettingsData): UpdateContactsBlockSettingsAction => ({
    type: ACTION_TYPES.UPDATE_CONTACTS_BLOCK_SETTINGS,
    payload: value,
});

export const updateCurrentTabs = (value: typeof initialState.currentTabs): UpdateCurrentTabsAction => ({
    type: ACTION_TYPES.UPDATE_CURRENT_TABS,
    payload: value,
});

type Tabs =
    | 'categories'
    | 'dashboard'
    | 'history'
    | 'kit'
    | 'inventory'
    | 'product'
    | 'logistics'
    | 'project'
    | 'offer'
    | 'projects'
    | 'template'
    | 'renters'
    | 'counterparty'
    | 'subrent'
    | 'subrentShipping'
    | 'shippings'
    | 'settings'
    | 'crew'
    | 'crewMember'
    | 'calendar';

export const saveCurrentTabToStore = (page: Tabs, tab: string) => {
    getStore().dispatch(
        updateCurrentTabs({
            [page]: tab,
        } as typeof initialState.currentTabs)
    );
};

export const getCurrentTabsSelector = (state: IRootState) => state.userSettings.currentTabs;

export const getRentIndicatorsBlockSettingsSelector = (state: IRootState) => state.userSettings.rentIndicatorsBlockSettings;
