export enum CalendarPageTabsEnum {
    ACTIVITY_FRAMES = 'activityFrames',
    ELEMENTS = 'elements',
    WORK_PLANNINGS = 'workPlannings',
    ACCESSIBILITY_MAP = 'accessibilityMap',
}

export enum CrewPageTabsEnum {
    PROFESSIONS = 'professions',
    CREW_MEMBERS = 'crewMembers',
}

export enum CrewMemberPageTabsEnum {
    DESCRIPTION = 'description',
    PROFESSIONS = 'profession',
}

export enum DashboardTabsEnum {
    RENT = 'RENT',
    SUBRENT = 'SUBRENT',
}

export enum HistoryPageTabsEnum {
    OPERATIONS = 'operations',
    EXPENSES = 'expenses',
    PAYMENTS = 'payments',
    INVENTORY_MOVEMENTS = 'inventoryMovements',
    LIFECYCLE_EVENTS = 'lifecycleEvents',
}

export enum InventoryPageTabsEnum {
    KITS = 'kits',
    PRODUCTS = 'products',
}

export enum KitPageTabsEnum {
    DESCRIPTION = 'description',
    KITS = 'composition',
    FEATURES = 'features',
}

export enum LogisticsPageTabsEnum {
    TRANSPORTATIONS = 'transportations',
    LOCATIONS = 'locations',
    VEHICLES = 'vehicles',
}

export enum ProjectsPageTabsEnum {
    PROJECTS = 'production',
    OFFERS = 'offers',
    TEMPLATES = 'templates',
}

export enum RentersPageTabsEnum {
    COUNTERPARTIES = 'counterparties',
    CONTACTS = 'contacts',
}

export enum SubrentPageTabsEnum {
    SHIPPINGS = 'shippings',
    TEMPLATES = 'templates',
}

export enum ProductPageTabsEnum {
    DESCRIPTION = 'description',
    FEATURES = 'features',
    INSTANCES = 'instances',
    VARIANTS = 'variants',
    INVENTORY_MOVEMENTS = 'inventoryMovements',
}

export enum ProductVariantPopoverTabsEnum {
    DESCRIPTION = 'description',
    FEATURES = 'features',
    RENT = 'rent',
    SUBRENT = 'subrent',
}

export enum OfferPageTabsEnum {
    DESCRIPTION = 'description',
    OPERATIONS = 'operations',
    ELEMENTS = 'elements',
    WORK_PLANNINGS = 'workPlannings',
    EXPENSES = 'expenses',
}

export enum CounterpartyPageTabsEnum {
    DESCRIPTION = 'description',
    PROJECTS = 'projects',
    SHIPPINGS = 'shippings',
    STATISTICS = 'statistics',
    OPERATIONS = 'operations',
}

export enum SubrentShippingPageTabsEnum {
    DESCRIPTION = 'description',
    OPERATIONS = 'operations',
    ELEMENTS = 'elements',
    STATISTICS = 'statistics',
}

export enum TemplatePageTabsEnum {
    DESCRIPTION = 'description',
    OPERATIONS = 'operations',
    ELEMENTS = 'elements',
    STATISTICS = 'statistics',
}

export enum ProjectPageTabsEnum {
    DESCRIPTION = 'description',
    OFFERS = 'offers',
    OPERATIONS = 'operations',
    ELEMENTS = 'elements',
    STATISTICS = 'statistics',
    TRANSPORTATIONS = 'transportations',
    WORK_PLANNINGS = 'workPlannings',
    EXPENSES = 'expenses',
}

export enum OperationPageTabsEnum {
    DESCRIPTION = 'description',
    ELEMENTS = 'elements',
    COMPOSITION = 'composition',
}

export enum SupportPageTabsEnum {
    PORTAL = 'PORTAL',
    REQUESTS = 'REQUESTS',
}

export enum OperationModuleTabsEnum {
    PRODUCTS = 'products',
    EQUIPMENT = 'equipment',
    ELEMENTS = 'elements',
}
