import React from "react";
import {IconCheck} from "../../icons";
import {Icon} from "antd";
import {Styles} from "../../../styles";
import {GridRecord} from "../../../../index";

export const tableCellBooleanRenderer = (value:any, rowData?:GridRecord) => {
    if(value === 'true' || value === true)
        return <Icon className={`${Styles.ICON_22}`} style={{ color: '#34bfa3'}} component={IconCheck} />;
    else if(value === 'false' || value === false)
        return 'Нет';
    else
        return null;
};
