import React, { CSSProperties, FC } from 'react';
import { getImagePath } from '../../../../shared/util/utils';
import { ImageObj } from '../../../../server';
import { Icon } from 'antd';
import { IconNoImage } from '../../../icons';
import { Avatar } from '../../../avatar/avatar';

interface DrawerAvatarProps {
    image?: ImageObj;
    isHighlighted?: boolean;
}

const highlightStyles: CSSProperties = {
    border: '5px solid #3d64d4',
    borderRadius: '50%',
    boxSizing: 'content-box',
};
const staticStyles: CSSProperties = {
    backgroundColor: '#e2e5ec',
    verticalAlign: 'middle',
};

export const DrawerAvatar: FC<DrawerAvatarProps> = ({ image, isHighlighted }) => {
    const src =
        image == null || image.externalId == null
            ? undefined
            : getImagePath({ ...image, externalId: image.externalId?.replace(/-/g, '') }, '158');

    return (
        <Avatar
            className={'rr-avatar-big'}
            style={{
                ...staticStyles,
                ...(isHighlighted ? highlightStyles : {}),
            }}
            src={src}
        >
            <Icon component={IconNoImage} />
        </Avatar>
    );
};
