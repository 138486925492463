import _ from "lodash";
import moment, {Moment} from "moment/moment";

export const datePickerFormValidatorFunction = _.debounce((rule, date: Moment | undefined, cb) => {
    if (date) {
        const inputValue = (moment(date)?.creationData().input as string) ?? '';
        const inputFormat = moment(date)?.creationData().format;
        if (
            !(/^\d{2}[.-]\d{2}[.-]\d{4}$/.test(inputValue) || /^\d{2}[.-]\d{2}[.-]\d{2}$/.test(inputValue) || /^\d{4}[-]\d{2}[-]\d{2}/.test(inputValue)) ||
            !moment(date, inputFormat, true).isValid()
        ) {
            cb('Некорректный формат даты');
        } else {
            cb();
        }
    } else {
        cb();
    }
}, 800)