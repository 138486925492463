import { PageURLParamDescription, PageURLParamDescriptions } from './descriptions';
import {
    AvailableFilterValuesObj,
    CustomFieldDataTypeCodeEnum,
    CustomFieldGroupObjRead,
    CustomFieldGroupObjWrite,
    CustomFieldObjRead,
    CustomFieldProcessingTypeCodeEnum,
    CustomFieldValueObjWrite,
    CustomFieldWithValueObjRead,
} from '../../server';
import { ServerUtils } from './serverUtils';
import { ParamsDescription } from './paramsUtils';
import { FormFieldsGroup, FormItemType } from '../../components/dynamicForm/DynamicForm';
import cloneDeep from 'lodash/cloneDeep';
import { getCustomFilterFieldProps } from '../../modules/main/inventory/components/CustomFilters/getCustomFilterFieldProps';
import { MoneyUtils } from './moneyUtils';

type CustomFieldsInParamsData<T> = {
    field: CustomFieldObjRead;
    param: T;
};

export abstract class CustomFieldsUtils {
    private static filterPrefix = 'customFieldValues.';

    static processingTypeValueMap = {
        [CustomFieldProcessingTypeCodeEnum.LONGSUM]: 'Число',
        [CustomFieldProcessingTypeCodeEnum.MONEYSUM]: 'Сумма денег',
        [CustomFieldProcessingTypeCodeEnum.USERLINK]: 'Пользователь',
        [CustomFieldProcessingTypeCodeEnum.CONTACTLINK]: 'Контакт',
        [CustomFieldProcessingTypeCodeEnum.RENTERLINK]: 'Контрагент',
        [CustomFieldProcessingTypeCodeEnum.PROJECTLINK]: 'Проект',
        [CustomFieldProcessingTypeCodeEnum.SUBRENTLINK]: 'Поставка',
    };
    static addPrefix = (str: string) => {
        return this.filterPrefix + str;
    };
    static deletePrefix = (str: string) => {
        const regex = new RegExp(`^${this.filterPrefix}`);
        return str.replace(regex, '');
    };
    static getCustomFieldsInParamsData = <T extends string | any>(
        customFieldMarkers: CustomFieldObjRead[],
        params: Record<string, T>
    ): CustomFieldsInParamsData<T>[] => {
        const customFieldsInParamsData: CustomFieldsInParamsData<T>[] = [];

        for (const param in params) {
            const field = customFieldMarkers.find((field) => field.customFieldIndexKey === param);
            if (field != null)
                customFieldsInParamsData.push({
                    field,
                    param: params[param],
                });
        }

        return customFieldsInParamsData;
    };
    static generateCustomFieldsDescription = <Params extends object>(
        customFieldMarkers: CustomFieldObjRead[] | undefined,
        defaultDescription: ParamsDescription<Params>
    ): ParamsDescription<Params> => {
        if (customFieldMarkers == null) return defaultDescription;

        const customFieldsDescription: ParamsDescription<object> = {};

        for (const { customFieldIndexKey, typeCode } of customFieldMarkers) {
            const value = (
                {
                    [CustomFieldDataTypeCodeEnum.BOOLEAN]: PageURLParamDescriptions.booleanParam,
                    [CustomFieldDataTypeCodeEnum.STRING]: PageURLParamDescriptions.arrayOfStringsParam,
                    [CustomFieldDataTypeCodeEnum.LONG]: PageURLParamDescriptions.numberInterval,
                    [CustomFieldDataTypeCodeEnum.DOUBLE]: PageURLParamDescriptions.numberInterval,
                    [CustomFieldDataTypeCodeEnum.DATE]: PageURLParamDescriptions.dateTimeIntervals,
                    [CustomFieldDataTypeCodeEnum.ENTITY]: PageURLParamDescriptions.numbersSelectParam,
                } satisfies Record<CustomFieldDataTypeCodeEnum, PageURLParamDescription>
            )[typeCode];

            if (value) customFieldsDescription[customFieldIndexKey] = value;
        }

        if (Object.keys(customFieldsDescription).length === 0) return defaultDescription;

        return {
            ...defaultDescription,
            ...customFieldsDescription,
        };
    };
    static generateCustomFieldsFilters = (
        customFieldMarkers: CustomFieldObjRead[] | undefined,
        params: Record<string, string>
    ): string[] => {
        if (customFieldMarkers == null) return [];
        const customFieldsInParamsData = CustomFieldsUtils.getCustomFieldsInParamsData(customFieldMarkers, params);

        const customFieldsFilters: Parameters<typeof ServerUtils.createRequestFilters>[0] = [];

        for (const {
            field: { typeCode, customFieldIndexKey, processingType },
            param,
        } of customFieldsInParamsData) {
            const filterField = this.addPrefix(customFieldIndexKey);

            if (typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN) {
                const value = PageURLParamDescriptions.booleanParam.fromString(param);
                if (value != null) customFieldsFilters.push([filterField, 'EQ', value]);
            }
            if (typeCode === CustomFieldDataTypeCodeEnum.STRING) {
                const value = PageURLParamDescriptions.arrayOfStringsParam.fromString(param);
                if (value != null) customFieldsFilters.push([filterField, 'IN', value]);
            }
            if (typeCode === CustomFieldDataTypeCodeEnum.DATE) {
                const value = PageURLParamDescriptions.dateTimeIntervals.fromString(param);
                if (value?.[0] != null) customFieldsFilters.push([filterField, 'GE', value[0].valueOf()]);
                if (value?.[1] != null) customFieldsFilters.push([filterField, 'LE', value[1].valueOf()]);
            }
            if (typeCode === CustomFieldDataTypeCodeEnum.ENTITY) {
                const value = PageURLParamDescriptions.numbersSelectParam.fromString(param);
                if (value != null) customFieldsFilters.push([filterField, 'IN', Array.isArray(value) ? value : [value]]);
            }
            if (typeCode === CustomFieldDataTypeCodeEnum.LONG || typeCode === CustomFieldDataTypeCodeEnum.DOUBLE) {
                const isMoney =
                    typeCode === CustomFieldDataTypeCodeEnum.LONG && processingType === CustomFieldProcessingTypeCodeEnum.MONEYSUM;
                const value = PageURLParamDescriptions.numberInterval.fromString(param);
                if (value?.[0] != null)
                    customFieldsFilters.push([filterField, 'GE', (isMoney ? MoneyUtils.toCents(value[0]) : value[0]).valueOf()]);
                if (value?.[1] != null)
                    customFieldsFilters.push([filterField, 'LE', (isMoney ? MoneyUtils.toCents(value[1]) : value[1]).valueOf()]);
            }
        }

        return ServerUtils.createRequestFilters(customFieldsFilters);
    };
    static getProcessedFiltersWithCustomFields = <PageParams extends object>(
        filters: FormFieldsGroup[],
        customFieldMarkers: CustomFieldObjRead[] | undefined,
        pageParams: PageParams,
        initialValues: PageParams,
        availableFiltersValues: Array<AvailableFilterValuesObj>
    ) => {
        if (customFieldMarkers == null || filters == null)
            return {
                filtersDataWithCustomFields: filters,
                initialValuesWithCustom: initialValues,
            };

        const newFilters = cloneDeep(filters);

        customFieldMarkers.forEach((customField, index) => {
            if (customField.customFieldIndexKey == null) return;

            const defaultHidden = pageParams[customField.customFieldIndexKey] == null;

            newFilters[1].fields = [
                ...newFilters[1].fields,
                {
                    label: customField.name,
                    id: customField.customFieldIndexKey,
                    type: FormItemType.Component,
                    defaultHidden,
                    ...getCustomFilterFieldProps(customField, initialValues, availableFiltersValues),
                },
            ];
        });

        return {
            filtersDataWithCustomFields: newFilters,
            initialValuesWithCustom: initialValues,
        };
    };
    static convertCustomFieldWithValueObjReadToCustomFieldValueObjWrite = (
        customField: CustomFieldWithValueObjRead
    ): CustomFieldValueObjWrite => {
        const { customFieldId, values, linkValues } = customField;

        const convertedValues: string[] = [...(values ?? []), ...(linkValues ?? []).map((linkValue) => String(linkValue.id))];

        return {
            customFieldId,
            values: convertedValues,
        };
    };
    static convertCustomFieldGroupObjReadToCustomFieldGroupObjWrite = (
        customFieldGroup: CustomFieldGroupObjRead
    ): CustomFieldGroupObjWrite => {
        const { fields } = customFieldGroup;

        const convertedFields = fields.map(this.convertCustomFieldWithValueObjReadToCustomFieldValueObjWrite);

        return {
            ...customFieldGroup,
            fields: convertedFields,
        };
    };
}
