import React from 'react';
import {StateBadgeData} from "../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../core/types/color";
import {SubrentStateCodeEnum} from "../../../../../server";

export const subrentShippingStateBadgeData: StateBadgeData<SubrentStateCodeEnum> = {
    [SubrentStateCodeEnum.NEW]: {
        color: Color.Azure, // Голубой
        name: 'Новая', // TODO Локализовать
    },
    [SubrentStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1, // Оранжевый
        name: 'В работе', // TODO Локализовать
    },
    [SubrentStateCodeEnum.FINISHED]: {
        color: Color.Green, // Зеленый
        name: 'Завершена', // TODO Локализовать
    },
};
