import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {BAUserRecord, BAUserRecordList, serverApi, ServerError, UserBARolesUpdateObj} from "../../../../../server";
import {showNotification} from "../../../../../components/notification/showNotification";
import {IRootState} from "../../../../../shared/reducers";
import {getServerError, getStateFromPath2} from "../../../../../shared/util/utils";
import {LOCATION_CHANGE} from "connected-react-router";
import {LocalizationEnum, localizeIntl} from '../../../../../localization';
import {getBusinessAccount} from "../../../../../shared/reducers/businessAccount.reducer";
import {getAvailableGridSortById} from "../../../../../shared/util/getAvailableGridSortById";
import {usersListColumns} from "../usersModuleListColums";

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'usersBA/LOAD_ENTITIES',
    RESET: 'usersBA/RESET',
    CHANGE_RULES: 'usersBA/SAVE_RULES',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'email' as string | undefined,
    sortOrder: 'DESC' as 'ASC' | 'DESC' | undefined,
    page: 1,
    limit: 10,
    status: undefined as  "INVITED" | "ACTIVE" | "BLOCKED"  | undefined,
    baRoles: undefined as 'AGENT' | 'ADMIN'  | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entities: null as Array<BAUserRecord>|null,
    filteredCount: 0,
    params: {
        ...initialParamsState
    }
};

export type usersBAState = Readonly<typeof initialState>;

// Reducer

export default (state: usersBAState = initialState, action): usersBAState => {
    switch (action.type) {

        case LOCATION_CHANGE:
            let pathName = action.payload && action.payload.location &&  action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if(/\/\d+\/settings\/users\/?/.test(pathName) ){

                /*if(params.startDate){
                    const startDate = params.startDate.toString().split(',').map((item)=>item ? moment(+item).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf() : undefined);
                    if(startDate[1]) startDate[1] += 24*60*60*1000-1;
                    if(!startDate[0] && !startDate[1]) params.startDate = undefined;
                    else if(!startDate[1]) params.startDate = [startDate[0]];
                    else params.startDate = startDate;
                }*/

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        status: params.status !== undefined ? params.status : initialParamsState.status,
                        baRoles: params.baRoles !== undefined ? params.baRoles : initialParamsState.baRoles,
                    }
                };
            }
            return state;


        case REQUEST(ACTION_TYPES.CHANGE_RULES):
            return {
                ...state,
                loading: true
            };
        case FAILURE(ACTION_TYPES.CHANGE_RULES):
            return {
                ...state,
                loading: false
            };
        case SUCCESS(ACTION_TYPES.CHANGE_RULES):
            return {
                ...state,
                loading: !false
            };


        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loading: false,
                entities: (action.payload.data as BAUserRecordList).records,
                filteredCount: (action.payload.data as BAUserRecordList).listAttributes.filteredCount
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {

        let state: usersBAState = (getState() as IRootState).usersBA;
        let params = state.params;
        const sortBy = getAvailableGridSortById(params.sortBy, usersListColumns);

        let filters:string[] = [];
        if(params.status) filters.push('stateCode;EQ;' +  params.status);
        if(params.baRoles) filters.push('baRoles;EQ;' +  params.baRoles);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.administrationListBAUsers(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                sortBy,
                params.sortOrder,
                params.search,
                params.status,
                params.baRoles,
                {query: {filters: filters}}
            )
        }).then((result) => {
            if(result instanceof Error){
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
                //console.log('====');
                //console.dir(result);
            }else{

            }
        });
    };
};

export const administrationAssignRolesToUser = (intl, businessAccountId: number, id: number, userBARolesUpdateObj: UserBARolesUpdateObj) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_RULES,
            payload:   serverApi.administrationAssignRolesToUser(businessAccountId, id, userBARolesUpdateObj)
        }).then((result) => {
            if(result instanceof Error){
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__ROLES_UPDATE_FAILED));
            }else{
                // TODO Как-то обновлять текущий список!!!
                // TODO Выводить уведомление!!!
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__ROLES_UPDATED) );
                dispatch(loadEntities(intl, businessAccountId));
                setTimeout(()=>dispatch(getBusinessAccount(businessAccountId, true)), 1000);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});
