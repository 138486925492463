import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { InstanceRecord, InstanceStateCodeEnum, serverApi, ServerError, VersionedEntityObj } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';

import { getServerError, getStateFromPath2 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { loadEntity } from '../../products/reducers/product.reducer';
import { ProductPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import {MoneyUtils} from "../../../../../core/utils/moneyUtils";

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'productInstance/LOAD_ENTITIES',
    DELETE_ENTITIES: 'productInstance/DELETE_ENTITIES',
    RESET: 'productInstances/RESET',
    RESET_INSTANCES: 'productInstances/RESET_INSTANCES',
    SET_LOADING: 'productInstance/SET_LOADING',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'lastActivityDate' as string | undefined,
    sortOrder: 'DESC' as 'ASC' | 'DESC' | undefined,
    page: 1,
    limit: 10,
    hideArchive: undefined as string | undefined,
    variantId: undefined as string | undefined,
    stateCode: undefined as InstanceStateCodeEnum | undefined,
    supplierId: undefined as string | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<InstanceRecord> | null,
    filteredCount: 0,
    totalCount: 0,

    params: {
        ...initialParamsState,
    },
};

export type productsInstancesListState = Readonly<typeof initialState>;

// Reducer

export default (state: productsInstancesListState = initialState, action): productsInstancesListState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (/\/\d+\/inventory\/products\/?/.test(pathName) && params.tab === ProductPageTabsEnum.INSTANCES) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : initialParamsState.hideArchive,
                        variantId: params.variantId !== undefined ? params.variantId : initialParamsState.variantId,
                        stateCode: params.stateCode !== undefined ? params.stateCode : initialParamsState.stateCode,
                        supplierId: params.supplierId !== undefined ? params.supplierId : initialParamsState.supplierId,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: action.payload.data.records,
                filteredCount: action.payload.data.listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_INSTANCES:
            return {
                ...state,
                entities: null,
            };

        case ACTION_TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DELETE_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

// Actions

export const getProductInstancesFilters = (params: typeof initialParamsState, subrentModuleEnabled?: boolean): string[] => {
    let filters: string[] = [];
    if (params.variantId !== undefined) filters.push('variantId;EQ;' + params.variantId);

    //let limit = params.limit;
    //let startForm = (params.page - 1) * params.limit;

    if (typeof params.hideArchive === 'string') {
        const decomissionedFilters: string =
            'stateCode;IN;' +
            [
                InstanceStateCodeEnum.ACTIVE,
                InstanceStateCodeEnum.NEW,
                InstanceStateCodeEnum.UNDERMAINTENANCE,
                InstanceStateCodeEnum.SUPPLIERACTIVE,
            ].join(';');

        const foreignFilters: string = 'supplierId;EQ;-1';

        if (params.hideArchive === 'decomissioned') filters.push(decomissionedFilters);
        if (params.hideArchive === 'foreign') filters.push(foreignFilters);
        if (params.hideArchive === 'foreign+decomissioned') filters.push(foreignFilters, decomissionedFilters);
    }

    if (params.stateCode !== undefined) {
        if (
            subrentModuleEnabled ||
            (!subrentModuleEnabled &&
                params.stateCode !== InstanceStateCodeEnum.SUPPLIERACTIVE &&
                params.stateCode !== InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED)
        ) {
            filters.push(`stateCode;IN;${params.stateCode}`);
        }
    }
    if (subrentModuleEnabled && params.supplierId !== undefined) filters.push(`supplierId;EQ;${params.supplierId}`);

    return filters;
};

export const loadEntities = (intl, businessAccountId: number, id: number) => {
    return (dispatch, getState) => {
        let state = getState().productsInstancesList;
        let subrentModuleEnabled = subrentModuleEnabledSelector(getState());

        let params = state.params;

        let sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;
        let sortOrder = params.sortOrder;

        let filters = getProductInstancesFilters(params, subrentModuleEnabled)//: string[] = [];
        //if (params.variantId !== undefined) filters.push('variantId;EQ;' + params.variantId);

        let limit = params.limit;
        let startForm = (params.page - 1) * params.limit;

        // if (typeof params.hideArchive === 'string') {
        //     const decomissionedFilters: string =
        //         'stateCode;IN;' +
        //         [
        //             InstanceStateCodeEnum.ACTIVE,
        //             InstanceStateCodeEnum.NEW,
        //             InstanceStateCodeEnum.UNDERMAINTENANCE,
        //             InstanceStateCodeEnum.SUPPLIERACTIVE,
        //         ].join(';');
        //
        //     const foreignFilters: string = 'supplierId;EQ;-1';
        //
        //     if (params.hideArchive === 'decomissioned') filters.push(decomissionedFilters);
        //     if (params.hideArchive === 'foreign') filters.push(foreignFilters);
        //     if (params.hideArchive === 'foreign+decomissioned') filters.push(foreignFilters, decomissionedFilters);
        // }

        // if (params.stateCode !== undefined) {
        //     if (
        //         subrentModuleEnabled ||
        //         (!subrentModuleEnabled &&
        //             params.stateCode !== InstanceStateCodeEnum.SUPPLIERACTIVE &&
        //             params.stateCode !== InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED)
        //     ) {
        //         filters.push(`stateCode;IN;${params.stateCode}`);
        //     }
        // }
        // if (subrentModuleEnabled && params.supplierId !== undefined) filters.push(`supplierId;EQ;${params.supplierId}`);

        if (params.search) {
            startForm = 0;
        }

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listProductInstances(businessAccountId, id, limit, startForm, filters, sortBy, sortOrder, params.search),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            }
        });
    };
};
export type setLoadingState = Readonly<typeof initialState>;

export const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetInstances = () => ({
    type: ACTION_TYPES.RESET_INSTANCES,
});

export const deleteEntities = (intl, businessAccountId: number, productId: number, instances: VersionedEntityObj[]) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: serverApi.deleteProductInstancesBulk(businessAccountId, productId, { entities: instances }),
        }).then((result) => {
            if (result instanceof Error) {
                // не Удалены
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Удалены
                showNotification('success', instances.length === 1 ? 'Экземпляр удален' : 'Экземпляры удалены');
                dispatch(loadEntities(intl, businessAccountId, productId));
                dispatch(loadEntity(businessAccountId, productId));
            }
        });
    };
};
