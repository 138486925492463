import { ProcessItemFunction } from '../../../components/Calendar/hooks/useCalendarItems';
import { RentActivityFrameRecord, RentActivityFrameTypeCodeEnum } from '../../../../../../server';
import moment from 'moment';

export const processActivityFramesCalendarItem: ProcessItemFunction<RentActivityFrameRecord> = ({
    record,
    items,
    screenLeft,
    screenRight,
    groupIndex,
}) => {
    const startTime = +moment(record.startDate) < screenLeft ? moment(screenLeft) : moment(record.startDate);
    const entTime = +moment(record.finishDate) > screenRight ? moment(screenRight) : moment(record.finishDate);

    items.push({
        elementType: record.rentActivityFrameTypeCode === RentActivityFrameTypeCodeEnum.SUBRENT ? 'activitySubrent' : 'activityProject',
        id: record.id,
        record,
        start_time: startTime,
        end_time: entTime,
        group: groupIndex.current,
        problemsAndWarnings: record.problemsAndWarnings,
    });

    groupIndex.current += 1;
};
