/**
 *
 */

/**
 * Ширина бокового меню в px
 */
export const MENU_WIDTH:number = 240;


/**
 * Ширина свернутого бокового меню в px
 */
export const MENU_COLLAPSED_WIDTH:number = 80;


/**
 * Высота хэдера в px
 */
export const HEADER_HEIGHT:number = 70;


/**
 * Высота футера в px
 */
export const FOOTER_HEIGHT:number = 70;


/**
 * Интервал проверки данных БА в мсек
 */
export const UPDATE_DATA_INTERVAL_MSEC:number = 2 * 1000;

/**
 * Интервал обновления индикаторов БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_INDICATORS_INTERVAL_MSEC:number = .5 * (60 * 1000);


/**
 * Интервал обновления БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_INFO_INTERVAL_MSEC:number = 30 * (60 * 1000);


/**
 * Интервал обновления прав текущего пользователя в мсек
 */
export const GET_PERMISSIONS_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления ценовых схем в мсек
 */
export const GET_PRICING_SCHEMES_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления настроек БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_PREFERENCES_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления контактов БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_CONTACTS_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления данных БА для дашборда в мсек
 */
export const GET_BUSINESS_ACCOUNT_DASHBOARD_DATA_INTERVAL_MSEC:number = 5 * (60 * 1000);

/**
 * Дефолтная локаль
 */
export const DEFAULT_LOCALE:string = 'ru_RU'//'en_US';

/**
 * Задержка до показа подсказок с помощью
 */
export const HELP_TOOLTIPS_SHOW_DELAY_SEC:number = 1;

/**
 * Ширина колонки с проблемами
 */
export const GRID_PROBLEM_COLUMN_WIDTH:number = 58;

/**
 * Ширина колонки с картинками
 */
export const GRID_IMAGE_COLUMN_WIDTH: number = 56;

/**
 * Ширина колонки с действиями
 */
export const GRID_ACTIONS_COLUMN_WIDTH: number = 64;

/**
 * Ширина колонки с действиями
 */
export const GRID_ACTIONS_COLUMN_ITEM_WIDTH: number = 45;

export const getActionsColumnWidth = (columns: number) => {
    return GRID_ACTIONS_COLUMN_ITEM_WIDTH * columns + 12;
}

/**
 * Ширина maxWidth
 */
export const MAX_WIDTH = (minWidth: number): number => {
    return minWidth * 2;
};


export const MAX_DEEP_CATEGORIES: number = 5;

/**
 * Максимальный размер вложения в байтах
 */
export const MAX_ATTACHMENT_FILE_SIZE_LIMIT_IN_BYTES: number = 10 * 1024 * 1024;

/**
 * Лимит на число вложений сущности
 */
export const MAX_ATTACHMENTS_LIMIT_FOR_ENTITY: number = 10;

/**
 * Лимит на единоразовую загрузку.
 */
export const MAX_ATTACHMENTS_LIMIT_FOR_UPLOADING: number = 10;

/**
 * Максимальный размер вложения в байтах
 */
export const MAX_DOCUMENT_TEMPLATE_FILE_SIZE_LIMIT_IN_BYTES: number = 10 * 1024 * 1024;

/**
 * Интервал обновления конкурентных операций
 */
export const GET_CONCURRENT_OPERATIONS_INTERVAL_MSEC: number = 20 * (1000);